import {BrmSessionAccountResponse, BrmSessionResponse} from './responses/brm-session-response.model';
import {BrmSystemSettingsResponse} from './responses/brm-system-settings-response.model';
import {BehaviorSubject, Observable} from 'rxjs';

export class BrmSession {

  public session: BrmSessionResponse;
  private _update: BehaviorSubject<BrmSessionResponse>;

  constructor(session: BrmSessionResponse) {
    this.session = session;
    this._update = new BehaviorSubject<BrmSessionResponse>(this.session);
  }

  public update(data: object): void {
    Object.assign(this.session.account, data);

    // For any component listening for changes
    this._update.next(this.session);
  }

  public get data(): BrmSessionResponse {
    return this.session;
  }

  public get beamerFilter(): string {
    return this.session != null && this.session.beamer_filter != null ? this.session.beamer_filter : null;
  }

  public get dataCentre(): string {
    return this.session != null && this.session.data_center != null ? this.session.data_center : null;
  }

  public get version(): string {
    return this.session != null ? this.session.server_version : null;
  }

  public get account(): BrmSessionAccountResponse {
    return this.session != null && this.session.account != null ? this.session.account : null;
  }

  public get namespace(): string {
    return this.session != null && this.session.shop_namespace != null ? this.session.shop_namespace : null;
  }

  public get email(): string {
    return this.session != null && this.session.user != null && this.session.user.email != null ? this.session.user.email : null;
  }

  public get settings(): BrmSystemSettingsResponse {
    return this.session != null && this.session.shop_settings != null ? this.session.shop_settings : null;
  }

  public get package(): string {
    return this.session != null && this.session.account != null ? this.session.account.product_package : '';
  }

  public get role(): string {
    return this.session != null && this.session.user != null && this.session.user.role != null ? this.session.user.role : '';
  }

  public get shop(): string {
    return this.session != null && this.session.shop_name != null ? this.session.shop_name : '';
  }

  public get userid(): number {
    return this.session != null && this.session.user_id != null ? this.session.user_id : null;
  }

  public get superuser(): boolean {
    return this.session != null && this.session.user != null ? this.session.user.superuser : false;
  }

  public get billingContact(): boolean {
    return this.session != null && this.session.user != null ? this.session.user.billing_contact : false;
  }

  public get admin(): boolean {
    return this.session != null && this.session.user != null ? this.session.user.admin : false;
  }

  public get switchShops(): boolean {
    return this.session != null && this.session.user != null ? this.session.user.switch_shops : false;
  }

  public get primaryContact(): boolean {
    return this.session != null && this.session.user != null ? this.session.user.primary_contact : false;
  }

  public get operations(): boolean {
    return this.session != null && this.session.user != null ? this.session.user.operations : false;
  }

  public get loginCount(): number {
    return this.session != null && this.session.user != null ? this.session.user.login_count : 0;
  }

  public get defaultLanguage(): string {
    return (this.session != null &&
    this.session.shop_settings != null &&
    this.session.shop_settings.shop_language_code != null ? this.session.shop_settings.shop_language_code.toLowerCase() : 'en');
  }

  public get countryCode(): string {
    return this.session != null && this.session.shop_settings != null ? this.session.shop_settings.shop_country : '';
  }

  /**
   * Generates ISO localisation code, defaults to en-gb
   */
  public get localeCode(): string {
    /*let localeStr = '';

    if (this.defaultLanguage != null) {
      localeStr += this.defaultLanguage + '-';
    }

    if (this.countryCode != null) {
      if (this.countryCode.toLowerCase() === 'uk') {
        localeStr += 'gb';
      } else {
        localeStr += this.countryCode;
      }
    } else {
      localeStr += 'gb';
    }

    return localeStr.toLowerCase();*/

    // @ts-ignore
    const userLang = navigator.language || navigator.userLanguage;

    return userLang !== null ? userLang : 'en-GB';
  }

  public sessionUpdates(): Observable<BrmSessionResponse> {
    return this._update.asObservable();
  }

}
