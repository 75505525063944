import {Injectable} from '@angular/core';
import {BrmLocation} from '../../api/settings/brm-location';
import {BehaviorSubject, Observable, Subject, Subscription} from 'rxjs';
import {tap} from 'rxjs/operators';
import {BrmCacheService} from '../../brm-cache.service';
import {BrmSessionService} from '../../brm-session.service';
import {BrmSession} from '../../api/session/brm-session';
import {GeneralUtil} from '../../../util';

@Injectable({
  providedIn: 'root'
})
export class BrmLocationService {

  public restrictedAllList: string[] = [];

  public restrictedUser: boolean = true;

  public selectedLocations: string[] = [];
  public selectedBrmLocations: BrmLocation[] = [];
  public locationsList: BrmLocation[] = [];

  public loaded: boolean = false;

  private sessionSubscription: Subscription;

  public locationChange$: BehaviorSubject<string[]> = new BehaviorSubject<string[]>([]);

  constructor(private cache: BrmCacheService, private brmSession: BrmSessionService) {
    this.listenForSession();
  }

  private listenForSession(): void {
    this.sessionSubscription = this.brmSession.session$.subscribe(
      (session: BrmSession) => {
        this.restrictedAllList = this.getRestrictedLocations();
        this.restrictedUser = this.isARestrictedUser();

        // If the user is restricted and only has 1 location, we default to that location
        if (this.restrictedUser && this.restrictedAllList.length === 1) {
          this.setSelectedLocation(this.restrictedAllList[0]);
        }
      }
    );
  }

  /**
   * Returns a list of locations the user is locked too
   */
  private getRestrictedLocations(): string[] {
    return (this.brmSession.session.user.locations || []);
  }

  /***
   * Checks if the user is locked down to only using a certain number of locations
   */
  isARestrictedUser(): boolean {
    if (this.brmSession.session.user.operations) {
      return false;
    } else {
      return this.getRestrictedLocations().length > 0;
    }

    return true;
  }

  public getLocationsData(): Observable<BrmLocation[]> {
    return this.cache.multiLocationCache.getData().pipe(
      tap({
        next: (value: BrmLocation[]) => {
          // We've loaded all locations relevant for this store, if they're a restricted user, we need to have a list of only locations
          // for the user
          if (this.restrictedAllList.length > 0 && this.restrictedUser) {
            this.locationsList = value.filter((location: BrmLocation) => {
              return this.isInRestrictedList(location.name);
            });
          } else {
            // If they're not restricted, we display them all
            this.locationsList = value;
          }

          // Reset the current active selected locations, so that we update the BrmLocation values, don't re-emit don't need to do so
          this.setSelectedLocations(this.selectedLocations, false);

          this.loaded = true;
        }
      })
    );
  }

  setLocationsToAll(): void {
    this.setSelectedLocations([]);
  }

  setSelectedLocation(location: string, emit: boolean = true): void {
    this.setSelectedLocations([location]);
  }

  setSelectedLocations(locations: string[], emit: boolean = true): void {
    this.selectedLocations = locations;
    this.selectedBrmLocations = this.getBrmLocationsFromNames(locations);

    if (emit) {
      this.emitLocations();
    }
  }

  emitLocations(): void {
    this.locationChange$.next(this.getSelectedLocations());
  }

  getSelectedLocations(): string[] {
    if (this.restrictedUser && this.selectedLocations.length === 0) {
      // ALL RESTRICTED LOCATIONS
      return this.restrictedAllList;
    }

    return this.selectedLocations;
  }

  getSelectedBrmLocations(): BrmLocation[] {
    return this.getBrmLocationsFromNames(this.getSelectedLocations());
  }

  getBrmLocationsFromNames(names: string[]): BrmLocation[] {
    const results: BrmLocation[] = [];

    names.forEach((locationName: string) => {
      const locationNameLower = locationName.toLowerCase();

      const findBrmLocation = this.locationsList.find((brmLocation: BrmLocation) => {
        const brmLocationNameLower = (brmLocation.name || '').toLowerCase();

        return locationNameLower === brmLocationNameLower;
      });

      if (findBrmLocation != null) {
        results.push(findBrmLocation);
      }
    });

    return results;
  }

  private isInRestrictedList(name: string): boolean {
    const nameToLower = (name || '').toLowerCase();

    // If name provided is an empty string, return false
    if (!GeneralUtil.isNonEmptyString(nameToLower)) {
      return false;
    }

    const find = this.restrictedAllList.find((location: string) => {
      const locationToLower = (location || '').toLowerCase();

      return locationToLower !== '' && locationToLower === nameToLower;
    });

    return find != null;
  }
}
