import {Injectable} from '@angular/core';
import {BrmSessionService} from '../../brm-session.service';
import {BehaviorSubject, Observable} from 'rxjs';
import {BrmSession} from '../../api/session/brm-session';
import {BrmDateAndTimeFormats} from '../../data/brm-date-and-time-formats';

@Injectable({
  providedIn: 'root'
})
export class BrmDatesAndTimeService {

  private _timeFormatId: string = 'UK';
  private _dateFormatId: string = 'UK';
  private _country: string;

  private timeFormatSubject: BehaviorSubject<string> = new BehaviorSubject<string>(this._timeFormatId);
  public timeFormat$: Observable<string> = this.timeFormatSubject.asObservable();

  private dateFormatSubject: BehaviorSubject<string> = new BehaviorSubject<string>(this._dateFormatId);
  public dateFormat$: Observable<string> = this.dateFormatSubject.asObservable();

  constructor(private brmSession: BrmSessionService) {
    this.listenForSessionChange();
  }

  private listenForSessionChange(): void {
    this.brmSession.session$.subscribe(
      (session: BrmSession) => {
        this.setDateFormat(session.shop_settings.shop_date_format);
        this.setTimeFormat(session.shop_settings.shop_time_format);
        this.setCountry(session.shop_settings.shop_country);
      },
      (error: any) => {

      },
      () => {

      }
    );
  }

  public get timeFormatId(): string {
    return this._timeFormatId;
  }

  public get dateFormatId(): string {
    return this._dateFormatId;
  }

  private setTimeFormat(format: string): void {
    format = format || '';

    if (format.trim() === '') {
      format = 'UK';
    }

    this._timeFormatId = (format || 'UK').toUpperCase();
    this.timeFormatSubject.next(format);
  }

  private setDateFormat(format: string): void {
    format = format || '';

    if (format.trim() === '') {
      format = 'UK';
    }

    this._dateFormatId = (format || 'UK').toUpperCase();
    this.dateFormatSubject.next(format);
    // (<BrmDatepickerAdapter>this.dateAdapter).setFormat(BrmDateAndTimeFormats.DATE_FORMAT_OBJ[format]);
  }

  public getTimeHint(format: string): string {
    format = format || '';

    if (format.trim() === '') {
      format = 'UK';
    }

    return BrmDateAndTimeFormats.TIME_LUXON_FORMAT_HINT_OBJ[format];
  }

  private setCountry(country: string): void {
    this._country = (country || '').trim().toUpperCase();
  }

  public firstDayOfWeek(): number {
    const startDay = this.brmSession.session.shop_settings.shop_calendar_start_day_of_week;

    if (startDay != null) {
      return BrmDateAndTimeFormats.DAYS_OF_WEEK.indexOf(startDay);
    }

    if (this.brmSession.session.data_center === 'us') {
      // If in US default to sunday if no date set
      return 1;
    }

    return 0;
  }
}
