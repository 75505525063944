import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {RestRequestsService} from '../../../core/services/rest-requests.service';
import {AppControlService} from '../../../core/services/app-control.service';
import {BackgroundRequestsService} from '../../../core/services/background-requests.service';
import {shareReplay, tap} from 'rxjs/operators';
import {BrmItemServiceHistory, BrmItemServicing} from '../../../core/brm2/api/inventory/items/brm-item-service-history';

@Injectable({
  providedIn: 'root'
})
export class ServiceHistoryService {

  public serviceEntryLoader$: Observable<BrmItemServiceHistory[] | BrmItemServiceHistory>;
  public serviceEntrySaver$: Observable<BrmItemServiceHistory>;
  public serviceEntries: BrmItemServiceHistory[] = [];

  constructor(private rest: RestRequestsService, private appControl: AppControlService, private background: BackgroundRequestsService) {
  }

  public loadServiceEntries(itemId: number): Observable<BrmItemServiceHistory[] | BrmItemServiceHistory> {
    const apiUrl = this.appControl.apiUrl + '/servicing/items/' + itemId;

    return this.serviceEntryLoader$ = this.rest.getRequest(apiUrl, {}).pipe(
      tap((response: BrmItemServiceHistory[]) => this.addServiceEntries(response, true)),
      shareReplay(1)
    );
  }

  public loadServiceEntry(id: number, itemId: number): Observable<BrmItemServiceHistory> {
    const apiUrl = this.appControl.apiUrl + '/servicing/items/' + itemId + '/' + id;

    return this.serviceEntryLoader$ = this.rest.getRequest(apiUrl, {}).pipe(
      tap((response: BrmItemServiceHistory) => this.addServiceEntries(response))
    );
  }

  public saveServiceEntry(data: BrmItemServiceHistory, id?: number, itemId?: number): Observable<BrmItemServicing> {
    let apiUrl = this.appControl.apiUrl + '/servicing/items/' + itemId;

    if (id != null) {
      apiUrl += '/' + id;
    }

    data['make_operational'] = true;

    return this.rest.postRequest(apiUrl, JSON.stringify(data)).pipe(
      tap((response: BrmItemServicing) => {
        this.addServiceEntries(response.service_history);
      })
    );
  }

  public addServiceEntries(serviceEntries: BrmItemServiceHistory | BrmItemServiceHistory[], reload?: boolean): void {
    if (!Array.isArray(serviceEntries)) {
      // Convert to an array if it isn't already
      serviceEntries = [serviceEntries];
    }

    if (reload) {
      this.serviceEntries = serviceEntries;
    } else {
      const additionalEntries: BrmItemServiceHistory[] = [];

      serviceEntries.forEach((entry: BrmItemServiceHistory) => {
        const alreadyExists = this.getServiceEntry(entry.id);

        if (alreadyExists) {
          Object.assign(alreadyExists, entry);
        } else {
          additionalEntries.push(entry);
        }
      });

      this.serviceEntries = this.serviceEntries.concat(additionalEntries);
    }
  }

  public deleteServiceEntry(entry: BrmItemServiceHistory, itemId: number): void {
    const deleteObs = this.rest.deleteRequest(this.appControl.apiUrl + '/servicing/item/' + itemId + '/' + entry.id, {});

    this.background.doRequest('delete-service-entry-' + entry.id, deleteObs, 'Deleted ' + entry.id + ' service entry',
      null,
      (response) => {
        this.removeServiceEntry(entry);
      });
  }

  public removeServiceEntry(entry: BrmItemServiceHistory): void {
    const position = this.serviceEntries.indexOf(entry);

    if (position >= 0) {
      this.serviceEntries.splice(position, 1);
    }
  }

  public getServiceEntry(id: number): BrmItemServiceHistory {
    if (this.serviceEntries == null) {
      return null;
    }

    const found: BrmItemServiceHistory = this.serviceEntries.find((sh: BrmItemServiceHistory) => {
      return sh.id === id;
    });

    return found;
  }

  public isServiceEntryLoaded(id: number): boolean {
    return this.getServiceEntry(id) != null;
  }

}
