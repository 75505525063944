import { Component, OnDestroy, OnInit } from '@angular/core';
import { FirebaseAuthService } from '../../../core/services/firebase-auth.service';
import { Subscription } from 'rxjs';
import { Router } from '@angular/router';
import { NotificationsService } from '../../../core/services/notifications/notifications.service';
import { AngularFireAuth } from '@angular/fire/compat/auth';


@Component({
  selector: 'app-verify',
  templateUrl: './verify.component.html',
  styleUrls: ['./verify.component.scss', '../login.component.scss']
})
export class VerifyComponent implements OnInit, OnDestroy {

  private userStateSub: Subscription;
  private userInterval;


  public userStateLoaded: boolean;

  public isResending: boolean;
  public lastResend: number;
  public resendCoolDown = 60;

  public isVerifying: boolean;
  public lastVerifyCheck: number;
  public verifyCoolDown = 20;

  private cooldownInterval;

  constructor(private fbAuth: FirebaseAuthService, private afAuth: AngularFireAuth, private router: Router,
    private notify: NotificationsService) {
    this.userStateLoaded = false;
    this.isResending = false;
  }

  async ngOnInit() {
    if (this.fbAuth.getUser() == null) {
      this.router.navigate(['/', 'login']);
    } else {
      // Copied from splash
      // Checks that the user has logged in.
      this.userStateSub = this.fbAuth.loginState.subscribe(value => {
        // By default the loginState will emit null at first, we need ignore this initial value
        // this is what hasInitialCheckComplete does, it lets you know when Firebase's login status has been done
        // for the first time
        if (value == null) {
          // null indicates non-logged in user, therefore redirect to login
          this.router.navigate(['/', 'login']);
        } else {
          // if the user is logged in, we need to check their status + setup interval to check status
          this.waitForVerification();
        }
      });
    }
  }

  ngOnDestroy(): void {
    clearInterval(this.userInterval);
    clearInterval(this.cooldownInterval);
  }

  /**
   * Angular will stop checking the status of a function in the template after a couple seconds since the last
   * action therefore, we set an interval every second to trigger the button change state
   */
  private setupCooldownInterval() {
    // clear interval if it already exists
    clearInterval(this.cooldownInterval);

    // set interval for every 1s
    this.cooldownInterval = setInterval(() => {
      this.resendCoolDownRemaining();
      this.verifyCoolDownRemaining();
    }, 500);
  }

  /***
   * Does the initial verification check and setups the interval for checking every 60 seconds if the user
   * has verified their email
   */
  public waitForVerification(): void {
    if (this.fbAuth.getUser().emailVerified) {
      // If they're verified, we can redirect them to load which will trigger the setup procedure
      this.router.navigate(['/', 'load']).then();
    } else {
      // They're not verified, therefore we automatically check every 60 seconds for an update on this status
      this.userInterval = setInterval(() => {
        // call verification check
        this.checkForVerification();
      }, 60000);

      // Change the screen from purely a loaderSubscription to the verification buttons etc, controlled by this variable
      this.userStateLoaded = true;

      // Do initial check of the user's verification status
      this.checkForVerification();

      // setup cooldown interval
      this.setupCooldownInterval();
    }
  }

  /***
   * Handles checking the user's email verification status
   * Called by an interval every 60 seconds and can be triggered by the user
   * every 20 seconds.
   * @param userCheck - Whether or not the function was triggered by a user or the app
   */
  public checkForVerification(userCheck: boolean = false): void {
    const timeNow = new Date().getTime();

    // We only prevent the user from spamming the verification process
    // If it is pressed too many times, Firebase will eventually time the user out
    if ((this.lastVerifyCheck == null || (timeNow - this.lastVerifyCheck) > 20000) || !userCheck) {

      if (userCheck) {
        // Store the last time the user attempted to verify their account
        this.lastVerifyCheck = new Date().getTime();
        // Set boolean, for button disabling
        this.isVerifying = true;
      }

      // Reload user's data from Firebase to see if they've verified themselves
      this.fbAuth.getUser().reload().then(
        fullfilled => {
          // If verified, go back to the load sequence
          if (this.fbAuth.getUser() != null && this.fbAuth.getUser().emailVerified) {
            clearInterval(this.userInterval);
            this.router.navigate(['/', 'load']).then();
          } else {
            if (userCheck) {
              // Alert the user that they're not verified if they've requested a check
              this.notify.addWarningNotification('Email not verified');
            }
          }
        }
      ).catch(error => {
        // On fail, alert the user to reload the page. If they've verified, it'll be caught on reload
        this.notify.addFailNotification('Failed to check verification status, please try reloading');
        console.log('Verification load error below');
        console.error(error);
      }).finally(() => {
        // Called when the promise finishes - only if it's a user triggered check. We need to re-enable the button.
        if (userCheck) {
          this.isVerifying = false;
        }
      });
    } else {
      const waitRemaining = 20 - Math.ceil((timeNow - this.lastVerifyCheck) / 1000);
      this.notify.addWarningNotification('Please wait ' + waitRemaining + 's before checking verified status.');
    }
  }

  logout(): void {
    this.fbAuth.logout().then(
      fullfilled => {
        this.router.navigate(['/', 'login']).then();
      }
    );
  }

  /**
   * If for some reason the user doesn't receive the email, they can request a new email is sent to them.
   * This function limits it to every 60 seconds.
   */
  resendEmail(): void {
    // Calculates the remaining time after the last resend request
    const cooldownRemaining = this.resendCoolDownRemaining();

    // Check the user is logged in via Firebase, they're not currently requesting a new email and that they haven't requested one
    // within the last minute
    if (this.fbAuth.getUser() != null && !this.isResending && (this.lastResend == null || cooldownRemaining === 0)) {
      // Set that we're waiting for a resend request, disables the resend button
      this.isResending = true;
      // Record the last time it was requested
      this.lastResend = new Date().getTime();

      // Send firebase request for new email
      this.fbAuth.getUser().sendEmailVerification().then(
        fullfilled => {
          // Fullfilled, no longer waiting therefore enable the resend button
          this.isResending = false;
        }
      ).catch(error => {
        // On error, enable the button
        this.isResending = false;
        // Log error
        console.log('Error sending email');
        console.error(error);
      });
    } else if (this.lastResend != null && cooldownRemaining > 0) {
      // Called when the user tries to request another email shortly after requesting another

      // Warn user
      this.notify.addWarningNotification('Please allow ' + cooldownRemaining + ' seconds before requesting a new verification email');
    }
  }

  /**
   * When the user clicks to verify their account, we need to stop them from spamming it therefore we set it on a "cooldown"
   * Firebase will block users temporarily if they spam it
   * This function returns the time remaining before it enables the button again
   */
  public verifyCoolDownRemaining(): number {
    // Get the current time and calculate the difference between now and the last time they attempted to verify
    const timeNow = new Date().getTime();
    let timeDif = this.lastVerifyCheck == null ? 0 : timeNow - this.lastVerifyCheck;

    // If they haven't verified before, or the time different is greater than our set cooldown, we return 0
    // indicating the button should be enabled
    if (this.lastVerifyCheck == null || timeDif >= this.verifyCoolDown * 1000) {
      return 0;
    } else {
      // Calculate the time remaining and return it
      timeDif = this.verifyCoolDown - Math.ceil(timeDif / 1000);
      return timeDif;
    }
  }

  /**
   * When the user clicks to resend their verification email, we need to stop them from spamming it therefore we set it on a "cooldown"
   * Firebase will block users temporarily if they spam it
   * This function returns the time remaining before it enables the button again
   */
  public resendCoolDownRemaining(): number {
    // Get the current time and calculate the difference between now and the last time they attempted to resend verification
    const timeNow = new Date().getTime();
    let timeDif = this.lastResend == null ? 0 : timeNow - this.lastResend;

    // If they haven't verified before, or the time different is greater than our set cooldown, we return 0
    // indicating the button should be enabled
    if (this.lastResend == null || timeDif >= this.resendCoolDown * 1000) {
      return 0;
    } else {
      // Calculate the time remaining and return it
      timeDif = this.resendCoolDown - Math.ceil(timeDif / 1000);
      return timeDif;
    }
  }

}
