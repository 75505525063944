export class BrmReservationErrors {
  public static RESERVATION_ASSET_OVERLAP = 'avl002';
  public static RESERVATION_UNEXPECTED_DATES = 'res003';

  public static RESERVATION_ITEM_NOT_AVAILABLE = 'avl001';
  public static RESERVATION_ADD_ITEM_NOT_FOUND = 'ass000';
  public static RESERVATION_SWAP_ITEM_BRIKEB = 'ass010';

  public static RESERVATION_ITEM_MUST_BE_PACKAGE = 'res033';
  public static RESERVATION_ITEM_CANNOT_BE_PACKAGE = 'res034';
}


