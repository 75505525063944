import {BrmCache} from './brm-cache';
import {Observable} from 'rxjs';
import {map, tap} from 'rxjs/operators';
import {RestRequestsService} from '../../services/rest-requests.service';
import {AppControlService} from '../../services/app-control.service';
import {BrmPaymentMethods} from '../api/settings/brm-payment-methods';

export class BrmPaymentMethodsCache extends BrmCache<BrmPaymentMethods> {

  private rest: RestRequestsService;
  private appControl: AppControlService;

  constructor(_caches, _appControl, _rest) {
    super(_caches, 'paymentmethods');

    this.appControl = _appControl;
    this.rest = _rest;
  }

  protected loadData(): Observable<BrmPaymentMethods> {
    return this.rest.getRequest(this.appControl.apiUrl + '/paymentmethods', {}).pipe(
      map((value: BrmPaymentMethods) => {
        return value;
      })
    );
  }

  public isMethodEnabled(name: string): boolean {
    if (this.hasDataLoaded()) {
      return this.cachedData.payment_methods.indexOf(name) >= 0;
    }

    return null;
  }

  public updatePaymentMethods(data: object): Observable<BrmPaymentMethods> {
    return this.rest.postRequest(`${this.appControl.apiUrl}/paymentmethods`, JSON.stringify(data)).pipe(
      map((value: BrmPaymentMethods) => {
        return value;
      }),
      tap({
        next: value => {
          this.cachedData = value;
        }
      })
    );
  }

  protected onLoad(): void {
  }
}
