import {Injectable} from '@angular/core';
import {MatIconRegistry} from '@angular/material/icon';
import {DomSanitizer} from '@angular/platform-browser';


@Injectable({
  providedIn: 'root'
})


export class IconService {

  constructor(private matIconRegistry: MatIconRegistry,
              private sanitizer: DomSanitizer) {
  }
  
  registerIcon(iconName: string) {
    this.matIconRegistry.addSvgIcon(
      iconName,
      this.sanitizer.bypassSecurityTrustResourceUrl(`./assets/svgs/${iconName}.svg`)
    );
  }
}
