import {AfterViewInit, Directive, ElementRef, OnDestroy} from '@angular/core';
import {of, Subject, timer} from 'rxjs';
import {debounceTime, delay, delayWhen, switchMap, takeUntil, tap} from 'rxjs/operators';

@Directive({
  selector: '[brmLanguageHighlight]'
})
export class BrmLanguageHighlightDirective implements AfterViewInit, OnDestroy {

  private animation: Subject<number> = new Subject<number>();
  private destroyed$: Subject<boolean> = new Subject<boolean>();

  private animating: boolean = false;

  readonly animationDuration: number = 4000;

  constructor(private element: ElementRef) {
    this.initialiseAnimationSubscription();
  }

  private initialiseAnimationSubscription(): void {
    this.animation.pipe(
      takeUntil(this.destroyed$),
      switchMap(value => {
        if (this.animating) {
          this.element.nativeElement.classList.remove('brm-language-field--highlight');
          return timer(50);
        }

        return of(value);
      }),
      switchMap(value => {
        this.animating = true;
        this.element.nativeElement.classList.add('brm-language-field--highlight');

        return timer(this.animationDuration);
      }),
      tap((value) => {
        this.animating = false;
        this.element.nativeElement.classList.remove('brm-language-field--highlight');
      })
    ).subscribe();
  }

  ngOnDestroy(): void {
    this.destroyed$.next(true);
    this.destroyed$.complete();
  }

  ngAfterViewInit(): void {
    this.element.nativeElement.classList.add('brm-language-field');
  }

  public doHighlight(): void {
    this.animation.next(new Date().getTime());
  }

}
