import {Injectable} from '@angular/core';
import {CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, Router} from '@angular/router';
import {Observable} from 'rxjs';
import {AppControlService} from '../services/app-control.service';
import {BrmSettingsService} from '../services/brm/brm-settings.service';
import {BrmLightspeedService} from '../brm2/services/lightspeed/brm-lightspeed.service';

@Injectable({
  providedIn: 'root'
})
export class InitialLoadGuard implements CanActivate {

  private static firstLoaded: boolean = true;

  /***
   * Initial load guard protects the rest of the application loading before
   * @param control - app control service, app-wide data
   * @param router - router instance used by the app for navigation
   */
  constructor(private brmLightspeed: BrmLightspeedService, private control: AppControlService, private brm: BrmSettingsService, private router: Router) {
  }


  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {


    if (InitialLoadGuard.firstLoaded) {
      InitialLoadGuard.firstLoaded = false;
      // If we're loading the app for the first time, we need to disable routes which need additional initial load
      // functionality conducted first

      if (next.queryParams.hasOwnProperty('disableLHSNav')) {
        this.control.hideNavigationSetting = next.queryParams['disableLHSNav'];
      }

      // Store the migration token, if provided
      if (next.queryParams.hasOwnProperty('migrationToken')) {
        this.brm.migrationToken = next.queryParams['migrationToken'];
      }

      if (next.queryParams['token']) {
        this.brm.firebaseToken = next.queryParams['token'];
      }

      // If the URL contains forceLogin, we store this value so the load up sequence / Splash can force the user to login
      if (next.queryParams.hasOwnProperty('forceLogin')) {
        this.control.forceLogin = next.queryParams['forceLogin'] === 'true';
      }

      // If the URL contains a redirect count, we need to make this accessible later
      if (next.queryParams.hasOwnProperty('redirectCount')) {
        this.control.redirectCount = Number(next.queryParams['redirectCount'] || 0);
      }

      // Store URL parameters
      this.control.redirectUrlParams = next.queryParams;

      // Store the location the user was going too,
      // split by '?' to remove url params
      this.control.redirectUrl = decodeURIComponent(state.url.split('?')[0]);

      const url = this.control.redirectUrl.toLowerCase();

      if (url.indexOf('/load') === 0 || url.indexOf('/login') === 0) {
        this.control.redirectUrl = '/gettingstarted';

        return true;
      }

      if (this.brmLightspeed.hasLightspeedDataInUrlParameters(next.queryParams)) {
        this.brmLightspeed.storeLightspeedData(next.queryParams);

        this.control.redirectUrl = '/reservations';
        this.control.redirectUrlParams = {mode: 'create'};
      }


      // Redirect the user to the load page
      this.router.navigate(['/', 'load']);

      return false;
    }

    // If the initial load functionality has been completed, allow the user access to the route
    return true;
  }
}
