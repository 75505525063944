export class BrmUserRoles {
  public static BASIC: string = 'basic';
  public static STAFF: string = 'staff';
  public static MECHANIC: string = 'mechanic';
  public static SHOP_MANAGER: string = 'shop_manager';

  public static ROLES: string[] = [
    BrmUserRoles.BASIC, BrmUserRoles.STAFF, BrmUserRoles.SHOP_MANAGER, BrmUserRoles.MECHANIC,
  ];
}
