import {Component, Inject, OnInit} from '@angular/core';
import {LoadingDialogData} from './loading-dialog-data';
import {Observable, of} from 'rxjs';
import {catchError, tap} from 'rxjs/operators';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';

@Component({
  selector: 'app-loading-dialog',
  templateUrl: './loading-dialog.component.html',
  styleUrls: ['./loading-dialog.component.scss']
})
export class LoadingDialogComponent implements OnInit {

  public loader$: Observable<any>;

  private loaderError: boolean = false;

  constructor(public dialogRef: MatDialogRef<LoadingDialogComponent>,
              @Inject(MAT_DIALOG_DATA) public data: LoadingDialogData) {
    this.dialogRef.disableClose = true;
  }

  ngOnInit() {
    this.listenToObservable();
  }

  private listenToObservable(): void {
    this.loader$ = this.data.observable.pipe(
      tap({
        next: value => {
        },
        error: error => {
          this.loaderError = true;
          this.dialogRef.close(false);
        },
        complete: () => {
          this.dialogRef.close(!this.loaderError);
        }
      })
    );
  }

}
