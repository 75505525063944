import {
  AfterContentInit,
  AfterViewChecked,
  AfterViewInit,
  Component, ContentChild,
  ContentChildren,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
  QueryList, TemplateRef,
  ViewContainerRef
} from '@angular/core';
import {BrmLanguagesService} from '../../brm-languages.service';
import {Subscription} from 'rxjs';
import {BrmLanguageHighlightDirective} from '../../directives/brm-language-highlight.directive';
import {UntypedFormGroup, ValidatorFn} from '@angular/forms';
import {takeUntil} from 'rxjs/operators';
import {GeneralUtil} from '../../../../../util';

@Component({
  selector: 'brm-language-field',
  template: ``,
  styleUrls: ['./brm-language-field.component.scss']
})
export class BrmLanguageFieldComponent implements OnInit, OnDestroy, AfterContentInit, AfterViewChecked {

  // We use the BrmLanguageHighlightDirective in order to trigger a field highlight when the language is changed
  @ContentChildren(BrmLanguageHighlightDirective) highlightFields: QueryList<BrmLanguageHighlightDirective>;

  @Input() fieldName: string;
  @Input() formGroup: UntypedFormGroup;
  @Input() data: object;
  @Input() createControls: boolean = true;
  @Input() validators: ValidatorFn[];
  @Input() defaultLanguageValidators: ValidatorFn[];

  // Need to pass the component a template, which is the template of the layout of the field
  // @ContentChild(TemplateRef)
  @Input() template: TemplateRef<any>;

  @Output('initialised') _initialised: EventEmitter<boolean> = new EventEmitter();

  currentLanguage: string = '';


  public initialised: boolean = false;

  private statusChangeSubscription: Subscription;
  private languageSubscription: Subscription;
  public languageSelected: boolean = false;
  public highlight: boolean = false;

  private previousViewLanguage: string;

  constructor(public brmLanguage: BrmLanguagesService, private _viewContainer: ViewContainerRef) {
    this._viewContainer.clear();
  }

  ngOnInit() {
    if (this.createControls) {
      if (this.fieldName && this.formGroup) {
        this.autoCreateFormControls();
      } else {
        throw new TypeError('Must provide fieldName and formGroup for brm-language-field to auto generate controls');
      }
    }

    this.handleLanguage();
    this.handleFormGroup();
  }

  ngOnDestroy(): void {
    if (this.languageSubscription) {
      this.languageSubscription.unsubscribe();
    }

    // this.statusChangeSubscription.unsubscribe();
  }

  ngAfterViewChecked(): void {
    if (this.highlight) {
      this.highlight = false;

      this.highlightFields.forEach((field: BrmLanguageHighlightDirective) => {
        field.doHighlight();
      });
    }
  }

  ngAfterContentInit(): void {

  }

  private handleFormGroup(): void {
    this.formGroup.valueChanges.subscribe(
      (valueChange) => {
        this.updateView(true);
      }
    );
  }

  private getFieldValue(language: string): string {
    let fieldValue = null;
    const defaultLanguage = this.brmLanguage.getDefaultLanguage();

    if (this.data && this.data.hasOwnProperty('language_config')) {
      if (this.data['language_config'].hasOwnProperty(language) && this.data['language_config'][language].hasOwnProperty(this.fieldName)) {
        const valueInLang = this.data['language_config'][language][this.fieldName] || '';

        if (valueInLang.trim().length > 0) {
          fieldValue = valueInLang;
        } else {
          fieldValue = null;
        }
      }

      if (fieldValue == null && this.data['language_config'].hasOwnProperty(defaultLanguage) && this.data['language_config'][defaultLanguage].hasOwnProperty(this.fieldName)) {
        const valueInLang = this.data['language_config'][defaultLanguage][this.fieldName] || '';

        if (valueInLang.trim().length > 0) {
          fieldValue = valueInLang;
        } else {
          fieldValue = null;
        }
      }
    }

    if (fieldValue == null && this.data) {
      if (this.data.hasOwnProperty(this.fieldName)) {
        fieldValue = this.data[this.fieldName] || '';
      }
    }

    return fieldValue;
  }

  private handleLanguage(): void {
    this.languageSubscription = this.brmLanguage.language$.subscribe(
      (next: string) => {
        // If the value changes, highlight
        if (this.currentLanguage !== next && this.initialised) {
          this.highlight = true;
        }

        this.currentLanguage = next;
        this.initialised = true;

        if (this.currentLanguage != null) {
          this.updateView();
        }

      }
    );
  }

  private updateView(triggeredByValueUpdate: boolean = false): void {
    if (this._viewContainer && this.template && this.previousViewLanguage !== this.currentLanguage) {
      this._viewContainer.clear();
      this._viewContainer.createEmbeddedView(this.template, {lang: this.currentLanguage});
    }

    /*
      Check current language
        If not default language, check content
          If blank, we need to set to the default language value
        If default language
          Do nothing

        If switching languages
          If previous language is not default language
            Set to blank
     */

    if (this.currentLanguage !== this.brmLanguage.getDefaultLanguage()) {
      const currentLanguageControl = (<UntypedFormGroup>this.formGroup.controls[this.currentLanguage]).controls[this.fieldName];
      const currentLanguageValue = currentLanguageControl.value;
      const defaultLanguageValue = (<UntypedFormGroup>this.formGroup.controls[this.brmLanguage.getDefaultLanguage()]).controls[this.fieldName].value;

      if (!GeneralUtil.isNonEmptyString(currentLanguageValue) && !triggeredByValueUpdate) {
        currentLanguageControl.setValue(defaultLanguageValue);
      }
    }

    if (this.previousViewLanguage != null && this.previousViewLanguage !== this.currentLanguage) {
      const defaultLanguageValue = (<UntypedFormGroup>this.formGroup.controls[this.brmLanguage.getDefaultLanguage()]).controls[this.fieldName].value;
      const previousLanguageControl = (<UntypedFormGroup>this.formGroup.controls[this.previousViewLanguage]).controls[this.fieldName];
      const previousLanguageValue = previousLanguageControl.value;

      if (this.previousViewLanguage !== this.brmLanguage.getDefaultLanguage()) {
        if (previousLanguageValue === defaultLanguageValue && previousLanguageValue != null) {
          previousLanguageControl.setValue(null);
        }
      }
    }

    this.previousViewLanguage = this.currentLanguage;

    /*if (this.previousViewLanguage != null && this.previousViewLanguage !== this.brmLanguage.getDefaultLanguage()) {
      // If not the default language we need to handle the content
      const languageControl = (<FormGroup>this.formGroup.controls[this.previousViewLanguage]);
      const languageValue = languageControl.controls[this.fieldName].value;
      const defaultLanguageControl = (<FormGroup>this.formGroup.controls[this.brmLanguage.getDefaultLanguage()]);
      let defaultLanguageValue = defaultLanguageControl.controls[this.fieldName].value;

      /!*if (this.data.hasOwnProperty('language_config') && this.data['language_config'].hasOwnProperty(this.brmLanguage.getDefaultLanguage())) {
        defaultLanguageValue = this.data['language_config'][this.brmLanguage.getDefaultLanguage()][this.fieldName];
      }*!/

      if (GeneralUtil.isNonEmptyString(languageValue) && languageValue === defaultLanguageValue) {
        // If it still matches the default language, then we need to update it's value
        languageControl.controls[this.fieldName].setValue(null);
      } else if (!GeneralUtil.isNonEmptyString(languageValue)) {
        console.log('Set to default:', defaultLanguageValue);
        languageControl.controls[this.fieldName].setValue(defaultLanguageValue);
      }
    }*/


  }

  private autoCreateFormControls(): void {
    this.brmLanguage.createFormControlForField(this.formGroup, this.fieldName, this.data, this.validators, this.defaultLanguageValidators);
  }

}
