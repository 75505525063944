import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {ImageEditorScalerComponent} from './image-editor-scaler.component';
import {ReactiveFormsModule} from '@angular/forms';
import {MediaLibraryModule} from '../media-libary/media-library.module';
import {MatDialogModule} from '@angular/material/dialog';
import {MatSliderModule} from '@angular/material/slider';
import {MatInputModule} from '@angular/material/input';
import {MatProgressSpinnerModule} from '@angular/material/progress-spinner';
import {MatFormFieldModule} from '@angular/material/form-field';
import {MatButtonModule} from '@angular/material/button';
import {MatIconModule} from '@angular/material/icon';
import {MatMenuModule} from '@angular/material/menu';

@NgModule({
  declarations: [ImageEditorScalerComponent],
  imports: [
    CommonModule,
    MatIconModule,
    MatButtonModule,
    MatFormFieldModule,
    MatProgressSpinnerModule,
    MatInputModule,
    MatSliderModule,
    MatDialogModule,
    MatMenuModule,
    MediaLibraryModule,
    ReactiveFormsModule
  ],
  exports: [
    ImageEditorScalerComponent
  ]
})
export class ImageEditorScalerModule {
}
