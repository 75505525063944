import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {ReactiveFormsModule} from '@angular/forms';
import {RouterModule} from '@angular/router';
import {FailLoginComponent} from './fail-login.component';
import {FailLoginRoutingModule} from './fail-login.routing.module';
import {MatIconModule} from '@angular/material/icon';
import {MatTooltipModule} from '@angular/material/tooltip';


@NgModule({
  declarations: [
    FailLoginComponent
  ],
  imports: [
    CommonModule,
    ReactiveFormsModule,
    MatTooltipModule,
    MatIconModule,
    RouterModule,
    FailLoginRoutingModule
  ],
  exports: []
})
export class FailLoginModule {
}
