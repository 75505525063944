import {Injectable} from '@angular/core';
import {RestRequestsService} from '../../../../services/rest-requests.service';
import {AppControlService} from '../../../../services/app-control.service';
import {Observable} from 'rxjs';
import {BrmPriceGroup} from '../../../api/pricegroups/brm-price-group';
import {BrmPricePointResponse} from '../../../../../modules/inventory/price-groups/responses/brm-price-point-response.model';
import {BrmSessionService} from '../../../brm-session.service';
import {map, tap} from 'rxjs/operators';
import {BrmPriceGroupPrint} from '../../../api/inventory/brm-price-group-print';

@Injectable({
  providedIn: 'root'
})
export class BrmPriceGroupsApiService {

  constructor(private rest: RestRequestsService, private appControl: AppControlService,
              private brmSession: BrmSessionService) {
  }

  public getPriceGroups(): Observable<BrmPriceGroup[]> {
    return this.rest.getRequest(`${this.appControl.apiUrl}/pricegroups`, {});
  }

  public createPriceGroup(groupName: string, priceProfiles: number[] = null): Observable<BrmPriceGroup> {
    const createObj = {
      name: groupName,
      suspended: false,
      price_profiles: [
        {
          description: '',
          end_shop_local_time: '',
          extra_day_price: '',
          index: 0,
          price_map: {},
          start_shop_local_time: '',
          free: false
        }
      ]
    };

    priceProfiles = priceProfiles || this.brmSession.session.shop_settings.shop_pricing_profile_mins;

    priceProfiles.forEach(value => createObj.price_profiles[0].price_map[value] = null);

    return this.rest.putRequest(`${this.appControl.apiUrl}/pricegroups`, JSON.stringify(createObj));
  }

  public updatePriceGroup(groupName: string, data: object): Observable<BrmPriceGroup> {
    return this.rest.postRequest(`${this.appControl.apiUrl}/pricegroups/${encodeURIComponent(groupName)}`, JSON.stringify(data), false);
  }

  public deletePriceGroup(groupId: string): Observable<any> {
    return this.rest.deleteRequest(`${this.appControl.apiUrl}/pricegroups/${encodeURIComponent(groupId)}`, {}, false);
  }

  public getPriceGroupPrint(): Observable<BrmPriceGroupPrint> {
    return this.rest.getRequest(`${this.appControl.apiUrl}/pricegroups/report`, {});
  }

  public createPricePoint(minutes: number): Observable<BrmPricePointResponse> {
    return this.rest.postRequest(`${this.appControl.apiUrl}/pricepoints`, JSON.stringify({
      time_in_mins: minutes
    })).pipe(
      tap((value: BrmPricePointResponse) => {
        // Update session
        this.brmSession.updateSessionShopSettings({shop_pricing_profile_mins: value.shop_pricing_profile_mins});
      })
    );
  }

  public deletePricePoint(minutes: number): Observable<any> {
    return this.rest.deleteRequest(`${this.appControl.apiUrl}/pricepoints/${minutes}`, {}).pipe(
      tap((value: BrmPricePointResponse) => {
        // Update session
        this.brmSession.updateSessionShopSettings({shop_pricing_profile_mins: value.shop_pricing_profile_mins});
      })
    );
  }
}
