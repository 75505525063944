import {BrowserModule} from '@angular/platform-browser';
import {APP_INITIALIZER, ErrorHandler, NgModule} from '@angular/core';

import {AppRoutingModule} from './app-routing.module';
import {AppComponent} from './app.component';
import {CoreModule} from './core/core/core.module';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {AuthZeroInterceptor} from './core/interceptors/auth0-interceptor';
import {HTTP_INTERCEPTORS} from '@angular/common/http';
import {environment} from '../environments/environment';
import {FirebaseAuthService} from './core/services/firebase-auth.service';
import {NotificationsService} from './core/services/notifications/notifications.service';
import {Router} from '@angular/router';
import {FirebaseUIModule, firebase, firebaseui} from 'firebaseui-angular';
import {AppControlService} from './core/services/app-control.service';
import {BrmRestDataService} from './core/brm2/services/rest/brm-rest-data.service';
import {MatNativeDateModule} from '@angular/material/core';
import {HttpClientModule} from '@angular/common/http';
import {CookieService} from 'ngx-cookie-service';

import {FullCalendarModule} from '@fullcalendar/angular'; // the main connector. must go first
import dayGridPlugin from '@fullcalendar/daygrid';
import timeGridPlugin from '@fullcalendar/timegrid';
import interactionPlugin from '@fullcalendar/interaction';
import listPlugin from '@fullcalendar/list';
import resourceTimelinePlugin from '@fullcalendar/resource-timeline';
import {AngularFireModule} from '@angular/fire/compat';
import {AngularFireAuth, AngularFireAuthModule} from '@angular/fire/compat/auth';
import {AngularFireDatabaseModule} from '@angular/fire/compat/database';
import {NgxPopperModule} from 'ngx-popper';
import {BrmLuxonPipe} from './core/brm2/services/datesandtime/pipe/brm-luxon.pipe';
import {ConsoleInterceptorService} from './core/services/console-interceptor/console-interceptor.service';
import {initialiseConsoleInterceptor} from './core/services/console-interceptor/console-interceptor.factory';
import {ServerRestErrorLogsService} from './core/services/server-rest-error-logs/server-rest-error-logs.service';
import {IconService} from './core/services/icons/icon-service.service';
import {TabulatorCheckboxComponent} from './modules/shared/tabulator-formatters/tabulator-checkbox/tabulator-checkbox.component';
import {MatCheckboxModule} from '@angular/material/checkbox';
import {ToastComponent} from './modules/shell/toast/toast.component';
import { RollbarErrorHandler, rollbarFactory, RollbarService } from './core/services/rollbar.service';

FullCalendarModule.registerPlugins([
  dayGridPlugin,
  timeGridPlugin,
  interactionPlugin,
  listPlugin,
  resourceTimelinePlugin
]);

const firebaseUiAuthConfig: firebaseui.auth.Config = {
  signInFlow: 'popup',
  signInOptions: [
    {
      provider: firebase.auth.GoogleAuthProvider.PROVIDER_ID,
      scopes: [
        'email'
      ],
      customParameters: {
        'auth_type': 'reauthenticate'
      },
    },
    // firebase.auth.FacebookAuthProvider.PROVIDER_ID,
    firebase.auth.EmailAuthProvider.PROVIDER_ID,
  ],
  tosUrl: 'https://bikerentalmanager.com/terms/',
  privacyPolicyUrl: 'https://www.iubenda.com/privacy-policy/88387555',
  credentialHelper: firebaseui.auth.CredentialHelper.NONE
};

@NgModule({
  declarations: [
    AppComponent,
    TabulatorCheckboxComponent,
  ],
  imports: [
    AngularFireModule.initializeApp(environment.firebase),
    AngularFireDatabaseModule,
    AngularFireAuthModule,
    FirebaseUIModule.forRoot(firebaseUiAuthConfig),
    NgxPopperModule.forRoot({}),
    BrowserAnimationsModule,
    BrowserModule,
    CoreModule,
    AppRoutingModule,
    MatNativeDateModule,
    FullCalendarModule,
    HttpClientModule,
    MatCheckboxModule,
  ],
  providers: [
    IconService,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AuthZeroInterceptor,
      multi: true,
      deps: [
        NotificationsService,
        AngularFireAuth,
        FirebaseAuthService,
        Router,
        BrmRestDataService,
        AppControlService,
        ServerRestErrorLogsService,
      ]
    },
    {
      provide: 'googleTagManagerId', useValue: environment.gtmId
    },
    BrmLuxonPipe,
    ConsoleInterceptorService,
    {
      provide: APP_INITIALIZER,
      useFactory: initialiseConsoleInterceptor,
      deps: [ConsoleInterceptorService],
      multi: true
    },
    [CookieService],
    { provide: ErrorHandler, useClass: RollbarErrorHandler },
    { provide: RollbarService, useFactory: rollbarFactory },
  ],
  exports: [],
  bootstrap: [AppComponent]
})
export class AppModule {
}

export function getDocument() {
  return (typeof document !== 'undefined') ? document : null;
}
