import {Component, OnInit} from '@angular/core';
import {BrmSessionService} from '../../../../core/brm2/brm-session.service';
import {GeneralUtil} from '../../../../core/util';
import {DateTime} from 'luxon';
import {BrmUserRoles} from '../../../../core/brm2/data/brm-user-roles';
import {BrmSession} from '../../../../core/brm2/api/session/brm-session';

@Component({
  selector: 'app-trial-message',
  templateUrl: './trial-message.component.html',
  styleUrls: ['./trial-message.component.scss']
})
export class TrialMessageComponent implements OnInit {

  public isTrial: boolean = false;
  public trialMessage: string = '';
  public trialLink: string = 'https://bikerentalmanager.com/subscription/?currency=gbp&term=annual';

  constructor(private brmSession: BrmSessionService) {

  }

  ngOnInit() {
    this.listenForSession();
  }

  private listenForSession(): void {
    this.brmSession.session$.subscribe(
      (response: BrmSession) => {
        const daysInTrial = this.daysInTrialRemaining();
        this.isTrial = this.brmSession.session.account.scope_trial && this.checkForTrial() && daysInTrial <= 7 && (this.brmSession.session.user.role === BrmUserRoles.SHOP_MANAGER || this.brmSession.session.user.operations);
        this.trialMessage = this.getTrialMessage();

        let currency = response.shop_settings.shop_currency || 'USD';
        currency = currency.toUpperCase();

        if (currency !== 'GBP') {
          currency = 'USD';
        }

        this.trialLink = `https://bikerentalmanager.com/subscription/?currency=${currency}&term=annual`;
      }
    );
  }

  checkForTrial(): boolean {
    return GeneralUtil.isNonEmptyString(this.brmSession.session.account.trial_end_date);
  }

  getTrialMessage(): string {
    const daysLeft = this.daysInTrialRemaining();

    if (daysLeft == 0) {
      return `Your trial ends at midnight.`;
    } else if (daysLeft < 0) {
      return `Your trial has expired`;
    }

    return `Your trial has ${this.daysInTrialRemaining()} days remaining`;
  }

  private daysInTrialRemaining(): number {
    const endDate = DateTime.fromISO(this.brmSession.session.account.trial_end_date).endOf('day');
    const now = DateTime.utc();

    return Math.round(endDate.diff(now, 'days').toObject()['days']);
  }

}
