import {BrmCache} from './brm-cache';
import {Observable} from 'rxjs';
import {map, tap} from 'rxjs/operators';
import {RestRequestsService} from '../../services/rest-requests.service';
import {AppControlService} from '../../services/app-control.service';
import {BrmOnlineChannel} from "../api/settings/brm-online-channel";

export class BrmOnlineChannelCache extends BrmCache<BrmOnlineChannel[]> {

  private rest: RestRequestsService;
  private appControl: AppControlService;

  constructor(_caches, _appControl, _rest) {
    super(_caches, 'onlinechannels');

    this.appControl = _appControl;
    this.rest = _rest;
  }

  protected loadData(): Observable<BrmOnlineChannel[]> {
    return this.rest.getRequest(this.appControl.apiUrl + '/onlinechannels', {}).pipe(
      map((value: BrmOnlineChannel[]) => {
        return value;
      })
    );
  }

  public deleteOnlineChannel(channel: BrmOnlineChannel): Observable<BrmOnlineChannel> {
    return this.rest.deleteRequest(this.appControl.apiUrl + '/onlinechannels/' + channel.code, {}).pipe(
      tap((value: BrmOnlineChannel) => {
          if (this.cachedData != null) {
            this.cachedData.splice(this.cachedData.indexOf(channel), 1);
          }
        }, (error: any) => {
        }
      ));
  }

  public findOnlineChannel(code: string): BrmOnlineChannel {

    if (this.cachedData == null) {
      return null;
    }

    return this.cachedData.find((value: BrmOnlineChannel) => {
      return value.code == code;
    })
  }

  public saveOnlineChannel(code: string, data: object, isNew: boolean = false): Observable<BrmOnlineChannel> {

    let apiCall = '/onlinechannels';

    if (!isNew) {
      apiCall = '/onlinechannels/' + code;
    }

    return this.rest.postRequest(this.appControl.apiUrl + apiCall, JSON.stringify(data)).pipe(
      map((value: BrmOnlineChannel) => {
        return value;
      }),
      tap((value: BrmOnlineChannel) => {
        if (isNew) {
          this.cachedData.push(value);
        } else {
          const channel = this.findOnlineChannel(code);

          if (channel != null) {
            Object.assign(channel, value);
          }
        }
      })
    );
  }

  protected onLoad(): void {
  }

}
