import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {LanguagesUtil} from '../../../core/util/languages-util';

@Component({
  selector: 'app-language-selector',
  templateUrl: './language-selector.component.html',
  styleUrls: ['./language-selector.component.scss']
})
export class LanguageSelectorComponent implements OnInit {

  // Component takes string array of language codes, and a single string of the current selected language code
  @Input() languages;
  @Input() selected;

  // Two outputs, event fires when a language is selected and when the menu is opened
  @Output() languageSelected;
  @Output() menuOpened;

  constructor() {
    // Initiate outputs and selected value;
    this.languageSelected = new EventEmitter<string>();
    this.menuOpened = new EventEmitter<boolean>();
    this.selected = '';
    this.languages = [];
  }

  ngOnInit() {
    if (this.selected === '' && this.languages.length > 0) {
      // Automatically select the first language, if no language has been set by default
      this.onLanguageSelected(this.languages[0]);
    }
  }

  public onLanguageSelected(code: string): void {
    this.selected = code;
    this.languageSelected.emit(code);
  }

  public getLanguageName(code: string): string {
    return LanguagesUtil.getLanguageName(code);
  }

  public onLanguageMenuOpen(event) {
    this.menuOpened.emit(true);
  }

}
