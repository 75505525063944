import {Component, OnInit} from '@angular/core';
import {FirebaseAuthService} from '../../core/services/firebase-auth.service';
import {Router} from '@angular/router';

@Component({
  selector: 'app-fail-login',
  templateUrl: './fail-login.component.html',
  styleUrls: ['./fail-login.component.scss']
})
export class FailLoginComponent implements OnInit {

  constructor(private fbAuth: FirebaseAuthService, private router: Router) {
  }

  ngOnInit() {
  }

  doLogout(): void {
    this.fbAuth.logout().then(
      fullfilled => {
        this.router.navigate(['/', 'login']).then();
      }
    );
  }

}
