import { Component, OnDestroy, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import { AppControlService } from '../../core/services/app-control.service';
import { Router } from '@angular/router';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { FirebaseAuthService } from '../../core/services/firebase-auth.service';
import { FirebaseUISignInFailure, FirebaseUISignInSuccessWithAuthResult } from 'firebaseui-angular';
import { AngularFireAuth } from '@angular/fire/compat/auth';
import { BrmSettingsService } from 'src/app/core/services/brm/brm-settings.service';
import firebase from 'firebase/compat/app';


@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit, OnDestroy {

  public loading: boolean = true;

  public loginStateSubscription: Subscription;

  constructor(private control: AppControlService, private router: Router,
    public fbAuth: FirebaseAuthService, private afAuth: AngularFireAuth,
    private brm: BrmSettingsService) {
  }

  ngOnInit() {
    if (this.fbAuth.isLoggedIn()) {
      this.redirectUser(this.fbAuth.getUser());
    } else if (this.brm.firebaseToken != null) {
      this.goToLoad();
    } else {
      this.listenForAuthChanges();
      setTimeout(() => {
        this.loading = false;
      }, 1000);
    }
  }

  ngOnDestroy(): void {
    if (this.loginStateSubscription != null && !this.loginStateSubscription.closed) {
      this.loginStateSubscription.unsubscribe();
    }
  }

  public listenForAuthChanges(): void {
    this.loginStateSubscription = this.fbAuth.loginState.subscribe((user) => {
      if (user) {
        this.redirectUser(user);
      }
    });
  }

  private redirectUser(user: firebase.User): void {
    if (user?.emailVerified) {
      // User logged in! and they're verified
      this.goToLoad();
    } else {
      // User logged in! But not verified
      this.goToVerify();
    }
  }

  private goToLoad(): void {
    this.router.navigate(['/', 'load']);
  }

  private goToVerify(): void {
    this.router.navigate(['/', 'login', 'verify']);
  }

  successCallback(signInSuccessData: FirebaseUISignInSuccessWithAuthResult) {

  }

  errorCallback(errorData: FirebaseUISignInFailure) {
    console.log('Failed to log in, error below');
    console.error(errorData);
  }
}
