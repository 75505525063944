import {UntypedFormControl, ValidationErrors} from '@angular/forms';

export function longitudeValidator(control: UntypedFormControl): ValidationErrors {
  const regex = RegExp('$|^(-)?([0-9]{1,3}(\\.[0-9]{0,20})?)$');

  if (!regex.test(control.value)) {
    return {
      latitude: false
    };
  } else {
    const value = Number(control.value);

    if (value >= -180 && value <= 180) {
      return null;
    } else {
      return {
        latitude: true
      };
    }
  }
}
