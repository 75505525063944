import {Component, OnDestroy, OnInit} from '@angular/core';
import {ShellControlService} from '../shell-control.service';
import {Router, ActivatedRoute, NavigationEnd} from '@angular/router';
import {GettingStartedService} from '../../getting-started/getting-started.service';
import {BrmPrivilegesService} from '../../../core/brm2/services/privileges/brm-privileges.service';
import {BrmUserSettingsService} from '../../../core/brm2/services/user-settings/brm-user-settings.service';
import {Subject} from 'rxjs';
import {BrmLightspeedService} from '../../../core/brm2/services/lightspeed/brm-lightspeed.service';
import {BrmUserRoles} from '../../../core/brm2/data/brm-user-roles';
import {BrmSessionService} from '../../../core/brm2/brm-session.service';
import {AppControlService} from '../../../core/services/app-control.service';
import {BrmSettingsService} from '../../../core/services/brm/brm-settings.service';
import {IconService} from '../../../core/services/icons/icon-service.service';

@Component({
  selector: 'app-side-nav',
  templateUrl: './side-nav.component.html',
  styleUrls: ['./side-nav.component.scss']
})
export class SideNavComponent implements OnInit, OnDestroy {

  private destroyed$: Subject<boolean> = new Subject<boolean>();

  public BrmUserRoles = BrmUserRoles;
  public activatedRouterParam: string;
  public activeSubNavigation: string;
  public activatedRoute: string = '';
  private currentBrmRole: string = '';
  public displayNewReservations: boolean = this.brmSession.session.shop_settings.eng_show_reservations_menu  || this.brmPrivileges.isOperationsUser();
  public forceNewReservations: boolean = this.brmSession.session.shop_settings.eng_full_new_reservations_migration;
  public activatedRouterReservationLink: string = (this.forceNewReservations) ? 'newreservations' : 'reservations';


  constructor(public shellControl: ShellControlService,
              public router: Router,
              private route: ActivatedRoute,
              public gettingStarted: GettingStartedService,
              private userSettings: BrmUserSettingsService,
              public brmPrivileges: BrmPrivilegesService,
              public brmLightspeed: BrmLightspeedService,
              public brm: BrmSettingsService,
              public brmSession: BrmSessionService,
              public appControl: AppControlService,
              private icon: IconService,
  ) {


    this.icon.registerIcon('three-dots');
    this.icon.registerIcon('reservation-manage');
    this.icon.registerIcon('reservation-create');
    this.icon.registerIcon('inventory');
    this.icon.registerIcon('money');
    this.icon.registerIcon('bicycle');
    this.icon.registerIcon('delivery');
    this.icon.registerIcon('graph');
    this.icon.registerIcon('share');
    this.icon.registerIcon('transaction');
    this.icon.registerIcon('settings');
    this.icon.registerIcon('settings-general');
    this.icon.registerIcon('settings-online');
    this.icon.registerIcon('bell');
    this.icon.registerIcon('integration');
    this.icon.registerIcon('payment-management');
    this.icon.registerIcon('payment-billing');
  }

  public navigationStructure: Array<{}> = [
    {
      title: 'Reservations',
      order: 1,
      activatedRouterLink: this.activatedRouterReservationLink,
      routerLink: ['/', this.activatedRouterReservationLink],
      icon: 'reservation',
      submenuTitle: 'RESERVATIONS',
      submenu: [{
        title: 'Create Reservation',
        icon: 'reservation-create',
        routerLink: ['/', this.activatedRouterReservationLink],
        condition: true,
        queryParams: {mode: this.forceNewReservations ? 'product' : 'create'}
      }, {
        title: 'Manage Reservations',
        icon: 'reservation-manage',
        routerLink: ['/', this.activatedRouterReservationLink],
        condition: true,
        queryParams: {mode: this.forceNewReservations ? 'reservations' : 'manage'}
      }, {
        title: 'Product View 2.0',
        icon: 'reservation-create',
        routerLink: ['/', 'newreservations'],
        condition: this.displayNewReservations && !this.forceNewReservations,
        queryParams: {mode: 'create'}
      }, {
        title: 'Reservations View 2.0',
        icon: 'reservation-manage',
        routerLink: ['/', 'newreservations'],
        condition: this.displayNewReservations && !this.forceNewReservations,
        queryParams: {mode: 'manage'}
      }]
    }, {
      title: 'Inventory',
      order: 3,
      icon: 'inventory',
      activatedRouterLink: 'inventory',
      routerLink: ['/', 'inventory'],
      submenuTitle: 'INVENTORY & PRICES',
      submenu: [{
        title: 'General',
        icon: 'inventory',
        routerLink: ['/', 'inventory', 'general'],
        condition: true,
        dots: true,
      }, {
        title: 'Price Management',
        icon: 'money',
        routerLink: ['/', 'inventory', 'pricing', 'pricegroups'],
        condition: true,
      }, {
        title: 'Bulk Update',
        icon: 'bicycle',
        routerLink: ['/', 'inventory', 'bulk', 'bulkupdate'],
        condition: true,
      }, {
        title: 'Print',
        icon: 'bicycle',
        routerLink: ['/', 'inventory', 'print'],
        condition: true,
        dots: true,
      }]
    }, {
      title: 'Maintenance',
      order: 4,
      icon: 'maintenance',
      routerLink: ['/', 'maintenance'],
      activatedRouterLink: 'maintenance',
      operand: '!==',
      roles: [BrmUserRoles.BASIC],
    }, {
      title: 'Delivery',
      order: 5,
      icon: 'delivery',
      routerLink: ['/', 'delivery'],
      activatedRouterLink: 'delivery',
      session: 'scope_delivery'
    }, {
      title: 'Reports',
      order: 6,
      icon: 'reports',
      routerLink: ['/', 'reports'],
      activatedRouterLink: 'reports',
      roles: [BrmUserRoles.SHOP_MANAGER],
      submenuTitle: 'Reports & Graphs',
      submenu: [{
        title: 'Graphs',
        icon: 'graph',
        routerLink: ['/', 'reports', 'graphs'],
        condition: true,
      }, {
        title: 'Export Data',
        icon: 'share',
        routerLink: ['/', 'reports', 'export'],
        condition: true,
      }, {
        title: 'Transactions',
        icon: 'transaction',
        routerLink: ['/', 'reports', 'transactionv2'],
        condition: true,
      }]
    }, {
      title: 'Settings',
      order: 7,
      icon: 'settings',
      submenuTitle: 'All Settings',
      activatedRouterLink: 'settings',
      routerLink: ['/', 'settings'],
      submenu: [{
        title: 'All Settings',
        icon: 'settings',
        routerLink: ['/', 'settings'],
        condition: true,
        dots: true,
      }, {
        title: 'General Settings',
        icon: 'settings-general',
        routerLink: ['/', 'settings', 'general'],
        condition: true,
        dots: true,
      }, {
        title: 'Online Settings',
        icon: 'settings-online',
        routerLink: ['/', 'settings', 'online'],
        condition: true,
        dots: true,
      }, {
        title: 'Notifications',
        icon: 'bell',
        routerLink: ['/', 'settings', 'notifications'],
        condition: true,
        dots: true,
      }, {
        title: 'Integrations',
        icon: 'integration',
        routerLink: ['/', 'settings', 'integrations'],
        condition: this.displayIntegrationsSection(),
        dots: true,
      }, {
        title: 'Payment Management',
        icon: 'payment-management',
        routerLink: ['/', 'settings', 'payment-management'],
        condition: this.displayIntegrationsSection(),
        dots: true,
      }, {
        title: 'BRM Account & Billing',
        icon: 'payment-billing',
        routerLink: ['/', 'settings', 'my-account'],
        condition: true,
        dots: true,
      }],
      operand: '!==',
      roles: [BrmUserRoles.BASIC, BrmUserRoles.MECHANIC],
    }, {
      title: 'Issues',
      order: 8,
      icon: 'issues',
      activatedRouterLink: 'issues',
      routerLink: ['/', 'issues'],
      hide: true,
    }, {
      title: 'Switch Store',
      order: 9,
      icon: 'switch-stores',
      routerLink: ['/', 'storeswitch'],
      activatedRouterLink: 'storeswitch',
      roles: ['BrmUserRoles.STAFF'],
      privilege: ['isOperationsUser', 'canSwitchShops'],
      session: 'organisation_id',
    },
    {
      title: 'Customers',
      order: 2,
      routerLink: ['/', 'customers'],
      icon: 'customers',
      activatedRouterLink: 'customers',
      operand: '!==',
      roles: [BrmUserRoles.BASIC],
    }
  ];
  public nav: Array<{}> = [];


  ngOnInit() {
    this.shellControl.expandedNavigation = this.isMenuExpanded;
    this.setRouterParams();
    this.populateNavigation();

    this.router.events.subscribe((event) => {
      this.activatedRouterParam = null;
      if (event instanceof NavigationEnd) {
        this.setRouterParams();
      }
    });

    this.activatedRouterReservationLink = (this.brmSession.session.shop_settings.eng_full_new_reservations_migration) ? 'newreservations' : 'reservations';
  }

  ngOnDestroy(): void {
    this.destroyed$.next(true);
    this.destroyed$.complete();
  }

  private setRouterParams(): void {
    this.activatedRoute = this.router.url;
    this.activatedRouterParam = this.route.snapshot.queryParams.mode;
    if (!this.activatedRouterParam) {
      this.activatedRouterParam = this.getSubNavigation(2);
    }

    this.activeSubNavigation = this.getSubNavigation().split('?')[0];
  }

  private get isMenuExpanded(): boolean {
    // If hide_menu hasn't been set yet (null), return a default of false (not expanded)
    // If hide_menu is set, the inverse value is returned (hide_menu === true means isExpanded === false)
    return this.userSettings.settings.hide_menu === null ? false : !this.userSettings.settings.hide_menu;
  }

  setMenuStatement(): void {

    this.shellControl.toggleNavigation();
    const statement = this.shellControl.expandedNavigation;

    this.userSettings.updateSettings({
      // please note - hide is the inverse of 'expanded'
      hide_menu: !statement
    });

  }

  /***
   * Reads the current active URL and returns the top most route,
   * e.g. /inventory/category -> inventory
   * e.g. /settings/general -> settings
   */
  public getSubNavigation(type = 1): string {
    // split on /
    const activeUrl = this.router.url;
    const split = activeUrl.split('/');

    return split.length >= 2 ? split[type] : '';
  }

  displayOnlineSection(): boolean {
    return this.brm.hasPrivilege('pages.settings.customisation') || this.brm.hasPrivilege('pages.settings.advanced')
      || this.brm.hasPrivilege('pages.settings.pfMatrix') || this.brm.hasPrivilege('pages.settings.languages')
      || this.brm.hasPrivilege('pages.settings.publish') || this.brm.hasPrivilege('pages.settings.control');
  }

  displayNotificationsSection(): boolean {
    return this.brm.hasPrivilege('pages.settings.notifications.who')
      || this.brm.hasPrivilege('pages.settings.notifications.when')
      || this.brm.hasPrivilege('pages.settings.notifications.what');
  }

  displayIntegrationsSection(): boolean {
    return this.brm.hasPrivilege('pages.settings.paymentProcessors') || this.brm.hasPrivilege('pages.settings.pointOfSale')
      || this.brm.hasPrivilege('pages.settings.slack') || this.brm.hasPrivilege('pages.settings.bikedotrent')
      || this.brm.session.data.account.scope_sbc;
  }

  private populateNavigation() {
    // Check for non-permission/non-privilege items
    this.navigationStructure.forEach((perm: any) => {
      if (!perm.roles && !perm.privilege && !perm.session && !perm.hide) {
        this.nav.push(perm);
      }
    });

    // Check for switchStore status, if true, run the below function and add any switchStore related items
    this.brmPrivileges.canSwitchShops$.subscribe((event) => {
      if (event) {
        this.findMatchingPermissionAndAddMenuItem('canSwitchShops');
      }
    });

    // Check the operationUser status, if true, run the below function to add any operationUser items
    this.brmPrivileges.isOperationsUser$.subscribe((event) => {
      if (event) {
          this.findMatchingPermissionAndAddMenuItem('isOperationsUser');
        }
    });

    // Check for session restricted items
    this.brmSession.session$.subscribe((event) => {
      this.navigationStructure.forEach((perm: any, x) => {
        if (event.account[perm.session] === true && !perm.hide) {
          this.addMenuItemIfNotExist(perm);
        }
      });
    });

    // Check for permission restricted items, we also need to make sure there are no extra privileges that have snuck through, they should have been resolved above
    this.brmPrivileges.role$.subscribe((event) => {
      this.currentBrmRole = event;
      this.navigationStructure.forEach((perm: any, x) => {
        if (perm.roles && !perm.privilege) {
          if (perm.operand === '!==') {
            if (!perm.roles.includes(this.currentBrmRole) && !perm.hide) {
              this.addMenuItemIfNotExist(perm);
            }
          } else {
            if (perm.roles.includes(this.currentBrmRole)) {
              this.addMenuItemIfNotExist(perm);
            }
          }
        }
      });
    });

    // Order the nav based on the ordering given in the array
    this.nav.sort((a, b) => a['order'] - b['order']);
  }


  // Take the permission boolean (isOpsUser, canSwitchShops) and run it against the nav structure and push any matching items in
  private findMatchingPermissionAndAddMenuItem(privilegeToCheck: string) {
    this.navigationStructure.forEach((navItemFromStructure: any, x) => {
        if ((navItemFromStructure.privilege && navItemFromStructure.privilege.includes(privilegeToCheck)) && !navItemFromStructure.hide) {
          if (navItemFromStructure.icon === 'switch-stores') {
            if (privilegeToCheck === 'canSwitchShops') {
              this.addMenuItemIfNotExist(navItemFromStructure);
            }
          } else {
            this.addMenuItemIfNotExist(navItemFromStructure);
          }
        }
      });
  }

  // Check if the item exists in the new array, if not, add it in
  private addMenuItemIfNotExist(navItemFromStructure) {
    if (!this.nav.some(navItem => navItem === navItemFromStructure)) {
      this.nav.push(navItemFromStructure);
    }
  }
}
