import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {HttpClientModule} from '@angular/common/http';
import {CoreRoutingModule} from './core.routing.module';
import {SplashModule} from '../../modules/splash/splash.module';
import {ModulesModule} from '../../modules/modules.module';
import {NotificationComponent} from '../services/notifications/notification/notification.component';
import {MatSnackBarModule} from '@angular/material/snack-bar';

/**
 * CoreModule is the central module which houses all imports of the smaller modules
 */

@NgModule({
  declarations: [
    NotificationComponent
  ],
  imports: [
    CommonModule,
    HttpClientModule,
    // Component modules
    SplashModule,
    ModulesModule,
    // End of component modules
    MatSnackBarModule,
    // Core routing
    CoreRoutingModule
  ],
  exports: [

  ]
})
export class CoreModule {
}
