import {BrmCache} from './brm-cache';
import {Observable} from 'rxjs';
import {finalize, map, takeUntil, tap} from 'rxjs/operators';
import {RestRequestsService} from '../../services/rest-requests.service';
import {AppControlService} from '../../services/app-control.service';
import {BrmKiosk} from '../api/kiosks/brm-kiosk';

export class BrmDevicesCache extends BrmCache<BrmKiosk[]> {

  private rest: RestRequestsService;
  private appControl: AppControlService;

  constructor(_caches, _appControl, _rest) {
    super(_caches, 'devices');

    this.appControl = _appControl;
    this.rest = _rest;
  }

  protected loadData(): Observable<BrmKiosk[]> {
    return this.rest.getRequest(this.appControl.apiUrl + '/kiosks', {}).pipe(
      map((value: BrmKiosk[]) => {
        return value;
      })
    );
  }

  protected onLoad(): void {
  }

  public deleteKiosk(id: string): Observable<any> {
    return this.rest.deleteRequest(`${this.appControl.apiUrl}/kiosks/${encodeURIComponent(id)}`, {}, false).pipe(
      tap({
        next: (value) => {
          if (this.hasDataLoaded()) {
            const indexToRemove = this.cachedData.findIndex((kiosk: BrmKiosk) => {
              return kiosk.id === id;
            });

            if (indexToRemove >= 0) {
              this.cachedData.splice(indexToRemove, 1);
            }
          }
        }
      })
    );
  }

  public addKiosk(data?: object): Observable<any> {

    /*if (this.id) {
      api += '/' + this.id;
      delete data['connected_ts'];
    } else {
      data['connected_ts'] = new Date().getTime();
    }*/

    return this.rest.postRequest(this.appControl.apiUrl + '/kiosks', JSON.stringify(data)).pipe(
      map((response: BrmKiosk) => {
        return response;
      }),
      tap({
        next: (value: BrmKiosk) => {
          this.cachedData.push(value);
        }
      })
    );
  }

  public updateKiosk(id: string, data: object): Observable<any> {
    return this.rest.postRequest(this.appControl.apiUrl + '/kiosks/' + encodeURIComponent(id), JSON.stringify(data), false).pipe(
      map((response: BrmKiosk) => {
        return response;
      }),
      tap({
        next: (value: BrmKiosk) => {
          const findKiosk = this.cachedData.find((kiosk: BrmKiosk) => {
            return kiosk.id === id;
          });

          if (findKiosk != null) {
            Object.assign(findKiosk, value);
          }
        }
      })
    );
  }

  // TODO: the Kiosk implementation of getData has a different signature than the other caches.
  // forceReload should be a boolean, but this now clashes with the brmExtras cache.
  public getData(excludePublic?: boolean, forceReload?): Observable<BrmKiosk[]> {
    if (excludePublic === null) {
      excludePublic = false;
    }

    return super.getData(forceReload).pipe(
      map((value: BrmKiosk[]) => {
        if (excludePublic) {
          return value.filter((kiosk: BrmKiosk) => {
            return !kiosk.public;
          });
        }

        return value;
      })
    );
  }
}


