import {Component, Inject, OnInit} from '@angular/core';
import {RestRequestsService} from '../../../../core/services/rest-requests.service';
import {AppControlService} from '../../../../core/services/app-control.service';
import {BrmSessionService} from '../../../../core/brm2/brm-session.service';
import {Observable} from 'rxjs';
import {BrmRequestDetailEntry, BrmRequestDetails} from '../../../../core/brm2/api/requests/brm-request-details';
import {map} from 'rxjs/operators';
import {copyContent} from '../../../../core/util/general-util';
import {NotificationsService} from '../../../../core/services/notifications/notifications.service';
import {MAT_DIALOG_DATA, MatDialog, MatDialogRef} from '@angular/material/dialog';

@Component({
  selector: 'app-request-id-dialog',
  templateUrl: './request-id-dialog.component.html',
  styleUrls: ['./request-id-dialog.component.scss']
})
export class RequestIdDialogComponent implements OnInit {

  public pageLoader$: Observable<BrmRequestDetailEntry[]>;

  constructor(public dialogRef: MatDialogRef<RequestIdDialogComponent>,
              @Inject(MAT_DIALOG_DATA) public data: string,
              private dialog: MatDialog,
              private rest: RestRequestsService, private appControl: AppControlService,
              public brmSession: BrmSessionService,
              private notify: NotificationsService) {
    this.pageLoader$ = this.createPageLoader();
  }

  ngOnInit() {
  }

  private createPageLoader(): Observable<BrmRequestDetailEntry[]> {
    return this.rest.getRequest(`${this.appControl.apiUrl}/requests/${this.data}`, {}).pipe(
      map((response: BrmRequestDetails) => {
        return response.events.sort((a: BrmRequestDetailEntry, b: BrmRequestDetailEntry) => {
          if (a.created_ts > b.created_ts) {
            return -1;
          } else if (a.created_ts < b.created_ts) {
            return 1;
          }

          return 0;
        });
      })
    );
  }

  onClickCloseButton(): void {
    this.dialogRef.close(true);
  }

  onRequestIdClick(event): void {
    copyContent(this.data);

    this.notify.addSuccessNotification('Request debug Id successfully copied');
  }

}
