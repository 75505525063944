import {Injectable} from '@angular/core';
import {BehaviorSubject, Observable, of, Subject} from 'rxjs';
import {BrmInventory} from '../../../core/brm2/api/inventory/shape/brm-inventory';
import {catchError, startWith, switchMap, takeUntil, tap} from 'rxjs/operators';
import {ShapeInventory} from '../inventory-shape/data/shape-inventory';
import {BrmCacheService} from '../../../core/brm2/brm-cache.service';
import {NotificationsService} from '../../../core/services/notifications/notifications.service';

@Injectable({
  providedIn: 'root'
})
export class InventoryService {

  public draggingEnabled = false;
  private _includeNonOperationals = false;
  private _includeArchived = false;
  public nonOp$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(this.includeNonOperationals);
  public archived$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(this.includeArchived);

  private _inventoryRef: BrmInventory;

  public inventory: ShapeInventory;

  public view: 'status' | 'price_group' | 'sku' | 'description' |  'home_location' | 'current_location' | 'reservation_loading' | 'stem' = 'status';
  public include: string[] = [];

  public autoExpandOnShape = {
    category: null,
    family: null,
    line: null
  };

  private inventoryLoaderSubject$: Subject<boolean> = new Subject<boolean>();
  public inventoryLoader$: Observable<BrmInventory>;

  public inventoryLoading: boolean = false;

  constructor(private cache: BrmCacheService, private notify: NotificationsService) {
    this.inventoryLoader$ = this.createInventoryLoader();
  }

  get includeNonOperationals(): boolean {
    return this._includeNonOperationals;
  }

  set includeNonOperationals(value: boolean) {
    this._includeNonOperationals = value;
    this.nonOp$.next(this.includeNonOperationals);
  }

  get includeArchived(): boolean {
    return this._includeArchived;
  }

  set includeArchived(value: boolean) {
    this._includeArchived = value;
    this.archived$.next(this.includeArchived);
  }

  private createInventoryLoader(): Observable<BrmInventory> {
    console.log('Creating inventory loader');
    return this.inventoryLoaderSubject$.pipe(
      startWith(false),
      switchMap((forceReload: boolean) => {
        console.log('Force loading?');
        this.inventoryLoading = true;
        return this.cache.inventoryCache.getData(forceReload).pipe(
          // cancel request if a new one comes in
          takeUntil(this.inventoryLoaderSubject$),
          catchError(error => {
            this.notify.addFailNotification('There was a problem loading the inventory data, please try refreshing');
            return of(null);
          })
        );
      }),
      tap((next: BrmInventory) => {
        this.inventoryLoading = false;
        if (next != null) {
          this._inventoryRef = next;
          this.processInventory();
        }
      })
    );
  }

  public reloadInventory(): void {
    this.inventoryLoaderSubject$.next(true);
  }

  public recalculateInventory(): void {
    if (this.inventory != null) {
      this.inventory.recalculateInventory();
    }
  }

  public updateCapacityAndAssets(): void {
    if (this.inventory != null) {
      this.inventory.updateCapacityAndAssets();
    }
  }

  private processInventory(): void {
    if (this._inventoryRef != null) {
      this.inventory = new ShapeInventory(this._inventoryRef);
      this.inventory.updateCapacityAndAssets();
    }
  }

  public autoExpandCategory(id: string): void {
    this.autoExpandOnShape['category'] = id;
  }

  public autoExpandFamily(id: number): void {
    this.autoExpandOnShape['family'] = id;
  }

  public autoExpandLine(id: number): void {
    this.autoExpandOnShape['line'] = id;
  }
}
