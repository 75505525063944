import {BrmCache} from './brm-cache';
import {Observable} from 'rxjs';
import {map, tap} from 'rxjs/operators';
import {RestRequestsService} from '../../services/rest-requests.service';
import {AppControlService} from '../../services/app-control.service';
import {BrmPriceGroupsApiService} from '../api-services/inventory/price-groups/brm-price-groups-api.service';
import {BrmPriceGroup} from '../api/pricegroups/brm-price-group';

export class BrmPriceGroupCache extends BrmCache<BrmPriceGroup[]> {

  private rest: RestRequestsService;
  private appControl: AppControlService;

  constructor(_caches, _appControl, _rest, public priceGroupsApi: BrmPriceGroupsApiService) {
    super(_caches, 'pricegroups');

    this.appControl = _appControl;
    this.rest = _rest;
  }

  protected loadData(): Observable<BrmPriceGroup[]> {
    return this.priceGroupsApi.getPriceGroups();
  }

  protected onLoad(): void {
  }

  public filterPriceGroupsBySearch(search: string): BrmPriceGroup[] {
    search = (search || '').toLowerCase();

    return (this.cachedData || []).filter((group: BrmPriceGroup) => {
      const groupNameLower = (group.name || '').toLowerCase();

      return groupNameLower.includes(search);
    });
  }

  public updatePriceGroup(groupName: string, data: object): Observable<BrmPriceGroup> {
    return this.priceGroupsApi.updatePriceGroup(groupName, data).pipe(
      tap(value => {
        const groupObj = this.getGroupByName(groupName);

        if (groupObj) {
          Object.assign(groupObj, value);
        }
      })
    );
  }

  public deletePriceGroup(groupId: string): Observable<any> {
    return this.priceGroupsApi.deletePriceGroup(groupId).pipe(
      tap(
        success => {
          if (this.hasDataLoaded()) {
            const groupIndex = this.getRawData().findIndex(group => group.name === groupId);

            if (groupIndex) {
              this.cachedData.splice(groupIndex, 1);
            }
          }
        },
        err => {

        }
      )
    );
  }

  private getGroupByName(name: string): BrmPriceGroup {
    if (this.hasDataLoaded()) {
      return this.getRawData().find(value => value.name === name);
    }

    return null;
  }

}
