import {Injectable, OnDestroy} from '@angular/core';
import {CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, Router} from '@angular/router';
import {Observable, of, Subject} from 'rxjs';
import {GettingStartedService} from '../../../../modules/getting-started/getting-started.service';
import {BrmPrivilegesService} from '../../services/privileges/brm-privileges.service';

@Injectable({
  providedIn: 'root'
})
export class GettingStartedGuard implements CanActivate, OnDestroy {

  /**
   * Prevents the user from going to different routes outside of reservations
   * @param brmPrivileges
   * @param router
   */
  constructor(private brmPrivileges: BrmPrivilegesService, private router: Router,
              private gettingStarted: GettingStartedService) {

  }

  ngOnDestroy(): void {
  }

  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {

    if (this.gettingStarted.isShopLive()) {
      this.router.navigate(['/', 'home']);
      return false;
    } else {
      return true;
    }
  }
}
