import {BrmCache} from './brm-cache';
import {Observable} from 'rxjs';
import {map, tap} from 'rxjs/operators';
import {RestRequestsService} from '../../services/rest-requests.service';
import {AppControlService} from '../../services/app-control.service';
import {BrmLocation} from '../api/settings/brm-location';

export class BrmMultiLocationCache extends BrmCache<BrmLocation[]> {

  private rest: RestRequestsService;
  private appControl: AppControlService;

  constructor(_caches, _appControl, _rest) {
    super(_caches, 'locations');

    this.appControl = _appControl;
    this.rest = _rest;
  }

  protected loadData(): Observable<BrmLocation[]> {
    return this.rest.getRequest(this.appControl.apiUrl + '/locations', {}).pipe(
      map((value: BrmLocation[]) => {
        return value;
      })
    );
  }

  public deleteLocation(location: BrmLocation): Observable<BrmLocation> {
    return this.rest.deleteRequest(this.appControl.apiUrl + '/locations/' + location.name, {}).pipe(
      tap((value: BrmLocation) => {
          if (this.cachedData != null) {
            this.cachedData.splice(this.cachedData.indexOf(location), 1);
          }
        }, (error: any) => {
        }
      ));
  }

  public findLocation(name: string): BrmLocation {

    if (this.cachedData == null) {
      return null;
    }

    return this.cachedData.find((value: BrmLocation) => {
      return value.name === name;
    });
  }

  public saveLocation(name: string, data: object, isNew: boolean = false): Observable<BrmLocation> {

    let apiCall = '/locations';

    if (!isNew) {
      apiCall = '/locations/' + name;
    }

    return this.rest.postRequest(this.appControl.apiUrl + apiCall, JSON.stringify(data)).pipe(
      map((value: BrmLocation) => {
        return value;
      }),
      tap((value: BrmLocation) => {
        if (isNew) {
          this.cachedData.push(value);
        } else {
          const location = this.findLocation(name);

          if (location != null) {
            Object.assign(location, value);
          }
        }
      })
    );
  }

  protected onLoad(): void {
  }

}
