import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {BrmDynamicLanguagePipe} from './pipes/brm-dynamic-language.pipe';
import {LanguageCodePipe} from './pipes/language-code.pipe';
import {BrmLanguageFieldComponent} from './components/brm-language-field/brm-language-field.component';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {BrmLanguageHighlightDirective} from './directives/brm-language-highlight.directive';
import {MatFormFieldModule} from '@angular/material/form-field';

@NgModule({
  declarations: [
    BrmDynamicLanguagePipe,
    LanguageCodePipe,
    BrmLanguageFieldComponent,
    BrmLanguageHighlightDirective
  ],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    MatFormFieldModule
  ],
  providers: [
    BrmDynamicLanguagePipe
  ],
  exports: [
    BrmDynamicLanguagePipe,
    LanguageCodePipe,
    BrmLanguageFieldComponent,
    BrmLanguageHighlightDirective
  ]
})
export class BrmLanguagesModule {
}
