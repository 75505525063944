import {Injectable, TemplateRef} from '@angular/core';
import {Observable, of, Subject} from 'rxjs';
import {AppControlService} from '../../../services/app-control.service';
import {RestRequestsService} from '../../../services/rest-requests.service';
import {catchError, map} from 'rxjs/operators';
import {BrmItemSearch} from '../../api/inventory/items/brm-item-search';
import {BrmCacheService} from '../../brm-cache.service';
import {BrmMaintenanceTask} from '../../api/settings/brm-maintenance-task';
import {HandsFreeInputType} from './hands-free-input-types';
import {BrmItem} from '../../api/inventory/items/brm-item';
import {BrmOrganisationUserResponse} from '../../../services/brm/responses/brm-organisation-user-response.model';
import {HandsFreeResult} from './hands-free-result';
import {BrmReservationsSearch} from '../../api/reservations/brm-reservations-search';
import {MatDialog} from '@angular/material/dialog';
import {ActivatedRoute, Router} from '@angular/router';
import {HandsFreeBroadcast} from './hands-free-broadcast';

@Injectable({
  providedIn: 'root'
})
export class HandsFreeService {

  private handsFreeInput: Subject<string> = new Subject<string>();
  public handsFreeInput$: Observable<string> = this.handsFreeInput.asObservable();

  public handsFreeEnabled: boolean = false;

  public handsFreeResult$: Subject<HandsFreeResult> = new Subject<HandsFreeResult>();

  public handsFreeBroadcast$: Subject<HandsFreeBroadcast> = new Subject<HandsFreeBroadcast>();
  public handsFreeTemplates: TemplateRef<any>[] = [];
  public handsFreeTemplate: TemplateRef<any>;


  constructor(private appControl: AppControlService, private rest: RestRequestsService,
              private cache: BrmCacheService, private dialog: MatDialog,
              private activatedRoute: ActivatedRoute, private router: Router) {
  }

  public broadcastInput(input: string): void {
    let dialogComponent = null;

    if (this.dialog.openDialogs.length > 0) {
      dialogComponent = this.dialog.openDialogs[this.dialog.openDialogs.length - 1].componentInstance;
    }

    this.handsFreeBroadcast$.next(<HandsFreeBroadcast>{
      route: this.router.url,
      dialogOpen: this.dialog.openDialogs.length > 0,
      mostRecentDialogComponent: dialogComponent,
      input: input,
      type: this.getInputType(input, true)
    });
  }

  public setHandsFreeContext(template: TemplateRef<any>): void {
    this.handsFreeTemplates.unshift(template);
  }

  public removeHandsFreeContext(template: TemplateRef<any>): void {
    const index = this.handsFreeTemplates.indexOf(template);

    if (index >= 0) {
      this.handsFreeTemplates.splice(index, 1);
    }
  }

  public removeAllHandsFreeContexts(): void {
    this.handsFreeTemplates = [];
  }

  public broadcastHandsFreeInput(query: string): void {
    this.handsFreeInput.next(query);
  }

  public disableHandsFreeMode(): void {
    this.handsFreeEnabled = false;
  }

  public toggleHandsFreeMode(): void {
    this.handsFreeEnabled = !this.handsFreeEnabled;
  }

  public sendResult(result: HandsFreeResult): void {
    this.handsFreeResult$.next(result);
  }

  public getInputType(input: string, rawSupported: boolean = false): HandsFreeInputType {
    if (input.charAt(0) === '@') {
      return HandsFreeInputType.USER_SEARCH;
    } else if (input.charAt(0) === '.') {
      return HandsFreeInputType.MAINTENANCE_TASK_SEARCH;
    } else if (input.charAt(0) === '-') {
      return HandsFreeInputType.RESERVATION_SEARCH;
    } else if (rawSupported) {
      return HandsFreeInputType.RAW_INPUT;
    }

    return HandsFreeInputType.ASSET_SEARCH;
  }

  public checkForReservationReference(reference: string): Observable<BrmReservationsSearch> {
    reference = (reference || '').toLowerCase();

    return this.rest.getRequest(`${this.appControl.apiUrl}/reservations`, {
      q: reference,
      hands_free: true
    }).pipe(
      catchError((error) => {
        return of({
          count: -1,
          data: [],
          has_more: false,
          offset: 0,
          total_count: 0
        });
      }),
      map((value: BrmReservationsSearch) => value)
    );
  }

  public checkForMaintenanceTask(taskNameRequested: string): Observable<BrmMaintenanceTask> {
    taskNameRequested = (taskNameRequested || '').toLowerCase();
    taskNameRequested = taskNameRequested.charAt(0) === '.' ? taskNameRequested.substr(1) : taskNameRequested;

    return this.cache.maintenanceTaskCache.getData().pipe(
      catchError((error) => {
        return of(null);
      }),
      map((next: BrmMaintenanceTask[]) => {
        if (next != null) {
          const findTask = (next || []).find((task: BrmMaintenanceTask) => {
            let taskName = (task.id || '').toLowerCase();
            taskName = taskName.charAt(0) === '.' ? taskName.substr(1) : taskName;

            return taskNameRequested === taskName;
          });

          if (findTask != null) {
            return findTask;
          } else {
            return null;
          }
        } else {
          return null;
        }
      })
    );
  }

  public checkForAsset(assetIdOrBarcode: string): Observable<BrmItem[]> {
    const searchParameters = {
      q: assetIdOrBarcode
    };

    const assetIdOrBarcodeLowercase = (assetIdOrBarcode || '').toLowerCase();

    return this.rest.getRequest(`${this.appControl.apiUrl}/items`, searchParameters).pipe(
      catchError((error) => {
        return of(null);
      }),
      map((next: BrmItemSearch) => {
        if (next != null) {
          const asset = next.data.find((item: BrmItem) => {
            const itemBarcodeLower = (item.barcode || '').toLowerCase();
            const itemAssetIdLower = (item.store_ref || '').toLowerCase();

            return (itemBarcodeLower !== '' && itemBarcodeLower === assetIdOrBarcodeLowercase) || (itemAssetIdLower !== '' && itemAssetIdLower === assetIdOrBarcodeLowercase);
          });

          if (asset != null) {
            return [asset];
            /*} else if (next.data.length === 1) {
              return next.data;
            }*/
          } else if (next.data.length === 0) {
            return [];
          } else if (next.data.length > 0) {
            return next.data;
          }
        } else {
          return [];
        }
      })
    );
  }

  public checkForUser(userId: string): Observable<any> {
    return this.rest.getRequest(`${this.appControl.apiUrl}/users`, {}).pipe(
      catchError((error) => {
        return of(null);
      }),
      map((next: BrmOrganisationUserResponse[]) => {
        if (next != null) {
          return (next || []).find((value: BrmOrganisationUserResponse) => {
            console.log(value.last_name.toLowerCase(), userId.toLowerCase(), value.last_name.toLowerCase() === userId.toLowerCase());
            return value.last_name.toLowerCase() === userId.toLowerCase();
          });
        } else {
          return null;
        }
      })
    );
  }
}
