import {Component, Inject, OnDestroy, OnInit} from '@angular/core';
import {forkJoin, Observable, Subject} from 'rxjs';
import {BrmReservationsSearch} from '../../../../core/brm2/api/reservations/brm-reservations-search';
import {BrmReservation} from '../../../../core/brm2/api/reservations/brm-reservation';
import {RestRequestsService} from '../../../../core/services/rest-requests.service';
import {AppControlService} from '../../../../core/services/app-control.service';
import {BrmCacheService} from '../../../../core/brm2/brm-cache.service';
import {NotificationsService} from '../../../../core/services/notifications/notifications.service';
import {Router} from '@angular/router';
import {map, shareReplay, takeUntil, tap} from 'rxjs/operators';
import {BrmItemSearch} from '../../../../core/brm2/api/inventory/items/brm-item-search';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {ChangeProductFamilyDialogData} from './change-product-family-dialog-data';
import {ProductFamilyModel} from '../../services/models/product-family.model';
import {BrmSettingsService} from '../../../../core/services/brm/brm-settings.service';
import {BreadcrumbsService} from '../../../../core/services/breadcrumbs.service';

@Component({
  selector: 'app-change-product-family-dialog',
  templateUrl: './change-product-family-dialog.component.html',
  styleUrls: ['./change-product-family-dialog.component.scss']
})
export class ChangeProductFamilyDialogComponent implements OnInit, OnDestroy {

  private destroyed$: Subject<boolean> = new Subject<boolean>();

  public pageLoader$: Observable<BrmReservationsSearch>;

  public reservationCount: number;
  public reservations: BrmReservation[] = [];
  public itemsCount: number;

  public saving = false;
  public errorSaving = false;
  public errorMessage: string;
  public family: ProductFamilyModel;

  constructor(private dialogRef: MatDialogRef<ChangeProductFamilyDialogComponent>, @Inject(MAT_DIALOG_DATA) public data: ChangeProductFamilyDialogData,
              private rest: RestRequestsService, private appControl: AppControlService, private cache: BrmCacheService,
              private notify: NotificationsService, private router: Router, public brm: BrmSettingsService, private breadcrumbs: BreadcrumbsService) {
  }

  ngOnInit() {
    this.family = this.data.family;
    this.pageLoader$ = this.createPageLoader();
  }

  ngOnDestroy(): void {
    this.destroyed$.next(true);
    this.destroyed$.complete();
  }

  onCloseClick(): void {
    this.dialogRef.close({
      family: this.data.family
    });
  }

  onSubmit(): void {
    // If not already saving
    if (!this.saving) {
      // if family is the same then close the popup
      if (this.family.id === this.data.line.product_family_id) {
        this.onCloseClick();
        return;
      }

      // unset error message
      this.errorSaving = false;
      // mark as loading
      this.saving = true;
      // disable the pop-up closing from click off
      this.dialogRef.disableClose = true;

      this.cache.inventoryCache.moveProductLine(this.data.line.id, this.family.id)
        .pipe(
          takeUntil(this.destroyed$)
        ).subscribe(
        (next) => {
          // Update product line ID
          this.data.line.product_family_id = this.family.id;

          // return the data to the form
          this.dialogRef.close({
            family: this.family,
            line: next
          });
          this.notify.addSuccessNotification('Product line moved to product family [' + this.cache.inventoryCache.getProductFamily(this.family.id).name + ']');
          this.saving = false;
        },
        error => {
          this.errorMessage = error['error']['message'] || 'There was an error moving the product line';
          this.dialogRef.disableClose = false;
          this.errorSaving = true;
          this.saving = false;
        }
      );
    }
  }

  private createPageLoader(): Observable<any> {
    return forkJoin([
      this.createItemsLoader(),
      this.createReservationLoader()
    ]).pipe(
      tap({
        next: value => {
        }
      })
    );
  }

  createItemsLoader(): Observable<BrmItemSearch> {
    return this.rest.getRequest(this.appControl.apiUrl + '/items', {
      product_line_id: this.data.line.id,
      limit: 100

    }).pipe(
      map((value: BrmItemSearch) => {
        return value;
      }),
      tap({
        next: value => {
          this.itemsCount = value.total_count;
        },
        error: err => {
          this.itemsCount = -1;
        }
      })
    );
  }

  private createReservationLoader(): Observable<BrmReservationsSearch> {
    return this.rest.getRequest(this.appControl.apiUrl + '/reservations/live', {
      item_id: this.data.line.id,
    }).pipe(
      shareReplay(1),
      takeUntil(this.destroyed$),
      map((value: BrmReservationsSearch) => {
        return value;
      }),
      tap({
        next: value => {
          this.reservationCount = value.total_count;
          this.reservations = value.data;
        },
        error: err => {
          this.reservationCount = -1;
        }
      })
    );
  }

  goToReservation(entry: BrmReservation): void {
    this.dialogRef.close(true);
    this.router.navigate(['/', 'reservations', entry.reference, 'detail']).then();
  }

  public goToInventory(): void {
    this.dialogRef.close(true);

    this.router.navigate(['/', 'inventory', 'general', 'shape']).then();
  }

}

