import {NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';
import {InitialLoadGuard} from '../../core/guards/initial-load.guard';
import {AuthGuard} from '../../core/guards/auth.guard';
import {NotificationsComponent} from './notifications.component';

const routes: Routes = [
  {path: 'notifications', canActivate:[InitialLoadGuard, AuthGuard], component: NotificationsComponent},
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
  providers: []
})
export class NotificationsRoutingModule {}
