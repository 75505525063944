import {BrmCache} from './brm-cache';
import {Observable} from 'rxjs';
import {map, tap} from 'rxjs/operators';
import {RestRequestsService} from '../../services/rest-requests.service';
import {AppControlService} from '../../services/app-control.service';
import {BrmMaintenanceTask} from "../api/settings/brm-maintenance-task";

export class BrmMaintenanceTaskCache extends BrmCache<BrmMaintenanceTask[]> {

  private rest: RestRequestsService;
  private appControl: AppControlService;

  constructor(_caches, _appControl, _rest) {
    super(_caches, 'maintenancetasks');

    this.appControl = _appControl;
    this.rest = _rest;
  }

  protected loadData(): Observable<BrmMaintenanceTask[]> {
    return this.rest.getRequest(this.appControl.apiUrl + '/servicing/tasks', {}).pipe(
      map((value: BrmMaintenanceTask[]) => {
        return value;
      })
    );
  }

  public deleteMaintenanceTask(task: BrmMaintenanceTask): Observable<BrmMaintenanceTask> {
    return this.rest.deleteRequest(this.appControl.apiUrl + '/servicing/tasks/' + task.id, {}).pipe(
      tap((value: BrmMaintenanceTask) => {
          if (this.cachedData != null) {
            this.cachedData.splice(this.cachedData.indexOf(task), 1);
          }
        }, (error: any) => {
        }
      ));
  }

  public findMaintenanceTask(id: string): BrmMaintenanceTask {

    if (this.cachedData == null) {
      return null;
    }

    return this.cachedData.find((value: BrmMaintenanceTask) => {
      return value.id == id;
    })
  }

  public saveMaintenanceTask(id: string, data: object, isNew: boolean = false): Observable<BrmMaintenanceTask> {

    let apiCall = '/servicing/tasks';

    if (!isNew) {
      apiCall = '/servicing/tasks/' + id;
    }

    return this.rest.postRequest(this.appControl.apiUrl + apiCall, JSON.stringify(data)).pipe(
      map((value: BrmMaintenanceTask) => {
        return value;
      }),
      tap((value: BrmMaintenanceTask) => {
        if (isNew) {
          this.cachedData.push(value);
        } else {
          const task = this.findMaintenanceTask(id);

          if (task != null) {
            Object.assign(task, value);
          }
        }
      })
    );
  }

  protected onLoad(): void {
  }

}
