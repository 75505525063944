import {BrmCache} from './brm-cache';
import {Observable} from 'rxjs';
import {map, tap} from 'rxjs/operators';
import {RestRequestsService} from '../../services/rest-requests.service';
import {AppControlService} from '../../services/app-control.service';
import {BrmOfferingGroup} from '../api/settings/brm-offering-group';

export class BrmOfferingGroupCache extends BrmCache<BrmOfferingGroup[]> {

  private rest: RestRequestsService;
  private appControl: AppControlService;

  constructor(_caches, _appControl, _rest) {
    super(_caches, 'offeringgroups');

    this.appControl = _appControl;
    this.rest = _rest;
  }

  protected loadData(): Observable<BrmOfferingGroup[]> {
    return this.rest.getRequest(this.appControl.apiUrl + '/offeringgroups', {}).pipe(
      map((value: BrmOfferingGroup[]) => {
        return value;
      })
    );
  }

  public deleteOfferingGroup(offeringGroup: BrmOfferingGroup): Observable<BrmOfferingGroup> {
    return this.rest.deleteRequest(this.appControl.apiUrl + '/offeringgroups/' + offeringGroup.id, {}).pipe(
      tap((value: BrmOfferingGroup) => {
          if (this.cachedData != null) {
            this.cachedData.splice(this.cachedData.indexOf(offeringGroup), 1);
          }
        }, (error: any) => {
        }
      ));
  }

  public findOfferingGroup(id: number): BrmOfferingGroup {

    if (this.cachedData == null) {
      return null;
    }

    return this.cachedData.find((value: BrmOfferingGroup) => {
      return value.id == id;
    });
  }

  public saveOfferingGroup(id: number, data: object, isNew: boolean = false): Observable<BrmOfferingGroup> {

    let apiCall = '/offeringgroups';

    if (!isNew) {
      apiCall = '/offeringgroups/' + id;
    }

    return this.rest.postRequest(this.appControl.apiUrl + apiCall, JSON.stringify(data)).pipe(
      map((value: BrmOfferingGroup) => {
        return value;
      }),
      tap((value: BrmOfferingGroup) => {
        if (isNew) {
          this.cachedData.push(value);
        } else {
          const offeringGroup = this.findOfferingGroup(id);

          if (offeringGroup != null) {
            Object.assign(offeringGroup, value);
          }
        }
      })
    );
  }

  protected onLoad(): void {
  }

}
