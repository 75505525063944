import {Injectable, isDevMode} from '@angular/core';
import {Observable} from 'rxjs';
import {map} from 'rxjs/operators';
import {environment} from '../../../../../environments/environment';
import {RestRequestsService} from '../../../services/rest-requests.service';

/**
 * Represents a name & value for an online 'language'
 */
export interface BrmLocaleLanguage {
  name: string;
  value: string;
}

/**
 * Represents a code & name data object
 */
export interface BrmLocaleCodeNamePair {
  Code: string;
  Name: string;
}

interface BrmLocaleLanguageResponse {
  language: BrmLocaleLanguage[];
}

@Injectable({
  providedIn: 'root',
})
export class BrmLocaleService {
  constructor(
    private rest: RestRequestsService
  ) {
  }

  /**
   * Returns the base URL for API calls
   * @private also exists at {@link AppControlService#baseAddress} but duplicated to avoid circular dependency
   */
  private baseAddress(): string {
    return isDevMode() ? environment.devBaseUrl : environment.baseUrl;
  }

  public getOnlineLanguages(): Observable<BrmLocaleLanguageResponse> {
    return this.rest.getRequest(`${this.baseAddress()}locale/online`, {});
  }

  public getAvailableLanguages(): Observable<BrmLocaleLanguage[]> {
    return this.getOnlineLanguages().pipe(map((value) => value.language));
  }

  /**
   * Retrieves a list of valid Country Codes and Display Names
   */
  public getCountryCodes(activeLanguageCode: string): Observable<BrmLocaleCodeNamePair[]> {
    return this.rest.getRequest(`${this.baseAddress()}locale/countrycodes`, {lang: activeLanguageCode});
  }

  /**
   * Retrieves a list of valid Country Codes and Display Names
   */
  public getTimeZones(): Observable<string[]> {
    return this.rest.getRequest(`${this.baseAddress()}locale/timezone`, {});
  }

  /**
   * Retrieves a list of valid Currency Codes and Display Names
   */
  public getCurrencies(activeLanguageCode: string): Observable<BrmLocaleCodeNamePair[]> {
    return this.rest.getRequest(`${this.baseAddress()}locale/currency`, {lang: activeLanguageCode});
  }

}
