import {Component, Inject, OnInit} from '@angular/core';
import {Observable, of} from 'rxjs';
import {EventHistoryDialog} from '../../../shared/event-history-dialog/event-history-dialog';
import {RestRequestsService} from '../../../../core/services/rest-requests.service';
import {AppControlService} from '../../../../core/services/app-control.service';
import {BrmInventoryHistoryEntry} from '../../../../core/brm2/api/inventory/brm-inventory-history-entry';
import {BrmItem} from '../../../../core/brm2/api/inventory/items/brm-item';
import {ProductLineModel} from '../../services/models/product-line.model';
import {map, tap} from 'rxjs/operators';
import {ReservationsViewerDialog} from './reservations-viewer-dialog';
import {BrmReservationsSearch} from '../../../../core/brm2/api/reservations/brm-reservations-search';
import {GeneralUtil} from '../../../../core/util';
import {BrmReservation} from '../../../../core/brm2/api/reservations/brm-reservation';
import {Router} from '@angular/router';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';

@Component({
  selector: 'app-reservations-viewer-dialog',
  templateUrl: './reservations-viewer-dialog.component.html',
  styleUrls: ['./reservations-viewer-dialog.component.scss']
})
export class ReservationsViewerDialogComponent implements OnInit {

  public pageLoader$: Observable<any>;
  public historyTitle = 'Item';

  public name = '';
  public id;

  constructor(public dialogRef: MatDialogRef<ReservationsViewerDialogComponent>,
              @Inject(MAT_DIALOG_DATA) public data: ReservationsViewerDialog,
              private rest: RestRequestsService, private appControl: AppControlService,
              private router: Router) {
    this.pageLoader$ = this.createPageLoader();
  }

  ngOnInit() {
  }

  private createPageLoader(): Observable<BrmReservationsSearch> {
    switch (this.data.type) {
      default:
      case 'item':
        this.historyTitle = 'Item';
        this.name = (<BrmItem>this.data.data).store_ref;
        this.id = (<BrmItem>this.data.data).id;
        break;
      case 'pl':
        this.historyTitle = 'Product Line';
        this.name = (<ProductLineModel>this.data.data).name;
        this.id = (<ProductLineModel>this.data.data).id;
        break;
    }

    return this.rest.getRequest(this.appControl.apiUrl + '/reservations/live', {
      item_id: this.id
    }).pipe(
      map((value: BrmReservationsSearch) => {
        return value;
      })
    );
  }

  goToReservation(entry: BrmReservation): void {
    this.dialogRef.close(true);
    this.router.navigate(['/', 'reservations', entry.reference, 'detail']).then();
  }

  onClickCloseButton(): void {
    this.dialogRef.close(true);
  }


}
