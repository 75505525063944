import { Injectable } from '@angular/core';
import { BrmSessionService } from '../../brm-session.service';
import { DateTime } from 'luxon';

declare var userGuiding: any;

@Injectable({
  providedIn: 'root'
})
export class UserGuidingService {

  private timeOut: number;
  private attempts: number = 0;

  constructor(private brmSession: BrmSessionService) {
  }

  public initialiseUserGuiding(): void {
    this.doAttempt();
  }

  private doAttempt(): void {
    const attempt = this.tryInitialise();

    if (!attempt && this.attempts < 40) {
      this.timeOut = window.setTimeout(() => {
        this.doAttempt();
      }, 50);
    } else {
      clearTimeout(this.timeOut);
    }
  }

  private daysInTrialRemaining(): number {
    try {
      const endDate = DateTime.fromISO(this.brmSession.session.account.trial_end_date);
      const now = DateTime.utc();

      return Math.round(endDate.diff(now, 'days').toObject()['days']);
    } catch (error) {
      return -1;
    }
  }

  private tryInitialise(): boolean {
    this.attempts++;

    try {
      if (userGuiding) {
        userGuiding.identify(`${this.brmSession.session.user.id}`);
        userGuiding.track('segment', {
          dataCenter: this.brmSession.session.data_center,
          namespace: this.brmSession.session.shop_namespace,
          package: this.brmSession.session.account.product_package,
          trialEndDate: this.brmSession.session.account.trial_end_date,
          trial: this.daysInTrialRemaining() > 0,
          email: this.brmSession.session.user.email
        });
      }
    } catch (error) {
    }

    return false;
  }
}
