import {Injectable} from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class LoggerService {

  private _messages: LogMessage[];

  constructor() {
    this._messages = [
      new LogMessage('Error contacting server', LOGGER_TYPES.ERROR),
      new LogMessage('Really log notification message test, Really log notification message test' +
        ', Really log notification message test, Really log notification message test', LOGGER_TYPES.SUCCESS),
      new LogMessage('Duplicated categories detected', LOGGER_TYPES.WARNING)
    ];
  }

  public log(msg: string, type?: LOGGER_TYPES) {
    if (type == null) {
      type = 0;
    }

    this._messages.push(new LogMessage(msg, type));

    if (type === LOGGER_TYPES.SUCCESS) {
      console.log(msg);
    } else if (type === LOGGER_TYPES.WARNING) {
      console.warn(msg);
    } else if (type === LOGGER_TYPES.ERROR) {
      console.error(msg);
    }
  }

  public get messages(): LogMessage[] {
    return this._messages;
  }

}

export enum LOGGER_TYPES {
  SUCCESS = 0,
  WARNING = 1,
  ERROR = 2
}

export class LogMessage {
  type: LOGGER_TYPES;
  message: string;
  time: number;

  constructor(message: string, type?: LOGGER_TYPES) {
    if (type == null) {
      type = LOGGER_TYPES.SUCCESS;
    }

    this.type = type;
    this.message = message;
    this.time = new Date().getTime();
  }

  public getTimeString(): string {
    const date = new Date(this.time);

    return date.toLocaleString();
  }
}
