export class BrmDateAndTimeFormats {

  public static readonly DAY_CODES: string[] = [
    'Mo',
    'Tu',
    'We',
    'Th',
    'Fr',
    'Sa',
    'Su'
  ];

  public static readonly UK_DATES_FORMAT = 'DD/MM/YYYY';
  public static readonly UK_LUXON_DATES_FORMAT = 'dd/LL/yyyy';
  public static readonly UK_TIME_FORMAT = 'HH:mm';
  public static readonly UK_LUXON_TIME_FORMAT = 'HH:mm';
  public static readonly UK_LUXON_TIME_FORMAT_HINT = 'HH:MM';
  public static readonly UK_TIME_FULL_FORMAT = 'HH:mm:ss';
  public static readonly UK_LUXON_TIME_FULL_FORMAT = 'HH:mm:ss';

  public static readonly US_DATES_FORMAT = 'MM/DD/YYYY';
  public static readonly US_LUXON_DATES_FORMAT = 'LL/dd/yyyy';
  public static readonly US_TIME_FORMAT = 'hh:mm a';
  public static readonly US_LUXON_TIME_FORMAT = 'hh:mm a';
  public static readonly US_LUXON_TIME_FORMAT_HINT = 'HH:MM am/pm';
  public static readonly US_TIME_FULL_FORMAT = 'hh:mm:ss a';
  public static readonly US_LUXON_TIME_FULL_FORMAT = 'hh:mm:ss a';

  public static readonly FR_DATES_FORMAT = 'DD.MM.YYYY';
  public static readonly FR_LUXON_DATES_FORMAT = 'dd.LL.yyyy';
  public static readonly FR_TIME_FORMAT = 'HH:mm';
  public static readonly FR_LUXON_TIME_FORMAT = 'HH:mm';
  public static readonly FR_LUXON_TIME_FORMAT_HINT = 'HH:MM';
  public static readonly FR_TIME_FULL_FORMAT = 'HH:mm:ss';
  public static readonly FR_LUXON_TIME_FULL_FORMAT = 'HH:mm:ss';

  public static readonly JP_DATES_FORMAT = 'YYYY/MM/DD';
  public static readonly JP_LUXON_DATES_FORMAT = 'yyyy/LL/dd';

  public static readonly DATE_FORMAT_OBJ = {
    'UK': BrmDateAndTimeFormats.UK_DATES_FORMAT,
    'US': BrmDateAndTimeFormats.US_DATES_FORMAT,
    'FR': BrmDateAndTimeFormats.FR_DATES_FORMAT,
    'JP': BrmDateAndTimeFormats.JP_DATES_FORMAT
  };

  public static readonly DATE_LUXON_FORMAT_OBJ = {
    'UK': BrmDateAndTimeFormats.UK_LUXON_DATES_FORMAT,
    'US': BrmDateAndTimeFormats.US_LUXON_DATES_FORMAT,
    'FR': BrmDateAndTimeFormats.FR_LUXON_DATES_FORMAT,
    'JP': BrmDateAndTimeFormats.JP_LUXON_DATES_FORMAT
  };

  public static readonly TIME_FORMAT_OBJ = {
    'UK': BrmDateAndTimeFormats.UK_TIME_FORMAT,
    'US': BrmDateAndTimeFormats.US_TIME_FORMAT,
    'FR': BrmDateAndTimeFormats.FR_TIME_FORMAT
  };

  public static readonly TIME_LUXON_FORMAT_OBJ = {
    'UK': BrmDateAndTimeFormats.UK_LUXON_TIME_FORMAT,
    'US': BrmDateAndTimeFormats.US_LUXON_TIME_FORMAT,
    'FR': BrmDateAndTimeFormats.FR_LUXON_TIME_FORMAT
  };

  public static readonly TIME_LUXON_FORMAT_HINT_OBJ = {
    'UK': BrmDateAndTimeFormats.UK_LUXON_TIME_FORMAT_HINT,
    'US': BrmDateAndTimeFormats.US_LUXON_TIME_FORMAT_HINT,
    'FR': BrmDateAndTimeFormats.FR_LUXON_TIME_FORMAT_HINT
  };

  // UK, FR are 24 hour format, US is 12 hour format
  public static readonly TWELVE_HOUR_IDS = 'US';

  public static readonly TIME_FULL_FORMAT_OBJ = {
    'UK': BrmDateAndTimeFormats.UK_TIME_FULL_FORMAT,
    'US': BrmDateAndTimeFormats.US_TIME_FULL_FORMAT,
    'FR': BrmDateAndTimeFormats.FR_TIME_FULL_FORMAT,
  };

  public static readonly TIME_LUXON_FULL_FORMAT_OBJ = {
    'UK': BrmDateAndTimeFormats.UK_LUXON_TIME_FULL_FORMAT,
    'US': BrmDateAndTimeFormats.US_LUXON_TIME_FULL_FORMAT,
    'FR': BrmDateAndTimeFormats.FR_LUXON_TIME_FULL_FORMAT
  };

  // List of all 12 hour formats that we want to support when user inputs a time
  public static readonly TWELVE_HOUR_FORMATS: string[] = [
    'hmma', // ?
    'h mm a',
    'h:mm a',
    'h mma',
    'h:mma',
    'hmmA', // ?
    'h mm A',
    'h:mm A',
    'h mmA',
    'h:mmA',
    'hhmma', // ?
    'hh mm a',
    'hh:mm a',
    'hh mma',
    'hh:mma',
    'hhmmA', // ?
    'hh mm A',
    'hh:mm A',
    'hh mmA',
    'hh:mmA',
    'hA',
    'h A',
    'hhA',
    'hh A',
    'ha',
    'h a',
    'hha',
    'hh a',
  ];

  // List of all 24 hour formats we want to support when a user inputs a time
  public static readonly TWENTY_FOUR_HOUR_FORMATS: string[] = [
    'HH mm',
    'HH:mm',
    'H mm',
    'H:mm'
  ];

  public static readonly DAY_SUNDAY: string = 'SUNDAY';
  public static readonly DAY_MONDAY: string = 'MONDAY';
  public static readonly DAY_TUESDAY: string = 'TUESDAY';
  public static readonly DAY_WEDNESDAY: string = 'WEDNESDAY';
  public static readonly DAY_THURSDAY: string = 'THURSDAY';
  public static readonly DAY_FRIDAY: string = 'FRIDAY';
  public static readonly DAY_SATURDAY: string = 'SATURDAY';

  public static readonly DAYS_OF_WEEK: string[] = [
    BrmDateAndTimeFormats.DAY_SUNDAY,
    BrmDateAndTimeFormats.DAY_MONDAY,
    BrmDateAndTimeFormats.DAY_TUESDAY,
    BrmDateAndTimeFormats.DAY_WEDNESDAY,
    BrmDateAndTimeFormats.DAY_THURSDAY,
    BrmDateAndTimeFormats.DAY_FRIDAY,
    BrmDateAndTimeFormats.DAY_SATURDAY
  ];

  public static TIME_ISO_REGEX_PATTERN = /^([0-1]?[0-9]|2[0-3]):([0-5][0-9])$/;

}
