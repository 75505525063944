import {BrmInventoryTypes} from '../data/brm-inventory-types';
import {isPhysicalAssetId} from '../data/brm-inventory-data';

export function isIdAssetOrProductLine(id: number): BrmInventoryTypes {
  if (!isPhysicalAssetId(id)) {
    return BrmInventoryTypes.PRODUCTLINE;
  }

  return BrmInventoryTypes.ASSET;
}

export function downloadDataAsCsv(data: object[], fileName: string): void {
  if (data == null || data.length === 0) {
    return;
  }

  const keys = Object.keys(data[0]);
  const csvHeader = keys.join(',') + '\r\n';
  const bodyData = data.map(row => keys.map(key => row[key]).join(',')).join('\r\n');
  const completeData = csvHeader + bodyData;

  downloadFile(completeData, `${fileName}.csv`, 'text/csv');
}


export function downloadDataAsJson(data: object[], fileName: string): void {
  if (data == null || data.length === 0) {
    return;
  }
  downloadFile(JSON.stringify(data), `${fileName}.json`);
}


export function downloadFile(data, fileName: string, fileType: string = 'application/json'): void {
  const blob = new Blob([data], {type: fileType});
  const downloadUrl = window.URL.createObjectURL(blob);
  const link = document.createElement('a');
  link.style.display = 'none';
  link.href = downloadUrl;
  link.download = `${fileName}`;
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
  window.URL.revokeObjectURL(downloadUrl);
}
