import {Component, Inject, OnDestroy, OnInit} from '@angular/core';
import {ActionModalDialogData} from './action-modal-dialog-data';
import {Observable, Subject, Subscription} from 'rxjs';
import {DomSanitizer, SafeHtml, SafeStyle} from '@angular/platform-browser';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';

@Component({
  selector: 'app-action-modal-dialog',
  templateUrl: './action-modal-dialog.component.html',
  styleUrls: ['./action-modal-dialog.component.scss']
})
export class ActionModalDialogComponent implements OnInit, OnDestroy {

  private destroyed$: Subject<boolean> = new Subject<boolean>();
  private actionSubscription: Subscription;

  public loading = false;

  public content: SafeHtml = '';

  public cancelBtnText: string = 'Cancel';

  constructor(public dialogRef: MatDialogRef<ActionModalDialogData>,
              @Inject(MAT_DIALOG_DATA) public data: ActionModalDialogData,
              private _sanitizer: DomSanitizer) {
    if (this.data.contentText != null) {
      this.content = this.sanitiseContent(this.data.contentText);
    }

    if (this.data.cancelText != null) {
      this.cancelBtnText = this.data.cancelText;
    }
  }

  ngOnInit() {
  }

  ngOnDestroy(): void {
    this.destroyed$.next(true);
    this.destroyed$.complete();
  }

  onCancelClick(): void {
    this.dialogRef.close(false);
  }

  onConfirmClick(): void {
    if (this.data.action) {
      this.loading = true;

      this.actionSubscription = this.data.action.subscribe(
        (next) => {
          this.dialogRef.close(true);
        },
        error => {
          this.dialogRef.close(false);
          this.loading = false;
        },
        () => {
          this.loading = false;
        }
      );
    } else {
      this.dialogRef.close(true);
    }
  }

  onLinkClick(): void {
    if (this.data.leftLinkAction) {
      this.loading = true;

      this.actionSubscription = this.data.leftLinkAction.subscribe(
        (next) => {
          this.dialogRef.close(true);
        },
        error => {
          this.dialogRef.close(false);
          this.loading = false;
        },
        () => {
          this.loading = false;
        }
      );
    } else {
      this.dialogRef.close(true);
    }
  }

  private sanitiseContent(data: string): SafeHtml {
    return this._sanitizer.bypassSecurityTrustHtml(data);
  }

}
