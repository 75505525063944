import {BrmCache} from './brm-cache';
import {Observable} from 'rxjs';
import {map, tap} from 'rxjs/operators';
import {RestRequestsService} from '../../services/rest-requests.service';
import {AppControlService} from '../../services/app-control.service';
import {BrmCategory} from '../api/categories/brm-category';
import {BrmOpeningTimes, BrmOpeningTimesDay} from '../api/settings/brm-opening-times-response.model';

export class BrmOpeningHoursCache extends BrmCache<BrmOpeningTimes> {

  private rest: RestRequestsService;
  private appControl: AppControlService;

  constructor(_caches, _appControl, _rest) {
    super(_caches, 'openinghours');

    this.appControl = _appControl;
    this.rest = _rest;
  }

  protected loadData(): Observable<BrmOpeningTimes> {
    return this.rest.getRequest(this.appControl.apiUrl + '/settings/openinghours', {}).pipe(
      map((value: BrmOpeningTimes) => {
        return value;
      })
    );
  }

  protected onLoad(): void {
  }

  public update(times: BrmOpeningTimes): Observable<BrmOpeningTimes> {
    return this.rest.postRequest(`${this.appControl.apiUrl}/settings/openinghours`, JSON.stringify(times)).pipe(
      map((value: BrmOpeningTimes) => {
        return value;
      }),
      tap({
        next: (value: BrmOpeningTimes) => {
          this.cachedData = value;
        }
      })
    );
  }

  public getDay(day: 'Mo' | 'Tu' | 'We' | 'Th' | 'Fr' | 'Sa' | 'Su'): BrmOpeningTimesDay {
    const dayLower = (day || '').toLowerCase();

    if (this.hasDataLoaded()) {
      const dayKey = Object.keys(this.cachedData).find((key: string) => {
        return dayLower === key.toLowerCase();
      });

      if (dayKey != null) {
        return this.cachedData[dayKey];
      }
    }

    return null;
  }

}
