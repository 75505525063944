import {Component, HostListener, OnInit} from '@angular/core';
import {FirebaseAuthService} from './core/services/firebase-auth.service';
import {AppControlService} from './core/services/app-control.service';
import {environment} from '../environments/environment.prod';
import {ShellControlService} from './modules/shell/shell-control.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {
  title = 'brm-inventory';

  constructor(private fbAuth: FirebaseAuthService, private appControl: AppControlService, private shellControl: ShellControlService) {
    console.log('Version: ' + this.appControl.version);
    console.log(this.fbAuth);
    console.log('Live API: ' + environment.apiUrl);
    console.log('Dev API: ' + environment.devBaseUrl);
  }

  ngOnInit(): void {
    this.onAppResize(null);
  }

  /***
   * Gets the width of the document and automatically hides the navigation bar if they switch into mobile dimensions
   * @param event
   */
  @HostListener('window:resize', ['$event'])
  private onAppResize(event): void {
    // Credit: jQuery
    // Get width
    const width = Math.max(
      document.documentElement['clientWidth'],
      document.body['scrollWidth'],
      document.documentElement['scrollWidth'],
      document.body['offsetWidth'],
      document.documentElement['offsetWidth']
    );

    // If less than 786px, hide navigation bar
    if (width < 786) {
      this.shellControl.expandedNavigation = false;
    }

  }


}
