import {AfterContentChecked, Component, HostListener, isDevMode, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {UntypedFormControl, UntypedFormGroup, ValidatorFn, Validators} from '@angular/forms';
import {ActivatedRoute, Router} from '@angular/router';
import {LoggerService} from '../../../core/services/logger.service';
import {RestRequestsService} from '../../../core/services/rest-requests.service';
import {observable, Observable, of, Subject, Subscription, throwError} from 'rxjs';
import {animate, state, style, transition, trigger} from '@angular/animations';
import {COMMA, ENTER} from '@angular/cdk/keycodes';
import {ShellControlService} from '../../shell/shell-control.service';
import {AppControlService} from '../../../core/services/app-control.service';
import {map, filter, catchError, mergeMap, startWith, switchMap, tap, takeUntil} from 'rxjs/operators';
import {BrmSettingsService} from '../../../core/services/brm/brm-settings.service';
import {brm_taxonomy_types} from '../../../core/services/brm/data/brm-taxonomy-types';
import {FormCanDeactivateAbstractComponent} from '../../../core/guards/abstract/form-can-deactivate.abstract.component';
import {Brm400Error} from '../../../core/services/brm/responses/brm-400-error';
import {NotificationsService} from '../../../core/services/notifications/notifications.service';
import {CategoryModel} from '../services/models/category.model';
import {BrmCacheService} from '../../../core/brm2/brm-cache.service';
import {MatDialog, MatDialogConfig} from '@angular/material/dialog';
import {EventHistoryDialog} from '../../shared/event-history-dialog/event-history-dialog';
import {EventHistoryDialogComponent} from '../../shared/event-history-dialog/event-history-dialog.component';
import {BreadcrumbsService} from '../../../core/services/breadcrumbs.service';
import {BrmLanguagesService} from '../../../core/brm2/services/languages/brm-languages.service';
import {MatAutocompleteSelectedEvent} from '@angular/material/autocomplete';
import {BrmCategory, BrmCategoryTaxonomy} from '../../../core/brm2/api/inventory/shape/brm-category';
import {BrmSessionService} from '../../../core/brm2/brm-session.service';
import {GeneralUtil} from '../../../core/util';
import {LoadingDialogComponent} from '../../shared/loading-dialog/loading-dialog.component';
import {HttpEvent, HttpEventType, HttpProgressEvent, HttpResponse} from '@angular/common/http';
import {MessagedialogueComponent} from '../../shared/messagedialogue/messagedialogue.component';
import {BrmPrivilegesService} from '../../../core/brm2/services/privileges/brm-privileges.service';
import { ActionModalDialogComponent } from '../../shared/action-modal-dialog/action-modal-dialog.component';

@Component({
  selector: 'app-edit-category',
  templateUrl: './edit-category.component.html',
  styleUrls: ['./edit-category.component.scss', '../shared/inventory-shared.scss'],
  animations: [
    trigger('openCloseSection', [
      state('hide', style({height: 0, 'padding-top': 0, 'padding-bottom': 0})),
      state('*', style({height: 0, 'padding-top': 0, 'padding-bottom': 0})),
      state('show', style({height: '*', 'padding-top': '*', 'padding-bottom': '*'})),
      transition('* => *', animate('500ms ease-in-out'))
    ])
  ]
})
export class EditCategoryComponent extends FormCanDeactivateAbstractComponent implements OnInit, OnDestroy {

  public editForm: UntypedFormGroup;
  public destroyed$: Subject<boolean> = new Subject<boolean>();

  @ViewChild('categoryTagInput') categoryTagInput;

  public setTags = [];
  public setTags_indirect = [];
  public brmCategoryTaxonomy: BrmCategoryTaxonomy;
  readonly separatorKeysCodes: number[] = [ENTER];
  public imageOutput: string;
  // Set Image Dirty to false initially, as any image we load via the API is the original and we don't want to resend
  // the image data if it's unnecessary
  public imageDirty = false;
  public tagsDirty = false;

  public tagsFilter$: Observable<object[]>;

  public pageLoader$: Observable<CategoryModel>;
  public category: CategoryModel;
  public id: string;

  public requiredDefNameValidator: ValidatorFn[] = [Validators.required];
  public categoryNameLength: ValidatorFn[] = [Validators.maxLength(50)];
  public isOpsUser: boolean = this.brmPrivileges.isOperationsUser();

  constructor(public router: Router, private logger: LoggerService,
              private rest: RestRequestsService, private shellControl: ShellControlService,
              private brm: BrmSettingsService, private appControl: AppControlService,
              private notify: NotificationsService, private activatedRoute: ActivatedRoute,
              private dialog: MatDialog, public cache: BrmCacheService,
              private breadcrumbs: BreadcrumbsService,
              private brmLanguage: BrmLanguagesService,
              public brmSession: BrmSessionService,
              private brmPrivileges: BrmPrivilegesService) {
    super();

    // Form instance
    this.editForm = new UntypedFormGroup({
      image: new UntypedFormControl(null),
      tag: new UntypedFormControl(null),
      internal_only: new UntypedFormControl(false),
      language_config: new UntypedFormGroup({})
    });

    if (this.activatedRoute.snapshot.paramMap.has('id')) {
      this.id = this.activatedRoute.snapshot.paramMap.get('id');
    } else {
      this.onPageLoadFailure();
    }

    // Setup observable, listens for value changes on the tag input, then updates tags
    this.tagsFilter$ = this.editForm.controls['tag'].valueChanges.pipe(
      startWith(''),
      map(value => this.tagFilter(value))
    );
    this.pageLoader$ = this.createPageLoader();
  }

  ngOnInit() {

  }

  ngOnDestroy(): void {
    this.destroyed$.next(true);
    this.destroyed$.complete();
  }

  private createPageLoader(): Observable<any> {
    return this.cache.inventoryCache.loadCategory(this.id).pipe(
      tap({
        next: (data: BrmCategory) => {
          this.category = data;
        },
        complete: () => {
          this.setupBreadcrumbs();
          this.updateForm();
        }
      }),
      catchError(err => {
        this.onPageLoadFailure();

        return of(null);
      })
    );
  }

  private setupBreadcrumbs(): void {
    if (this.category) {
      const defLang = this.brm.session.defaultLanguage;

      let categoryName = '';

      if (this.category.language_config.hasOwnProperty(defLang)) {
        categoryName = this.category.language_config[defLang]['name'];
      }

      this.breadcrumbs.updateBreadcrumb('category-edit', {
        label: `Edit category "${categoryName}"`,
        path: ['/', 'inventory', 'general', 'shape', {queryParams: {category: this.category.id}}],
        hidden: false
      });
    }
  }

  public onPageLoadFailure(): void {
    this.notify.addFailNotification('Failed to load category data');
    this.router.navigate(['/', 'inventory']);
  }

  /***
   * Takes input from tag formControl and filters out suggests for tags to be added
   */
  public tagFilter(value: string): object[] {
    value = value.toLowerCase();


    // Check we have input to check against
    if (value.length === 0) {
      const allTags = [];

      Object.keys(brm_taxonomy_types).forEach(key => {
        if (this.setTags.indexOf(key) === -1) {
          allTags.push({
            label: brm_taxonomy_types[key],
            value: key
          });
        }
      });

      // Return all tags if no input
      return allTags;
    }

    const responseTags = [];

    // Loop through all the tags
    Object.keys(brm_taxonomy_types).forEach((key) => {
      const tagLower = brm_taxonomy_types[key].toLowerCase();

      // If our search string is contained within the tag string, we add it to the suggested tags
      if (tagLower.includes(value) && this.setTags.indexOf(key) === -1) {
        responseTags.push({
          label: brm_taxonomy_types[key],
          value: key
        });
      }
    });

    // Return our tags
    return responseTags;
  }

  public isFormDirty(): boolean {
    return this.editForm.controls['language_config'].dirty ||
      this.imageDirty ||
      this.editForm.controls['internal_only'].dirty || this.tagsDirty;
  }

  public removeTag(tag: string): void {
    if (this.setTags.indexOf(tag) >= 0) {
      if (this.categoryTagInput != null) {
        this.editForm.controls['tag'].setValue(' ');
        this.editForm.controls['tag'].setValue('');
      }
      this.setTags.splice(this.setTags.indexOf(tag), 1);
      this.tagsDirty = true;
      // this.editForm.markAsDirty();
    }
  }

  public addTag(tag: string): void {
    if (this.setTags.indexOf(tag) === -1 && tag.length > 0 && brm_taxonomy_types[tag] != null) {
      this.setTags.push(tag);

      this.editForm.controls['tag'].updateValueAndValidity({onlySelf: false, emitEvent: true});

      this.categoryTagInput.nativeElement.value = '';
      this.tagsDirty = true;
    }
  }

  public tagSelected(data: MatAutocompleteSelectedEvent): void {
    this.addTag(data.option.value);
  }

  public onImageOutput(imageData: string): void {
    this.editForm.markAsDirty();
    this.imageOutput = imageData;
    this.imageDirty = true;
  }

  private updateForm(): void {
    this.tagsDirty = false;
    this.imageDirty = false;

    this.setTags = this.category.taxonomy_terms;
    this.setTags_indirect = this.category.indirect_taxonomy_terms;
    // this.editForm.controls['name'].setValue(this.invent.activeCategory.name);
    this.editForm.controls['internal_only'].setValue(this.category.internal_only);
  }

  public getTagLabel(tagKey: string): string {
    return brm_taxonomy_types[tagKey];
  }

  public submitForm(): void {
    if (this.editForm.valid) {
      const categoryNameChanged = Object.entries(this.category.language_config).some(
        ([key, { name }]) =>
          name !== this.editForm.controls['language_config'].value[key].name
      );
      if (categoryNameChanged) {
        const config: MatDialogConfig = GeneralUtil.smallDialogConfigPopup(true);
        config.data = {
          displayButton: true,
          displayCancel: true,
          buttonType: 'danger',
          buttonText: 'Save',
          dialogTitle: 'Update category name?',
          contentText: 'Making these changes will affect child items. Are you sure you want to proceed?',
          action: this.updateCategory(),
        };
        this.dialog.open(ActionModalDialogComponent, config);
      } else {
        this.updateCategory();
      }
    }
  }

  private updateCategory(): void {
    const updateObj = {
      'id': this.category.id,
      'name': this.editForm.value['name'],
      'taxonomy_terms': this.setTags,
      'indirect_taxonomy_terms': this.setTags_indirect,
      'internal_only': this.editForm.value['internal_only'],
      'language_config': this.editForm.controls['language_config'].value
    };

    if (this.imageDirty) {
      updateObj['image_data_uri'] = this.imageOutput;
    }

    updateObj['language_config'] = this.brmLanguage.readyLanguageConfigDataForApi(updateObj['language_config'], this.category);


    const loaderDialogData = {
      message: 'Updating category...'
    };

    loaderDialogData['observable'] = this.cache.inventoryCache.updateCategory(this.category.id, updateObj, true).pipe(
      takeUntil(this.destroyed$),
      tap(
        (response: HttpEvent<BrmCategory>) => {
          if (response.type === HttpEventType.UploadProgress) {
            const eventProgress: HttpProgressEvent = response;
            const percentage = Math.round(eventProgress.loaded / eventProgress.total * 100);

            if (percentage !== 100) {
              loaderDialogData.message = `${percentage}% uploaded`;
            } else {
              loaderDialogData.message = `Processing data...`;
            }
          } else if (response.type === HttpEventType.Response) {
            this.editForm.markAsPristine();
            this.imageDirty = false;
            this.tagsDirty = false;
          }
        })
    );

    const config: MatDialogConfig = GeneralUtil.smallDialogConfigPopup(true);
    config.data = loaderDialogData;

    this.dialog.open(LoadingDialogComponent, config);

  }

  onClickHistoryButton(): void {
    const config: MatDialogConfig = new MatDialogConfig();
    const data: EventHistoryDialog = {
      id: this.category.id.toString(),
      type: 'category',
      data: this.category
    };

    config.data = data;
    config.width = '66%';
    config.disableClose = false;

    const dialogRef = this.dialog.open(EventHistoryDialogComponent, config);

    dialogRef.afterClosed().subscribe(result => {
    });
  }

  /**
   * Goes back to the inventory page
   */
  public goToInventory(): void {
    // navigate to / which is the inventory
    this.router.navigate(['/', 'inventory', 'general', 'shape']).then();
  }

  canDeactivate(): boolean {
    return !this.editForm.dirty;
  }

  public determineTag(): void {
    // Calculate tag without waiting for night cycle job
    const updateObj = {
      'categoryId': this.category.id,
    };
    this.cache.inventoryCache.determineTaxonomy(this.brmSession.session.shop_namespace, updateObj, true)
      .subscribe((response: HttpEvent<BrmCategoryTaxonomy>) => {
        if (response.type === HttpEventType.Response) {
          this.brmCategoryTaxonomy = response.body;
          this.editForm.markAsPristine();
          this.imageDirty = false;
          this.tagsDirty = false;
          const directTaxonomy = [];
          const inDirectTaxonomy = [];
          const calculatedTaxonomy = [];

          this.brmCategoryTaxonomy.direct_taxonomy.forEach(key => {
            directTaxonomy.push(this.getTagLabel(key));
          });

          this.brmCategoryTaxonomy.saved_indirect_taxonomy.forEach(key => {
            inDirectTaxonomy.push(this.getTagLabel(key));
          });

          this.brmCategoryTaxonomy.calculated_indirect_taxonomy.forEach(key => {
            calculatedTaxonomy.push(this.getTagLabel(key));
          });

          const messageDialogData = `<h5>Tag Details </h5>
            <strong>Direct  Tags:</strong> ${directTaxonomy.toString()}
            <br><strong>Indirect Tags:</strong> ${inDirectTaxonomy.toString()}
            <br><strong>Calculated Indirect Tags:</strong> ${calculatedTaxonomy.toString()}`;
          const config: MatDialogConfig = GeneralUtil.smallDialogConfigPopup(true);
          config.data = messageDialogData;
          this.dialog.open(MessagedialogueComponent, config);
        }
      });
  }
}
