import {UntypedFormControl, ValidationErrors, ValidatorFn} from '@angular/forms';
import {GeneralUtil} from '../util';

export function wildcardOrMinLengthValidator(minLength: number): ValidatorFn {

  return (control: UntypedFormControl) => {
    let test: boolean = false;

    if (control.value !== null) {
      const controlValueStr = control.value.toString().trim();
      test = controlValueStr.length > 0 && controlValueStr.length < minLength ? false : true;
    }

    if (test) {
      return null;
    } else {
      return {
        wildcardOrMinLength: true
      };
    }
  };
}

export function percentageValidatorOptional(control: UntypedFormControl): ValidationErrors {
  if (GeneralUtil.isNonEmptyString(control.value)) {
    return percentageValidator(control);
  } else {
    return null;
  }
}

export function percentageValidator(control: UntypedFormControl): ValidationErrors {
  const regex = RegExp('^(100|[0-9]{1,2})(\\.[0-9]{0,2})?$');

  if (regex.test(control.value)) {
    return null;
  } else {
    return {
      taxRate: true
    };
  }
}
