import {UntypedFormControl, ValidationErrors} from '@angular/forms';

export function taxRateValidator(control: UntypedFormControl): ValidationErrors {
  const regex = RegExp('^([0-9]{1,2}(\\.[0-9]{0,4})?)$');

  if (regex.test(control.value)) {
    return null;
  } else {
    return {
      taxRate: true
    };
  }
}
