import {Component, Input, OnChanges, OnInit, SimpleChanges} from '@angular/core';
import {Observable, of} from 'rxjs';
import {RestRequestsService} from '../../../../core/services/rest-requests.service';
import {DomSanitizer, SafeHtml} from '@angular/platform-browser';
import {catchError, map, shareReplay} from 'rxjs/operators';
import {AppControlService} from '../../../../core/services/app-control.service';

@Component({
  selector: 'app-freshsales-article',
  templateUrl: './freshsales-article.component.html',
  styleUrls: ['./freshsales-article.component.scss']
})
export class FreshsalesArticleComponent implements OnInit, OnChanges {

  @Input() freshsalesId;
  @Input() fontSize: number;
  @Input() ignoreMarginBottom: boolean = false;
  @Input() load: boolean = true;

  public freshDeskLoader$: Observable<SafeHtml>;
  public error: any;

  constructor(private rest: RestRequestsService, private _sanitiser: DomSanitizer,
              private appControl: AppControlService) {
  }

  ngOnInit() {
    if (this.load) {
      this.loadArticle();
    }
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.hasOwnProperty('load') && this.load) {
      this.loadArticle();
    }
  }

  private loadArticle(): void {
    // We only want to set the loader once
    if (this.freshDeskLoader$ != null) {
      return;
    }

    this.freshDeskLoader$ = this.rest.getRequest(this.appControl.baseAddress + 'helparticles/' + this.freshsalesId,
      {})
      .pipe(
        shareReplay(1),
        map(value => {
          return this._sanitiser.bypassSecurityTrustHtml(value['description']) || '';
        }),
        catchError((err: any, caught: Observable<SafeHtml>) => {
          this.error = err;
          return of(null);
        })
      );
  }

}
