import iso from 'iso-639-1';
import {GeneralUtil} from './general-util';

export class LanguagesUtil {

  static getLanguages(config: object): string[] {
    let languages = [];

    if (config != null) {

      Object.keys(config).forEach(lang => {
        languages.push(lang.toLowerCase());
      });

      languages = languages.sort();
    }

    if (languages.length === 0) {
      // Default to English
      languages.push('en');
    }

    return languages;
  }

  /**
   * Returns the language_config value if exists and non-null
   * Returns the default_language within language_config if exists and non-null
   * Returns the top most level if exists and non-null
   * Returns empty if all of the above fail
   * @param wholeObject - Whole object from API including language_config
   * @param languageCode - Language code wanted
   * @param defaultLanguage - Default language if the request language fails
   * @param fieldName - Field name to get data from
   */
  static getLanguageFieldData(wholeObject: object, languageCode: string, defaultLanguage: string, fieldName: string): string {
    if (
      wholeObject.hasOwnProperty('language_config') &&
      wholeObject['language_config'].hasOwnProperty(languageCode) &&
      wholeObject['language_config'][languageCode].hasOwnProperty(fieldName)
    ) {
      const fieldValue = wholeObject['language_config'][languageCode][fieldName];

      if (GeneralUtil.isNonEmptyString(fieldValue)) {
        return fieldValue;
      }
    }

    if (
      wholeObject.hasOwnProperty('language_config') &&
      wholeObject['language_config'].hasOwnProperty(defaultLanguage) &&
      wholeObject['language_config'][defaultLanguage].hasOwnProperty(fieldName)
    ) {
      const fieldValue = wholeObject['language_config'][defaultLanguage][fieldName];

      if (GeneralUtil.isNonEmptyString(fieldValue)) {
        return fieldValue;
      }
    }


    if (wholeObject.hasOwnProperty(fieldName)) {
      if (GeneralUtil.isNonEmptyString(wholeObject[fieldName])) {
        return wholeObject[fieldName];
      }
    }

    return '';
  }

  static getLanguageName(code: string): string {
    const lang = iso.getName(code);

    return lang != null ? lang : 'Unknown';
  }

}
