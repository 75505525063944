import {Component, Inject, OnInit} from '@angular/core';
import {Observable, of} from 'rxjs';
import {AppControlService} from '../../../core/services/app-control.service';
import {RestRequestsService} from '../../../core/services/rest-requests.service';
import {map, tap} from 'rxjs/operators';
import {BrmMediaEntry} from '../../../core/brm2/api/media/brm-media-entry';
import {MediaLibraryData} from './media-library-data';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';

@Component({
  selector: 'app-media-libary',
  templateUrl: './media-library.component.html',
  styleUrls: ['./media-libary.component.scss']
})
export class MediaLibraryComponent implements OnInit {

  public errorLoading = false;
  public libraryLoader$: Observable<any>;

  public mediaEntries: BrmMediaEntry[];
  public selectedMediaEntry: BrmMediaEntry;
  public positionIndex: number = 0;
  public selectedIndex: number = -1;

  constructor(public dialogRef: MatDialogRef<MediaLibraryComponent>,
              public appControl: AppControlService,
              public rest: RestRequestsService,
              @Inject(MAT_DIALOG_DATA) public data: MediaLibraryData) {
  }

  ngOnInit() {
    this.libraryLoader$ = this.createPageLoader();
  }

  private createPageLoader(): Observable<any> {
    return this.rest.getRequest(this.appControl.apiUrl + '/media', {
      filters: 'extras,reservationtypes'
    }).pipe(
      map((value: BrmMediaEntry[]) => {
        return value;
      }),
      tap({
        next: value => {
          /*this.mediaEntries = [{
            image_url: 'https://storage.googleapis.com/brm-cdn/images/extras/pedals-shimano-spd-mtb.png',
            name: 'Test'
          }];*/

          this.mediaEntries = value;

          // Set to the image they used before if possible
          this.setSelectedMedia();
        },
        error: err => {
          this.errorLoading = true;
        }
      })
    );
  }

  /**
   * Check the data passed to the media library dialog and see if we already use an image from there
   */
  private setSelectedMedia(): void {
    if (this.mediaEntries != null && this.data != null && this.data.selectedUrl != null) {
      let foundIndex = -1;

      const found = this.mediaEntries.find((value: BrmMediaEntry, index: number) => {
        foundIndex = index;

        return value.image_url.toLowerCase() === this.data.selectedUrl.toLowerCase();
      });

      if (found != null) {
        this.goToImage(foundIndex);
      } else {
        this.goToFirstImage();
      }
    } else {
      this.goToFirstImage();
    }
  }

  private goToFirstImage(): void {
    this.positionIndex = 0;
    this.goToImage(this.positionIndex);
  }

  public goToImage(index: number): void {
    if (this.mediaEntries != null && index >= 0 && index < this.mediaEntries.length) {
      this.selectedMediaEntry = this.mediaEntries[index];
    }
  }

  public goToNext(): void {
    if (this.selectedMediaEntry != null) {
      this.positionIndex = this.positionIndex + 1 >= this.mediaEntries.length ? this.mediaEntries.length - 1 : this.positionIndex + 1;

      this.goToImage(this.positionIndex);
    }
  }

  public goToPrevious(): void {
    this.positionIndex = this.positionIndex - 1 >= 0 ? this.positionIndex - 1 : 0;

    this.goToImage(this.positionIndex);
  }

  public selectImage(): void {
    if (this.positionIndex === this.selectedIndex) {
      this.selectedIndex = -1;
    } else {
      this.selectedIndex = this.positionIndex;
    }

    this.updateData();
    this.dialogRef.close(false);
  }

  /**
   * Updates the data object passed to this dialog with the selected image
   */
  private updateData(): void {
    if (this.data != null && this.data.selectedUrl != null) {
      if (this.selectedIndex === -1) {
        this.data.selectedUrl = null;
      } else {
        this.data.selectedUrl = this.mediaEntries[this.selectedIndex].image_url;
      }
    }
  }

  onCancelClick(): void {
    this.data.selectedUrl = null;
    this.dialogRef.close(false);
  }

  onConfirmClick(): void {

  }
}
