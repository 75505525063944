import {Component, EventEmitter, Input, OnInit, Output, ViewEncapsulation} from '@angular/core';
import {Router} from '@angular/router';
import {BrmSessionService} from '../../../../core/brm2/brm-session.service';
import {BrmUserRoles} from '../../../../core/brm2/data/brm-user-roles';

@Component({
  selector: 'app-store-message',
  templateUrl: './store-message.component.html',
  styleUrls: ['./store-message.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class StoreMessageComponent implements OnInit {

  @Output() removeMessage: EventEmitter<boolean> = new EventEmitter<boolean>();
  @Input() message: string;
  @Input() link: string[] | string;
  @Input() type: 'warning' | 'info' | 'success' = 'info';
  @Input() dismissible: boolean = false;

  public linkType: LINK_TYPE = LINK_TYPE.ROUTER;
  public linkTypeEnum = LINK_TYPE;

  public storeManagerRole = BrmUserRoles.SHOP_MANAGER;

  constructor(private router: Router, public brmSession: BrmSessionService) {

  }

  ngOnInit() {

  }

  public getLinkType(): LINK_TYPE {
    if (typeof this.link === 'string') {
      return LINK_TYPE.HREF;
    }

    return LINK_TYPE.ROUTER;
  }

  onMessageClick(event): void {
    if (this.link != null) {
      if (this.getLinkType() === LINK_TYPE.ROUTER) {
        this.router.navigate(<string[]>this.link).then();
      } else {
        window.location.href = <string>this.link;
      }
    }
  }

  onClickClose(event): void {
    event.preventDefault();
    event.stopPropagation();

    this.removeMessage.next(true);
  }

}

enum LINK_TYPE {
  HREF,
  ROUTER
}
