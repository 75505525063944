import {Injectable} from '@angular/core';
import {ConsoleErrorLogEntry} from './console-error-log-entry';
import {MatSnackBar} from '@angular/material/snack-bar';
import {ActivatedRoute, Router} from '@angular/router';

declare var ga: any;

@Injectable()
export class ConsoleInterceptorService {

  private static ERROR_MIN_TIME_BETWEEN: number = 2 * 1000;
  private static ERROR_TIME_OUT: number = 60 * 1000;
  private static MAX_ERRORS_PER_TIME_OUT: number = 10;

  public errors: ConsoleErrorLogEntry[] = [];

  private sentErrors: ConsoleErrorLogEntry[] = [];

  constructor(private _snackBar: MatSnackBar, private router: Router) {

  }

  init(): Promise<void> {
    return new Promise<void>((resolve, reject) => {
      this.interceptErrors();
      resolve();
    });
  }

  /***
   * Overrides the default console error behaviour, so we can intercept it
   */
  interceptErrors(): void {
    // Clone the original error method, bind it to console reference
    const error = console.error.bind(console);

    // Override default error
    console.error = (...args) => {
      const message = typeof args[0] === 'string' ? args[0] : '';
      const errorObj = args[1] instanceof Error ? args[1] : null;

      // If we have an actual error we want to deal with it
      if (errorObj != null) {
        this.addError(message, errorObj);
      }

      error(...args);
    };
  }

  private addError(message: string, error: Error): void {
    // If the message is the same as the previous, ignore this error
    if (this.errors.length > 0 && this.errors[this.errors.length - 1].message === error.message) {
      return;
    }

    const now = new Date().getTime();

    // Create our error we want to store
    const _error = <ConsoleErrorLogEntry>{
      message: error.message,
      error: error,
      created_ts: now,
      route: this.router.url
    };

    // If we're already at the maximum of X errors, remove the first
    if (this.errors.length >= ConsoleInterceptorService.MAX_ERRORS_PER_TIME_OUT) {
      this.errors.shift();

    }

    this.errors.push(_error);
    this.sendError(_error);
  }

  private sendError(error: ConsoleErrorLogEntry): void {
    let sendToGA = false;

    if (this.sentErrors.length === 0) {
      sendToGA = true;
    } else {
      const firstError = this.sentErrors[0];
      const lastError = this.sentErrors[this.sentErrors.length - 1];
      const now = new Date().getTime();
      const difNow = now - lastError.created_ts;
      const difFirst = now - firstError.created_ts;

      if (
        (this.sentErrors.length < ConsoleInterceptorService.MAX_ERRORS_PER_TIME_OUT || difFirst > ConsoleInterceptorService.ERROR_TIME_OUT)
        &&
        difNow >= ConsoleInterceptorService.ERROR_MIN_TIME_BETWEEN
      ) {
        // We only store the last 10, remove first
        if (this.sentErrors.length > ConsoleInterceptorService.MAX_ERRORS_PER_TIME_OUT) {
          this.sentErrors.shift();
        }

        sendToGA = true;
      }
    }

    if (sendToGA) {
      this.sentErrors.push(error);

      try {
        ga('send', 'event', error.route, error.message);
      } catch (error) {

      }
    }
  }
}
