import {Injectable} from '@angular/core';
import {BrmGenericError} from '../../brm2/api/brm-generic-error';
import {ServerErrorLogEntry} from './server-error-log-entry';

@Injectable({
  providedIn: 'root'
})
export class ServerRestErrorLogsService {

  private static MAX_ERRORS: number = 10;

  constructor() {
  }

  public serverErrors: any[] = [];

  public addServerError(brmError: BrmGenericError, url: string): void {
    const now = new Date().getTime();

    // Create our error we want to store
    const _error = <ServerErrorLogEntry>{
      message: brmError.message,
      error: brmError,
      created_ts: now,
      route: url
    };

    // If more than our maximum error log, then remove the first
    if (this.serverErrors.length >= ServerRestErrorLogsService.MAX_ERRORS) {
      this.serverErrors.shift();
    }

    // Add error
    this.serverErrors.push(_error);

    console.log('Error logs: ', this.serverErrors);
  }
}
