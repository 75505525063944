import {Component, Input, OnInit} from '@angular/core';
import {BrmLocation} from '../../../../core/brm2/api/settings/brm-location';

@Component({
  selector: 'brm-location-lozenge',
  template: `
    <div class="location-lozenge" *ngIf="locationName"
         [style.backgroundColor]="locationBackgroundColour"
         [style.color]="locationForegroundColour">{{locationShortName}}</div>
  `,
  styleUrls: ['./location-lozenge.component.scss']
})
export class LocationLozengeComponent implements OnInit {

  @Input() location: BrmLocation;
  @Input() name: string;

  public locationName: string;
  public locationShortName: string;
  public locationBackgroundColour: string = '';
  public locationForegroundColour: string = '#000';

  constructor() {
  }

  ngOnInit() {
    if (this.location != null) {
      this.locationName = this.location.name;
      this.locationShortName = this.location.short_name;
      this.locationBackgroundColour = this.location.colour;
      this.locationForegroundColour = this.location.text_colour;
    } else if (this.name != null) {
      this.locationName = this.name;
    }
  }

}
