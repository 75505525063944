import {Directive, ElementRef, OnInit, Input, HostListener} from '@angular/core';

@Directive({
  selector: '[AutoFocusInput]'
})
export class AutoFocusInputDirective implements OnInit {

  @Input() AutoFocusInput: boolean;
  @Input() autoFocusSelectAll: boolean = false;
  @Input() autoFocusTrapFocus: boolean = false;
  @Input() autoFocusDelay: number = 0;

  public timeout: number;

  constructor(private el: ElementRef) {
  }

  ngOnInit(): void {
    /*console.log('Focus');
    clearTimeout(this.timeout);
    if (this.el != null) {
      this.timeout = setTimeout(() => {
        this.focus();
      }, 50);
    }*/
    if (this.autoFocusDelay === 0) {
      this.focus();
    } else {
      setTimeout(() => {
        this.focus();
      }, this.autoFocusDelay);
    }
  }

  @HostListener('blur') onBlur(): void {
    if (this.autoFocusTrapFocus) {
      this.focus();
    }
  }


  focus(): void {
    try {
      this.el.nativeElement.focus();

      if (this.autoFocusSelectAll) {
        this.el.nativeElement.select();
      }
    } catch (error) {
      console.error('focus error: ', error);
    }
  }


}
