import {Component, OnDestroy, OnInit} from '@angular/core';
import {ShellControlService} from '../../shell-control.service';
import {interval, Observable, Subject} from 'rxjs';
import {map, startWith, takeUntil} from 'rxjs/operators';
import {BrmSettingsService} from '../../../../core/services/brm/brm-settings.service';

@Component({
  selector: 'app-side-nav-clock',
  templateUrl: './side-nav-clock.component.html',
  styleUrls: ['./side-nav-clock.component.scss']
})
export class SideNavClockComponent implements OnInit, OnDestroy {

  private destroyed$: Subject<boolean> = new Subject<boolean>();

  public clock$: Observable<number>;
  private clockValChange: number = 0;

  constructor(public shellControl: ShellControlService, private brm: BrmSettingsService) {
  }

  ngOnInit() {
    this.initialiseClock();
  }

  ngOnDestroy(): void {
    this.destroyed$.next(true);
    this.destroyed$.complete();
  }


  private initialiseClock(): void {
    // Set offset
    this.clock$ = interval(500).pipe(
      startWith(this.clockValChange),
      map((value) => {
        this.clockValChange = (this.clockValChange + 1) % 10;
        return this.clockValChange;
      })
    );
  }

}
