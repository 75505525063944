import {Pipe, PipeTransform} from '@angular/core';
import {LanguagesUtil} from '../../../../util';

@Pipe({
  name: 'languageCode'
})
export class LanguageCodePipe implements PipeTransform {

  transform(value: any, args?: any): any {
    return LanguagesUtil.getLanguageName(value);
  }

}
