import {Injectable, OnDestroy} from '@angular/core';
import {RestRequestsService} from '../../../services/rest-requests.service';
import {of, Subject, Subscription} from 'rxjs';
import {AppControlService} from '../../../services/app-control.service';
import {BrmSessionService} from '../../brm-session.service';
import {catchError, debounceTime, switchMap} from 'rxjs/operators';
import {BrmSession} from '../../api/session/brm-session';
import {BrmSessionUserSettings} from '../../api/session/brm-session-user-settings';

@Injectable({
  providedIn: 'root'
})
export class BrmUserSettingsService {

  private _data: BrmSessionUserSettings;
  private _userId: number;

  private sessionSub: Subscription;

  private settingsToSave: object;
  private appSettingsToSave: object;
  private settingsUpdateSubject$: Subject<object> = new Subject<object>();
  private appSettingsUpdateSubject$: Subject<object> = new Subject<object>();

  public saving: boolean = false;

  constructor(private rest: RestRequestsService, private appControl: AppControlService,
              private brmSession: BrmSessionService) {
    this.listenForSession();
    this.listenForSettingsUpdate();
    this.listenForAppSettingsUpdate();
  }

  private listenForSession(): void {
    this.sessionSub = this.brmSession.session$.subscribe(
      (value: BrmSession) => {
        if (value != null) {
          this._data = value.user.settings;
          this._userId = value.user.id;
        }
      }
    );
  }

  private listenForSettingsUpdate(): void {
    this.settingsUpdateSubject$.pipe(
      debounceTime(1500),
      switchMap(settings => {
        this.saving = true;

        return this.rest.postRequest(`${this.appControl.apiUrl}/users/${this._userId}/settings`, JSON.stringify(settings)).pipe(
          catchError((error) => {

            return of(null);
          })
        );
      })
    ).subscribe(
      (response: BrmSessionUserSettings) => {
        this.saving = false;
        if (response != null) {
          this._data = response;
        } else {
          console.error('There was a problem saving user app settings');
        }
      }
    );
  }

  private listenForAppSettingsUpdate(): void {
    this.appSettingsUpdateSubject$.pipe(
      debounceTime(1250),
      switchMap(appSettings => {
        const data = {
          'app_settings': appSettings
        };

        return this.rest.postRequest(`${this.appControl.apiUrl}/users/${this._userId}/settings`, JSON.stringify(data));
      })
    ).subscribe(
      (response: BrmSessionUserSettings) => {
        this._data = response;
      }
    );
  }

  public get settings(): BrmSessionUserSettings {
    return this._data;
  }

  public updateSettings(settings: object): void {
    if (this.settingsToSave != null) {
      this.settingsToSave = {...this.settingsToSave, ...settings};
    } else {
      this.settingsToSave = {...settings};
    }

    this.settingsUpdateSubject$.next(this.settingsToSave);
  }

  public updateAppSettings(settings: object): void {
    if (this.appSettingsToSave != null) {
      this.appSettingsToSave = {...this.appSettingsToSave, ...settings};
    } else {
      this.appSettingsToSave = {...settings};
    }

    this.appSettingsUpdateSubject$.next(this.appSettingsToSave);
  }

  public getAppSettings(): object {
    return this._data.app_settings;
  }

  /**
   * Gets an individual app setting from the user's settings
   * Returns an empty string otherwise
   * @param name - Name of setting to get
   */
  public getAppSetting(name: string): string {
    const appSettings = this.getAppSettings();

    try {
      return appSettings[name];
    } catch (error) {
      return '';
    }

    return null;
  }

}
