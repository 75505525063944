import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {AutoFocusInputDirective} from './auto-focus-input.directive';

/**
 * CoreModule is the central module which houses all imports of the smaller modules
 */

@NgModule({
  declarations: [
    AutoFocusInputDirective
  ],
  imports: [
    CommonModule
  ],
  exports: [
    AutoFocusInputDirective
  ]
})
export class AutoFocusInputModule {
}
