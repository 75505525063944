import {Injectable} from '@angular/core';
import {BehaviorSubject, forkJoin, Observable, of, Subscription, zip} from 'rxjs';
import {AppControlService} from '../../core/services/app-control.service';
import {RestRequestsService} from '../../core/services/rest-requests.service';
import {Brm400Error} from '../../core/services/brm/responses/brm-400-error';
import {NotificationsService} from '../../core/services/notifications/notifications.service';
import {catchError, tap} from 'rxjs/operators';
import {SettingsOnlineBasicResponse} from '../settings/settings-online-basic/responses/settings-online-basic-response.model';
import {GeneralUtil} from '../../core/util';
import {SettingsBikeDotRentResponse} from '../settings/settings-bike-dot-rent/responses/settings-bike-dot-rent-response.model';
import {BrmSetupApiResponse, SetupChildApiResponse} from '../../core/brm2/api/setup/brm-setup-api-response';

@Injectable({
  providedIn: 'root'
})
export class GettingStartedService {

  private _data: BrmSetupApiResponse;
  private _update: BehaviorSubject<number>;

  public loader: Subscription;

  public inventoryData: SetupChildApiResponse;
  public pricingData: SetupChildApiResponse;
  public openingHoursData: SetupChildApiResponse;
  public storeSettingsData: SetupChildApiResponse;
  public notificationsData: SetupChildApiResponse;

  private _onboardingPhase: string;
  public _isObwLive: boolean;
  public _isPublished: boolean;

  constructor(private appControl: AppControlService, private rest: RestRequestsService, private notify: NotificationsService) {
    this._update = new BehaviorSubject<number>(0);

    // Set phase to setup by default, as a precaution
    this._onboardingPhase = 'setup';
  }

  /**
   *  As we call this method in the Splash screen and to chain rest calls together easily, we use a promise.
   **/
  public loadAllData(): Promise<boolean> {
    return new Promise((resolve, reject) => {
      if (this.loader != null && !this.loader.closed) {
        this.loader.unsubscribe();
      }

      const apiUrl = this.appControl.apiUrl;

      this.loader = forkJoin([this.loadOnlinePublish(), this.loadBikeDotRent(), this.loadSetupData()]).subscribe(
        (value) => {
          return resolve(true);
        },
        (error) => {
          if (!(error instanceof Brm400Error)) {
            // BRM 400 errors are already handled by the interceptor
            // this.notify.addFailNotification('Failed to load getting started data, check console for detail');
          }

          return reject(false);
        }
      );
    });
  }

  public loadSetupData(): Observable<BrmSetupApiResponse> {
    return this.rest.getRequest(this.appControl.apiUrl + '/setup', {}).pipe(
      tap({
        next: (data: BrmSetupApiResponse) => {
          this._data = data;
          this._onboardingPhase = data.onboarding_phase;

          this.inventoryData = data.inventory.length === 1 ? data.inventory[0] : null;
          this.pricingData = data.pricing.length === 1 ? data.pricing[0] : null;
          this.openingHoursData = data.opening_hours.length === 1 ? data.opening_hours[0] : null;
          this.storeSettingsData = data.settings_store.length === 1 ? data.settings_store[0] : null;
          this.notificationsData = data.notifications_who.length === 1 ? data.notifications_who[0] : null;

          this._update.next(1);
        },
        error: (error) => {

        }
      }),
      catchError((err: any, caught: Observable<BrmSetupApiResponse>) => {
        this.notify.addFailNotification('Failed to load setup data');
        return of(null);
      })
    );
  }

  public get data(): BrmSetupApiResponse {
    return this._data;
  }

  /***
   *
   * The API returns data for each of the states of the lozenges, it returns it as an array therefore we need to make sure we get the first
   * entry (only return 1).
   * Check that they exist to avoid null exceptions
   *
   */

  public getInventoryData(): SetupChildApiResponse {
    return this.inventoryData;
  }

  public getPricingData(): SetupChildApiResponse {
    return this.pricingData;
  }

  public getOpeningHoursData(): SetupChildApiResponse {
    return this.openingHoursData;
  }

  public getStoreSettingsData(): SetupChildApiResponse {
    return this.storeSettingsData;
  }

  public getNotificationsWhoData(): SetupChildApiResponse {
    return this.notificationsData;
  }

  public getOnlineBooking(): SetupChildApiResponse {
    try {
      return this._data.online_bookings[0];
    } catch (e) {

    }

    return null;
  }

  public getInStoreBooking(): SetupChildApiResponse {
    try {
      return this._data.store_bookings[0];
    } catch (e) {
    }

    return null;
  }

  public getPaymentProcessors(): SetupChildApiResponse {
    try {
      return this._data.payment_providers[0];
    } catch (e) {

    }

    return null;
  }

  public get update(): Observable<number> {
    return this._update.asObservable();
  }

  public get onboardingPhase(): string {
    return this._onboardingPhase;
  }

  public isShopLive(): boolean {
    return this.onboardingPhase === 'live';
  }

  public isShopReady(): boolean {
    return this.onboardingPhase === 'ready';
  }

  public isShopInSetup(): boolean {
    return this.onboardingPhase === 'setup';
  }

  private loadOnlinePublish(): Observable<SettingsOnlineBasicResponse> {
    return this.rest.getRequest(this.appControl.apiUrl + '/settings/online', {}).pipe(
      tap(
        (value: SettingsOnlineBasicResponse) => {
          this._isObwLive = GeneralUtil.isNonEmptyString(value.online_live_url);
        },
        error => {
          this.notify.addFailNotification('Failed to load publish online - Please continue with caution');
        }
      )
    );
  }

  private loadBikeDotRent(): Observable<SettingsBikeDotRentResponse> {
    return this.rest.getRequest(this.appControl.apiUrl + '/integrations/bikedotrent', {}).pipe(
      tap(
        (value: SettingsBikeDotRentResponse) => {
          this._isPublished = value.published;
        },
        error => {
          this.notify.addFailNotification('Failed to load bike dot rent - Please continue with caution');
        }
      )
    );
  }
}
