import {AfterViewInit, Component, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {Subject, Subscription} from 'rxjs';
import {ActivatedRoute, ActivatedRouteSnapshot, NavigationEnd, Router} from '@angular/router';
import {filter, takeUntil} from 'rxjs/operators';
import {HandsFreeService} from '../../../core/brm2/services/hands-free/hands-free.service';
import {HandsFreeInputType} from '../../../core/brm2/services/hands-free/hands-free-input-types';
import {BrmItem} from '../../../core/brm2/api/inventory/items/brm-item';
import {BrmMaintenanceTask} from '../../../core/brm2/api/settings/brm-maintenance-task';
import {ServiceHistoryService} from '../../inventory/item-detail/service-history.service';
import {BrmOrganisationUserResponse} from '../../../core/services/brm/responses/brm-organisation-user-response.model';
import {HandsFreeRouteOptions} from '../../../core/brm2/services/hands-free/hands-free-route-options';
import {HandsFreeResult} from '../../../core/brm2/services/hands-free/hands-free-result';
import {BrmReservationsSearch} from '../../../core/brm2/api/reservations/brm-reservations-search';

@Component({
  selector: 'app-hands-free-overlay',
  templateUrl: './hands-free-overlay.component.html',
  styleUrls: ['./hands-free-overlay.component.scss']
})
export class HandsFreeOverlayComponent implements OnInit, OnDestroy, AfterViewInit {

  @ViewChild('handsFreeInput') handsFreeInput;
  @ViewChild('handsFreeOverlay') handsFreeOverlay;

  private blurTimeout;
  private destroyed$: Subject<boolean> = new Subject<boolean>();

  public unsupportedRoute: boolean = false;

  public handsFreeInputValue: string;

  private currentRoute: ActivatedRouteSnapshot;

  public loading = false;
  public errorMessage: string;
  public successMessage: string;
  public activeUser: BrmOrganisationUserResponse;
  public onGoingSubscription: Subscription;

  private lastInput: string;

  constructor(private router: Router, public handsFree: HandsFreeService, private activatedRoute: ActivatedRoute,
              public entryService: ServiceHistoryService) {
    this.listenForRouteChanges();
    this.listenForResults();
    this.checkIfHandsFreeSupported();
  }

  ngOnInit() {

  }

  ngOnDestroy(): void {
    if (this.blurTimeout) {
      clearTimeout(this.blurTimeout);
    }

    this.destroyed$.next(true);
    this.destroyed$.complete();
  }

  ngAfterViewInit(): void {

  }

  private listenForResults(): void {
    this.handsFree.handsFreeResult$.pipe(
      takeUntil(this.destroyed$)
    ).subscribe(
      (result: HandsFreeResult) => {
        const lastInputLower = (this.lastInput || '').toLowerCase();
        const resultInputLower = (result.input || '').toLowerCase();

        // Double check that input matches what was sent, so we don't unnecessarily update the message
        if (lastInputLower.indexOf(resultInputLower) >= 0) {
          this.successMessage = null;
          this.errorMessage = null;

          if (result.success) {
            this.successMessage = result.message;

            // If no specified sound, play default
            if (result.sound == null) {
              this.playSuccessSound();
            }
          } else {
            this.errorMessage = result.message;

            // If no specified sound, play default
            if (result.sound == null) {
              this.playErrorSound();
            }
          }

          // If we have a specified sound, play that sound
          if (result.sound === 'BEEP') {
            this.playBeepSound();
          } else if (result.sound === 'SUCCESS') {
            this.playSuccessSound();
          } else if (result.sound === 'ERROR') {
            this.playErrorSound();
          }

        }
      }
    );
  }

  private listenForRouteChanges(): void {
    this.router.events.pipe(
      takeUntil(this.destroyed$),
      // Only get events which are when the navigation has finished
      filter((value) => {
        return value instanceof NavigationEnd;
      })
    ).subscribe(
      (value: NavigationEnd) => {
        this.checkIfHandsFreeSupported();
      }
    );
  }

  private checkIfHandsFreeSupported(): void {
    let route = this.activatedRoute.firstChild;

    /**
     * Route provided is from the start, you have to loop through until the end
     */
    while (route) {
      if (route.firstChild) {
        route = route.firstChild;
      } else if (route.snapshot.data) {
        this.currentRoute = route.snapshot;
        route = null;
      } else {
        route = null;
      }
    }

    // If we have a valid route, we're going to check it has handsfree modes enabled
    if (this.currentRoute != null) {
      // Get the data associated with route
      try {
        const handsFreeData = this.currentRoute.routeConfig.data['handsfree'];

        // We have hands free data
        if (handsFreeData != null) {
          // Check one of the values provided to it is TRUE
          const hasEnabledOption = Object.keys(handsFreeData).find((parameter) => {
            return handsFreeData[parameter] === true;
          });

          // If at least one is true, we set the route as supported
          this.unsupportedRoute = !handsFreeData[hasEnabledOption];
        } else {
          this.unsupportedRoute = true;
        }
      } catch (error) {
        this.unsupportedRoute = true;
      }
    } else {
      this.unsupportedRoute = true;
    }
  }

  onHandsFreeInputBlur(event): void {
    if (this.blurTimeout) {
      clearTimeout(this.blurTimeout);
    }

    this.blurTimeout = setTimeout(() => {
      this.handsFreeInput.nativeElement.focus();
      this.blurTimeout = null;
    }, 200);
  }

  public onHandsFreeTriggered(): void {
    // Cancel any on-going action
    this.cancelSubscription();

    this.handsFree.broadcastInput(this.handsFreeInputValue);

    if (this.unsupportedRoute === false) {
      const supportedTypes: HandsFreeRouteOptions = this.currentRoute.routeConfig.data['handsfree'];
      const inputType = this.handsFree.getInputType(this.handsFreeInputValue, supportedTypes.rawInput);


      if (supportedTypes.reservationSearch && inputType === HandsFreeInputType.RESERVATION_SEARCH) {
        this.onReservationsSearch(this.handsFreeInputValue);
      } else if ((!supportedTypes.reservationSearch === supportedTypes.reservationSearch == null) && inputType === HandsFreeInputType.RESERVATION_SEARCH) {
        this.onHandsFreeError('Invalid page to input a reservation reference');
      } else if (supportedTypes.taskSearch && inputType === HandsFreeInputType.MAINTENANCE_TASK_SEARCH) {
        this.onMaintenanceTask(this.handsFreeInputValue);
      } else if ((!supportedTypes.taskSearch || supportedTypes.taskSearch == null) && inputType === HandsFreeInputType.MAINTENANCE_TASK_SEARCH) {
        this.onHandsFreeError('Invalid page to input a task');
      } else if (supportedTypes.userSearch && inputType === HandsFreeInputType.USER_SEARCH) {
        this.onUserTask(this.handsFreeInputValue);
      } else if ((!supportedTypes.userSearch || supportedTypes.userSearch == null) && inputType === HandsFreeInputType.USER_SEARCH) {
        this.onHandsFreeError('Invalid page to input a user');
      } else if (supportedTypes.rawInput && inputType === HandsFreeInputType.RAW_INPUT) {
        this.onRawInput(this.handsFreeInputValue);
      } else if (supportedTypes.assetSearch && inputType === HandsFreeInputType.ASSET_SEARCH) {
        this.onAssetSearch(this.handsFreeInputValue);
      } else if ((!supportedTypes.assetSearch || supportedTypes.assetSearch == null) && inputType === HandsFreeInputType.ASSET_SEARCH) {
        this.onHandsFreeError('Invalid page to input an item ID');
      }
    }

    // Store last input
    this.lastInput = this.handsFreeInputValue;

    // Clear input value
    this.handsFreeInputValue = null;
  }

  private onRawInput(searchQuery: string): void {
    this.handsFree.broadcastHandsFreeInput(searchQuery);
  }

  private cancelSubscription(): void {
    if (this.onGoingSubscription != null && !this.onGoingSubscription.closed) {
      this.onGoingSubscription.unsubscribe();
    }
  }

  public onUserTask(searchQuery: string): void {
    this.loading = true;

    searchQuery = searchQuery.substr(1);

    this.onGoingSubscription = this.handsFree.checkForUser(searchQuery).pipe(
      takeUntil(this.destroyed$)
    ).subscribe(
      (user: BrmOrganisationUserResponse) => {
        this.loading = false;

        if (user != null) {
          this.activeUser = user;
          this.onHandsFreeSuccess(`${user.first_name} ${user.last_name} set as active`);
        } else {
          this.onHandsFreeError(`User with ID ${searchQuery} not found`);
        }
      },
      (error) => {
      },
      () => {
        this.onHandsFreeInputBlur(null);
      }
    );
  }

  private onAssetSearch(searchQuery: string): void {
    this.loading = true;

    this.onGoingSubscription = this.handsFree.checkForAsset(searchQuery).pipe(
      takeUntil(this.destroyed$)
    ).subscribe(
      (items: BrmItem[]) => {
        this.loading = false;

        if (items.length > 1) {
          this.onHandsFreeError('Multiple items found');
        } else if (items.length === 0) {
          this.onHandsFreeError(`No item found with ID '${searchQuery}'`);
        } else if (items.length === 1) {
          // Remove any messages, as redirect will indicate
          this.onHandsFreeSuccess(`Item found with ID '${searchQuery}'`);
          // Redirect to correct location
          this.router.navigate(['/', 'inventory', 'item', items[0].id], {
            queryParams: {
              tab: 'mechanic'
            }
          }).then();
        }
      },
      (error) => {
      },
      () => {
        this.onHandsFreeInputBlur(null);
      }
    );
  }

  private onMaintenanceTask(searchQuery: string): void {
    this.loading = true;

    const cleanedSearchQuery = searchQuery.substr(1);

    const id = this.currentRoute.params['id'];

    this.onGoingSubscription = this.handsFree.checkForMaintenanceTask(cleanedSearchQuery).pipe(
      takeUntil(this.destroyed$),
    ).subscribe(
      (task: BrmMaintenanceTask) => {
        this.loading = false;

        if (task == null) {
          this.onHandsFreeError(`Service entry with ID '${searchQuery}' not found`);
        } else {
          this.handsFree.broadcastHandsFreeInput(searchQuery);
          this.onHandsFreeSuccess(`Service entry with ID '${searchQuery}' found`);
        }
      },
      (error) => {
      },
      () => {
        this.onHandsFreeInputBlur(null);
      }
    );
  }

  public closeHandsFree(): void {
    this.handsFree.disableHandsFreeMode();
  }

  private onReservationsSearch(searchQuery: string): void {
    this.loading = true;

    const cleanedSearchQuery = searchQuery.substr(1);

    const id = this.currentRoute.params['id'];

    this.onGoingSubscription = this.handsFree.checkForReservationReference(cleanedSearchQuery).pipe(
      takeUntil(this.destroyed$),
    ).subscribe(
      (task: BrmReservationsSearch) => {
        this.loading = false;

        if (task.count === -1) {
          this.onHandsFreeError(`Error requesting reservation reference '${cleanedSearchQuery}'`);
        } else if (task.count === 0) {
          this.onHandsFreeError(`No reservation found with reference '${cleanedSearchQuery}'`);
        } else if (task.count > 1) {
          this.onHandsFreeError(`Multiple reservations found with reference '${cleanedSearchQuery}'`);
        } else if (task.count === 1) {
          this.onHandsFreeSuccess(`Reservation with reference '${cleanedSearchQuery}' found`, true);

          this.router.navigate(['/', 'reservations', task.data[0].reference, 'detail']).then();
        }
      },
      (error) => {
      },
      () => {
        this.onHandsFreeInputBlur(null);
      }
    );
  }

  private onHandsFreeSuccess(successMsg: string, beep: boolean = false): void {
    this.errorMessage = null;
    this.successMessage = successMsg;

    if (!beep) {
      this.playSuccessSound();
    } else {
      this.playBeepSound();
    }
  }

  private onHandsFreeError(errorMsg: string): void {
    this.successMessage = null;
    this.errorMessage = errorMsg;
    this.playErrorSound();
  }


  public playErrorSound(): void {
    const failSound = new Audio('assets/audio/error.wav');
    failSound.play();
  }

  public playSuccessSound(): void {
    const successSound = new Audio('assets/audio/success.wav');
    successSound.play();
  }

  public playBeepSound(): void {
    const beepSound = new Audio('assets/audio/beep.wav');
    beepSound.play();
  }

}
