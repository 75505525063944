import {Component, Inject, OnDestroy, OnInit} from '@angular/core';
import {forkJoin, Observable, Subject} from 'rxjs';
import {RestRequestsService} from '../../../../core/services/rest-requests.service';
import {AppControlService} from '../../../../core/services/app-control.service';
import {BrmCacheService} from '../../../../core/brm2/brm-cache.service';
import {NotificationsService} from '../../../../core/services/notifications/notifications.service';
import {Router} from '@angular/router';
import {map, shareReplay, takeUntil, tap} from 'rxjs/operators';
import {ChangeSizePfDialogData} from './change-size-pf-dialog-data';
import {BrmReservationsSearch} from "../../../../core/brm2/api/reservations/brm-reservations-search";
import {BrmReservation} from "../../../../core/brm2/api/reservations/brm-reservation";
import {BrmItemSearch} from "../../../../core/brm2/api/inventory/items/brm-item-search";
import {BrmSettingsService} from "../../../../core/services/brm/brm-settings.service";
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';

@Component({
  selector: 'app-change-inventory-type-dialog',
  templateUrl: './change-inventory-type-dialog.component.html',
  styleUrls: ['./change-inventory-type-dialog.component.scss']
})
export class ChangeInventoryTypeDialogComponent implements OnInit, OnDestroy {

  private destroyed$: Subject<boolean> = new Subject<boolean>();

  public pageLoader$: Observable<BrmReservationsSearch>;
  public reservationCount: number;
  public reservations: BrmReservation[] = [];
  public itemsCount: number;

  public saving = false;
  public errorSaving = false;
  public inventory_type;

  constructor(private dialogRef: MatDialogRef<ChangeInventoryTypeDialogComponent>, @Inject(MAT_DIALOG_DATA) public data: ChangeSizePfDialogData,
              private rest: RestRequestsService, private appControl: AppControlService, private cache: BrmCacheService,
              private notify: NotificationsService, private router: Router, private brm: BrmSettingsService) {
  }

  ngOnInit() {
    this.inventory_type = this.data.family.inventory_type;
    this.pageLoader$ = this.createPageLoader();

  }

  ngOnDestroy(): void {
    this.destroyed$.next(true);
    this.destroyed$.complete();
  }

  onCloseClick(): void {
    this.dialogRef.close({
      inventory_type: this.data.family.inventory_type
    });
  }

  private createPageLoader(): Observable<any> {
    return forkJoin([
      this.createItemsLoader(),
      this.createReservationLoader()
    ]).pipe(
      tap({
        next: value => {
        }
      })
    );
  }

  onSubmit(): void {
    // If not already saving
    if (!this.saving && this.reservationCount == 0 && this.itemsCount == 0) {
      // unset error message
      this.errorSaving = false;
      // mark as loading
      this.saving = true;
      // disable the pop-up closing from click off
      this.dialogRef.disableClose = true;

      this.cache.inventoryCache.updateProductFamily(this.data.family.id, {
        inventory_type: this.inventory_type
      }).pipe(
        takeUntil(this.destroyed$)
      ).subscribe(
        (next) => {
          // return the data to the form
          this.dialogRef.close({
            inventory_type: this.inventory_type
          });
          this.notify.addSuccessNotification('Inventory type updated');
          this.saving = false;
        },
        error => {
          this.dialogRef.disableClose = false;
          this.errorSaving = true;
          this.saving = false;
        }
      );
    }
  }

  createItemsLoader(): Observable<BrmItemSearch> {
    return this.rest.getRequest(this.appControl.apiUrl + '/items', {
      product_family_id: this.data.family.id,
      limit: 100

    }).pipe(
      map((value: BrmItemSearch) => {
        return value;
      }),
      tap({
        next: value => {
          this.itemsCount = value.total_count;
        },
        error: err => {
          this.itemsCount = -1;
        }
      })
    );
  }

  /**
   * Upon attempting to change the size of the product line, we want to do some impact analysis
   * TODO: For now it's only reservation checking
   * We use this data to output to the user to warm them of the impact of their changes
   */
  private createReservationLoader(): Observable<BrmReservationsSearch> {

    return this.rest.getRequest(this.appControl.apiUrl + '/reservations/live', {
      product_family_id: this.data.family.id,
    }).pipe(
      shareReplay(1),
      takeUntil(this.destroyed$),
      map((value: BrmReservationsSearch) => {
        return value;
      }),
      tap({
        next: value => {
          this.reservationCount = value.total_count;
          this.reservations = value.data;
        },
        error: err => {
          this.reservationCount = -1;
        }
      })
    );
  }

  goToReservation(entry: BrmReservation): void {
    this.dialogRef.close(true);
    this.router.navigate(['/', 'reservations', entry.reference, 'detail']).then();
  }

  public goToInventory(): void {
    this.dialogRef.close(true);

    this.router.navigate(['/', 'inventory', 'general', 'shape']).then();
  }

}
