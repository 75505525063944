import {Component, EventEmitter, Input, isDevMode, OnChanges, OnInit, Output, SimpleChanges, ViewChild} from '@angular/core';
import {DomSanitizer} from '@angular/platform-browser';
import {environment} from '../../../../environments/environment';
import {AppControlService} from '../../../core/services/app-control.service';
import {LazyLoadingService} from '../../../core/services/lazy-loading.service';

declare var Croppie: any;

@Component({
  selector: 'app-image-editor',
  templateUrl: './image-editor.component.html',
  styleUrls: ['./image-editor.component.scss']
})
export class ImageEditorComponent implements OnInit, OnChanges {

  @Input() imageData: string;
  @Input() outputWidth = 200;
  @Input() outputHeight = 133;
  @Input() imageWidth: number;
  @Input() disabled: boolean;
  @Input() imageHeight: number;
  @Output() data: EventEmitter<string>;

  @ViewChild('croppieDiv') croppieDiv;
  @ViewChild('croppieFileUpload') croppieFileUpload;
  @ViewChild('uploadUrl') uploadUrl;

  public loading: boolean;
  public editing: boolean;
  public urlUploadShow: boolean;
  public uploadSelectionShow: boolean;

  public croppie: any;


  constructor(private _sanitizer: DomSanitizer, private appControl: AppControlService, private lazyLoader: LazyLoadingService) {
    this.editing = false;
    this.urlUploadShow = false;
    this.uploadSelectionShow = true;
    this.loading = true;

    this.data = new EventEmitter<string>();

    this.checkForBlob();
  }

  ngOnInit() {
    this.lazyLoadScript();
    // this.setupCroppie();
  }

  private lazyLoadScript(): void {
    const libLocation = 'croppie.js';

    this.lazyLoader.loadExternalScript(libLocation).then(
      fullfilled => {
        this.lazyLoadStyle();
      }
    ).catch(
      rejected => {
        this.loading = false;
      }
    );
  }

  private lazyLoadStyle(): void {
    let styleLocation = 'dynamic/croppie-style.css';

    if (isDevMode()) {
      // TODO: Due to ng serve turning scss/css to js files, we need to do this work around for now
      styleLocation = 'assets/css/dynamic/croppie-style.css';
    }

    this.lazyLoader.loadExternalStyles(styleLocation).then(
      fullfilled => {
        this.loading = false;
        this.setupCroppie();
      }
    ).catch(
      rejected => {
        this.loading = false;
      }
    );
  }

  ngOnChanges(changes: SimpleChanges): void {
    this.checkForBlob();
  }

  // Due to legacy code, google blobs are served causing errors
  private checkForBlob(): void {
    if (this.imageData != null && this.imageData.indexOf('serve?blob-key') >= 0) {
      const apiUrl = this.appControl.apiUrl.replace('/api/', '/');

      this.imageData = apiUrl + this.imageData;
    }
  }

  public removeImage(): void {
    // destroyHandsFreeListener the croppie instance in order to remove the image, errors if using bind
    this.croppie.destroy();

    // recreate croppie instance
    this.croppie = new Croppie(this.croppieDiv.nativeElement, {
      viewport: {width: this.outputWidth, height: this.outputHeight},
      enforceBoundary: false,
      showZoomer: true,
      enableOrientation: true
    });

    // clear image data
    this.imageData = null;

    // emit that we have no image data now
    this.data.emit(null);
  }

  private setupCroppie(): void {
    this.croppie = new Croppie(this.croppieDiv.nativeElement, {
      viewport: {width: this.outputWidth, height: this.outputHeight},
      enforceBoundary: false,
      showZoomer: true,
      enableOrientation: true
    });
  }

  public imageFileSelected(event) {
    const input = this.croppieFileUpload.nativeElement;

    if (input.files && input.files[0]) {
      const reader = new FileReader();

      reader.onload = e => {
        this.croppie.bind({
          url: e.target['result']
        });
      };

      this.editing = true;
      this.uploadSelectionShow = false;
      this.urlUploadShow = false;

      reader.readAsDataURL(input.files[0]);

      input.value = '';
    } else {
      console.log('Does not support file type');
    }
  }

  public toggleUrlUpload(event): void {
    event.stopPropagation();
    event.preventDefault();

    this.urlUploadShow = !this.urlUploadShow;
  }

  public getImage() {
    return this._sanitizer.bypassSecurityTrustStyle('url(' + this.imageData + ')');
  }

  public getImageUrl(): void {
    if (this.uploadUrl) {
      this.croppie.bind({
        url: this.uploadUrl.nativeElement.value
      });

      this.editing = true;
      this.urlUploadShow = false;
      this.uploadSelectionShow = false;
    }
  }

  public rotateClockwise(): void {
    if (this.croppie != null) {
      this.croppie.rotate(-90);
    }
  }

  public rotateAntiClockwise(): void {
    if (this.croppie != null) {
      this.croppie.rotate(90);
    }
  }

  public finsihEditImage(): void {
    this.croppie.result({
      type: 'base64',
      circle: false
    }).then(fullfilled => {
      this.imageData = fullfilled;

      this.data.emit(fullfilled);

      this.editing = false;
      this.uploadSelectionShow = true;
    });
  }

  public cancelImageEdit(): void {
    this.editing = false;
    this.uploadSelectionShow = true;
  }

  public hasImage(): boolean {
    return this.imageData != null && this.imageData !== '';
  }

  public get imageSize() {
    if (this.imageWidth != null && this.imageHeight != null) {
      return this._sanitizer.bypassSecurityTrustStyle(this.imageWidth + 'px ' + this.imageHeight + 'px');
    }

    return this._sanitizer.bypassSecurityTrustStyle('contain');
  }

}
