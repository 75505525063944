import { NgModule } from '@angular/core';
import { FreshSalesDialogComponent } from './freshsales-dialog.component';
import { CommonModule } from '@angular/common';
import { FreshSalesContentWrapModule } from '../freshsales-content-wrap/freshsales-content-wrap.module';
import { MatButtonModule } from '@angular/material/button';
import { MatDialogModule } from '@angular/material/dialog';
import { MatIconModule } from '@angular/material/icon';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';

@NgModule({
  declarations: [FreshSalesDialogComponent],
  imports: [
    CommonModule,
    MatProgressSpinnerModule,
    MatIconModule,
    MatDialogModule,
    MatButtonModule,
    FreshSalesContentWrapModule,
  ],
  exports: [FreshSalesDialogComponent],
})
export class FreshSalesDialogModule {}
