import {Injectable} from '@angular/core';
import {BrmInventoryCache} from './cache/brm-inventory-cache';
import {RestRequestsService} from '../services/rest-requests.service';
import {AppControlService} from '../services/app-control.service';
import {BrmPriceGroupCache} from './cache/brm-price-group-cache';
import {BrmCategoriesCache} from './cache/brm-categories-cache';
import {BrmCustomerDataFieldsCache} from './cache/brm-customer-data-fields-cache';
import {BrmReservationTypesCache} from './cache/brm-reservation-types-cache';
import {BrmMultiLocationCache} from './cache/brm-multi-location-cache';
import {BrmExtrasCache} from './cache/brm-extras-cache';
import {BrmPaymentMethodsCache} from './cache/brm-payment-methods-cache';
import {BrmTaxCodeCache} from './cache/brm-tax-code-cache';
import {BrmOpeningHoursCache} from './cache/brm-opening-hours-cache';
import {BrmMaintenanceTaskCache} from './cache/brm-maintenance-task-cache';
import {BrmOfferingGroupCache} from './cache/brm-offering-group-cache';
import {BrmPaymentProcessorsCache} from './cache/brm-payment-processors-cache';
import {BrmVanCache} from './cache/brm-van-cache';
import {BrmDriverCache} from './cache/brm-driver-cache';
import {BrmOnlineChannelCache} from './cache/brm-online-channel-cache';
import {BrmAdhocEmailCache} from './cache/brm-adhoc-email-cache';
import {BrmDevicesCache} from './cache/brm-devices-cache';
import {BrmPriceGroupsApiService} from './api-services/inventory/price-groups/brm-price-groups-api.service';
import {BrmCancellationEmailCache} from './cache/brm-cancellation-email-cache';

@Injectable({
  providedIn: 'root'
})
export class BrmCacheService {

  public inventoryCache: BrmInventoryCache;
  public priceGroupsCache: BrmPriceGroupCache;
  public categoriesCache: BrmCategoriesCache;
  public customerDataCache: BrmCustomerDataFieldsCache;
  public reservationTypesCache: BrmReservationTypesCache;
  public multiLocationCache: BrmMultiLocationCache;
  public extrasCache: BrmExtrasCache;
  public paymentMethodsCache: BrmPaymentMethodsCache;
  public taxCodesCache: BrmTaxCodeCache;
  public openingHoursCache: BrmOpeningHoursCache;
  public maintenanceTaskCache: BrmMaintenanceTaskCache;
  public offeringGroupsCache: BrmOfferingGroupCache;
  public paymentProcessorsCache: BrmPaymentProcessorsCache;
  public vansCache: BrmVanCache;
  public driversCache: BrmDriverCache;
  public deviceCache: BrmDevicesCache;
  public onlineChannelsCache: BrmOnlineChannelCache;
  public notificationsWhatAdhocCache: BrmAdhocEmailCache;
  public notificationsWhatCancellationCache: BrmCancellationEmailCache;

  constructor(private appControl: AppControlService, private rest: RestRequestsService,
              private priceGroupsApi: BrmPriceGroupsApiService) {
    this.inventoryCache = new BrmInventoryCache(this, appControl, rest);
    this.priceGroupsCache = new BrmPriceGroupCache(this, appControl, rest, priceGroupsApi);
    this.categoriesCache = new BrmCategoriesCache(this, appControl, rest);
    this.customerDataCache = new BrmCustomerDataFieldsCache(this, appControl, rest);
    this.reservationTypesCache = new BrmReservationTypesCache(this, appControl, rest);
    this.multiLocationCache = new BrmMultiLocationCache(this, appControl, rest);
    this.extrasCache = new BrmExtrasCache(this, appControl, rest);
    this.paymentMethodsCache = new BrmPaymentMethodsCache(this, appControl, rest);
    this.taxCodesCache = new BrmTaxCodeCache(this, appControl, rest);
    this.openingHoursCache = new BrmOpeningHoursCache(this, appControl, rest);
    this.maintenanceTaskCache = new BrmMaintenanceTaskCache(this, appControl, rest);
    this.offeringGroupsCache = new BrmOfferingGroupCache(this, appControl, rest);
    this.paymentProcessorsCache = new BrmPaymentProcessorsCache(this, appControl, rest);
    this.vansCache = new BrmVanCache(this, appControl, rest);
    this.driversCache = new BrmDriverCache(this, appControl, rest);
    this.onlineChannelsCache = new BrmOnlineChannelCache(this, appControl, rest);
    this.notificationsWhatAdhocCache = new BrmAdhocEmailCache(this, appControl, rest);
    this.notificationsWhatCancellationCache = new BrmCancellationEmailCache(this, appControl, rest);
    this.deviceCache = new BrmDevicesCache(this, appControl, rest);
  }

  public clearAllCaches(): void {
    this.inventoryCache.clearCache();
    this.priceGroupsCache.clearCache();
    this.categoriesCache.clearCache();
    this.customerDataCache.clearCache();
    this.reservationTypesCache.clearCache();
    this.multiLocationCache.clearCache();
    this.extrasCache.clearCache();
    this.paymentMethodsCache.clearCache();
    this.taxCodesCache.clearCache();
    this.openingHoursCache.clearCache();
    this.maintenanceTaskCache.clearCache();
    this.offeringGroupsCache.clearCache();
    this.paymentProcessorsCache.clearCache();
    this.vansCache.clearCache();
    this.driversCache.clearCache();
    this.onlineChannelsCache.clearCache();
    this.notificationsWhatAdhocCache.clearCache();
    this.notificationsWhatCancellationCache.clearCache();
    this.deviceCache.clearCache();
  }
}
