import {BrmCache} from './brm-cache';
import {Observable} from 'rxjs';
import {map} from 'rxjs/operators';
import {RestRequestsService} from '../../services/rest-requests.service';
import {AppControlService} from '../../services/app-control.service';
import {CategoryModel} from '../../../modules/inventory/services/models/category.model';
import {BrmCategory} from '../api/inventory/shape/brm-category';

export class BrmCategoriesCache extends BrmCache<BrmCategory[]> {

  private rest: RestRequestsService;
  private appControl: AppControlService;

  constructor(_caches, _appControl, _rest) {
    super(_caches, 'categories');

    this.appControl = _appControl;
    this.rest = _rest;
  }

  protected loadData(): Observable<CategoryModel[]> {
    return this.rest.getRequest(this.appControl.apiUrl + '/categories', {}).pipe(
      map((value: CategoryModel[]) => {
        return value;
      })
    );
  }

  protected onLoad(): void {
  }

  public getCategoryById(id: string): BrmCategory {
    return this.cachedData.find((category: BrmCategory) => category.id === id);
  }

}
