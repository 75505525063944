import {Injectable, OnDestroy} from '@angular/core';
import {BrmSessionService} from '../../brm-session.service';
import {RestRequestsService} from '../../../services/rest-requests.service';
import {BehaviorSubject, combineLatest, merge, Observable, of, Subject, Subscription, zip} from 'rxjs';
import {catchError, map, switchMap, takeUntil, tap} from 'rxjs/operators';
import {BrmPrivilege} from '../../enum';
import {BrmFeatures, BrmSessionResponse} from '../../../services/brm/responses/brm-session-response.model';
import {BrmSettingsService} from '../../../services/brm/brm-settings.service';
import {BrmSession} from '../../api/session/brm-session';
import {BrmFeaturesSettings} from '../../api/session/brm-features-settings';

@Injectable({
  providedIn: 'root'
})
export class BrmPrivilegesService implements OnDestroy {
  /**
   * BRM Privilege file handles the managing of a user's privileges calculated using the BRM Session data and privilege JSON files
   */

    // Holds the data from privilege JSON files
  private _rolePrivilegeData;
  private _packagePrivilegeData;

  // Listens to session updates
  private sessionSubscription: Subscription;

  /**
   *  Initialise a bunch of Observable privileges. Can be used with async pipe in templates and subscribed to in component controllers to
   *  get specific privilege information
   */
  private _isOperationsUser: BehaviorSubject<boolean> = new BehaviorSubject(false);
  public isOperationsUser$: Observable<boolean> = this._isOperationsUser.asObservable();

  private _isAdminUser: BehaviorSubject<boolean> = new BehaviorSubject(false);
  public isAdminUser$: Observable<boolean> = this._isAdminUser.asObservable();

  private _isBillingContact: BehaviorSubject<boolean> = new BehaviorSubject(false);
  public isBillingContact$: Observable<boolean> = this._isBillingContact.asObservable();

  private _canSwitchShops: BehaviorSubject<boolean> = new BehaviorSubject(false);
  public canSwitchShops$: Observable<boolean> = this._canSwitchShops.asObservable();

  private _isPrimaryContact: BehaviorSubject<boolean> = new BehaviorSubject(false);
  public isPrimaryContact$: Observable<boolean> = this._isPrimaryContact.asObservable();

  private _isSuperUser: BehaviorSubject<boolean> = new BehaviorSubject(false);
  public isSuperUser$: Observable<boolean> = this._isSuperUser.asObservable();

  private _role: BehaviorSubject<string> = new BehaviorSubject(null);
  public role$: Observable<string> = this._role.asObservable();

  private destroyed$: Subject<boolean> = new Subject<boolean>();

  constructor(private brmSession: BrmSessionService, private rest: RestRequestsService, private brm: BrmSettingsService) {
    // initialise listening for BRM session changes
    this.sessionSubscription = this.brmSession.session$.pipe(
      takeUntil(this.destroyed$),
      tap(value => this.onSessionUpdate(value))
    ).subscribe();
  }

  ngOnDestroy(): void {
    this.destroyed$.next(true);
    this.destroyed$.complete();
  }

  /**
   * Called when the session is updated, called upon bulk
   * @param session - BRM session data
   */
  private onSessionUpdate(session: BrmSession): void {
    let admin = false;
    let operations = false;
    let billing = false;
    let switchShops = false;
    let primary = false;
    let superUser = false;
    let role = null;

    // if we have a session, set each of the privileges to match the value from the API, default to false if the API gives null for a privilege
    if (session != null) {
      operations = session.user.operations || false;
      admin = session.user.admin || operations || false;
      billing = session.user.billing_contact || operations || false;
      switchShops = session.user.switch_shops || operations || false;
      primary = session.user.primary_contact || operations || false;
      superUser = session.user.superuser || operations || false;
      role = session.user.role || null;
    }

    console.log('Admin: ', admin);
    console.log('Operations: ', operations);
    console.log('billing: ', billing);
    console.log('switchShops: ', switchShops);
    console.log('primary: ', primary);
    console.log('superUser: ', superUser);
    console.log('role: ', role);

    // push the updated privileges to any subscriptions
    this._isAdminUser.next(admin);
    this._isOperationsUser.next(operations);
    this._isBillingContact.next(billing);
    this._canSwitchShops.next(switchShops);
    this._isPrimaryContact.next(primary);
    this._isSuperUser.next(superUser);
    this._role.next(role);
  }

  public getFeatures(): BrmFeaturesSettings {
    if (this.brmSession.hasSession()) {
      return this.brmSession.session.features;
    }

    return null;
  }

  public hasFeatureEnabled(featureId: string): boolean {
    if (this.brmSession.hasSession()) {
      return this.brmSession.session.features[featureId] || false;
    }

    return false;
  }

  /***
   * Checks if the user has permission to a certain area/feature
   * @param privilegeId - ID of the privilege being checked
   */
  public hasPrivilege(privilegeId: string): BrmPrivilege {
    if (!this.brmSession.hasSession() || this._rolePrivilegeData == null || this._packagePrivilegeData == null) {
      // If no session, not logged in therefore no permissions
      return BrmPrivilege.None;
    }

    let role = this.brmSession.session.user.role || '';
    let userPackage = this.brmSession.session.account.product_package || '';

    /*if (this.session.role != null) {
      // Check the role value isn't null, then move it to lowercase
      role = this.session.role.toLowerCase();
    }

    if (this.session.package != null) {
      // Check the package value isn't null, then move it to lowercase
      userPackage = this.session.package.toLowerCase();
    }

    if (role === '' || userPackage === '') {
      return false;
    }

    // TODO: Maybe remove this in future
    if (userPackage !== 'free') {
      userPackage = 'paid';
    }

    if (
      this._packagePrivileges.hasOwnProperty(userPackage)
      && this._packagePrivileges[userPackage].hasOwnProperty(privilegeId)
      && this._packagePrivileges[userPackage][privilegeId]
      && this._rolePrivileges.hasOwnProperty(role)
      && this._rolePrivileges[role].hasOwnProperty(privilegeId)
      && this._rolePrivileges[role][privilegeId]
    ) {
      return true;
    }*/

    return BrmPrivilege.None;
  }

  public getPrivilegeColour(privilege: BrmPrivilege): string {
    switch (privilege) {
      case BrmPrivilege.Operations:
        return '#B40012';
        break;

      case BrmPrivilege.PackageRole:

        break;
    }

    return '#000';
  }

  /***
   * To simplify the splash load up process and ensure it's maintainability we handle the files required to be loaded
   * in this service and call it from SplashComponent.
   */
  public loadPrivilegesData(): Observable<boolean> {
    return combineLatest([this.loadPackagePrivilegeData(), this.loadRolePrivilegeData()]).pipe(
      map((value: boolean[]) => {
        // If we have any false returned, we failed to load privilege data entire,
        // therefore we return false to any subscribers
        return value.indexOf(false) === -1;
      }),
      catchError((err, caught) => {
        return of(false);
      })
    );
  }

  private loadRolePrivilegeData(): Observable<boolean> {
    return this.rest.getRequest('./assets/brm/role-privileges.json', {}).pipe(
      tap({
        next: value => {
          this._rolePrivilegeData = value;

          // TODO: Temp fix
          if (value instanceof Object) {
            this.brm.rolePrivileges = value;
          }
        },
        error: err => {

        }
      }),
      map((value) => {
        return true;
      }),
      catchError((err, caught) => {
        return of(false);
      })
    );
  }

  private loadPackagePrivilegeData(): Observable<boolean> {
    return this.rest.getRequest('./assets/brm/package-privileges.json', {}).pipe(
      tap({
        next: value => {
          this._packagePrivilegeData = value;

          // TODO: Temp fix
          if (value instanceof Object) {
            this.brm.packagePrivileges = value;
          }
        },
        error: err => {

        }
      }),
      map((value) => {
        return true;
      }),
      catchError((err, caught) => {
        return of(false);
      })
    );
  }

  /**
   * GETTERS for all privilege variables
   */
  public isBillingContact(): boolean {
    return this._isBillingContact.value;
  }

  public isOperationsUser(): boolean {
    return this._isOperationsUser.value;
  }

  public isAdminUser(): boolean {
    return this._isAdminUser.value;
  }

  public isSuperUser(): boolean {
    return this._isSuperUser.value;
  }

  public getRole(): string {
    return this._role.value;
  }
}
