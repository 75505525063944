import {CommonModule} from '@angular/common';
import {NgModule} from '@angular/core';
import {LocationLozengeComponent} from './location-lozenge/location-lozenge.component';

@NgModule({
  declarations: [
    LocationLozengeComponent
  ],
  imports: [
    CommonModule,
  ],
  exports: [
    LocationLozengeComponent
  ]
})
export class LozengesModule {
}
