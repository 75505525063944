export class BrmLoginRedirectCodes {


  // Error codes to redirect the user to the login issue page
  public static readonly LOGIN_ISSUE_REDIRECTS: string [] = [
    'api002',
    'sec001',
    'sec008',
    'sec016',
    'sec017',
    'sec018',
    'sec019',
    'sec020'
  ];

}
