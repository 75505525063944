import {NgModule} from '@angular/core';

import {RouterModule, Routes} from '@angular/router';
import {InitialLoadGuard} from '../../core/guards/initial-load.guard';
import {AuthGuard} from '../../core/guards/auth.guard';
import {FailLoginComponent} from './fail-login.component';

const routes: Routes = [
  {path: 'disabled', canActivate: [AuthGuard], component: FailLoginComponent}
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
  providers: []
})
export class FailLoginRoutingModule {}
