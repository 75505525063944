import {Component, Inject, OnInit} from '@angular/core';
import {Observable, of} from 'rxjs';
import {AssetCountDialog} from '../../inventory/inventory-shape/asset-count-dialog/asset-count-dialog';
import {RestRequestsService} from '../../../core/services/rest-requests.service';
import {AppControlService} from '../../../core/services/app-control.service';
import {EventHistoryDialog} from './event-history-dialog';
import {map, tap} from 'rxjs/operators';
import {BrmInventoryHistoryEntry} from '../../../core/brm2/api/inventory/brm-inventory-history-entry';
import {BrmItem} from '../../../core/brm2/api/inventory/items/brm-item';
import {ProductLineModel} from '../../inventory/services/models/product-line.model';
import {ProductFamilyModel} from '../../inventory/services/models/product-family.model';
import {CategoryModel} from '../../inventory/services/models/category.model';
import {BrmSessionService} from '../../../core/brm2/brm-session.service';
import {GeneralUtil} from '../../../core/util';
import {RequestIdDialogComponent} from './request-id-dialog/request-id-dialog.component';
import {MAT_DIALOG_DATA, MatDialog, MatDialogConfig, MatDialogRef} from '@angular/material/dialog';

@Component({
  selector: 'app-item-event-history-dialog',
  templateUrl: './event-history-dialog.component.html',
  styleUrls: ['./event-history-dialog.component.scss']
})
export class EventHistoryDialogComponent implements OnInit {

  public pageLoader$: Observable<any>;
  public historyTitle = 'Item';
  public id = '';
  public name = '';

  constructor(public dialogRef: MatDialogRef<EventHistoryDialogComponent>,
              @Inject(MAT_DIALOG_DATA) public data: EventHistoryDialog,
              private dialog: MatDialog,
              private rest: RestRequestsService, private appControl: AppControlService,
              public brmSession: BrmSessionService) {
    this.pageLoader$ = this.createPageLoader();
  }

  ngOnInit() {
  }

  private createPageLoader(): Observable<BrmInventoryHistoryEntry[]> {
    let api = this.appControl.apiUrl;

    switch (this.data.type) {
      default:
      case 'item':
        this.historyTitle = 'Item';
        this.id = (<BrmItem>this.data.data).store_ref;
        this.name = (<BrmItem>this.data.data).name;
        api += '/items/' + this.data.id + '/events';
        break;

      case 'customer':
        this.id = (this.data.id).toString();
        this.name = '';
        this.historyTitle = 'Customer';
        api += `/customers/${this.id}/events`;
        break;

      case 'family':
        this.historyTitle = 'Family';
        this.id = (<ProductFamilyModel>this.data.data).id.toString();
        this.name = (<ProductFamilyModel>this.data.data).name;
        api += '/productfamilies/' + this.data.id + '/events';
        break;

      case 'line':
        this.historyTitle = 'Line';
        this.id = (<ProductLineModel>this.data.data).id.toString();
        this.name = (<ProductLineModel>this.data.data).name;
        api += '/productlines/' + this.data.id + '/events';
        break;

      case 'category':
        this.historyTitle = 'Category';
        this.id = (<CategoryModel>this.data.data).id.toString();
        this.name = (<CategoryModel>this.data.data).name;
        api += '/categories/' + this.data.id + '/events';
        break;
    }

    // /items/{{id}}/events
    return this.rest.getRequest(api, {}).pipe(
      map((value: BrmInventoryHistoryEntry[]) => {
        return value.sort((a: BrmInventoryHistoryEntry, b: BrmInventoryHistoryEntry) => {
          if (a.created_ts > b.created_ts) {
            return -1;
          } else {
            return 1;
          }
        });
      }),
      tap({
        error: err => {
          this.dialogRef.close(true);
        }
      })
    );
  }

  onClickCloseButton(): void {
    this.dialogRef.close(true);
  }

  showRequestDialog(requestId: string): void {
    const config: MatDialogConfig = GeneralUtil.largeDialogConfigPopup(false);
    const data = requestId;
    config.data = data;

    const dialogRef = this.dialog.open(RequestIdDialogComponent, config);
  }

}
