import {
  ErrorHandler,
  Inject,
  Injectable,
  InjectionToken,
} from '@angular/core';
import * as Rollbar from 'rollbar';
import versionInfo from '../../../../version.js';

const rollbarConfig = {
  accessToken: '40e77dac9f4b46a3bf467949eff9e555',
  captureUncaught: true,
  captureUnhandledRejections: true,
  payload: {
    code_version: versionInfo.commitSha,
    environment: 'test',
  },
};

@Injectable()
export class RollbarErrorHandler implements ErrorHandler {
  constructor(@Inject(RollbarService) private rollbar: Rollbar) {}

  handleError(err: any): void {
    console.error(err);
    this.rollbar.error(err.originalError || err);
  }
}

export function rollbarFactory() {
  return new Rollbar(rollbarConfig);
}

export const RollbarService = new InjectionToken<Rollbar>('rollbar');
