import {Component, OnInit} from '@angular/core';
import {ShellControlService} from '../../shell-control.service';
import {BrmLanguagesService} from '../../../../core/brm2/services/languages/brm-languages.service';

@Component({
  selector: 'app-side-nav-language-selector',
  templateUrl: './side-nav-language-selector.component.html',
  styleUrls: ['./side-nav-language-selector.component.scss']
})
export class SideNavLanguageSelectorComponent implements OnInit {

  constructor(public shellControl: ShellControlService, public brmLanguages: BrmLanguagesService) {
  }

  ngOnInit() {
  }

}
