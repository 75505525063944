import {BrmCache} from './brm-cache';
import {Observable} from 'rxjs';
import {map, tap} from 'rxjs/operators';
import {RestRequestsService} from '../../services/rest-requests.service';
import {AppControlService} from '../../services/app-control.service';
import {BrmReservationType} from '../api/reservations/brm-reservation-type';

export class BrmReservationTypesCache extends BrmCache<BrmReservationType[]> {

  private rest: RestRequestsService;
  private appControl: AppControlService;

  constructor(_caches, _appControl, _rest) {
    super(_caches, 'reservationtypes');

    this.appControl = _appControl;
    this.rest = _rest;
  }

  protected loadData(): Observable<BrmReservationType[]> {
    return this.rest.getRequest(this.appControl.apiUrl + '/reservationtypes', {}).pipe(
      map((value: BrmReservationType[]) => {
        return value;
      })
    );
  }

  protected onLoad(): void {
  }

  public getReservationTypeById(id: number): BrmReservationType {
    return (this.cachedData || []).find((value: BrmReservationType) => {
      return value.id === id;
    });
  }

  public getReservationTypeIndexById(id: number): number {
    return (this.cachedData || []).findIndex((value: BrmReservationType) => {
      return value.id === id;
    });
  }

  public createReservationType(data: object): Observable<BrmReservationType> {
    return this.rest.postRequest(`${this.appControl.apiUrl}/reservationtypes`, JSON.stringify(data)).pipe(
      tap(
        (next: BrmReservationType) => {
          if (this.hasDataLoaded()) {
            this.cachedData.push(next);
          }
        }
      )
    );
  }

  public deleteReservationType(id: number): Observable<any> {
    return this.rest.deleteRequest(`${this.appControl.apiUrl}/reservationtypes/${id}`, {}).pipe(
      tap(
        next => {
          if (this.hasDataLoaded()) {
            const typeIndex = this.getReservationTypeIndexById(id);

            if (typeIndex >= 0) {
              this.cachedData.splice(typeIndex, 1);
            }
          }
        },
        err => {

        }
      )
    );
  }

  public updateReservationType(id: number, data: object): Observable<BrmReservationType> {
    return this.rest.postRequest(`${this.appControl.apiUrl}/reservationtypes/${id}`, JSON.stringify(data)).pipe(
      tap(
        (next: BrmReservationType) => {
          const resType = this.getReservationTypeById(next.id);

          if (resType != null) {
            Object.assign(resType, next);
          }
        },
        err => {

        }
      )
    );
  }

}
