import {Component, ElementRef, Input, OnInit, ViewChild} from '@angular/core';
import {TagSelectOption} from './tag-select-option';
import {ENTER, TAB} from '@angular/cdk/keycodes';
import {Observable, Subject} from 'rxjs';
import {UntypedFormControl} from '@angular/forms';
import {map, startWith} from 'rxjs/operators';
import {MatAutocompleteSelectedEvent, MatAutocompleteTrigger} from '@angular/material/autocomplete';

@Component({
  selector: 'brm-tag-select',
  templateUrl: './tag-select.component.html',
  styleUrls: ['./tag-select.component.scss']
})
export class TagSelectComponent implements OnInit {

  @ViewChild('categoryTagInput', {read: MatAutocompleteTrigger})
  autoComplete: MatAutocompleteTrigger;
  @ViewChild('categoryTagInput') categoryTagInput: ElementRef;

  @Input() tags: TagSelectOption[] = [
    {
      value: 1,
      id: 1,
      label: 'Artic',
      customClasses: 'location-lozenge'
    },
    {
      value: 2,
      id: 2,
      label: 'Bat',
      customClasses: 'location-lozenge'
    }
  ];
  @Input() formControl: UntypedFormControl;
  @Input() placeholder: string;
  @Input() customTags: boolean = false;
  @Input() addChipOnBlur: boolean = false;
  @Input() multiple: boolean = false;

  readonly separatorKeysCodes: number[] = [ENTER, TAB];

  private inputSubject$: Subject<string> = new Subject<string>();
  public filter$: Observable<object[]>;

  public selectedTags: TagSelectOption[] = [];
  public inputText: string;

  constructor() {
  }

  ngOnInit() {
    this.setupFilter();
  }

  public onInputChange(): void {
    this.inputSubject$.next(this.inputText);
  }

  private setupFilter(): void {
    if (this.formControl != null) {

      this.filter$ = this.inputSubject$.pipe(
        startWith(''),
        map(value => this.tagFilter(value))
      );

    }
  }

  /***
   * Takes input from tag formControl and filters out suggests for tags to be added
   */
  public tagFilter(value: string): object[] {
    console.log('Value: ' + value);
    const idCheck = this.tags.find((tag: TagSelectOption) => {
      return tag.id === value;
    });

    if (idCheck != null) {
      // We've found an ID, meaning it came from the option select
      return [idCheck];
    }

    value = value.toLowerCase();

    // Check we have tags to filter through first
    if (this.tags == null || this.tags.length === 0) {
      return [];
    }

    console.log('?');

    // Check we have input to check against
    if (value.length === 0) {
      const allTags = [];

      this.tags.forEach(option => {
        if (this.selectedTags.indexOf(option) === -1) {
          allTags.push(option);
        }
      });

      console.log('? all: ', allTags);

      // Return all tags if no input
      return allTags;
    }

    const responseTags = [];

    // Loop through the taxonomy
    this.tags.forEach((option) => {
      const tagLower = option.label.toLowerCase();

      console.log(tagLower, value);

      // If our search string is contained within the tag string, we add it to the suggested tags
      if (tagLower.includes(value) && this.selectedTags.indexOf(option) === -1) {
        responseTags.push(option);
      }
    });

    // Return our tags
    return responseTags;
  }

  removeTag(tag: TagSelectOption): void {
    const position = this.selectedTags.indexOf(tag);

    if (position >= 0) {
      this.selectedTags.splice(position, 1);
    }

    this.forceGenerateOptions();
    this.resetAndFocusField();
  }

  public addTag(tag: TagSelectOption): void {
    if (!this.isTagSelected(tag)) {
      this.selectedTags.push(tag);

      // this.editForm.controls['tag'].updateValueAndValidity({onlySelf: false, emitEvent: true});

      this.inputSubject$.next('a');

      this.categoryTagInput.nativeElement.value = '';
    }


    this.forceGenerateOptions();
    this.resetAndFocusField();
  }

  addCustomTag(value: any): void {
    if (value == null || value.trim() === '') {
      return;
    }

    // If there's an active option, we need not to add a custom / string tag
    if (this.autoComplete.activeOption != null) {
      return;
    }

    // if (this.customTags) {
    this.addTag(<TagSelectOption>{
      label: value,
      id: value,
      custom: true
    });
    // }
  }

  tagSelected(data: MatAutocompleteSelectedEvent): void {
    const findTag = this.tags.find((tag: TagSelectOption) => {
      return tag.id === data.option.value;
    });

    if (findTag != null) {
      this.addTag(findTag);
    }
  }

  private resetAndFocusField(): void {
    if (this.categoryTagInput != null) {
      this.categoryTagInput.nativeElement.blur();
      this.categoryTagInput.nativeElement.value = '';
      this.categoryTagInput.nativeElement.focus();
    }
  }

  private forceGenerateOptions(): void {
    this.inputSubject$.next(this.categoryTagInput.nativeElement.value);

    // TODO: Improve this
    setTimeout(() => this.autoComplete.closePanel(), 50);
    setTimeout(() => this.autoComplete.openPanel(), 50);
  }

  private isTagSelected(tag: TagSelectOption): boolean {
    const find = this.selectedTags.find((value: TagSelectOption) => {
      return value.id === tag.id;
    });

    return find != null;
  }

}
