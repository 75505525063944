import {BrmCache} from './brm-cache';
import {Observable} from 'rxjs';
import {map} from 'rxjs/operators';
import {RestRequestsService} from '../../services/rest-requests.service';
import {AppControlService} from '../../services/app-control.service';
import {BrmNotificationsWhatAdhocResponse} from '../api/notifications/what/brm-notifications-what-adhoc-response';

export class BrmAdhocEmailCache extends BrmCache<BrmNotificationsWhatAdhocResponse> {

  private rest: RestRequestsService;
  private appControl: AppControlService;

  constructor(_caches, _appControl, _rest) {
    super(_caches, 'adhocemail');

    this.appControl = _appControl;
    this.rest = _rest;
  }

  protected loadData(): Observable<BrmNotificationsWhatAdhocResponse> {
    return this.rest.getRequest(this.appControl.apiUrl + '/notifications/what/adhoc', {}).pipe(
      map((value: BrmNotificationsWhatAdhocResponse) => {
        return value;
      })
    );
  }

  protected onLoad(): void {
  }

}
