import {BrmCache} from './brm-cache';
import {Observable} from 'rxjs';
import {map, tap} from 'rxjs/operators';
import {RestRequestsService} from '../../services/rest-requests.service';
import {AppControlService} from '../../services/app-control.service';
import {BrmCustomerDataField} from '../api/customer-data/brm-customer-data-field';
import {BrmCustomer} from '../api/customers/brm-customer';
import {moveItemInArray} from '@angular/cdk/drag-drop';

export class BrmCustomerDataFieldsCache extends BrmCache<BrmCustomerDataField[]> {

  private rest: RestRequestsService;
  private appControl: AppControlService;

  constructor(_caches, _appControl, _rest) {
    super(_caches, 'customerdata');

    this.appControl = _appControl;
    this.rest = _rest;
  }

  protected loadData(): Observable<BrmCustomerDataField[]> {
    return this.rest.getRequest(this.appControl.apiUrl + '/customerfields', {}).pipe(
      map((value: BrmCustomerDataField[]) => {
        return this.orderFieldsData(value);
      })
    );
  }

  protected onLoad(): void {
  }

  private orderFieldsData(fields: BrmCustomerDataField[]): BrmCustomerDataField[] {
    fields.sort((a: BrmCustomerDataField, b: BrmCustomerDataField) => {
      if (a.display_order > b.display_order) {
        return 1;
      } else if (a.display_order < b.display_order) {
        return -1;
      }

      return 0;
    });

    return fields;
  }

  public getFieldData(id: string): BrmCustomerDataField {
    if (this.hasDataLoaded()) {
      return this.cachedData.find((value: BrmCustomerDataField) => {
        return value.id === id;
      });
    }

    return null;
  }

  public updateField(id: string, data: object): Observable<BrmCustomerDataField> {
    let api = this.appControl.apiUrl + '/customerfields';

    if (id != null) {
      api += '/' + id;
    }

    return this.rest.postRequest(api, JSON.stringify(data)).pipe(
      map((value: BrmCustomerDataField) => {
        return value;
      }),
      tap({
        next: value => {
          // Update our cached data with the new data from the API
          const fieldInst = this.getFieldData(id);

          if (fieldInst != null) {
            Object.assign(fieldInst, value);
          } else if (this.hasDataLoaded()) {
            // If the cache has been loaded, load in our field inst
            this.cachedData.push(value);
            this.cachedData = this.orderFieldsData(this.cachedData);
          }
        }
      })
    );
  }

  public loadField(id: string): Observable<BrmCustomerDataField> {
    return this.rest.getRequest(this.appControl.apiUrl + '/customerfields/' + id, {}).pipe(
      map((value: BrmCustomerDataField) => {
        return value;
      }),
      tap({
        next: value => {
          const loadedData: BrmCustomerDataField = this.getFieldData(id);

          // If we've already loaded the data, we update our data
          if (loadedData != null) {
            Object.assign(loadedData, value);
          }
        }
      })
    );
  }

  public moveFields(previousIndex: number, currentIndex: number): void {
    moveItemInArray(this.cachedData, previousIndex, currentIndex);
  }

  public setOrder(id: string[]): void {
    if (this.cachedData) {
      this.cachedData.sort((a: BrmCustomerDataField, b: BrmCustomerDataField) => {
        const aPos = id.indexOf(a.id);
        const bPos = id.indexOf(b.id);

        return aPos - bPos;
      });
    }
  }

  public deleteField(field: BrmCustomerDataField): Observable<any> {
    return this.rest.deleteRequest(this.appControl.apiUrl + '/customerfields/' + field.id, {}).pipe(
      tap({
        complete: () => {
          this.cachedData.splice(this.cachedData.indexOf(field), 1);
        }
      })
    );
  }

  public updateFieldsOrder(order: string[]): Observable<BrmCustomerDataField[]> {
    return this.rest.postRequest(this.appControl.apiUrl + '/customerfields/order', JSON.stringify({'display_order': order})).pipe(
      map((value: BrmCustomerDataField[]) => {
        return value;
      }),
      tap({
        next: value => {
          this.cachedData.forEach((field: BrmCustomerDataField, index: number) => {
            field.display_order = index;
          });

          this.cachedData = this.orderFieldsData(this.cachedData);

          /*this.cachedData.sort((a: BrmCustomerDataField, b: BrmCustomerDataField) => {
            if (order.indexOf(a.id) > order.indexOf(b.id)) {
              return 1;
            } else if (order.indexOf(a.id) < order.indexOf(b.id)) {
              return -1;
            }

            return 0;
          });*/
        }
      })
    );
  }

}
