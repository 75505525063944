import {AfterViewInit, Component, OnInit, ChangeDetectorRef} from '@angular/core';
import {ShellControlService} from './shell-control.service';
import {InventoryService} from '../inventory/services/inventory.service';
import {AppControlService} from '../../core/services/app-control.service';
import {BackgroundRequestsService} from '../../core/services/background-requests.service';
import {HandsFreeService} from '../../core/brm2/services/hands-free/hands-free.service';
import {UserGuidingService} from '../../core/brm2/services/user-guiding/user-guiding.service';

declare var userGuiding: any;

@Component({
  selector: 'app-shell',
  templateUrl: './shell.component.html',
  styleUrls: ['./shell.component.scss']
})
export class ShellComponent implements OnInit, AfterViewInit {

  constructor(public shellControl: ShellControlService, private invent: InventoryService,
              public appControl: AppControlService, public background: BackgroundRequestsService,
              public handsFree: HandsFreeService, private userGuide: UserGuidingService,
              private cdr: ChangeDetectorRef) {

    // If positionIndex.html variable requests we hide navigation - do so.
    if (this.appControl.hideNavigationSetting) {
      this.shellControl.showNavigation = false;
    }

  }

  ngOnInit() {
    this.background.loading.subscribe(value => {
      this.cdr.detectChanges();
    });
  }

  ngAfterViewInit() {
    this.userGuide.initialiseUserGuiding();
  }


}
