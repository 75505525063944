import {TimeUtil} from './time/time-util';
import {MatDialogConfig} from '@angular/material/dialog';

export class GeneralUtil {

  static dateObjectToIsoDateTime(date: Date): string {
    let month: string | number = date.getUTCMonth() + 1;
    let day: string | number = date.getUTCDate();
    let hour: string | number = date.getUTCHours();
    let minutes: string | number = date.getUTCMinutes();
    let seconds: string | number = date.getUTCSeconds();

    if (month < 10) {
      month = '0' + month;
    }

    if (day < 10) {
      day = '0' + day;
    }

    if (hour < 10) {
      hour = '0' + hour;
    }

    if (minutes < 10) {
      minutes = '0' + minutes;
    }

    if (seconds < 10) {
      seconds = '0' + seconds;
    }

    return date.getUTCFullYear() + '-' + month + '-' + day + 'T' + hour + ':' + minutes + ':' + seconds;
  }

  static dateObjectToIso(date: Date): string {
    let month: string | number = date.getMonth() + 1;
    let day: string | number = date.getDate();

    if (month < 10) {
      month = '0' + month;
    }

    if (day < 10) {
      day = '0' + day;
    }

    return date.getFullYear() + '-' + month + '-' + day;
  }

  static isNonEmptyString(val: string): boolean {
    return (val != null && typeof val === 'string' && val.trim().length > 0);
  }

  static dialogConfigPopup(width: string, disableClose: boolean = false, panelClasses: string[] = []): MatDialogConfig {
    const config = new MatDialogConfig();
    config.maxWidth = 1000;
    config.width = width;
    config.panelClass = panelClasses;
    config.disableClose = disableClose;

    return config;
  }

  static extraLargeDialogConfigPopup(disableClose: boolean = true, panelClasses: string[] = []): MatDialogConfig {
    const config = new MatDialogConfig();
    config.maxWidth = 1200;
    config.width = '85%';
    config.maxHeight = '100vh';
    config.panelClass = panelClasses;
    // config.height = '100vh';
    config.disableClose = disableClose;

    return config;
  }

  static largeDialogConfigPopup(disableClose: boolean = true, panelClasses: string[] = []): MatDialogConfig {
    const config = new MatDialogConfig();
    config.maxWidth = 1000;
    config.width = '75%';
    config.maxHeight = '100vh';
    config.panelClass = panelClasses;
    // config.height = '100vh';
    config.disableClose = disableClose;

    return config;
  }

  static mediumDialogConfigPopup(disableClose: boolean = true, panelClasses: string[] = []): MatDialogConfig {
    const config = new MatDialogConfig();
    config.maxWidth = 1000;
    config.width = '50%';
    config.disableClose = disableClose;
    config.panelClass = panelClasses;

    return config;
  }

  static smallDialogConfigPopup(disableClose: boolean = true, panelClasses: string[] = []): MatDialogConfig {
    const config = new MatDialogConfig();
    config.maxWidth = 500;
    config.disableClose = disableClose;
    config.panelClass = panelClasses;

    return config;
  }

  static customWidthPxDialogConfigPopup(width = 500, disableClose: boolean = true, panelClasses: string[] = []): MatDialogConfig {
    const config = new MatDialogConfig();
    config.width = `${width}px`;
    /*config.width = '75%';
    config.maxHeight = '90%';
    config.height = '100vh';*/
    config.disableClose = disableClose;
    config.panelClass = panelClasses;

    return config;
  }

  static customWidthDialogConfigPopup(width = '20vw', minWidth = '20vw', maxWidth = '20vw', disableClose: boolean = true, panelClasses: string[] = []): MatDialogConfig {
    const config = new MatDialogConfig();
    config.width = width;
    config.maxWidth = maxWidth;
    config.minWidth = minWidth;
    /*config.width = '75%';
    config.maxHeight = '90%';
    config.height = '100vh';*/
    config.disableClose = disableClose;
    config.panelClass = panelClasses;

    return config;
  }

  /**
   * Redirects the user to a URL, if they're in an iframe it redirects the parent
   * @param url
   */
  static redirectOutOfIframe(url): void {
    try {
      if (window.self !== window.top) {
        window.top.location.href = url;
      } else {
        window.location.href = url;
      }
    } catch (e) {
      window.location.href = url;
    }
  }
}

export function copyContent(content): void {
  const el = document.createElement('textarea');
  el.value = content;
  document.body.appendChild(el);
  el.select();
  document.execCommand('copy');
  document.body.removeChild(el);
}


export function isANumber(input: string): boolean {
  const regex = /^-?\d+\.?\d*$/;

  return regex.test(input);
}

export function parametersToString(input: object): string {
  return Object.keys(input).map((inputKey: string) => {
    return `${inputKey}=${input[inputKey]}`;
  }).join('&');
}
