import {BrmCache} from './brm-cache';
import {Observable} from 'rxjs';
import {map} from 'rxjs/operators';
import {RestRequestsService} from '../../services/rest-requests.service';
import {AppControlService} from '../../services/app-control.service';
import {BrmNotificationsWhatCancellationResponse} from '../api/notifications/what/brm-notifications-what-cancellation-response';

/**
 * Singleton class acting as a cache for the Cancellation Email Template
 */
export class BrmCancellationEmailCache extends BrmCache<BrmNotificationsWhatCancellationResponse> {

  private rest: RestRequestsService;
  private appControl: AppControlService;

  constructor(_caches, _appControl, _rest) {
    super(_caches, 'cancellationemail');

    this.appControl = _appControl;
    this.rest = _rest;
  }

  /**
   * Loads the data into the cache from the API
   * @protected
   */
  protected loadData(): Observable<BrmNotificationsWhatCancellationResponse> {
    return this.rest.getRequest(this.appControl.apiUrl + '/notifications/what/cancellation', {}).pipe(
      map((value: BrmNotificationsWhatCancellationResponse) => {
        return value;
      })
    );
  }

  /**
   * Here to satisfy the implementation only
   * @protected
   */
  protected onLoad(): void {
  }

}
