import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {LoginModule} from './login/login.module';
import {NotificationsModule} from './notifications/notifications.module';
import {ShellModule} from './shell/shell.module';
import {RouterModule} from '@angular/router';
import {FailLoginModule} from './fail-login/fail-login.module';
import { LoadingDialogModule } from './shared/loading-dialog/loading-dialog.module';

@NgModule({
  declarations: [
  ],
  imports: [
    CommonModule,
    RouterModule,
    LoginModule,
    FailLoginModule,
    NotificationsModule,
    ShellModule,

    // Globally used component modules
    LoadingDialogModule
  ]
})
export class ModulesModule {
}
