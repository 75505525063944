import {Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialog, MatDialogRef} from '@angular/material/dialog';
import {EventHistoryDialog} from '../event-history-dialog/event-history-dialog';
import {UntypedFormControl, UntypedFormGroup, Validators} from '@angular/forms';
import {AppControlService} from '../../../core/services/app-control.service';
import {BrmSessionService} from '../../../core/brm2/brm-session.service';
import {Observable} from 'rxjs/index';
import {tap} from 'rxjs/operators';
import {RestRequestsService} from '../../../core/services/rest-requests.service';
import {taxRateValidator} from '../../../core/validators';
import {BrmUserSettingsService} from '../../../core/brm2/services/user-settings/brm-user-settings.service';
import {BrmPrivilegesService} from '../../../core/brm2/services/privileges/brm-privileges.service';

@Component({
  selector: 'app-initial-settings-dialog',
  templateUrl: './initial-settings-dialog.component.html',
  styleUrls: ['./initial-settings-dialog.component.css']
})
export class InitialSettingsDialogComponent implements OnInit {

  public form: UntypedFormGroup = new UntypedFormGroup({
    shop_country: new UntypedFormControl('', Validators.required),
    shop_time_zone: new UntypedFormControl('', Validators.required),
    shop_currency: new UntypedFormControl('', Validators.required),
    shop_tax_model: new UntypedFormControl('', Validators.required),
    shop_tax_rate: new UntypedFormControl('', [Validators.required, taxRateValidator])
  });

  public loading: boolean = false;

  public sessionLoader$: Observable<any>;

  constructor(public dialogRef: MatDialogRef<InitialSettingsDialogComponent>,
              @Inject(MAT_DIALOG_DATA) public data: EventHistoryDialog,
              private dialog: MatDialog, public appControl: AppControlService,
              public brmSession: BrmSessionService,
              private brmPrivileges: BrmPrivilegesService,
              private userSettings: BrmUserSettingsService,
              private rest: RestRequestsService) {
    this.dialogRef.disableClose = !this.brmPrivileges.isOperationsUser();

    this.sessionLoader$ = this.brmSession.session$.pipe(
      tap(value => {
        this.form.reset({
          shop_country: this.brmSession.session.shop_settings.shop_country,
          shop_time_zone: this.brmSession.session.shop_settings.shop_time_zone,
          shop_currency: this.brmSession.session.shop_settings.shop_currency,
          shop_tax_rate: this.brmSession.session.shop_settings.shop_tax_rate,
          shop_tax_model: this.brmSession.session.shop_settings.shop_tax_model
        });
      })
    );
  }

  ngOnInit(): void {
  }

  async formSubmitHandler(event) {
    if (this.form.valid && !this.loading) {
      this.dialogRef.disableClose = true;
      this.loading = true;

      await this.saveShopSettings();

      this.loading = false;
      this.dialogRef.disableClose = false;
    }
  }

  private async saveShopSettings() {
    const formData = Object.assign({}, this.form.value);
    formData['shop_tax_rate'] = +formData['shop_tax_rate'];

    try {
      //if (!this.brmPrivileges.isOperationsUser()) {
        this.userSettings.updateAppSettings({confirmed_initial: true});
      //}

      let response = await this.rest.postRequest(`${this.appControl.apiUrl}/settings/store`, JSON.stringify(formData), true, true).toPromise();

      if (response.ok) {
        response = response.body;
        this.brmSession.updateSessionShopSettings(response);

        this.dialogRef.close(true);
      }

    } catch (error) {
      console.log(error);
    }
  }

}
