import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { ShellComponent } from './shell.component';
import { InitialLoadGuard } from '../../core/guards/initial-load.guard';
import { EditCategoryComponent } from '../inventory/edit-category/edit-category.component';
import { AuthGuard } from '../../core/guards/auth.guard';
import { EditProductFamilyComponent } from '../inventory/edit-product-family/edit-product-family.component';
import { EditProductLineComponent } from '../inventory/edit-product-line/edit-product-line.component';
import { BrmBasicUserGuard } from '../../core/brm2/guards/brm-basic-user.guard';
import { GettingStartedGuard } from '../../core/brm2/guards/route-guards/getting-started.guard';
import { HomeGuard } from '../../core/brm2/guards/route-guards/home.guard';
import {BrmOpsGuard} from '../../core/brm2/services/privileges/guards/brm-ops.guard';

const childRoutes: Routes = [
  { path: '', redirectTo: 'gettingstarted', pathMatch: 'full' },
  { path: 'home', pathMatch: 'full', canActivate: [BrmBasicUserGuard, HomeGuard], loadChildren: () => import('../home/home.module').then(m => m.HomeModule) },
  {
    path: 'reports', canActivate: [BrmBasicUserGuard], loadChildren: () => import('../reports/reports.module').then(m => m.ReportsModule), data: {
      breadcrumb: {
        label: 'Reports',
        id: 'reports',
        icon: 'reports'
      }
    }
  },
  {
    path: 'issues', canActivate: [BrmBasicUserGuard], loadChildren: () => import('../issues/issues.module').then(m => m.IssuesModule), data: {
      breadcrumb: {
        label: 'Issues',
        id: 'issues',
        icon: 'issues'
      }
    }
  },
  {
    path: 'inventory', canActivate: [BrmBasicUserGuard], loadChildren: () => import('../inventory/inventory.module').then(m => m.InventoryModule), data: {
      breadcrumb: {
        label: 'Inventory',
        id: 'inventory',
        icon: 'inventory'
      }
    }
  },
  {
    path: 'settings', canActivate: [BrmBasicUserGuard], loadChildren: () => import('../settings/settings.module').then(m => m.SettingsModule), data: {
      breadcrumb: {
        label: 'Settings',
        id: 'settings',
        icon: 'settings'
      }
    }
  },
  {
    path: 'maintenance', canActivate: [BrmBasicUserGuard], loadChildren: () => import('../maintenance/maintenance.module').then(m => m.MaintenanceModule), data: {
      breadcrumb: {
        label: 'Maintenance',
        id: 'maintenance',
        icon: 'maintenance'
      }
    }
  },
  {
    path: 'delivery', canActivate: [BrmBasicUserGuard], loadChildren: () => import('../delivery/delivery.module').then(m => m.DeliveryModule), data: {
      breadcrumb: {
        label: 'Delivery',
        id: 'delivery',
        icon: 'delivery'
      }
    }
  },
  {
    path: 'gettingstarted',
    canActivate: [BrmBasicUserGuard, GettingStartedGuard],
    loadChildren: () => import('../getting-started/getting-started.module').then(m => m.GettingStartedModule),
    data: {
      breadcrumb: {
        label: 'Getting started',
        id: 'getting-started',
        icon: 'getting-started'
      }
    }
  },
  {
    path: 'reservations',
    loadChildren: () => import('../reservations/reservations.module').then(m => m.ReservationsModule),
    data: {
      breadcrumb: {
        label: 'Reservations',
        id: 'reservations',
        icon: 'reservations'
      }
    }
  },
  { path: 'category', canActivate: [BrmBasicUserGuard], component: EditCategoryComponent },
  { path: 'productfamily', canActivate: [BrmBasicUserGuard], component: EditProductFamilyComponent },
  { path: 'productline', canActivate: [BrmBasicUserGuard], component: EditProductLineComponent },
  {
    path: 'storeswitch', canActivate: [BrmBasicUserGuard], loadChildren: () => import('../shop-switch/shop-switch.module').then(m => m.ShopSwitchModule),
    data: {
      breadcrumb: {
        label: 'Switch stores',
        id: 'switch-stores',
        icon: 'switch-stores'
      }
    }
  },
  {
    path: 'newreservations',
    canActivate: [],
    loadChildren: () => import('../newreservations/newreservations.module').then(m => m.NewReservationsModule),
    data: {
      breadcrumb: {
        label: 'New reservations',
        id: 'reservations',
        icon: 'reservations'
      }
    }
  },
  {
    path: 'customers', canActivate: [BrmBasicUserGuard], loadChildren: () => import('../customers/customers.module').then(m => m.CustomersModule), data: {
      breadcrumb: {
        label: 'Customers',
        id: 'customers',
        icon: 'customers'
      }
    }
  },
  {
    path: 'opsstoreswitch',
    canActivate: [BrmOpsGuard],
    loadChildren: () => import('../shop-switch-ops/shop-switch-ops.module').then(m => m.ShopSwitchOpsModule),
    data: {
      breadcrumb: {
        label: 'Switch store (Ops)',
        id: 'switch-stores',
        icon: 'switch-stores'
      }
    }
  },
  { path: '*', redirectTo: '' }
];

const routes: Routes = [
  { path: '', canActivate: [InitialLoadGuard, AuthGuard], children: childRoutes, component: ShellComponent },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
  providers: []
})
export class ShellRoutingModule {
}
