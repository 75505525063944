import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {ReactiveFormsModule} from '@angular/forms';
import {ImageEditorModule} from '../../shared/image-editor/image-editor.module';
import {EditCategoryComponent} from './edit-category.component';
import {LanguageSelectorModule} from '../../shared/language-selector/language-selector.module';
import {FreshSalesArticleModule} from '../../shared/freshsales/freshsales-article/freshsales-article.module';
import {ImageEditorScalerModule} from '../../shared/image-editor-scaler/image-editor-scaler.module';
import {MatTooltipModule} from '@angular/material/tooltip';
import {NgxPopperModule} from 'ngx-popper';
import {TagSelectModule} from '../../shared/forms';
import {BrmLanguagesModule} from '../../../core/brm2/services/languages/brm-languages.module';
import {BrmLanguageSelectorModule} from '../../shared/brm-language-selector/brm-language-selector.module';
import {MatChipsModule} from '@angular/material/chips';
import {MatIconModule} from '@angular/material/icon';
import {MatProgressSpinnerModule} from '@angular/material/progress-spinner';
import {MatSelectModule} from '@angular/material/select';
import {MatOptionModule} from '@angular/material/core';
import {MatMenuModule} from '@angular/material/menu';
import {MatAutocompleteModule} from '@angular/material/autocomplete';
import {MatCheckboxModule} from '@angular/material/checkbox';
import {MatButtonModule} from '@angular/material/button';
import {MatFormFieldModule} from '@angular/material/form-field';
import {MatInputModule} from '@angular/material/input';

@NgModule({
  declarations: [
    EditCategoryComponent
  ],
  imports: [
    CommonModule,
    // EditCategoryRouting,
    ReactiveFormsModule,
    MatInputModule,
    MatFormFieldModule,
    MatButtonModule,
    MatCheckboxModule,
    MatAutocompleteModule,
    MatMenuModule,
    MatOptionModule,
    MatSelectModule,
    MatProgressSpinnerModule,
    MatIconModule,
    MatChipsModule,
    ImageEditorModule,
    ImageEditorScalerModule,
    LanguageSelectorModule,
    BrmLanguageSelectorModule,
    FreshSalesArticleModule,
    MatTooltipModule,
    NgxPopperModule,
    TagSelectModule,
    BrmLanguagesModule
  ],
  exports: []
})
export class EditCategoryModule {
}
