import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {BrmLanguageSelectorComponent} from './brm-language-selector.component';
import {BrmLanguagesModule} from '../../../core/brm2/services/languages/brm-languages.module';
import {MatMenuModule} from '@angular/material/menu';
import {MatInputModule} from '@angular/material/input';
import {MatFormFieldModule} from '@angular/material/form-field';
import {MatButtonModule} from '@angular/material/button';
import {MatIconModule} from '@angular/material/icon';

@NgModule({
  declarations: [BrmLanguageSelectorComponent],
  imports: [
    CommonModule,
    MatIconModule,
    MatButtonModule,
    MatFormFieldModule,
    MatInputModule,
    MatMenuModule,
    BrmLanguagesModule
  ],
  exports: [
    BrmLanguageSelectorComponent
  ]
})
export class BrmLanguageSelectorModule {
}
