import {Injectable} from '@angular/core';
import {Params} from '@angular/router';
import {LightspeedQueryParamsModel} from './lightspeed-query-params.model';
import {Observable} from 'rxjs';
import {RestRequestsService} from '../../../services/rest-requests.service';
import {AppControlService} from '../../../services/app-control.service';
import {map, takeUntil, tap} from 'rxjs/operators';
import {BrmCustomer} from '../../api/customers/brm-customer';
import {BrmLightspeedPay} from '../../api/lightspeed/brm-lightspeed-pay';

@Injectable({
  providedIn: 'root'
})
export class BrmLightspeedService {

  // Lightspeed data contains a customer
  public customer: boolean = false;
  // Lightspeed data loaded into service
  public loaded: boolean = false;
  // Lightspeed data from URL GET vars
  public lightSpeedData: LightspeedQueryParamsModel;
  // Lightspeed customer data loaded from BRM API
  public lightSpeedCustomer: BrmCustomer;

  constructor(private rest: RestRequestsService, private appControl: AppControlService) {
  }

  public hasLightspeedDataInUrlParameters(urlParams: object): boolean {
    const lightspeedQueryParams = ['type', 'accountID', 'systemUserID', 'employeeID', 'saleID', 'customerID', 'shopID', 'returnURL'];
    let paramCount = 0;

    lightspeedQueryParams.forEach((param: string) => {
      if (urlParams.hasOwnProperty(param)) {
        paramCount++;
      }
    });

    return paramCount > 4;
  }

  public onLightspeedPayAndReturn(reservationReference: string, lightspeedCustomerId: number, salesId: number): Observable<any> {
    const data = {
      reservation_ref: reservationReference,
      lightspeed_sale_id: Number(salesId)
    };

    if (lightspeedCustomerId) {
      data['lightspeed_customer_id'] = Number(lightspeedCustomerId);
    }

    return this.rest.postRequest(
      `${this.appControl.apiUrl}/payments/lightspeed/payandreturn`,
      JSON.stringify(data)
    ).pipe(
      map((value: any) => {
        return value;
      })
    );
  }

  public addLightspeedPayment(amount: number, reservationReference: string, notes?: string): Observable<BrmLightspeedPay> {
    return this.rest.postRequest(
      `${this.appControl.apiUrl}/payments/lightspeed/pay`,
      JSON.stringify({
        reservation_ref: reservationReference,
        amount: amount,
        notes: notes
      })
    ).pipe(
      map((value: BrmLightspeedPay) => {
        return value;
      })
    );
  }

  public getLightspeedCharges(customerId: number, reference: string): Observable<any> {
    return this.rest.getRequest(`${this.appControl.apiUrl}/payments/lightspeed/charges`, {
      customer_id: customerId,
      reservation_reference: reference
    });
  }

  public checkSale(reference: string, salesId: string): Observable<any> {
    return this.rest.postRequest(`${this.appControl.apiUrl}/payments/lightspeed/checksale`, JSON.stringify({
      lightspeed_sale_id: salesId,
      reservation_reference: reference
    }));
  }

  public loadLightspeedCustomer(): Observable<any> {
    return this.rest.getRequest(this.appControl.apiUrl + '/customers/lightspeed', {
      lightspeed_customer_id: this.lightSpeedData.customerID
    }).pipe(
      tap((next: BrmCustomer) => {
        this.lightSpeedCustomer = next;
      })
    );
  }

  public storeLightspeedData(urlParams: Params): void {
    // Clear old data if applicable
    this.clearLightspeedData();

    this.lightSpeedData = new LightspeedQueryParamsModel(urlParams);

    this.loaded = true;

    // Only say we have a lightspeed customer if we're provided with one
    if (this.lightSpeedData.customerID && this.lightSpeedData.customerID > 0) {
      this.customer = true;
    }
  }

  public clearLightspeedData(): void {
    this.customer = false;
    this.loaded = false;
    this.lightSpeedData = null;
  }
}
