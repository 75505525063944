import {Component, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {UntypedFormControl, UntypedFormGroup, ValidatorFn, Validators} from '@angular/forms';
import {ActivatedRoute, Router} from '@angular/router';
import {LoggerService} from '../../../core/services/logger.service';
import {RestRequestsService} from '../../../core/services/rest-requests.service';
import {Observable, of, Subject, Subscription, zip} from 'rxjs';
import {NotificationsService} from '../../../core/services/notifications/notifications.service';
import {ShellControlService} from '../../shell/shell-control.service';
import {AppControlService} from '../../../core/services/app-control.service';
import {filter, map, startWith, switchMap, takeUntil, tap} from 'rxjs/operators';
import {CategoryModel} from '../services/models/category.model';
import {ProductFamilyModel} from '../services/models/product-family.model';
import {COMMA, ENTER} from '@angular/cdk/keycodes';
import {BrmSettingsService} from '../../../core/services/brm/brm-settings.service';
import {FormCanDeactivateAbstractComponent} from '../../../core/guards/abstract/form-can-deactivate.abstract.component';
import {EventHistoryDialog} from '../../shared/event-history-dialog/event-history-dialog';
import {EventHistoryDialogComponent} from '../../shared/event-history-dialog/event-history-dialog.component';
import {ProductLineModel} from '../services/models/product-line.model';
import {BrmCacheService} from '../../../core/brm2/brm-cache.service';
import {RestructureProductFamilyDialog} from './restructure-product-family-dialog/restructure-product-family-dialog';
import {RestructureProductFamilyDialogComponent} from './restructure-product-family-dialog/restructure-product-family-dialog.component';
import {BreadcrumbsService} from '../../../core/services/breadcrumbs.service';
import {GeneralUtil} from '../../../core/util';
import {ChangeInventoryTypeDialogOutcome} from './change-inventory-type-dialog/change-inventory-type-dialog-outcome';
import {ChangeInventoryTypeDialogComponent} from './change-inventory-type-dialog/change-inventory-type-dialog.component';
import {ChangeSizePfDialogData} from './change-inventory-type-dialog/change-size-pf-dialog-data';
import {BrmLocation} from '../../../core/brm2/api/settings/brm-location';
import {SettingsSbcApiResponse} from '../../settings/settings-sbc/response/settings-sbc-api-response.model';
import {SettingsBikeDotRentResponse} from '../../settings/settings-bike-dot-rent/responses/settings-bike-dot-rent-response.model';
import {BrmVeloGuideData} from '../../../core/brm2/api/settings/brm-velo-guide-data';
import {BrmSessionService} from '../../../core/brm2/brm-session.service';
import {BrmLanguagesService} from '../../../core/brm2/services/languages/brm-languages.service';
import {MatDialog, MatDialogConfig} from '@angular/material/dialog';
import {ActionModalDialogData} from '../../shared/action-modal-dialog/action-modal-dialog-data';
import {ActionModalDialogComponent} from '../../shared/action-modal-dialog/action-modal-dialog.component';
import {MatAutocompleteSelectedEvent} from '@angular/material/autocomplete';
import {ANGULAR_EDITOR_HIDDEN_BTNS} from '../../../core/data/html-editor';
import {BrmTaxCode} from '../../../core/brm2/api/settings/brm-tax-code';
import {LoadingDialogComponent} from '../../shared/loading-dialog/loading-dialog.component';
import {HttpEvent, HttpEventType, HttpProgressEvent} from '@angular/common/http';
import {AngularEditorConfig} from '@kolkov/angular-editor';
import {BrmPriceGroup} from '../../../core/brm2/api/pricegroups/brm-price-group';
import {BrmItemSearch} from '../../../core/brm2/api/inventory/items/brm-item-search';
import {ToastService} from '../../shell/toast/toast.service';

@Component({
  selector: 'app-edit-product-family',
  templateUrl: './edit-product-family.component.html',
  styleUrls: ['./edit-product-family.component.scss', '../shared/inventory-shared.scss']
})
export class EditProductFamilyComponent extends FormCanDeactivateAbstractComponent implements OnInit, OnDestroy {
  @ViewChild('homeLocationInput') homeLocationInput;
  @ViewChild('channelTagInput') channelTagInput;

  private destroyed$: Subject<boolean> = new Subject();

  private productFamilyLineData: BrmItemSearch;

  private sbcPublished: boolean;
  private vgPublished: boolean;
  private brPublished: boolean;

  public setTags: string[] = [];
  public tagsDirty = false;
  public tagsFilter: Observable<object[]>;

  public editForm: UntypedFormGroup = new UntypedFormGroup({
    // brand: new FormControl(null),
    // name: new FormControl(null, Validators.required),
    image: new UntypedFormControl(null),
    inventory_type: new UntypedFormControl('item', Validators.required),
    home_location: new UntypedFormControl(''),
    tag: new UntypedFormControl(null),
    // channel: new FormControl(null),
    price_group: new UntypedFormControl(null, Validators.required),
    summary: new UntypedFormControl(null),
    notes: new UntypedFormControl(null),
    language_config: new UntypedFormGroup({}),
    tax_code_id: new UntypedFormControl(),
    rental_buffer_mins: new UntypedFormControl(),
    min_rental_mins: new UntypedFormControl(),
    max_rental_mins: new UntypedFormControl(),
  });

  public restructureForm: UntypedFormGroup = new UntypedFormGroup({
    category: new UntypedFormControl(null, Validators.required),
  });

  public updateSubscription: Subscription;
  public publishSubscription: Subscription;

  public imageDirty = false;
  public imageOutput: string;

  public locations: BrmLocation[] = [];
  public homeLocation: BrmLocation;

  readonly separatorKeysCodes: number[] = [ENTER, COMMA];

  @ViewChild('priceGroupInput') priceGroupInput;

  private priceGroupsData: BrmPriceGroup[] = [];

  public pageLoader$: Observable<any>;
  public category: CategoryModel;
  public categories: CategoryModel[];
  public family: ProductFamilyModel;
  public id: string;

  public lineSkus = [];

  public editorConfig: AngularEditorConfig = {
    editable: true,
    spellcheck: true,
    height: '10rem',
    sanitize: false,
    minHeight: '5rem',
    placeholder: 'Enter text here...',
    translate: 'no',
    uploadUrl: 'v1/images', // if needed
    customClasses: [],
    toolbarHiddenButtons: ANGULAR_EDITOR_HIDDEN_BTNS
  };

  public notesEditorConfig: AngularEditorConfig = {
    editable: true,
    spellcheck: true,
    height: '10rem',
    sanitize: false,
    minHeight: '5rem',
    placeholder: 'Enter text here...',
    translate: 'no',
    uploadUrl: 'v1/images', // if needed
    customClasses: [],
    toolbarHiddenButtons: ANGULAR_EDITOR_HIDDEN_BTNS
  };

  public requiredDefNameValidators: ValidatorFn[] = [Validators.required];
  public taxCodes: BrmTaxCode[] = [];

  constructor(public router: Router, private logger: LoggerService, public brmSession: BrmSessionService,
              private notify: ToastService, private shellControl: ShellControlService,
              private appControl: AppControlService, public brm: BrmSettingsService, private activatedRoute: ActivatedRoute,
              private dialog: MatDialog, private cache: BrmCacheService, private brmLanguage: BrmLanguagesService,
              private breadcrumbs: BreadcrumbsService, private rest: RestRequestsService,
              public brmLanguages: BrmLanguagesService
  ) {
    super();

    if (this.activatedRoute.snapshot.paramMap.has('id')) {
      this.id = this.activatedRoute.snapshot.paramMap.get('id');
    } else {
      this.onPageLoadFailure();
    }

    this.tagsFilter = this.editForm.controls['tag'].valueChanges.pipe(
      startWith(''),
      map(value => this.tagFilter(value))
    );
  }

  ngOnInit() {
    this.pageLoader$ = this.createPageLoader();
  }

  ngOnDestroy() {
    this.destroyed$.next(true);
    this.destroyed$.complete();
  }

  public onPageLoadFailure(): void {
    this.notify.addFailNotification('Failed to load product family data');
    this.router.navigate(['/', 'inventory']);
  }

  private initializeChannelTags() {
    if (GeneralUtil.isNonEmptyString(this.family.channel)) {
      this.setTags = this.family.channel.split(',');
    }
  }

  private createPageLoader(): Observable<any> {

    return zip(
      this.cache.inventoryCache.loadProductFamily(Number(this.id)),
      // this.cache.inventoryCache.getData(),
      this.getMultiLocationData(),
      this.loadBikeDotRentData(),
      this.loadVeloGuideData(),
      this.loadSbcData(),
      this.cache.priceGroupsCache.getData(),
      this.cache.categoriesCache.getData(),
      this.loadTaxCodes()
    ).pipe(
      // TODO: Evaluate if this is the best way to handle this
      switchMap(value => {
        this.family = value[0]; // this.cache.inventoryCache.getProductFamily(Number(this.id));
        this.priceGroupsData = value[5];

        if (this.family != null) {
          this.categories = value[6];
          this.homeLocation = this.cache.multiLocationCache.findLocation(this.family.home_location);

          return this.cache.inventoryCache.loadCategory(this.family.category_id);
        } else {
          this.onPageLoadFailure();

          return of(null);
        }
      }),
      tap({
        next: value => {
          // Set price group data
          this.category = value;

          this.getProductFamilyLines();
          this.setupBreadcrumbs();
          this.setupPage();
          this.updateForm();

          this.initializeChannelTags();
        }
      })
    );
  }

  private getProductFamilyLines(): Promise<object> {
    return new Promise((resolve, reject) => {
      // Solution is limited for the first 500 records. There is no way to query children by category
      this.rest.getRequest(`${this.appControl.apiUrl}/items`, {category: this.category, limit: 500})
        .pipe(
          takeUntil(this.destroyed$)
        )
        .subscribe((value: BrmItemSearch) => {
          this.productFamilyLineData = value;
        });
    });
  }

  private loadTaxCodes(): Observable<BrmTaxCode[]> {
    return this.cache.taxCodesCache.getData().pipe(
      tap(
        (next: BrmTaxCode[]) => {
          this.taxCodes = next;
        }
      )
    );
  }

  private setupBreadcrumbs(): void {
    let label = 'Edit product family';
    label += ' \"' + this.family.language_config[this.brm.session.defaultLanguage]['name'] + '\"';

    if (this.category != null) {
      this.breadcrumbs.updateBreadcrumb('category-edit', {
        label: this.category.language_config[this.brm.session.defaultLanguage]['name'],
        path: ['/', 'inventory', 'general', 'shape'],
        hidden: false,
        queryParams: {category: this.category.id}
      });

      this.breadcrumbs.updateBreadcrumb('product-family-edit', {
        label: label,
        hidden: false,
        path: ['/', 'inventory', 'general', 'shape'],
        queryParams: {
          category: this.category.id,
          family: this.id
        }
      });
    }
  }

  private setupPage(): void {
    this.lineSkus = this.getSkus();

    if (this.category != null) {
      this.restructureForm.controls['category'].setValue(this.category.id);
    }
  }

  private getSkus(): string[] {
    let skus = [];

    if (this.family != null && this.family.product_lines != null) {
      this.family.product_lines.forEach((value: ProductLineModel) => {
        skus = skus.concat(value.skus);
      });
    }

    return skus;
  }

  private updateForm(): void {
    this.editForm.reset(this.family);
    this.editForm.controls['inventory_type'].disable();
    // this.editForm.controls['brand'].setValue(this.invent.activeProductFamily.brand);
    /*this.editForm.controls['image'].setValue(this.family.image);
    this.editForm.controls['price_group'].setValue(this.family.price_group);
    this.editForm.controls['summary'].setValue(this.family.summary);
    this.editForm.controls['notes'].setValue(this.family.notes);
    this.editForm.controls['inventory_type'].setValue(this.family.inventory_type);

    this.editForm.controls['home_location'].setValue(this.family.home_location);
    this.editForm.controls['tax_code_id'].setValue(this.family.tax_code_id);*/
  }

  private confirmNameRenaming(): void {

    const config: MatDialogConfig = GeneralUtil.smallDialogConfigPopup(true);

    config.data = <ActionModalDialogData>{
      displayButton: true,
      displayCancel: true,
      cancelText: 'No',
      buttonType: 'danger',
      buttonText: 'Confirm',
      contentText: `Making these changes will affect child items. Are you sure you want to proceed?`,
      dialogTitle: 'Save and rename item?',
    };

    this.dialog.open(ActionModalDialogComponent, config).afterClosed().pipe(
      takeUntil(this.destroyed$)
    ).subscribe(
      (result) => {
        if (result) {
          this.saveDataForm();
        }
      }
    );
  }

  private saveDataForm(): void {
    // TODO: Replace with getRawData()
    const postData = {
      id: this.family.id,
      price_group: this.editForm.value['price_group'],
      summary: this.editForm.value['summary'],
      notes: this.editForm.value['notes'],
      home_location: this.editForm.value['home_location'],
      inventory_type: this.editForm.value['inventory_type'],
      language_config: this.editForm.controls['language_config'].value,
      tax_code_id: this.editForm.controls['tax_code_id'].value,
    };

    if (this.brmSession.session.features.scope_online_booking_buffers) {
      postData['rental_buffer_mins'] = Number(this.editForm.value['rental_buffer_mins']);
    }

    if (this.brmSession.session.features.scope_online_min_max_rentals) {
      postData['min_rental_mins'] = Number(this.editForm.value['min_rental_mins']);
      postData['max_rental_mins'] = Number(this.editForm.value['max_rental_mins']);
    }

    postData['language_config'] = this.brmLanguage.readyLanguageConfigDataForApi(postData['language_config'], this.family.language_config);


    Object.keys(postData.language_config).forEach((key: string) => {
      const langObj = postData.language_config[key];

      if (!langObj.hasOwnProperty('notes')) {
        langObj['notes'] = '';
      }
      if (!langObj.hasOwnProperty('summary')) {
        langObj['summary'] = '';
      }
      if (!langObj.hasOwnProperty('name')) {
        langObj['name'] = '';
      }

    });

    // create the needed one based on api
    postData['channel'] = this.setTags.join(',');

    if (this.imageDirty) {
      postData['image_data_uri'] = this.imageOutput;
    }

    const loaderDialogData = {
      message: 'Updating product family...'
    };

    loaderDialogData['observable'] = this.cache.inventoryCache.updateProductFamily(this.family.id, postData, true).pipe(
      filter(
        (response: HttpEvent<ProductFamilyModel>) => {
          if (response.type === HttpEventType.UploadProgress) {
            const eventProgress: HttpProgressEvent = response;
            const percentage = Math.round(eventProgress.loaded / eventProgress.total * 100);

            if (percentage !== 100) {
              loaderDialogData.message = `${percentage}% uploaded`;
            } else {
              loaderDialogData.message = `Processing data...`;
            }
          } else if (response.type === HttpEventType.Response) {
            this.notify.addSuccessNotification(`Updating product lines within PF`);
            return true;
          }

          return false;
        }
      ),
      switchMap(value => {
        return this.cache.inventoryCache.updateProductFamilyProductLines(this.family.id);
      }),
      tap((next) => {
        this.notify.addSuccessNotification('Successfully updated PF ' + name);

        this.editForm.markAsPristine();
        this.imageDirty = false;
        this.tagsDirty = false;
      })
    );


    const config: MatDialogConfig = GeneralUtil.smallDialogConfigPopup(true);
    config.data = loaderDialogData;

    this.dialog.open(LoadingDialogComponent, config);
  }

  public onImageOutput(imageData: string): void {
    this.editForm.markAsDirty();
    this.imageOutput = imageData;
    this.imageDirty = true;
  }

  public onLocationChange(event, input): void {
    const keyPressed = event.which || event.keyCode;
    const inputVal = event.target.value || event.srcElement.value;

    if (keyPressed === 8 && (inputVal == null || inputVal === '')) {
      // on backspace
      this.editForm.controls[input].setValue(null);
      inputVal.target.value = ' ';
    } else if (keyPressed === 13) {
      // on ENTER

    }
  }

  public onRestructureCategoryChange(value: string): void {
    const newCategory = this.cache.inventoryCache.getCategory(value);

    const data: RestructureProductFamilyDialog = {
      new_category: newCategory,
      old_category: this.category,
      product_family: this.family,
      confirmed: false
    };

    const config: MatDialogConfig = new MatDialogConfig();

    config.data = data;
    config.width = '350px';
    config.disableClose = true;

    const dialogRef = this.dialog.open(RestructureProductFamilyDialogComponent, config);

    dialogRef.afterClosed().subscribe(result => {
      if (!result) {
        this.restructureForm.controls['category'].setValue(this.category.id);
      } else {
        this.cache.inventoryCache.moveProductFamilyCategory(this.family, this.category, newCategory);

        this.category = newCategory;
        this.setupBreadcrumbs();
      }
    });
  }

  public goToInventory(): void {
    // navigate to / which is the inventory
    this.router.navigate(['/', 'inventory', 'general', 'shape']).then(
      (fullfilled) => {
      }
    );
  }

  public submitForm(): void {
    // check the form is valid and there isn't an on-going update
    if (this.editForm.valid && (this.updateSubscription == null || this.updateSubscription.closed)
      && (this.publishSubscription == null || this.publishSubscription.closed)) {
      const nameChanged = Object.entries(this.family.language_config).some(
        ([key, { name }]) =>
          name !== this.editForm.controls['language_config'].value[key]?.name
      );
      const priceGroupChanged = this.editForm.value['price_group'] !== this.family.price_group;
      if (nameChanged || priceGroupChanged) {
        this.confirmNameRenaming();
      } else {
        this.saveDataForm();
      }

    }
    if (!this.editForm.controls.price_group.value) {
      this.notify.addFailNotification('No price group assigned to product, changes were not saved');
    }
  }

  onClickHistoryButton(): void {
    const config: MatDialogConfig = new MatDialogConfig();
    const data: EventHistoryDialog = {
      id: this.family.id.toString(),
      type: 'family',
      data: this.family
    };

    config.data = data;
    config.width = '66%';
    config.disableClose = false;

    const dialogRef = this.dialog.open(EventHistoryDialogComponent, config);

    dialogRef.afterClosed().subscribe(result => {
    });
  }

  public get loading(): boolean {
    return this.updateSubscription != null && !this.updateSubscription.closed;
  }

  canDeactivate(): boolean {
    return !this.editForm.dirty;
  }

  editInventoryType() {
    const config: MatDialogConfig = GeneralUtil.mediumDialogConfigPopup(false);
    const data: ChangeSizePfDialogData = {
      family: this.family
    };
    config.data = data;

    const dialogRef = this.dialog.open(ChangeInventoryTypeDialogComponent, config);

    // Get the outcome size value from the dialog and update the form to reflect this
    dialogRef.afterClosed().subscribe((result: ChangeInventoryTypeDialogOutcome) => {
      this.editForm.controls['inventory_type'].setValue(result.inventory_type);
    });
  }

  private getMultiLocationData(): Observable<BrmLocation[]> {
    return this.cache.multiLocationCache.getData().pipe(
      tap({
        next: (value: BrmLocation[]) => {
          this.locations = value;
        }
      })
    );
  }

  public onHomeLocationOptionSelect(event: MatAutocompleteSelectedEvent): void {
    this.editForm.controls['home_location'].setValue(event.option.value['name']);
    this.homeLocation = event.option.value;
    this.editForm.controls['home_location'].markAsDirty();
    this.homeLocationInput.nativeElement.value = ' ';
  }

  public onBlurLocationChange(event, input): void {
    if (this.editForm.controls[input].value == null || (this.editForm.controls[input].value.trim() === '')) {
      event.target.value = '';
    }
  }


  public removeTag(tag: string): void {
    if (this.setTags.indexOf(tag) >= 0) {
      if (this.channelTagInput != null) {
        this.editForm.controls['tag'].setValue(' ');
        this.editForm.controls['tag'].setValue('');
      }
      this.setTags.splice(this.setTags.indexOf(tag), 1);
      this.tagsDirty = true;
      this.editForm.markAsDirty();
    }
  }

  public addTag(tag: string): void {
    if (this.setTags.indexOf(tag) === -1) {
      this.setTags.push(tag);

      this.editForm.controls['tag'].updateValueAndValidity({onlySelf: false, emitEvent: true});

      this.channelTagInput.nativeElement.value = '';
      this.tagsDirty = true;
    }
  }

  public tagSelected(data: MatAutocompleteSelectedEvent): void {
    this.addTag(data.option.value);
  }

  /***
   * Takes input from tag formControl and filters out suggests for tags to be added
   */
  public tagFilter(value: string): object[] {
    value = GeneralUtil.isNonEmptyString(value) ? value.toLowerCase() : '';

    // Check we have input to check against
    if (value.length === 0) {
      const allTags = [];

      if (this.sbcPublished) {
        allTags.push({
          label: 'SBC',
          value: 'SBC'
        });
      }

      if (this.vgPublished) {
        allTags.push({
          label: 'VeloGuide',
          value: 'VeloGuide'
        });
      }

      if (this.brPublished) {
        allTags.push({
          label: 'Bike.Rent',
          value: 'Bike.Rent'
        });
      }
      return allTags;
    }

    const responseTags = [];

    return responseTags;
  }

  private loadSbcData(): Observable<any> {
    const apiUrl = this.appControl.apiUrl;

    return this.rest.getRequest(apiUrl + '/integrations/sbc', {}).pipe(
      tap({
        next: (value: SettingsSbcApiResponse) => {
          this.sbcPublished = value.published;
        }
      })
    );
  }

  private loadBikeDotRentData(): Observable<any> {
    const apiUrl = this.appControl.apiUrl;

    return this.rest.getRequest(apiUrl + '/integrations/bikedotrent', {}).pipe(
      tap({
        next: (value: SettingsBikeDotRentResponse) => {
          this.brPublished = value.published;
        }
      })
    );
  }

  private loadVeloGuideData(): Observable<any> {
    const apiUrl = this.appControl.apiUrl;

    return this.rest.getRequest(apiUrl + '/integrations/veloguide', {}).pipe(
      tap({
        next: (value: BrmVeloGuideData) => {
          this.vgPublished = value.published;
        }
      })
    );
  }

}
