export const environment = {
  appVersion: require('../../package.json').version,
  production: true,
  loginEnabled: true,
  baseUrl: 'https://brm-test-us.uc.r.appspot.com/',
  devBaseUrl: 'https://941-dot-bikerentalmanager-us.appspot.com',
  apiUrl: 'api/',
  sbcApiUrl: 'https://brm-product-catalog.appspot.com/api/',
  devSbcApiUrl: 'https://brm-product-catalog.appspot.com/api/',
  forceLogin: false,
  firebase: {
    apiKey: 'AIzaSyBTuoUYeZoIC_x9kvkfhHpEh4MWpE6SNSs',
    authDomain: 'brm-hub.firebaseapp.com',
    databaseURL: 'https://brm-hub.firebaseio.com',
    projectId: 'brm-hub',
    storageBucket: 'brm-hub.appspot.com',
    messagingSenderId: '723038615376'
  },
  brm1RedirectUrl: '/',
  fullStory: {
    debug: false,
    host: 'fullstory.com',
    org: 'MJNAW',
    namespace: 'FS'
  },
  dimensions: {
    mobile: 576,
    tablet: 768,
    desktop: 992
  },
  // gtmId: 'GTM-5GX4B65'
  gtmId: 'GTM-P5KPQ8K'
};
