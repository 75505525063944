import {Component} from '@angular/core';
import {ShellControlService} from '../../shell-control.service';
import {BrmSessionService} from '../../../../core/brm2/brm-session.service';
import {BrmSettingsService} from '../../../../core/services/brm/brm-settings.service';

@Component({
  selector: 'app-side-nav-store-selector',
  templateUrl: './side-nav-store-selector.component.html',
  styleUrls: ['./side-nav-store-selector.component.scss']
})
/**
 * SideNav Store Selector
 * Shows the Ops store select link for ops, and store name for non-ops
 */
export class SideNavStoreSelectorComponent {

  constructor(public shellControl: ShellControlService,
              public brmSession: BrmSessionService,
              public settings: BrmSettingsService) {
  }

}
