import {Injectable} from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class LazyLoadingService {

  private _loadedStylesheets: string[] = [];
  private _loadedScripts: string[] = [];

  private _lazyLoadingPromises: { [key: string]: Promise<any> } = {};

  constructor() {
  }

  public loadExternalScript(scriptUrl: string): Promise<boolean> {
    console.log('Request to load script with URL ' + scriptUrl);
    if (this.hasScriptLoaded(scriptUrl)) {
      return new Promise((resolve, reject) => {
        resolve(true);
      });
    } else if (this.hasPromise(scriptUrl)) {
      return this._lazyLoadingPromises[scriptUrl];
    } else {
      this._lazyLoadingPromises[scriptUrl] = new Promise((resolve, reject) => {
        const scriptElement = document.createElement('script');
        scriptElement.src = scriptUrl;
        scriptElement.onerror = () => {
          reject();
        };
        scriptElement.onload = () => {
          delete this._lazyLoadingPromises[scriptUrl];

          if (!this.hasScriptLoaded(scriptUrl)) {
            this._loadedScripts.push(scriptUrl);


            resolve(true);
          }
        };
        document.body.appendChild(scriptElement);
      });

      return this._lazyLoadingPromises[scriptUrl];
    }
  }

  public loadExternalStyles(styleUrl: string): Promise<boolean> {
    console.log('Request to load style with URL ' + styleUrl);
    if (this.hasStylesheetLoaded(styleUrl)) {
      return new Promise((resolve, reject) => {
        resolve(true);
      });
    } else if (this.hasPromise(styleUrl)) {
      return this._lazyLoadingPromises[styleUrl];
    } else {
      this._lazyLoadingPromises[styleUrl] = new Promise((resolve, reject) => {
        const styleElement = document.createElement('link');
        styleElement.href = styleUrl;
        styleElement.rel = 'stylesheet';
        styleElement.onerror = () => {
          reject();
        };
        styleElement.onload = () => {
          delete this._lazyLoadingPromises[styleUrl];

          if (!this.hasStylesheetLoaded(styleUrl)) {
            this._loadedStylesheets.push(styleUrl);
            resolve(true);
          }
        };
        document.head.appendChild(styleElement);
      });

      return this._lazyLoadingPromises[styleUrl];
    }
  }

  private hasPromise(path: string): boolean {
    return this._lazyLoadingPromises.hasOwnProperty(path);
  }

  private hasStylesheetLoaded(path: string): boolean {
    return this._loadedStylesheets.indexOf(path) >= 0;
  }

  private hasScriptLoaded(path: string): boolean {
    return this._loadedScripts.indexOf(path) >= 0;
  }
}
