import {Injectable} from '@angular/core';
import {AppControlService} from '../../../services/app-control.service';
import {RestRequestsService} from '../../../services/rest-requests.service';
import {Observable} from 'rxjs/index';
import {BrmIssueSearch} from '../../api/issues/brm-issue-search';

@Injectable({
  providedIn: 'root'
})
export class BrmIssuesApiService {

  constructor(private appControl: AppControlService, private rest: RestRequestsService) {
  }

  public getSmartIssues(type: 'all' | 'business' = 'business', includeExpired: boolean = false, includeResolved: boolean = false): Observable<BrmIssueSearch> {
    const parameters = {};

    parameters['type'] = type;
    parameters['include_expired'] = includeExpired;
    parameters['include_resolved'] = includeResolved;

    return this.rest.getRequest(`${this.appControl.apiUrl}/issues`, parameters);
  }

  public setResolveStatus(id: number, resolveStatus: boolean = true, reason: string = null): Observable<any> {
    const data = {
      resolved: resolveStatus,
    };

    if (reason) {
      data['reason'] = reason;
    }

    return this.rest.postRequest(`${this.appControl.apiUrl}/issues/${id}`, JSON.stringify(data));
  }
}
