// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  appVersion: require('../../package.json').version + '-dev',
  production: false,
  loginEnabled: true,
  baseUrl: 'https://brm-test-us.uc.r.appspot.com/',
  devBaseUrl: 'https://brm-test-us.uc.r.appspot.com/',
  // devBaseUrl: 'http://localhost:8888/',
  apiUrl: 'api/',
  devApiUrl: 'https://brm-test-us.uc.r.appspot.com/',
  // devApiUrl: 'http://localhost:8888/api/',
  sbcApiUrl: 'https://brm-product-catalog.appspot.com/api/',
  devSbcApiUrl: 'http://localhost:8080/api/',
  forceLogin: false,
  firebase: {
    apiKey: 'AIzaSyBTuoUYeZoIC_x9kvkfhHpEh4MWpE6SNSs',
    authDomain: 'brm-hub.firebaseapp.com',
    databaseURL: 'https://brm-hub.firebaseio.com',
    projectId: 'brm-hub',
    storageBucket: 'brm-hub.appspot.com',
    messagingSenderId: '723038615376'
  },
  brm1RedirectUrl: 'https://us.bikerentalmanager.com/',
  fullStory: {
    debug: false,
    host: 'fullstory.com',
    org: 'MJNAW',
    namespace: 'FS'
  },
  dimensions: {
    mobile: 576,
    tablet: 768,
    desktop: 992
  },
  // gtmId: 'GTM-5GX4B65'
  gtmId: 'GTM-P5KPQ8K'
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
