import {Injectable, isDevMode, NgZone} from '@angular/core';
import {CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, Router} from '@angular/router';
import {environment} from '../../../environments/environment';
import {FirebaseAuthService} from '../services/firebase-auth.service';

@Injectable({
  providedIn: 'root'
})
export class AuthGuard implements CanActivate {

  constructor(private fbAuth: FirebaseAuthService, private router: Router, private ngZone: NgZone) {

  }

  async canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot) {

    // Add code to skip over login, only in developer mode
    if (!environment.loginEnabled) {
      return true;
    }

    if (this.fbAuth.userIsAuthenticated()) {
      return true;
    } else {
      this.ngZone.run(() => {
        this.router.navigate(['/', 'login']);
      });

      return false;
    }

  }
}
