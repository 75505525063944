import {Injectable} from '@angular/core';
import {LOGGER_TYPES, LoggerService} from '../../../core/services/logger.service';


@Injectable({
  providedIn: 'root',
})
export class ToastService {
  //TODO: Change to using a behaviour subject and an observable

  public active: boolean = false;
  public activeProgress: boolean = false;
  public listOfToastNotifications: Array<{}> = [];
  private activeTime: number = 5000; // 5 Seconds, this ties in with the timer for the progress in SCSS

  constructor(private logger: LoggerService) {
  }


  public addSuccessNotification(msg: string, title?: string): void {
    this.callNotification(msg, 'success', { title });
  }

  public addWarningNotification(msg: string, title?: string): void {
    this.callNotification(msg, 'warning', { title });
  }

  public addFailNotification(msg: string, title?: string): void {
    this.callNotification(msg, 'error', { title });
  }


  public callNotification = (msg?: string, type?: string, opt?: { title?: string, persist?: boolean, link?: string }) => {

    const toastId = Math.floor(Math.random() * 100000);
    this.openToast(toastId, msg, type, opt); // Open the toast
    this.logger.log(msg, LOGGER_TYPES[`${type.toUpperCase()}`]);
  };



  public openToast(toastId: number, msg?: string, type?: string, opt?: { title?: string, persist?: boolean, link?: string }) {
    this.listOfToastNotifications.push({
      id: toastId,
      msg: msg,
      type: type,
      opt: opt,
      timer: setTimeout(() => {
        this.closeToast(toastId);
      }, this.activeTime),
      active: true
    });
  }

  public openLink(e: MouseEvent, link?: string) {
    e.preventDefault();
    if (link) {
      window.open(link, '_blank');
    }
  }

  /**
   * 800 is the default setting to remove the toast, it is currently the best timing 
   * to remove the toast from the screen without any odd styling flashes
   */

  public closeToast(e?) {
    // Delete the toast from the array
    this.listOfToastNotifications = this.listOfToastNotifications.filter((toastIds: { id: any }) => toastIds.id !== Number(e)); 
  }

}


