export const ANGULAR_EDITOR_HIDDEN_BTNS: string[][] = [
  [
    'insertImage',
    'insertVideo',
    'strikeThrough',
    'fontName',
    'subscript',
    'superscript',
    'customClasses',
    'heading'
  ]
];
