export class Brm400Error {

  private _message: string;
  private _code: string;

  constructor(message: string, code: string) {
    this._message = message;
    this._code = code;
  }

  public get message(): string {
    return this._message;
  }

  public get code(): string {
    return this._code;
  }

  public toString(): string {
    return 'BRM API Error (' + this.code + '): ' + this.message;
  }

}
