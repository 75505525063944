export const BRM_PRODUCT_LINE_START_ID = 100000;
export const BRM_PRODUCT_LINE_END_ID = 999999999999999;
export const BRM_DEFAULT_ASSET_MAX_LIMIT = 300;

export function isPhysicalAssetId(id: number | string): boolean {
  if (typeof id === 'string') {
    id = Number(id);
  }

  if (id != null && id < BRM_PRODUCT_LINE_START_ID) {
    return true;
  } else if (id != null && id > BRM_PRODUCT_LINE_END_ID) {
    return true;
  }

  return false;
}

export function getProductLineId(productLineId: string | number): number {
  if (typeof productLineId === 'string') {
    productLineId = Number(productLineId);
  }

  productLineId = productLineId - (productLineId % 1000);

  return productLineId;
}

export function isVirtualId(virtualId: string | number): boolean {
  if (typeof virtualId === 'string') {
    virtualId = Number(virtualId);
  }

  return virtualId % 1000 > 0;
}

export function getIdType(id: number | string): 'item' | 'virtual' | 'product_line' {
  if (isPhysicalAssetId(id)) {
    return 'item';
  } else if (isVirtualId(id)) {
    return 'virtual';
  }

  return 'product_line';
}
