import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SplashComponent } from '../../modules/splash/splash.component';
import { SplashRoutingModule } from './splash.routing.module';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { InitialSettingsDialogModule } from '../shared/initial-settings-dialog/initial-settings-dialog.module';

@NgModule({
  declarations: [SplashComponent],
  imports: [
    CommonModule,
    SplashRoutingModule,
    MatProgressSpinnerModule,
    InitialSettingsDialogModule,
  ],
})
export class SplashModule {}
