import {BrmProductLine} from '../../../../core/brm2/api/inventory/shape/brm-product-line';

export class ShapeLine {

  total_capacity: number = 0;
  total_assets: number = 0;

  constructor(public data: BrmProductLine) {
    this.updateCapacityAndAssets();
  }

  public recalculateLine(): void {

  }

  updateCapacityAndAssets(): void {
    if (this.data != null) {
      this.total_capacity = this.data.capacity;
      this.total_assets = this.data.item_count;
    }
  }

}
