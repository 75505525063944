import {Injectable} from '@angular/core';
import {BrmInventoryTypes} from '../../data/brm-inventory-types';
import {BehaviorSubject, Observable} from 'rxjs';
import {BrmUserSettingsService} from '../user-settings/brm-user-settings.service';

@Injectable({
  providedIn: 'root'
})
export class BrmGbalService {

  public gbalMode: BrmInventoryTypes = BrmInventoryTypes.ASSET;
  private _gbalMode: BehaviorSubject<BrmInventoryTypes> = new BehaviorSubject(this.gbalMode);
  public gbalMode$: Observable<BrmInventoryTypes> = this._gbalMode.asObservable();

  constructor(private userSettings: BrmUserSettingsService) {
    this.setGbalFromUserSettings();
  }

  private setGbalFromUserSettings(): void {
    const setting = this.userSettings.getAppSetting('gbal_mode');

    if (setting === '' || setting == null) {
      this.gbalMode = BrmInventoryTypes.ASSET;
    } else if (setting === 'ASSET') {
      this.gbalMode = BrmInventoryTypes.ASSET;
    } else if (setting === 'PL') {
      this.gbalMode = BrmInventoryTypes.PRODUCTLINE;
    }

    console.log('Gbal: ', this.gbalMode);
  }

  public toggleGbalMode(): void {
    if (this.gbalMode === BrmInventoryTypes.ASSET) {
      this.gbalMode = BrmInventoryTypes.PRODUCTLINE;
      this.userSettings.updateAppSettings({
        'gbal_mode': 'PL'
      });
    } else {
      this.gbalMode = BrmInventoryTypes.ASSET;
      this.userSettings.updateAppSettings({
        'gbal_mode': 'ASSET'
      });
    }

    this._gbalMode.next(this.gbalMode);
  }
}
