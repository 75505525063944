import {Component, OnInit, ViewChild} from '@angular/core';
import {BrmSettingsService} from '../../../../core/services/brm/brm-settings.service';
import {BrmCacheService} from '../../../../core/brm2/brm-cache.service';
import {Observable} from 'rxjs';
import {BrmLocation} from '../../../../core/brm2/api/settings/brm-location';
import {MatMenuTrigger} from '@angular/material/menu';
import {BrmSessionService} from '../../../../core/brm2/brm-session.service';
import {BrmLocationService} from '../../../../core/brm2/services/location/brm-location.service';

@Component({
  selector: 'app-location-selector',
  templateUrl: './location-selector.component.html',
  styleUrls: ['./location-selector.component.scss', '../breadcrumb-header.component.scss']
})
export class LocationSelectorComponent implements OnInit {
  @ViewChild(MatMenuTrigger) locationMenu: MatMenuTrigger;

  public locationLoader$: Observable<BrmLocation[]>;

  public loaded: boolean = false;

  constructor(public brm: BrmSettingsService, public cache: BrmCacheService, public brmSession: BrmSessionService, public brmLocation: BrmLocationService) {
    this.locationLoader$ = this.brmLocation.getLocationsData();
  }


  ngOnInit() {
  }

}
