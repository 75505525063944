import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {FreshSalesContentWrapComponent} from './freshsales-content-wrap.component';

@NgModule({
  declarations: [
    FreshSalesContentWrapComponent
  ],
  imports: [
    CommonModule
  ],
  exports: [
    FreshSalesContentWrapComponent
  ]
})
export class FreshSalesContentWrapModule {
}
