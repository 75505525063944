import {BrmCache} from './brm-cache';
import {Observable} from 'rxjs';
import {map, tap} from 'rxjs/operators';
import {RestRequestsService} from '../../services/rest-requests.service';
import {AppControlService} from '../../services/app-control.service';
import {BrmVan} from "../api/settings/brm-van";

export class BrmVanCache extends BrmCache<BrmVan[]> {

  private rest: RestRequestsService;
  private appControl: AppControlService;

  constructor(_caches, _appControl, _rest) {
    super(_caches, 'vans');

    this.appControl = _appControl;
    this.rest = _rest;
  }

  protected loadData(): Observable<BrmVan[]> {
    return this.rest.getRequest(this.appControl.apiUrl + '/deliveries/vans', {}).pipe(
      map((value: BrmVan[]) => {
        return value;
      })
    );
  }

  public deleteVan(van: BrmVan): Observable<BrmVan> {
    return this.rest.deleteRequest(this.appControl.apiUrl + '/deliveries/vans/' + van.id, {}).pipe(
      tap((value: BrmVan) => {
          if (this.cachedData != null) {
            this.cachedData.splice(this.cachedData.indexOf(van), 1);
          }
        }, (error: any) => {
        }
      ));
  }

  public findVan(id: number): BrmVan {

    if (this.cachedData == null) {
      return null;
    }

    return this.cachedData.find((value: BrmVan) => {
      return value.id == id;
    })
  }

  public saveVan(id: number, data: object, isNew: boolean = false): Observable<BrmVan> {

    let apiCall = '/deliveries/vans';

    if (!isNew) {
      apiCall = '/deliveries/vans/' + id;
    }

    return this.rest.postRequest(this.appControl.apiUrl + apiCall, JSON.stringify(data)).pipe(
      map((value: BrmVan) => {
        return value;
      }),
      tap((value: BrmVan) => {
        if (isNew) {
          this.cachedData.push(value);
        } else {
          const van = this.findVan(id);

          if (van != null) {
            Object.assign(van, value);
          }
        }
      })
    );
  }

  protected onLoad(): void {
  }

}
