import {ChangeDetectorRef, OnDestroy, Pipe, PipeTransform} from '@angular/core';
import {DateTime} from 'luxon';
import {Subscription} from 'rxjs';
import {BrmDatesAndTimeService} from '../brm-dates-and-time.service';
import {BrmSessionService} from '../../../brm-session.service';
import {BrmLuxonDatePipe} from './brm-luxon-date.pipe';
import {BrmLuxonTimePipe} from './brm-luxon-time.pipe';

@Pipe({
  name: 'brmLuxon'
})
export class BrmLuxonPipe implements PipeTransform, OnDestroy {

  private timeSubscription: Subscription;
  private dateSubscription: Subscription;
  private format: string = 'UK';
  private dateFormat: string = 'UK';

  constructor(private brmDatesAndTime: BrmDatesAndTimeService, private session: BrmSessionService, private _ref: ChangeDetectorRef,
              private luxonDate: BrmLuxonDatePipe, private luxonTime: BrmLuxonTimePipe) {
    this.initialiseDateChange();
  }

  ngOnDestroy(): void {
    this.timeSubscription.unsubscribe();
    this.dateSubscription.unsubscribe();
  }

  private initialiseDateChange(): void {
    this.timeSubscription = this.brmDatesAndTime.timeFormat$.subscribe(
      (next: string) => {
        this.format = next;
        this._ref.markForCheck();
      }
    );
    this.dateSubscription = this.brmDatesAndTime.dateFormat$.subscribe(
      (next: string) => {
        this.dateFormat = next;
        this._ref.markForCheck();
      }
    );
  }


  transform(value: number, fullTime = false, setTimeZone = true, includeTime = true, ...args): any {
    try {

      let returnStr = `${this.luxonDate.transform(value, setTimeZone)}`;

      if (includeTime) {
        returnStr += ` ${this.luxonTime.transform(value, fullTime, false, setTimeZone)}`;
      }

      return returnStr;

    } catch (error) {
      console.error(error);
    }

    return value;
  }

}
