import {Injectable} from '@angular/core';
import {filter, takeUntil} from 'rxjs/operators';
import {ActivatedRoute, NavigationEnd, PRIMARY_OUTLET, Router} from '@angular/router';
import {BreadcrumbEntry} from '../data/breadcrumbs/breadcrumb-entry';
import {BreadcrumbRouteData} from '../data/breadcrumbs/breadcrumb-route-data';

@Injectable({
  providedIn: 'root'
})
export class BreadcrumbsService {

  public breadcrumbs: BreadcrumbEntry[] = [];

  private pathConstruction: string[];

  constructor(private activatedRoute: ActivatedRoute, private router: Router) {
    this.setupInitialBreadcrumb();
    this.setupRouterEventListener();
  }

  /**
   * Our event listening subscription doesn't get the initial breadcrumb therefore we must capture it here
   */
  setupInitialBreadcrumb(): void {
    // this.breadcrumbs = this.getBreadcrumbs(this.activatedRoute);
    this.processBreadcrumbs(this.activatedRoute);
  }

  /***
   * We want to listen for changes in the router, for when the user is navigating around the application.
   * Therefore we subscribe to the router
   */
  setupRouterEventListener(): void {
    this.router.events.pipe(
      // Only get events which are when the navigation has finished
      filter((value) => {
        return value instanceof NavigationEnd;
      })
    ).subscribe(
      (value: NavigationEnd) => {
        // this.breadcrumbs = this.getBreadcrumbs(this.activatedRoute);
        this.processBreadcrumbs(this.activatedRoute);
      }
    );
  }

  private processBreadcrumbs(route: ActivatedRoute): void {
    // We need to store the global path whilst we're generating the breadcrumbs
    this.pathConstruction = ['/'];

    const processedBreadcrumbs = this.processRoute(route);

    this.breadcrumbs = processedBreadcrumbs;
  }

  private processRoute(route: ActivatedRoute, breadcrumbs: BreadcrumbEntry[] = []): BreadcrumbEntry[] {
    // If we have a routeConfig then we're not on the root path
    if (route.routeConfig) {

      // If the route we're currently on has breadcrumb data
      if (route.routeConfig.data && route.routeConfig.data['breadcrumb']) {
        const breadcrumbData: BreadcrumbRouteData = route.routeConfig.data['breadcrumb'];
        const newCrumbs = this.processBreadcrumb(route, breadcrumbData);

        breadcrumbs = breadcrumbs.concat(newCrumbs);
      } else if (route.routeConfig.path !== '') {
        // add auto generated breadcrumbs if they're missing
        const urlParts = route.routeConfig.path.split('/');
        // If the part of the URL isn't an empty string, we push the path onto the construction path
        this.pathConstruction = this.pathConstruction.concat(urlParts);

        const newCrumb = new BreadcrumbEntry(route.routeConfig.path, route.routeConfig.path, [...this.pathConstruction]);
        breadcrumbs.push(newCrumb);
      }

    }

    if (route.firstChild) {
      return this.processRoute(route.firstChild, breadcrumbs);
    }

    return breadcrumbs;
  }

  private processBreadcrumb(route: ActivatedRoute, breadcrumb: BreadcrumbRouteData): BreadcrumbEntry[] {
    const crumbs = [];

    if (breadcrumb && breadcrumb.additionalPaths != null) {
      breadcrumb.additionalPaths.forEach((fakeRoute: BreadcrumbRouteData) => {
        const fakeCrumb = new BreadcrumbEntry(fakeRoute.id, fakeRoute.label, fakeRoute.path, null,
          fakeRoute.icon, null, fakeRoute.hidden);
        crumbs.push(fakeCrumb);
      });
    }

    // Part of the router path that this breadcrumb was assigned to
    const breadcrumbDefPath: string = route.routeConfig.path;

    if (breadcrumb.path == null) {

      // If we have a URL section, we want to add it to the path construction
      if (breadcrumbDefPath !== '') {
        // If the URL is a complicated URL, which isn't using children, we need to split it by /
        // to extract the correct parts of the URL
        const urlParts = breadcrumbDefPath.split('/');
        // If the part of the URL isn't an empty string, we push the path onto the construction path
        this.pathConstruction = this.pathConstruction.concat(urlParts);
      }

      // Replace variables in the URL with the actual values
      this.replaceUrlParameterVariablesWithData(route, this.pathConstruction);

      // Create new crumb from this path
      const newCrumb = new BreadcrumbEntry(breadcrumb.id, breadcrumb.label, [...this.pathConstruction], null,
        breadcrumb.icon, null, breadcrumb.hidden);
      crumbs.push(newCrumb);
    } else if (breadcrumb.path != null && breadcrumb.ignorePathConstruction) {
      // Create new crumb from this path
      const newCrumb = new BreadcrumbEntry(breadcrumb.id, breadcrumb.label, breadcrumb.path, null,
        breadcrumb.icon, null, breadcrumb.hidden);
      crumbs.push(newCrumb);
    }

    return crumbs;
  }

  private replaceUrlParameterVariablesWithData(activatedRoute: ActivatedRoute, path: any[]): string[] {
    const params = activatedRoute.snapshot.paramMap;

    if (params.keys.length === 0) {
      // if no parameters just return path as it is
      return path;
    }

    path.forEach((value: any, index: number) => {
      if (typeof value === 'string') {
        // remove first character which would be : in a variable
        const paramName = value.substr(1);
        if (value.substr(0, 1) === ':' && params.has(paramName)) {
          // check it was a variable by comparing it to : and then check the parameters has that variable name.
          // update path value
          path[index] = params.get(paramName);
        }
      }
    });

    return path;
  }

  public updateBreadcrumb(id: string, data: BreadcrumbEntry | object): void {
    const entry = this.breadcrumbs.find((crumb: BreadcrumbEntry) => {
      return crumb.id === id;
    });

    if (entry != null) {
      Object.assign(entry, data);

      console.log(entry);
    }
  }

  public goBack(): void {

  }
}
