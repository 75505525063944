import {NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';
import {LoginComponent} from './login.component';
import {AuthGuard} from '../../core/guards/auth.guard';
import {VerifyComponent} from './verify/verify.component';
import {SuspendedComponent} from './suspended/suspended.component';
import {LoginFailedComponent} from './login-failed/login-failed.component';
import {InitialLoadGuard} from '../../core/guards/initial-load.guard';

const childRoutes: Routes = [
  {path: '', canActivate: [], component: LoginComponent},
  {path: 'verify', canActivate: [AuthGuard], component: VerifyComponent},
  {path: 'suspended', canActivate: [], component: SuspendedComponent},
  {path: 'issue', canActivate: [], component: LoginFailedComponent},
  {path: '*', redirectTo: '', pathMatch: 'full'}
];

const routes: Routes = [
  {path: 'login', canActivate: [InitialLoadGuard], children: childRoutes},
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
  providers: []
})
export class LoginRoutingModule {
}
