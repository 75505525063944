import {BrmInventory} from '../../../../core/brm2/api/inventory/shape/brm-inventory';
import {ShapeCategory} from './shape-category';
import {BrmCategory} from '../../../../core/brm2/api/inventory/shape/brm-category';

export class ShapeInventory {

  categories: ShapeCategory[];
  category_display_order: string[];

  total_capacity: number = 0;
  total_assets: number = 0;

  constructor(public data: BrmInventory) {
    this.category_display_order = data.category_display_order;
    this.processInventory();
  }

  private processInventory(): void {
    this.categories = this.data.categories.map((category: BrmCategory) => {
      return new ShapeCategory(category);
    });
  }

  public updateCapacityAndAssets(): void {
    this.total_capacity = 0;
    this.total_assets = 0;

    this.categories.forEach((category: ShapeCategory) => {
      category.updateCapacityAndAssets();

      this.total_capacity += category.total_capacity;
      this.total_assets += category.total_assets;
    });
  }

  public recalculateInventory(): void {
    // Get all categories which exist in the inventory cache
    const categoriesToDelete = this.categories.filter((category: ShapeCategory) => {
      return !this.doesCategoryExistInRawData(category.data.id);
    });

    let i = this.categories.length - 1;

    while (i >= 0) {
      if (categoriesToDelete.indexOf(this.categories[i]) >= 0) {
        this.categories.splice(i, 1);
      } else {
        this.categories[i].recalculateCategory();
      }

      i--;
    }

    // Find the missing categories in our data structure, we do this after the above, as we won't need to recalculate these values
    const missingCategories = this.data.categories.filter((category: BrmCategory) => {
      return !this.doesCategoryExist(category.id);
    });

    // For each missing category, that's in the cache but not in the Shape format, we create ShapeCategory
    missingCategories.forEach((category: BrmCategory) => {
      this.categories.push(new ShapeCategory(category));
    });

    this.updateCapacityAndAssets();
  }

  public doesCategoryExist(id: string): boolean {
    return this.categories.find((data: ShapeCategory) => {
      return data.data.id === id;
    }) != null;
  }

  private doesCategoryExistInRawData(id: string): boolean {
    return this.data.categories.find((category: BrmCategory) => {
      return category.id === id;
    }) != null;
  }
}
