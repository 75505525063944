import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {BrmTimeFormatterDirective} from './directives/brm-time-formatter.directive';
import {BrmLuxonTimePipe} from './pipe/brm-luxon-time.pipe';
import {BrmLuxonPipe} from './pipe/brm-luxon.pipe';
import {BrmLuxonDatePipe} from './pipe/brm-luxon-date.pipe';

@NgModule({
  declarations: [
    BrmTimeFormatterDirective,
    BrmLuxonTimePipe,
    BrmLuxonPipe,
    BrmLuxonDatePipe
  ],
  imports: [
    CommonModule,
  ],
  providers: [
    BrmLuxonTimePipe,
    BrmLuxonDatePipe
  ],
  exports: [
    BrmTimeFormatterDirective,
    BrmLuxonTimePipe,
    BrmLuxonPipe,
    BrmLuxonDatePipe
  ]
})
export class BrmDatesTimeModule {

}
