import {BrmCache} from './brm-cache';
import {Observable} from 'rxjs';
import {map, tap} from 'rxjs/operators';
import {RestRequestsService} from '../../services/rest-requests.service';
import {AppControlService} from '../../services/app-control.service';
import {BrmExtra} from '../api/inventory/extras';
import {HttpEvent, HttpEventType, HttpResponse} from '@angular/common/http';

export class BrmExtrasCache extends BrmCache<BrmExtra[]> {

  private rest: RestRequestsService;
  private appControl: AppControlService;

  constructor(_caches, _appControl, _rest) {
    super(_caches, 'extras');

    this.appControl = _appControl;
    this.rest = _rest;
  }

  protected loadData(parameters?: object): Observable<BrmExtra[]> {
    if (parameters === null) {
      parameters = {};
    }

    return this.rest.getRequest(this.appControl.apiUrl + '/extras?status=active,recovered', parameters).pipe(
      map((value: BrmExtra[]) => {
        this.sortExtras(value);

        return value;
      })
    );
  }

  private sortExtras(extras: BrmExtra[]): void {
    extras.sort((a: BrmExtra, b: BrmExtra) => {
      if (a.display_order > b.display_order) {
        return 1;
      } else if (a.display_order < b.display_order) {
        return -1;
      }

      return 0;
    });
  }

  protected filterData(data: BrmExtra[], parameters?: any): BrmExtra[] {
    return data.filter(({type}) => type === parameters.type);
  }

  protected onLoad(): void {
  }

  public getExtra(id: number): BrmExtra {
    if (this.cachedData) {
      return this.cachedData.find((value: BrmExtra) => {
        return value.id === id;
      });
    }

    return null;
  }

  public createExtra(extra: object, allHttpEvents: boolean = false): Observable<BrmExtra | HttpEvent<BrmExtra>> {
    return this.rest.postRequest(this.appControl.apiUrl + '/extras', JSON.stringify(extra), true, allHttpEvents).pipe(
      tap({
        next: (value: BrmExtra | HttpEvent<BrmExtra>) => {
          if (allHttpEvents && (<HttpEvent<BrmExtra>>value).type === HttpEventType.Response) {
            value = (<HttpResponse<BrmExtra>>value).body;
            this.updateOrAddExtra(value.id, value);
          } else if (!allHttpEvents) {
            value = <BrmExtra>value;
            this.updateOrAddExtra(value.id, value);
          }
        }
      })
    );
  }

  public updateExtra(id: number, extra: object, allHttpEvents: boolean = false): Observable<BrmExtra | HttpEvent<BrmExtra>> {
    return this.rest.postRequest(this.appControl.apiUrl + '/extras/' + id, JSON.stringify(extra), true, allHttpEvents).pipe(
      tap({
        next: (value: BrmExtra | HttpEvent<BrmExtra>) => {
          if (allHttpEvents && (<HttpEvent<BrmExtra>>value).type === HttpEventType.Response) {
            value = (<HttpResponse<BrmExtra>>value).body;
            this.updateOrAddExtra(value.id, value);
          } else if (!allHttpEvents) {
            value = <BrmExtra>value;
            this.updateOrAddExtra(value.id, value);
          }
        }
      })
    );
  }

  private updateOrAddExtra(id: number, data: BrmExtra): void {
    if (this.cachedData != null) {
      const extra = this.getExtra(id);

      if (extra) {
        Object.assign(extra, data);
      } else {
        this.cachedData.push(data);
      }

      this.sortExtras(this.cachedData);
    }
  }

  public deleteExtra(id: number): Observable<any> {
    return this.rest.deleteRequest(this.appControl.apiUrl + '/extras/' + id, {id: id}).pipe(
      tap({
        next: value => {
          this.removeExtraFromCache(id);
        }
      })
    );
  }

  private removeExtraFromCache(id: number): void {
    if (this.cachedData) {
      const extra = this.getExtra(id);
      const pos = this.cachedData.indexOf(extra);

      if (pos >= 0) {
        this.cachedData.splice(pos, 1);

        this.sortExtras(this.cachedData);
      }
    }
  }

}
