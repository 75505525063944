import {Injectable, isDevMode} from '@angular/core';
import {NavigationEnd, Router} from '@angular/router';
import {environment} from '../../../environments/environment';
import {BrmSettingsService} from './brm/brm-settings.service';
import {Observable, Subscription, throwError} from 'rxjs';
import {RestRequestsService} from './rest-requests.service';
import {catchError, shareReplay} from 'rxjs/operators';
import {NotificationsService} from './notifications/notifications.service';
import {GoogleTagManagerService} from 'angular-google-tag-manager';
import {BrmLocaleCodeNamePair, BrmLocaleService} from '../brm2/services/locale/brm-locale.service';
import {BrmSessionService} from '../brm2/brm-session.service';
const version = require('../../../../version.js');

declare var disableLHSNav: boolean;
declare var FreshworksWidget;

interface Environment {
  id: string;
  urls: string[];
  regexMatch: RegExp;
  gcpProject: string;
  apiUrl: string;
  cbpBucket: string;
  kmsApiUrl: string;
}

const environments: Environment[] = [
  {
    id: 'us-test',
    urls: ['brm-test-us.uc.r.appspot.com', 'brm-test-us.web.app'],
    regexMatch: new RegExp(/brm-test-us--[\w-]{1,}\.web\.app/),
    gcpProject: '',
    apiUrl: 'https://brm-test-us.uc.r.appspot.com',
    cbpBucket: 'bike_rent_test_us',
    kmsApiUrl: 'https://us-central1-brm-test-us.cloudfunctions.net/keyManagementApi',
  },
  {
    id: 'us-prod',
    urls: ['us.bikerentalmanager.com', 'brm-us.web.app'],
    regexMatch: new RegExp(/brm-us--[\w-]{1,}\.web\.app/),
    gcpProject: '',
    apiUrl: 'https://us.bikerentalmanager.com',
    cbpBucket: 'bike_rent_us',
    kmsApiUrl: 'https://us-central1-brm-test-us.cloudfunctions.net/keyManagementApi',
  },
  {
    id: 'eu-test',
    urls: ['brm-test-eu.ew.r.appspot.com', 'brm-test-eu.web.app'],
    regexMatch: new RegExp(/brm-test-eu--[\w-]{1,}\.web\.app/),
    gcpProject: 'bikerentalmanager-us',
    apiUrl: 'https://brm-test-eu.ew.r.appspot.com',
    cbpBucket: 'bike_rent_test_eu',
    kmsApiUrl: 'https://us-central1-brm-test-us.cloudfunctions.net/keyManagementApi',
  },
  {
    id: 'eu-prod',
    urls: ['app.bikerentalmanager.com', 'brm-eu.web.app'],
    regexMatch: new RegExp(/brm-eu--[\w-]{1,}\.web\.app/),
    gcpProject: '',
    apiUrl: 'https://app.bikerentalmanager.com',
    cbpBucket: 'bike_rent_eu',
    kmsApiUrl: 'https://us-central1-brm-test-us.cloudfunctions.net/keyManagementApi',
  }
];

@Injectable({
  providedIn: 'root'
})
export class AppControlService {

  private _version = Number(environment.appVersion);

  private _server = version.server;
  private _environment = version.environment;
  public cbpBucket: string;

  private _disableSplashModal: boolean;

  // If the URL contains forceLogin then it will force the user to log back into their store
  private _forceLogin: boolean;

  private _disableLHSNav: boolean;
  // Has the application just userStateLoaded and have we not conducted 'additional initial load functionality'
  private _initialLoad: boolean;
  // Store a URL for the app to rediect to eventually
  private _redirectUrl: string;
  // Store redirects URL variables e.g. url?connected=true
  private _redirectUrlParams: object;

  // Store the url value which counts how many times it's been redirected
  private _redirectCount: number;

  // If the user is navigating the application via the getting started page, we need to adjust the app e.g. back and next buttons
  // to simplify this, we set this variable if they've navigated to the pages from getting started which then adds the buttons
  // to the shell component
  private _gettingStarted: boolean;
  private _gettingStartedRoutes: string[] = [
    '/inventory/shape',
    '/inventory/pricegroups',
    '/settings/general',
    '/settings/openinghours',
    '/settings/notifications/who',
    '/settings/bikedotrent',
    '/settings/publish'
  ];

  private _timezones: string[];
  private _timezoneLoader: Observable<string[]>;

  private _countries: BrmLocaleCodeNamePair[];
  private _countriesLoader: Observable<BrmLocaleCodeNamePair[]>;

  private _currencies: BrmLocaleCodeNamePair[];
  private _currenciesLoader: Observable<BrmLocaleCodeNamePair[]>;

  private googleAnalyticsSubscription: Subscription;

  public suspendedShopName: string;

  public freshWorksOpen: boolean = false;

  /**
   * AppControl service holds app-wide data e.g. is this the initial load of the app?
   */
  constructor(private router: Router,
              private brm: BrmSettingsService,
              private rest: RestRequestsService,
              private notify: NotificationsService,
              private gtmService: GoogleTagManagerService,
              private localeService: BrmLocaleService,
              private brmSession: BrmSessionService) {
    this._initialLoad = true;
    this._gettingStarted = false;

    this._disableSplashModal = true;

    // Set redirect url to /, so that if no redirect url is provided it will go to the base of the app on redirect
    this._redirectUrl = '/';

    this.hideFreshworksWidget();

    this.getEnvironment();
  }

  private getEnvironment(): void {
    const {host} = window.location;
    const res = environments.find(item => item.urls.includes(host) || item.regexMatch.test(host));
    const data = res || environments.find(item => item.id === 'us-test');
    this.cbpBucket = data.cbpBucket;
  }

  /**
   * Function hides the help button widget, we use our own button so we want to hide this
   */
  public hideFreshworksWidget(): void {
    if (FreshworksWidget) {
      FreshworksWidget('hide');
    }
  }

  /**
   * Function shows the builtin help button with Freshworks
   */
  public showFreshworksWidget(): void {
    if (FreshworksWidget) {
      FreshworksWidget('show');
    }
  }

  /**
   * Toggles open/close state for freshworks help
   */
  public toggleFreshworksWidgetOpenState(): void {
    if (FreshworksWidget) {
      this.freshWorksOpen = !this.freshWorksOpen;

      if (this.freshWorksOpen) {
        FreshworksWidget('open');
      } else {
        FreshworksWidget('close');
      }
    }
  }

  public fromGettingStarted(): boolean {
    return this._gettingStarted;
  }

  public goToGettingStartedRoute(position: number): void {
    if (position >= 0 && position < this._gettingStartedRoutes.length) {
      this.router.navigate([this._gettingStartedRoutes[position]]);
    } else {
      this.router.navigate(['/', 'gettingstarted']);
    }
  }

  public goBackFromHistory(): void {
    window.history.back();
  }

  public set gettingStarted(bool: boolean) {
    this._gettingStarted = bool;
  }

  public get displaySplashModal(): boolean {
    return this._disableSplashModal;
  }

  public set displaySplashModal(display: boolean) {
    this._disableSplashModal = display;
  }

  public get initialLoad(): boolean {
    return this._initialLoad;
  }

  public set initialLoad(load: boolean) {
    this._initialLoad = load;
  }

  public get redirectUrl(): string {
    return this._redirectUrl;
  }

  public set redirectUrl(url: string) {
    this._redirectUrl = url;
  }

  public get redirectUrlParams(): object {
    return this._redirectUrlParams;
  }

  public set redirectUrlParams(params: object) {
    this._redirectUrlParams = params;
  }

  public get forceLogin(): boolean {
    return this._forceLogin;
  }

  public set forceLogin(bool: boolean) {
    this._forceLogin = bool;
  }

  public get redirectCount(): number {
    return this._redirectCount;
  }

  public set redirectCount(count: number) {
    this._redirectCount = count;
  }

  public set hideNavigationSetting(bool: boolean) {
    this._disableLHSNav = bool;
  }

  public get hideNavigationSetting(): boolean {
    if (this._disableLHSNav != null) {
      return this._disableLHSNav;
    }

    if (disableLHSNav != null) {
      return disableLHSNav;
    }

    return false;
  }

  public getOBWLink(): string {
    return 'https://' + this.brm.session.dataCentre + '.bikerentalmanager.com/book.html?shop=' + this.brm.session.namespace;
  }

  public get apiUrl(): string {
    return this.sessionApiUrl + this.brm.session.namespace;
  }

  public get sessionApiUrl(): string {
    return this.baseAddress + environment.apiUrl;
  }

  /**
   * Returns the base URL for the application (incl. BRM backend API)
   */
  public get baseAddress(): string {
    return isDevMode() ? environment.devBaseUrl : environment.baseUrl;
  }

  /**
   * Retrieves available TimeZones
   */
  public getTimezones(): Observable<string[]> {
    if (this._timezoneLoader != null) {
      return this._timezoneLoader;
    }

    this._timezoneLoader = new Observable<string[]>(observable => {
      if (this._timezones != null) {
        observable.next(this._timezones);
        observable.complete();
      } else {
        this.localeService.getTimeZones().subscribe(
          (response: string[]) => {
            this._timezones = response;
            observable.next(this._timezones);
            observable.complete();
          },
          (error) => {
            observable.error(error);
            observable.complete();
          }
        );
      }
    }).pipe(
      // Prevent repeated calls, save first value and "replay" it for new subscription
      shareReplay(1),
      catchError(error => {
        this.notify.addFailNotification('Failed to load timezones, please try reloading');
        return throwError(error);
      })
    );

    return this._timezoneLoader;
  }

  /**
   * Retrieves Countries in the selected language
   */
  public getCountries(): Observable<BrmLocaleCodeNamePair[]> {
    if (this._countriesLoader != null) {
      return this._countriesLoader;
    }

    this._countriesLoader = new Observable<BrmLocaleCodeNamePair[]>(observable => {
      if (this._countries != null) {
        observable.next(this._countries);
        observable.complete();
      } else {
        this.localeService.getCountryCodes(this.brmSession.session.shop_settings.shop_language_code).subscribe(
          (response: BrmLocaleCodeNamePair[]) => {
            this._countries = response;
            observable.next(this._countries);
            observable.complete();
          },
          (error) => {
            observable.error(error);
            observable.complete();
          }
        );
      }
    }).pipe(
      // Prevent repeated calls, save first value and "replay" it for new subscription
      shareReplay(1),
      catchError(error => {
        this.notify.addFailNotification('Failed to load countries, please try reloading');
        return throwError(error);
      })
    );

    return this._countriesLoader;
  }

  /**
   * Retrieves Currencies in the selected language
   */
  public getCurrencies(): Observable<BrmLocaleCodeNamePair[]> {
    if (this._currenciesLoader != null) {
      return this._currenciesLoader;
    }

    this._currenciesLoader = new Observable<BrmLocaleCodeNamePair[]>(observable => {
      if (this._currencies != null) {
        observable.next(this._currencies);
        observable.complete();
      } else {
        this.localeService.getCurrencies(this.brmSession.session.shop_settings.shop_language_code).subscribe(
          (response: BrmLocaleCodeNamePair[]) => {
            this._currencies = response;
            observable.next(this._currencies);
            observable.complete();
          },
          (error) => {
            observable.error(error);
            observable.complete();
          }
        );
      }
    }).pipe(
      // Prevent repeated calls, save first value and "replay" it for new subscription
      shareReplay(1),
      catchError(error => {
        this.notify.addFailNotification('Failed to load currencies, please try reloading');
        return throwError(error);
      })
    );

    return this._currenciesLoader;
  }

  /**
   * Setup Google Analytics, as it's a single page application we're not triggering a new page each time. Therefore
   * we need to manually call Google analytics. We do this by subscribing to the router events and listen for the navigation
   * end event, then manually call GA.
   */
  public setupGoogleAnalytics(): void {
    this.gtmService.addGtmToDom().then(fullfilled => {
      this.googleAnalyticsSubscription = this.router.events.subscribe(
        event => {
          if (event instanceof NavigationEnd) {
            const gtmTag = {
              event: 'page',
              pageName: event.url
            };

            this.gtmService.pushTag(gtmTag).then();
          }
        }
      );
    }, rejected => {

    });
  }

  public reloadApp(): void {
    window.location.reload();
  }

  public get version(): number {
    return this._version;
  }

  public get environment(): Environment {
    const {host} = window.location;
    const res = environments.find(item => item.urls.includes(host) || item.regexMatch.test(host));
    return res || environments.find(item => item.id === 'us-test');
  }
}
