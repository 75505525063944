import { Injectable } from '@angular/core';
import firebase from 'firebase/compat/app';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { BrmGenericError } from '../brm2/api/brm-generic-error';
import { AngularFireAuth } from '@angular/fire/compat/auth';

@Injectable({
  providedIn: 'root'
})
export class FirebaseAuthService {

  private _user: firebase.User;
  private _token: string;

  private _loginState: BehaviorSubject<firebase.User>;

  private _initialCheck: boolean;

  public loginError: BrmGenericError;

  /***
   * Handles the Firebase Authorisation of the app
   * @param afAuth - Angular Fire service injection
   */
  constructor(private afAuth: AngularFireAuth) {
    this._loginState = new BehaviorSubject(null);

    this._initialCheck = false;

    // Listen for state changes in the user's authorisation
    this.initAuthStateListener();
  }

  public async loginWithToken(token: string) {
    return this.afAuth.signInWithCustomToken(token);
  }

  /**
   * Listens for changes in the user's authorisation status and carries out actions based on the output
   * TODO: Re-evaluate this code, might be redundant due to the new method of handling log in, in splash
   */
  private initAuthStateListener(): void {
    // Listen for state changes
    this.afAuth.authState.subscribe(user => {
      this._initialCheck = true;

      // If a user object is returned, the user has been logged in
      if (user) {
        this._user = user;

        // Get and store user's token
        this.getUserToken().then(
          fullfilled => {
            this._token = fullfilled;
            localStorage.setItem('t', this._token);

            this._loginState.next(this._user);
          }
        ).catch(
          rejected => {

          }
        );

      } else {
        this._user = null;

        this._loginState.next(null);
      }
    });
  }

  // Old code used for creating a user with an email/password TODO: Remove if 100% confirmed no longer using email/password combo
  public registerUser(email: string, password: string): Promise<any> {
    return this.afAuth.createUserWithEmailAndPassword(email, password);
  }

  // Logs a user in with email / password TODO: Remove if 100% confirmed no longer using email/password combo
  public userLogin(email: string, password: string): Promise<any> {
    return this.afAuth.signInWithEmailAndPassword(email, password);
  }

  public isLoggedIn(): boolean {
    return this._user != null;
  }

  /**
   * Checks if the user is logged in, if they're not logged in but we've stored a local copy of their user data from
   * a previous log in, we attempt to set that
   */
  public userIsAuthenticated(): boolean {
    // Return true or false, based on if we have a user object
    return this._user != null;
  }

  // Logs a user out
  public async logout() {
    // Call log out
    // TODO: Change logout code to await
    return this.afAuth.signOut().then(
      fullfilled => {
        // Remove user data
        localStorage.removeItem('t');
        this._user = null;

        return true;
      },
      (error) => {
        console.log('Error logging out user');
        console.log(error);

        return false;
      }
    );
  }

  // Returns the user token (async as Firebase service will auto try to refresh if expired)
  public async getUserToken() {
    return this._user.getIdToken();
  }

  public getToken(): string {
    return this._token;
  }

  public getUser(): firebase.User {
    return this._user != null ? this._user : null;
  }

  public get loginState(): Observable<firebase.User> {
    return this._loginState.asObservable();
  }

  public hasInitialCheckComplete(): boolean {
    return this._initialCheck;
  }
}
