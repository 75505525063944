import {Injectable} from '@angular/core';
import {Router} from '@angular/router';
import {BrmSessionAccountResponse, BrmSessionResponse} from './responses/brm-session-response.model';
import {BrmSession} from './brm-session';
import {BrmSessionService} from '../../brm2/brm-session.service';

@Injectable({
  providedIn: 'root'
})
export class BrmSettingsService {

  private _locationTags: string[];
  private _tooltips: object;
  private _packagePrivileges: object;
  private _rolePrivileges: object;

  private _migrationToken: string;
  private _token: string;

  private _session: BrmSession;


  constructor(private router: Router, private brmSession: BrmSessionService) {
  }

  public setSession(session: BrmSessionResponse) {
    this._session = new BrmSession(session);

    // Hook up to new BRM2 class
    // @ts-ignore - the object is the same, just different interfaces causing issues
    this.brmSession.setSession(session);
  }

  public get session(): BrmSession {
    return this._session;
  }

  get firebaseToken(): string {
    return this._token;
  }

  set firebaseToken(token: string) {
    console.log('set token to: ', token);
    this._token = token;
  }

  clearCapturedFirebaseToken(): void {
    this._token = undefined;
  }

  get migrationToken(): string {
    return this._migrationToken;
  }

  set migrationToken(value: string) {
    this._migrationToken = value;
  }

  public set locationTags(tags: string[]) {
    this._locationTags = tags;
  }

  public get locationTags(): string[] {
    return this._locationTags;
  }

  public set tooltips(tips: object) {
    this._tooltips = tips;
  }

  public get tooltips(): object {
    return this._tooltips;
  }

  public set packagePrivileges(obj: object) {
    this._packagePrivileges = obj;
  }

  public set rolePrivileges(obj: object) {
    this._rolePrivileges = obj;
  }

  public get assetType(): string {
    if (this.session != null) {
      return this.session.settings.shop_item_type_name;
    }

    return 'Bike';
  }

  public get assetTypePlural(): string {
    if (this.session != null) {
      return this.session.settings.shop_item_type_name_plural;
    }

    return 'Bikes';
  }

  /***
   * Check if the user is on a free package
   */
  public isFreePackage(): boolean {
    if (this.session != null && this.session.package != null) {
      // If session isn't null and package isn't null, they must have a package assigned to them
      const pack = this.session.package.toLowerCase();

      return pack === 'free';
    } else if (this.session == null) {
      // If session is null, they shouldn't be logged in? Therefore, they're not a free package
      return false;
    } else if (this.session != null && this.session.package == null) {
      // If they have a session, but no package, we're supporting BRM 1.0 which defaults to FREE when no package
      return true;
    }

    // Any other cases, return false
    return false;
  }

  public getTooltip(name: string): string {
    if (this._tooltips != null && this._tooltips.hasOwnProperty(name)) {
      return this._tooltips[name];
    }

    return 'Tooltip not found';
  }

  /***
   * Checks if the user has permission to a certain area/feature
   * @param privilegeId - ID of the privilege being checked
   */
  public hasPrivilege(privilegeId: string): boolean {
    if (this.session == null) {
      // If no session, not logged in therefore no permissions
      return false;
    }

    let role = '';
    let userPackage = '';

    if (this.session.role != null) {
      // Check the role value isn't null, then move it to lowercase
      role = this.session.role.toLowerCase();
    }

    if (this.session.package != null) {
      // Check the package value isn't null, then move it to lowercase
      userPackage = this.session.package.toLowerCase();
    }

    if (role === '' || userPackage === '') {
      return false;
    }

    // TODO: Maybe remove this in future
    if (userPackage !== 'free') {
      userPackage = 'paid';
    }

    if (
      this._packagePrivileges.hasOwnProperty(userPackage)
      && this._packagePrivileges[userPackage].hasOwnProperty(privilegeId)
      && this._packagePrivileges[userPackage][privilegeId]
      && this._rolePrivileges.hasOwnProperty(role)
      && this._rolePrivileges[role].hasOwnProperty(privilegeId)
      && this._rolePrivileges[role][privilegeId]
    ) {
      return true;
    }

    return false;
  }

  public goToPrivilegedRoute(routePath: string[], privilegeId: string): void {
    if (this.hasPrivilege(privilegeId)) {
      this.router.navigate(routePath).then(
        fullfilled => {

        },
        rejected => {

        }
      );
    }
  }

  public isSuperUser(): boolean {
    return this.session != null ? this.session.superuser : false;
  }

  public isOperationsUser(): boolean {
    return this.session != null ? this.session.operations : false;
  }

  public isSuspended(): boolean {
    return this.session != null ? this.session.account.shop_suspended : false;
  }

  public isBillingContact(): boolean {
    return this.session != null ? this.session.billingContact : false;
  }

  public isPrimaryContact(): boolean {
    return this.session != null ? this.session.primaryContact : false;
  }

  public isAdmin(): boolean {
    return this.session != null ? this.session.admin : false;
  }

  public canSwitchShops(): boolean {
    return this.session != null ? this.session.switchShops : false;
  }

  public getAccount(): BrmSessionAccountResponse {
    return this.session.account;
  }
}
