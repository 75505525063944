import { Injectable, OnDestroy } from '@angular/core';
import { AngularFireDatabase } from '@angular/fire/compat/database';
import { BehaviorSubject, Observable, of, Subject } from 'rxjs';
import { BrmFirebaseEventMessage } from './data/brm-firebase-event-message';
import { catchError, concatMap, exhaustMap, map, switchMap, takeUntil } from 'rxjs/operators';
import { BrmCustomer } from '../../api/customers/brm-customer';
import { RestRequestsService } from '../../../services/rest-requests.service';
import { AppControlService } from '../../../services/app-control.service';
import { BrmSettingsService } from '../../../services/brm/brm-settings.service';
import { BrmCacheService } from '../../brm-cache.service';
import { BrmCustomerGroup } from '../../api/groups/brm-customer-group';
import { BrmSessionService } from '../../brm-session.service';
import { BrmSession } from '../../api/session/brm-session';
import { environment } from '../../../../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class BrmFirebaseCustomersService implements OnDestroy {

  private checkFromTime: number = new Date().getTime();
  private destroyed$: Subject<boolean> = new Subject<boolean>();

  private _onCustomerRegister$: Subject<BrmCustomer> = new Subject<BrmCustomer>();
  public onCustomerRegister$: Observable<BrmCustomer> = this._onCustomerRegister$.asObservable();
  private _customerArray: BrmCustomer[] = [];

  private _onGroupRegister$: Subject<BrmCustomerGroup> = new Subject<BrmCustomerGroup>();
  public onGroupRegister$: Observable<BrmCustomerGroup> = this._onGroupRegister$.asObservable();
  private _groupArray: BrmCustomerGroup[] = [];

  private customerLoader$: Subject<number> = new Subject<number>();
  private groupLoader$: Subject<number> = new Subject<number>();

  private endLoader$: Subject<boolean> = new Subject<boolean>();

  private firebaseEvents: Subject<BrmFirebaseEventMessage> = new Subject<BrmFirebaseEventMessage>();
  public firebaseEvents$: Observable<BrmFirebaseEventMessage> = this.firebaseEvents.asObservable();

  private firebaseMessages: BrmFirebaseEventMessage[] = [];

  constructor(private db: AngularFireDatabase,
    private rest: RestRequestsService,
    private appControl: AppControlService,
    private brm: BrmSettingsService,
    private cache: BrmCacheService,
    private brmSession: BrmSessionService) {

  }

  ngOnDestroy(): void {
    this.destroyed$.next(true);
    this.destroyed$.complete();

    this.endLoader$.next(true);
    this.endLoader$.complete();
  }

  public getFirebaseMessages(): BrmFirebaseEventMessage[] {
    return this.firebaseMessages;
  }

  public removeFirebaseMessage(message: BrmFirebaseEventMessage) {
    this.firebaseMessages.splice(this.firebaseMessages.indexOf(message), 1);
  }

  private setupCustomerLoader(): void {
    this.customerLoader$.pipe(
      takeUntil(this.endLoader$),
      catchError((err, caught) => {
        return of(null);
      }),
      concatMap((value) => {
        return this.rest.getRequest(this.appControl.apiUrl + '/customers/' + value, {});
      }),
      map((value: BrmCustomer) => {
        return value;
      })
    ).subscribe(
      (next: BrmCustomer) => {
        if (next != null) {
          this.addCustomer(next);
        }
      }
    );
  }

  private setupGroupLoader(): void {
    this.groupLoader$.pipe(
      takeUntil(this.endLoader$),
      catchError((err, caught) => {
        return of(null);
      }),
      concatMap((value) => {
        return this.rest.getRequest(`${this.appControl.apiUrl}/groups/${value}`, {});
      }),
      map((value: BrmCustomerGroup) => {
        return value;
      })
    ).subscribe(
      (next: BrmCustomerGroup) => {
        if (next != null) {
          this.addCustomerGroup(next);
        }
      }
    );
  }

  public setupFirebaseListeners(): void {
    const session = this.brmSession.session;

    // Listen for changes within the store
    try {
      const version = session.server_version.replace('.', '');
      const namespace = session.shop_namespace;
      let url = session.event_subscription_url;
      url = url.replace(environment.firebase.databaseURL, '');

      console.log('Listening for cus @ ' + url);

      this.db.object<{ [key: string]: BrmFirebaseEventMessage }>(url).valueChanges()
      .subscribe(
        (value) => {
          // process the messages
          this.processMessages(value);
        },
        error => {
          console.log('FB Error: ', error);
        }
      );
    } catch (error) {
      console.log('Error: ', error);
    }
  }

  public startListeningForCustomerRegistration(): void {
    this._customerArray = [];
    this._groupArray = [];

    this.setupCustomerLoader();
    this.setupGroupLoader();

    this.setupFirebaseListeners();
  }

  public stopListeningForCustomerRegistration(): void {
    this._customerArray = [];

    this.endLoader$.next(true);
    this.endLoader$.complete();
  }

  private processMessages(messages: { [key: string]: BrmFirebaseEventMessage }): void {
    if (messages == null) {
      return;
    }

    const now = new Date().getTime();

    // Loop each message
    Object.keys(messages).forEach((key: string) => {
      const event: BrmFirebaseEventMessage = messages[key];

      // If the event timestamp is greater than the last time we processed messages, we know it's new
      if (event.timestamp >= this.checkFromTime) {
        console.log('Event type: ', event.type);
        console.log(event);

        // Store firebase messages
        this.firebaseMessages.push(event);

        // Check the type of the message
        if (event.type === 'customer-reg') {
          // customer-reg = new customer registered
          this.customerLoader$.next(Number(event.payload.customerId));
        } else if (event.type === 'inventory-shape-change') {
          // TODO: Handle this better?
          // When the inventory shape is changed, we need to reload the inventory to ensure consistent count
          this.cache.inventoryCache.markForReload();
        } else if (event.type === 'group-reg') {
          this.groupLoader$.next(event.payload['groupId']);
        }

        // Push out all events to any subscription
        this.firebaseEvents.next(event);
      }
    });

    this.checkFromTime = now;
  }

  private emitCustomerData(customer: BrmCustomer): void {
    this._onCustomerRegister$.next(customer);
  }

  addCustomer(customer: BrmCustomer): void {
    this._customerArray.push(customer);
    this.emitCustomerData(customer);
  }

  get customers(): BrmCustomer[] {
    return this._customerArray;
  }

  private emitGroupData(group: BrmCustomerGroup): void {
    this._onGroupRegister$.next(group);
  }

  addCustomerGroup(group: BrmCustomerGroup): void {
    this._groupArray.push(group);
    this.emitGroupData(group);
  }

  get groups(): BrmCustomerGroup[] {
    return this._groupArray;
  }
}
