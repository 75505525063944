import {Injectable} from '@angular/core';
import {Router} from '@angular/router';
import {BrmInventoryTypes} from '../../core/brm2/data/brm-inventory-types';
import {BehaviorSubject, Observable} from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ShellControlService {

  private _showNavigation: boolean = true;
  private _expandNavigation: boolean = false;
  private _showBreadcrumbs: boolean = true;

  /**
   * Shell control service controls the layout of the "shell" of the app. The shell consists
   * of the top header and left hand side navigation bar.
   */
  constructor(private router: Router) {
  }

  /**
   * Toggles the left hand side menu
   */
  public toggleNavigation(): void {
    this._expandNavigation = !this._expandNavigation;
  }

  /**
   * Get status of left hand side menu
   */
  public get expandedNavigation(): boolean {
    return this._expandNavigation;
  }

  public set expandedNavigation(bool: boolean) {
    this._expandNavigation = bool;
  }

  public set showNavigation(show: boolean) {
    this._showNavigation = show;
  }

  public get showNavigation(): boolean {
    return this._showNavigation;
  }

  public get showBreadcrumbs(): boolean {
    return this._showBreadcrumbs;
  }

}
