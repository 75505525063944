import {Directive, ElementRef, HostListener, Input} from '@angular/core';
import {Router} from '@angular/router';

@Directive({
  selector: '[appSideNavDropdownSelector]'
})
export class SideNavDropdownSelectorDirective {

  @Input() activatedRouterLink: string = '';
  @Input() isMenuHolder: boolean = false;
  currentState: string = '';
  constructor(
    private router: Router,
    private el: ElementRef,
    private window: Window
  ) {
  }

  @HostListener('mouseenter')
  async onMouseEnter() {
    if (this.window.matchMedia('screen and (min-width: 1025px)').matches) {
      this.currentState = 'add';
      await this.highlight('add');
    }
  }

  @HostListener('mouseleave')
  async onMouseLeave() {
    this.currentState = 'remove';
    await this.highlight('remove');
  }

  @HostListener('click', ['$event']) onMouseClick(event: any) {
    if (
      event.target.classList.contains('sidenav-entry__router') ||
      this.window.matchMedia('screen and (max-width: 1024px)').matches &&
      !event.target.classList.contains('sidenav-entry__hide')
    ) {
        this.highlight( 'toggle');
    }

    if (
      event.target.classList.contains('sidenav-entry__router') &&
      this.window.matchMedia('screen and (min-width: 1025px)').matches &&
      this.activatedRouterLink
    ) {
      this.router.navigateByUrl(this.activatedRouterLink);
    }
  }

  private async highlight(type: string) {
    const element = this.el.nativeElement.getElementsByClassName('sidenav-entry--dropdown__entry')[0];
    if (type === 'add') {
      await this.promiseTimeOut(300);
      if (this.currentState !== 'remove') {
        element.classList.add('sidenav-entry--dropdown__active');
      }
    } else if (type === 'remove') {
      element.classList.remove('sidenav-entry--dropdown__active');
    } else if (type === 'toggle') {
      element.classList.toggle('sidenav-entry--dropdown__active');
    }
  }

  private promiseTimeOut(ms) {
    return new Promise(resolve => setTimeout(resolve, ms));
  }

}
