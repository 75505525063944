import {BrmCache} from './brm-cache';
import {iif, Observable} from 'rxjs';
import {AppControlService} from '../../services/app-control.service';
import {RestRequestsService} from '../../services/rest-requests.service';
import {filter, map, switchMap, tap} from 'rxjs/operators';
import {CategoryModel} from '../../../modules/inventory/services/models/category.model';
import {ProductFamilyModel} from '../../../modules/inventory/services/models/product-family.model';
import {ProductLineModel} from '../../../modules/inventory/services/models/product-line.model';
import {BrmItem} from '../api/inventory/items/brm-item';
import {BrmInventory} from '../api/inventory/shape/brm-inventory';
import {BrmCategory} from '../api/inventory/shape/brm-category';
import {BrmProductFamily} from '../api/inventory/shape/brm-product-family';
import {BrmProductLine} from '../api/inventory/shape/brm-product-line';
import {HttpEvent, HttpEventType, HttpResponse} from '@angular/common/http';

export class BrmInventoryCache extends BrmCache<BrmInventory> {

  private rest: RestRequestsService;
  private appControl: AppControlService;

  constructor(_caches, _appControl, _rest) {
    super(_caches, 'inventory');

    this.appControl = _appControl;
    this.rest = _rest;
  }

  loadData(): Observable<BrmInventory> {
    return this.rest.getRequest(this.appControl.apiUrl + '/inventory?expand=categories.product_families.product_lines&include_item_count=true', {}).pipe(
      map((value: BrmInventory) => {
        return value;
      })
    );
  }

  protected onLoad(): void {
    this.processCapacity();
  }

  public loadCategory(id: number | string, expandPfs: boolean = false): Observable<BrmCategory> {
    let expand = '';

    if (expandPfs) {
      expand += 'product_families';
    }

    return this.rest.getRequest(`${this.appControl.apiUrl}/categories/${id}`, {
      expand: expand
    }).pipe(

    );
  }

  /*
      CACHE PROCESSING METHODS
      Technical out of data, we've moved the inventory processing to it's own class
      To remove unnecessary code in the cache and prevent pollution of the data
   */

  public processCapacity(onlyCategory?: CategoryModel): void {
    if (this.hasDataLoaded() || onlyCategory != null) {
      const categoryArray = onlyCategory != null ? [onlyCategory] : this.cachedData.categories;

      this.cachedData.total_capacity = 0;

      categoryArray.forEach(
        // Loop each category
        (category: CategoryModel) => {

          let categoryCount = 0;

          // Loop each product family within the category
          category.product_families.forEach(
            (family: ProductFamilyModel) => {

              let familyCount = 0;

              family.product_lines.forEach(
                (line: ProductLineModel) => {
                  // Loop each product line,
                  // Add the capacity to the family capacity count
                  familyCount += line.capacity;
                }
              );

              // Set the family capacity to this value
              family.capacity = familyCount;
              // Add the family capacity to the category capacity
              categoryCount += familyCount;
            }
          );

          // Set the category capacity
          category.capacity = categoryCount;
        }
      );

      // update total capacity
      this.cachedData.categories.forEach((category: CategoryModel) => {
        this.cachedData.total_capacity += category.capacity;
      });
    }
  }

  /*
      CATEGORY METHODS
   */

  public getCategory(id: string): CategoryModel {
    if (this.hasDataLoaded()) {
      return this.getRawData().categories.find((category: CategoryModel) => {
        return category.id === id;
      });
    }

    return null;
  }

  /*
  const data = {
        'language_config': {},
        'image_url': null,
        'internal_only': false,
        'display_order': 0
      };

      data['language_config'][defLang] = {
        'name': categoryNameOrObject
      };
   */
  /**
   * Does an API call to create a new category
   * @param data - Data for the category creation, example above
   * @param placeAtTop - Place the new category at the top of the results?
   */
  public addCategory(data: object, placeAtTop: boolean = false): Observable<CategoryModel> {
    return this.rest.putRequest(this.appControl.apiUrl + '/categories', JSON.stringify(data)).pipe(
      map((value: CategoryModel) => {
        return value;
      }),
      tap({
        next: value => {
          if (this.hasDataLoaded()) {
            // mark the categories cache to be reloaded on next request
            this.caches.categoriesCache.markForReload();

            if (placeAtTop) {
              console.log('Place category at front');
              this.cachedData.categories.unshift(value);
            } else {
              this.cachedData.categories.push(value);
            }

            // Push changes
            this.pushChanges();
          }
        }
      })
    );
  }

  public updateCategory(id: string, data: object, allHttpEvents: boolean = false): Observable<CategoryModel | HttpEvent<BrmCategory>> {
    // We ignore certain fields on updateCategory, the reason for this is when we call an update it doesn't return product_families data
    const ignoreFields = ['product_families', 'product_family_display_order'];

    // When we update a category, we need to do an API call first then update the data locally with the new data
    return this.rest.postRequest(this.appControl.apiUrl + '/categories/' + id, JSON.stringify(data), true, allHttpEvents).pipe(
      map((value: CategoryModel) => {
        return value;
      }),
      tap({
        next: value => {
          // mark the categories cache to be reloaded on next request
          this.caches.categoriesCache.markForReload();

          const cachedCategory = this.getCategory(id);

          if (cachedCategory != null) {
            // loop through all the JSON values then update the cached data, ignoring a couple values
            Object.keys(value).forEach(key => {
              // make sure we don't include the ignore fields
              if (ignoreFields.indexOf(key) === -1) {
                cachedCategory[key] = value[key];
              }
            });
          }
        }
      })
    );
  }

  public deleteCategory(category: CategoryModel): Observable<any> {
    const deleteData = {
      id: category.id
    };

    return this.rest.deleteRequest(this.appControl.apiUrl + '/categories/' + category.id, JSON.stringify(deleteData)).pipe(
      tap({
        next: value => {
          if (this.hasDataLoaded()) {
            let foundIndex = -1;
            const cachedCategory = this.cachedData.categories.find((cModel: CategoryModel, index: number) => {
              const found = cModel.id === category.id;

              if (found) {
                foundIndex = index;
              }

              return found;
            });

            if (cachedCategory != null) {
              // mark the categories cache to be reloaded on next request
              this.caches.categoriesCache.markForReload();

              this.cachedData.categories.splice(foundIndex, 1);

              // Push changes
              this.pushChanges();
            }
          }
        }
      })
    );
  }

  /*
      PRODUCT FAMILY METHODS
   */

  public loadProductFamily(id: number): Observable<ProductFamilyModel> {
    return this.rest.getRequest(`${this.appControl.apiUrl}/productfamilies/${id}`, {}).pipe(

    );
  }

  public getProductFamily(id: number): ProductFamilyModel {
    let foundFamily = null;

    if (this.hasDataLoaded()) {
      const category = this.cachedData.categories.find((categorySearch: CategoryModel) => {

        const family = categorySearch.product_families.find((familySearch: ProductFamilyModel) => {
          return familySearch.id === id;
        });

        if (family) {
          foundFamily = family;

          return true;
        }

        return false;
      });
    }

    return foundFamily;
  }

  public addProductFamily(category: CategoryModel, data: object): Observable<ProductFamilyModel> {
    return this.rest.putRequest(this.appControl.apiUrl + '/productfamilies', JSON.stringify(data)).pipe(
      map((value: ProductFamilyModel) => {
        return value;
      }),
      tap({
        next: value => {
          if (category) {
            category.product_families.push(value);

            // re-process the quantities
            this.processCapacity(category);

            // Reload price groups
            this.caches.priceGroupsCache.markForReload();

            // Push changes
            this.pushChanges();
          }
        }
      })
    );
  }

  private getProductLines(productFamilyId: number): Observable<ProductLineModel[]> {
    return this.rest.getRequest(`${this.appControl.apiUrl}/productlines`, {
      product_family_id: productFamilyId
    });
  }

  public updateProductFamilyProductLines(id: number): Observable<any> {
    return this.getProductLines(id).pipe(
      tap((next: ProductLineModel[]) => {
          const pf = this.getProductFamily(id);

          if (pf) {
            pf.product_lines = next;
          }
        }
      )
    );
  }

  public updateProductFamily(id: number, data: object, allHttpEvents: boolean = false): Observable<ProductFamilyModel | HttpEvent<ProductFamilyModel>> {
    const ignoreFields = ['product_line_display_order', 'product_lines'];

    let pf: ProductFamilyModel;

    if (allHttpEvents) {
      return this.rest.postRequest(`${this.appControl.apiUrl}/productfamilies/${id}`, JSON.stringify(data), true, allHttpEvents).pipe(
        tap(
          (response: HttpEvent<ProductFamilyModel>) => {
            if (response.type === HttpEventType.Response) {
              pf = this.getProductFamily(response.body.id);

              if (pf) {
                Object.assign(pf, response.body);
              }
            }
          }
        )
      );
    }

    return this.rest.postRequest(`${this.appControl.apiUrl}/productfamilies/${id}`, JSON.stringify(data)).pipe(
      switchMap((value: ProductFamilyModel) => {
        pf = this.getProductFamily(value.id);

        if (pf) {
          Object.assign(pf, value);
        }

        return iif(() => pf != null, this.getProductLines(value.id));
      }),
      map((value) => {
        if (value) {
          pf.product_lines = value;
        }

        return pf;
      }),
      tap({
        next: value => {
          // Push changes
          this.pushChanges();
        }
      })
    );
  }


  public deleteProductFamily(family: ProductFamilyModel): Observable<any> {
    const deleteData = {
      id: family.id
    };

    return this.rest.deleteRequest(this.appControl.apiUrl + '/productfamilies/' + family.id, JSON.stringify(deleteData)).pipe(
      tap({
        next: value => {
          if (this.hasDataLoaded()) {
            let productFamily = null;

            const category = this.cachedData.categories.find((categoryModel: CategoryModel) => {
              productFamily = categoryModel.product_families.find((familyModel: ProductFamilyModel) => {
                return family.id === familyModel.id;
              });

              return productFamily != null;
            });

            if (category != null && productFamily != null) {
              category.product_families.splice(category.product_families.indexOf(productFamily), 1);
            }

            // Reload price groups
            this.caches.priceGroupsCache.markForReload();

            // Push changes
            this.pushChanges();
          }
        }
      })
    );
  }

  public moveProductFamilyCategory(family: ProductFamilyModel, oldCategory: CategoryModel, newCategory: CategoryModel): void {
    const posInOldCategory = oldCategory.product_families.indexOf(family);

    if (posInOldCategory >= 0) {
      oldCategory.product_families.splice(posInOldCategory, 1);
    }

    newCategory.product_families.push(family);

    // update new capacities
    this.processCapacity();

    // Reload price groups
    this.caches.priceGroupsCache.markForReload();

    // Push changes
    this.pushChanges();
  }

  /*
      PRODUCT LINE METHODS
   */

  public loadProductLine(id: number, incItemCount: boolean = false): Observable<ProductLineModel> {
    return this.rest.getRequest(`${this.appControl.apiUrl}/productlines/${id}`, {
      include_item_count: incItemCount
    }).pipe(

    );
  }

  public getProductLine(id: number): ProductLineModel {
    let foundCategory = null, foundFamily = null, foundLine = null;

    if (this.hasDataLoaded()) {
      foundCategory = this.cachedData.categories.find((categorySearch: CategoryModel) => {

        foundFamily = categorySearch.product_families.find((familySearch: ProductFamilyModel) => {

          foundLine = familySearch.product_lines.find((lineSearch: ProductLineModel) => {
            return lineSearch.id === id;
          });
          return foundLine != null;
        });

        return foundFamily;
      });
    }

    return foundLine;
  }

  public addProductLine(family: ProductFamilyModel, data: object): Observable<ProductLineModel> {
    return this.rest.putRequest(this.appControl.apiUrl + '/productlines', JSON.stringify(data)).pipe(
      map((value: ProductLineModel) => {
        return value;
      }),
      tap({
        next: value => {
          if (family != null) {
            family.product_lines.push(value);
          }

          // Push changes
          this.pushChanges();
        }
      })
    );
  }

  public updateProductLine(id: number, data: object): Observable<ProductLineModel> {
    return this.rest.postRequest(this.appControl.apiUrl + '/productlines/' + id, JSON.stringify(data)).pipe(
      map((value: ProductLineModel) => {
        return value;
      }),
      tap({
        next: value => {
          const cachedProductLine = this.getProductLine(id);

          if (cachedProductLine != null) {
            Object.assign(cachedProductLine, value);
          }

          // Push changes
          this.pushChanges();
        }
      })
    );
  }

  public updateProductLineShortcode(id: number, newShortcode: string): Observable<ProductLineModel> {
    return this.rest.postRequest(`${this.appControl.apiUrl}/productlines/${id}/shortcode`, JSON.stringify({short_code: newShortcode})).pipe(
      map((value: ProductLineModel) => {
        return value;
      }),
      tap({
        next: value => {
          const cachedProductLine = this.getProductLine(id);

          if (cachedProductLine != null) {
            Object.assign(cachedProductLine, value);
          }

          // Push changes
          this.pushChanges();
        }
      })
    );
  }


  public moveItem(id: number, productLineId: number): Observable<BrmItem> {

    return this.rest.postRequest(this.appControl.apiUrl + '/items/' + id + '/move', JSON.stringify({product_line_id: productLineId})).pipe(
      map((value: BrmItem) => {
        return value;
      })
    );
  }

  public moveProductLine(id: number, productFamilyId: number): Observable<ProductLineModel> {

    return this.rest.postRequest(`${this.appControl.apiUrl}/productlines/${id}/move`, JSON.stringify({product_family_id: productFamilyId})).pipe(
      map((value: ProductLineModel) => {
        return value;
      }),
      tap({
        next: value => {
          if (this.hasDataLoaded()) {
            const cachedProductLine = this.getProductLine(id);
            const sourceFamily = this.getProductFamily(cachedProductLine.product_family_id);
            const destinationFamily = this.getProductFamily(productFamilyId);

            if (sourceFamily != null && destinationFamily != null) {
              const indexInSource = sourceFamily.product_lines.indexOf(cachedProductLine);

              destinationFamily.product_lines.push(cachedProductLine);
              if (indexInSource >= 0) {
                sourceFamily.product_lines.splice(indexInSource, 1);
              }
            }

            // Push changes
            this.pushChanges();
          }
        }
      })
    );
  }

  public archiveProductLine(line: ProductLineModel): Observable<any> {
    const archiveData = {
      id: line.id
    };

    return this.rest.postRequest(`${this.appControl.apiUrl}/productlines/${line.id}/archive`, JSON.stringify(archiveData)).pipe(
      tap({
        next: value => {
          if (this.hasDataLoaded()) {
            let foundCategory = null, foundFamily = null, foundLine = null;

            foundCategory = this.cachedData.categories.find((categoryModel: CategoryModel) => {
              foundFamily = categoryModel.product_families.find((familyModel: ProductFamilyModel) => {
                foundLine = familyModel.product_lines.find((lineModel: ProductLineModel) => {
                  return line.id === lineModel.id;
                });

                return foundLine != null;
              });

              return foundFamily != null;
            });

            if (foundFamily != null) {
              foundFamily.product_lines = foundFamily.product_lines.filter((val: ProductLineModel) => {
                return val !== line;
              });

              // Push changes
              this.pushChanges();
            }
          }
        }
      })
    );
  }

  public deleteProductLine(line: ProductLineModel): Observable<any> {
    const deleteData = {
      id: line.id
    };

    return this.rest.deleteRequest(this.appControl.apiUrl + '/productlines/' + line.id, JSON.stringify(deleteData)).pipe(
      tap({
        next: value => {
          if (this.hasDataLoaded()) {
            let foundCategory = null, foundFamily = null, foundLine = null;

            foundCategory = this.cachedData.categories.find((categoryModel: CategoryModel) => {
              foundFamily = categoryModel.product_families.find((familyModel: ProductFamilyModel) => {
                foundLine = familyModel.product_lines.find((lineModel: ProductLineModel) => {
                  return line.id === lineModel.id;
                });

                return foundLine != null;
              });

              return foundFamily != null;
            });

            if (foundFamily != null) {
              foundFamily.product_lines = foundFamily.product_lines.filter((val: ProductLineModel) => {
                return val !== line;
              });

              // Push changes
              this.pushChanges();
            }
          }
        }
      })
    );
  }

  public directLoadCategory(categoryId: string | number): Observable<BrmCategory> {
    return this.rest.getRequest(`${this.appControl.apiUrl}/categories/${categoryId}`, {}).pipe(
      map((value: BrmCategory) => {
        return value;
      })
    );
  }

  public directLoadProductFamily(productFamilyId: number): Observable<BrmProductFamily> {
    return this.rest.getRequest(`${this.appControl.apiUrl}/productfamilies/${productFamilyId}`, {}).pipe(
      map((value: BrmProductFamily) => {
        return value;
      })
    );
  }

  public directLoadProductLine(productLineId: number): Observable<BrmProductLine> {
    return this.rest.getRequest(`${this.appControl.apiUrl}/productlines/${productLineId}`, {}).pipe(
      map((value: BrmProductLine) => {
        return value;
      })
    );
  }

  public determineTaxonomy(shop: string, data: object, allHttpEvents: boolean = false): Observable<object | HttpEvent<BrmCategory>> {
    // When we determine tags, we need to do an API call which will give result of related tags of that category.
    return this.rest.postRequest(this.appControl.apiUrl  + '/integrations/bikedotrent/recalculateTaxonomy' , JSON.stringify(data), true, allHttpEvents)
    .pipe(
      map((value: object) => {
        return value;
      }),
      tap({
        next: value => {
          this.caches.categoriesCache.markForReload();
        }
      })
    );
  }
}
