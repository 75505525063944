import {ChangeDetectorRef, OnDestroy, Pipe, PipeTransform} from '@angular/core';
import {BrmLanguagesService} from '../brm-languages.service';
import {Subject} from 'rxjs';
import {takeUntil} from 'rxjs/operators';
@Pipe({
  name: 'brmDynamicLanguage',
  pure: false
})
export class BrmDynamicLanguagePipe implements PipeTransform, OnDestroy {
  private destroyed$: Subject<boolean> = new Subject<boolean>();
  private lastLanguage: string;
  private lastValue: string;
  constructor(private brmLanguage: BrmLanguagesService, private _ref: ChangeDetectorRef) {
    this.initialiseLanguageChange();
  }
  private initialiseLanguageChange(): void {
    this.brmLanguage.language$.pipe(
      takeUntil(this.destroyed$)
    ).subscribe(
      (next: string) => {
        this._ref.markForCheck();
      }
    );
  }
  ngOnDestroy(): void {
    this.destroyed$.next(true);
    this.destroyed$.complete();
  }

  // Specific to RLE, will only be called once
  public transformDynamicLanguage(value: any, fieldName?: string, args?: any[]) {
    const langCode = this.brmLanguage.activeLanguageCode;
    fieldName = fieldName || '';
    this.lastLanguage = langCode;
    return this.formatDynamicLanguage(value, fieldName, args, langCode);
  }

  transform(value: any, fieldName?: string, args?: any[]): any {
    const langCode = this.brmLanguage.activeLanguageCode;
    fieldName = fieldName || '';
    if (langCode === this.lastLanguage || value === null) {
      return this.lastValue || '';
    }
    this.lastLanguage = langCode;
    return this.formatDynamicLanguage(value, fieldName, args, langCode);
  }

  private formatDynamicLanguage(value: string, fieldName?: string, args?: any[], langCode?: string): string {
    if (value.hasOwnProperty('language_config')) {
      if (
        value['language_config'].hasOwnProperty(langCode)
        && value['language_config'][langCode].hasOwnProperty(fieldName)
        && this.isNonEmptyString(value['language_config'][langCode][fieldName])
      ) {
        return this.setValue(value['language_config'][langCode][fieldName]);
      }
      if (
        value['language_config'].hasOwnProperty('en')
        && this.isNonEmptyString(value['language_config']['en'][fieldName])
      ) {
        return this.setValue(value['language_config']['en'][fieldName]);
      }
    }
    if (value.hasOwnProperty(fieldName)) {
      return this.setValue(value[fieldName]);
    }
    return '';
  }
  private setValue(val: string): string {
    this.lastValue = val;
    return val;
  }
  private isNonEmptyString(val: string): boolean {
    if (val == null) {
      return false;
    } else if (val.trim() === '') {
      return false;
    }
    return true;
  }
}