import {Injectable} from '@angular/core';
import {CanActivate, CanActivateChild, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree} from '@angular/router';
import {Observable} from 'rxjs';
import {BrmPrivilegesService} from '../brm-privileges.service';

@Injectable({
  providedIn: 'root'
})
export class BrmOpsGuard implements CanActivate, CanActivateChild {

  constructor(private brmPrivileges: BrmPrivilegesService) {
  }

  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    return this.brmPrivileges.isOperationsUser();
  }

  canActivateChild(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    return this.brmPrivileges.isOperationsUser();
  }

}
