import {BrmProductFamily} from '../../../../core/brm2/api/inventory/shape/brm-product-family';
import {BrmProductLine} from '../../../../core/brm2/api/inventory/shape/brm-product-line';
import {ShapeLine} from './shape-line';

export class ShapeFamily {

  public lines: ShapeLine[];
  public product_line_display_order: number[];

  expanded: 'show' | 'hide' = 'hide';
  total_capacity: number = 0;
  total_assets: number = 0;

  constructor(public data: BrmProductFamily) {
    this.product_line_display_order = data.product_line_display_order;
    this.processFamily();
  }

  public processFamily(): void {
    if (this.data != null) {
      this.lines = this.data.product_lines.map((line: BrmProductLine) => {
        return new ShapeLine(line);
      });
    } else {
      this.lines = [];
    }
  }

  public updateCapacityAndAssets(): void {
    this.total_capacity = 0;
    this.total_assets = 0;

    this.lines.forEach((line: ShapeLine) => {
      line.updateCapacityAndAssets();
      this.total_capacity += line.data.capacity;

      // TODO: Need a way to get item count on an object
      this.total_assets += line.total_assets;
    });
  }

  public recalculateFamily(): void {
    // Get all categories which exist in the inventory cache
    const linesToDelete = this.lines.filter((line: ShapeLine) => {
      return !this.doesLineExistInRawData(line.data.id);
    });

    let i = this.lines.length - 1;

    while (i >= 0) {
      if (linesToDelete.indexOf(this.lines[i]) >= 0) {
        this.lines.splice(i, 1);
      } else {
        this.lines[i].recalculateLine();
      }

      i--;
    }

    // Find the missing categories in our data structure, we do this after the above, as we won't need to recalculate these values
    const missingLines = this.data.product_lines.filter((line: BrmProductLine) => {
      return !this.doesLineExist(line.id);
    });

    // For each missing category, that's in the cache but not in the Shape format, we create ShapeCategory
    missingLines.forEach((line: BrmProductLine) => {
      this.lines.push(new ShapeLine(line));
    });

    this.updateCapacityAndAssets();
  }

  public doesLineExist(id: number): boolean {
    return this.lines.find((data: ShapeLine) => {
      return data.data.id === id;
    }) != null;
  }

  private doesLineExistInRawData(id: number): boolean {
    return this.data.product_lines.find((line: BrmProductLine) => {
      return line.id === id;
    }) != null;
  }

  public toggleDropdown(): void {
    this.expanded = this.expanded === 'hide' ? 'show' : 'hide';
  }

  public expandDropdown(): void {
    this.expanded = 'show';
  }

  public hideDropdown(): void {
    this.expanded = 'hide';
  }
}
