import {Injectable, OnDestroy} from '@angular/core';
import {CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, Router} from '@angular/router';
import {Observable, of, Subject} from 'rxjs';
import {BrmPrivilegesService} from '../services/privileges/brm-privileges.service';
import {catchError, map, takeUntil, tap} from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class BrmBasicUserGuard implements CanActivate, OnDestroy {

  private destroyed$: Subject<boolean> = new Subject<boolean>();

  /**
   * Prevents the user from going to different routes outside of reservations
   * @param brmPrivileges
   * @param router
   */
  constructor(private brmPrivileges: BrmPrivilegesService, private router: Router) {

  }

  ngOnDestroy(): void {
    this.destroyed$.next(true);
    this.destroyed$.complete();
  }

  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {

    return this.brmPrivileges.role$.pipe(
      takeUntil(this.destroyed$),
      catchError((err, caught) => {
        // if we catch an error, then return the user as a basic user to prevent issues
        return of('basic');
      }),
      map((value: string) => {

        return value !== 'basic';
      }),
      tap((value: boolean) => {

        if (!value) {
          this.router.navigate(['/', 'reservations']);
        }

        return true;
      })
    );
  }
}
