import { Component, isDevMode, OnDestroy, OnInit } from '@angular/core';
import { combineLatest, forkJoin, Observable, of, Subscription, zip } from 'rxjs';
import { Router } from '@angular/router';
import { AppControlService } from '../../core/services/app-control.service';
import { FirebaseAuthService } from '../../core/services/firebase-auth.service';
import { catchError, switchMap, tap } from 'rxjs/operators';
import { RestRequestsService } from '../../core/services/rest-requests.service';
import { environment } from '../../../environments/environment';
import { HttpErrorResponse } from '@angular/common/http';
import { BrmSettingsService } from '../../core/services/brm/brm-settings.service';
import { InventoryService } from '../inventory/services/inventory.service';
import { GettingStartedService } from '../getting-started/getting-started.service';
import { NotificationsService } from '../../core/services/notifications/notifications.service';
import { Brm400Error } from '../../core/services/brm/responses/brm-400-error';
import { BrmPrivilegesService } from '../../core/brm2/services/privileges/brm-privileges.service';
import { BrmCacheService } from '../../core/brm2/brm-cache.service';
import { Title } from '@angular/platform-browser';
import { BrmTooltipsService } from '../../core/brm2/services/tooltips/brm-tooltips.service';
import { BrmSessionResponse } from '../../core/services/brm/responses/brm-session-response.model';
import { BrmFirebaseCustomersService } from '../../core/brm2/services/firebase/brm-firebase-customers.service';
import { GeneralUtil } from '../../core/util';
import { BrmSessionService } from '../../core/brm2/brm-session.service';
import { MatDialog } from '@angular/material/dialog';
import { InitialSettingsDialogComponent } from '../shared/initial-settings-dialog/initial-settings-dialog.component';
import { BrmUserSettingsService } from '../../core/brm2/services/user-settings/brm-user-settings.service';

declare var Beamer: any;
declare var beamer_config: any;
declare var FS: any;

@Component({
  selector: 'app-splash',
  templateUrl: './splash.component.html',
  styleUrls: ['./splash.component.scss']
})
export class SplashComponent implements OnInit, OnDestroy {

  public errorMessage: string;

  public loading: boolean = true;

  private warningTimeout;

  private loginStateSub: Subscription;

  /**
   * SplashComponent is the page which loads before any other page is accessed, it handles any additional loading
   * required by the application such as the configuration file
   */
  constructor(private router: Router, private control: AppControlService,
    public fbAuth: FirebaseAuthService, private rest: RestRequestsService,
    private brm: BrmSettingsService, private appControl: AppControlService,
    private gettingStarted: GettingStartedService,
    private notify: NotificationsService, private brmPrivileges: BrmPrivilegesService,
    private cache: BrmCacheService, private titleService: Title,
    private tooltips: BrmTooltipsService, private privileges: BrmPrivilegesService,
    private firebaseCustomers: BrmFirebaseCustomersService,
    private matDialog: MatDialog,
    private brmSession: BrmSessionService,
    private userSettings: BrmUserSettingsService) {
  }

  ngOnInit() {
    // Clear all the caches on load, this is due to shop switch redirecting to login rather than
    // reloading the app. Therefore the best option is to always clear the cache here
    this.cache.clearAllCaches();

    this.warningTimeout = setTimeout(() => {
      this.notify.addWarningNotification('Please try a refresh, or continue to wait');
    }, 1000 * 25);

    this.loginHandler().then(logged => {

    }).catch(error => {

    });
  }

  ngOnDestroy() {
    clearTimeout(this.warningTimeout);

    if (this.loginStateSub != null && !this.loginStateSub.closed) {
      this.loginStateSub.unsubscribe();
    }
  }

  private async loginHandler() {
    let userToken = null;
    let error: string;

    if (this.control.forceLogin) {

      await this.fbAuth.logout();
      await this.router.navigate(['/', 'login']);

    } else {

      // See if we've logged in via
      if (this.fbAuth.getUser() != null) {
        try {
          userToken = await this.fbAuth.getUserToken();
        } catch (error) {
          error = 'fire001';
        }
      }

      // If we've failed to get a user token, but we have a firebasetoken we'll try login with the token
      if (userToken == null && this.brm.firebaseToken != null) {
        try {
          await this.fbAuth.loginWithToken(this.brm.firebaseToken);
        } catch (error) {
          error = 'fire004';
        }
      }

      if (this.fbAuth.isLoggedIn()) {
        this.handleVerification();
      } else if (error) {
        await this.router.navigate(['/', 'login', 'issue'], {
          queryParams: {
            code: error
          }
        });
      } else {
        await this.router.navigate(['/', 'login']);
      }

    }
  }

  private handleVerification(): void {
    if (this.fbAuth.getUser() != null && this.fbAuth.getUser().emailVerified) {
      this.doStartup().then(value => {

      }).catch(err => {

      });
    } else {
      this.router.navigate(['/', 'login', 'verify']).then();
    }
  }

  private async doStartup() {
    try {
      const userSession = await this.checkUserSession().toPromise();

      if (userSession != null) {
        const tooltipData = await this.tooltips.loadTooltips().toPromise();
        const privileges = await this.privileges.loadPrivilegesData().toPromise();
        const setupData = await this.gettingStarted.loadSetupData().toPromise();

        this.onStartupComplete();
      } else {
        // await this.router.navigate(['/', 'login', 'issue'], {
        //   queryParams: {
        //     code: 'startup001'
        //   }
        // })
      }
    } catch (error) {
    }
  }

  private async onStartupComplete() {
    this.loading = false;

    // We now check if the user should go to BRM 2 or BRM 1 within the BRM 2 load up sequence
    // Therefore we need to do various checks on the user's session to make sure they go to the
    // right place
    if (this.shouldAppRedirect() && this.appControl.redirectCount <= 3) {
      // if (this.appControl.redirectCount <= 3) {
      // Call function which redirects out of an iframe, if they're in one
      GeneralUtil.redirectOutOfIframe(`${environment.brm1RedirectUrl}?redirectCount=${this.appControl.redirectCount + 1}`);
    } else if (this.shouldAppRedirect() && this.appControl.redirectCount > 3) {
      // } else if (this.appControl.redirectCount > 3) {
      // If we've been redirected too often, then we catch this and let the user know
      this.router.navigate(['/', 'login', 'issue'], {
        queryParams: {
          code: 'redirectLoop'
        }
      })
    } else {

      // initialise beamer
      this.initBeamer(this.brm.session.userid, this.brm.session.email, this.brm.session.beamerFilter, null);

      this.control.initialLoad = false;


      // Setup Fullstory if it is undefined
      if (!isDevMode() && !this.brm.isOperationsUser()) {
        if (typeof FS === 'undefined') {
          this.setupFullstory();
        } else {
          // Restart Fullstory
          FS.restart();
        }
      }

      this.appControl.setupGoogleAnalytics();

      this.router.navigate([this.control.redirectUrl], { queryParams: this.control.redirectUrlParams }).then(
        (success: boolean) => {
          console.log('Redirected to: ', this.control.redirectUrl);

          this.initialSettingsDialog();
        }
      );
    }
  }

  private checkUserSession(): Observable<BrmSessionResponse> {
    return this.rest.getRequest(this.appControl.sessionApiUrl + 'session', {}).pipe(
      catchError((error, caught) => {
        console.log('Error: ', error);
        if (error != null) {
          // Error message == null means that the interceptor already handled the error
          if (error instanceof HttpErrorResponse) {
            if (error.error != null && error.error.hasOwnProperty('code')) {
              if (error.error['code'] === 'api002') {
                this.errorMessage = 'No mapping';
                this.router.navigate(['/', 'disabled']).then();
              } else {
                this.errorMessage = '(' + error.error['code'] + ') ' + error.error['message'];
              }
            } else {
              this.errorMessage = error.message;
            }
          } else {
            if (!(error instanceof Brm400Error)) {
              // BRM 400 errors are already handled by the interceptor
              this.errorMessage = 'An error occurred';
            }
          }
        }

        return of(null);
      }),
      tap({
        next: (response: BrmSessionResponse) => {
          if (response != null) {
            this.brm.setSession(response);
            this.firebaseCustomers.startListeningForCustomerRegistration();

            this.titleService.setTitle('BRM-' + this.brm.session.dataCentre.toUpperCase());
          }
        },
        error: err => {
          console.log('Error loading session');
        }
      })
    );
  }

  /**
   * Called when all initial load tasks are completed
   */
  private onInitialTasksComplete(): void {
    this.loading = false;

    // Initial task userStateLoaded, therefore if we are on this page again we know it's no longer initial load and not to reload
    // anything

    // Sets the splash pop up based on the number of login by the user
    if (this.brm.session != null && this.brm.session.namespace != null) {

      // We now check if the user should go to BRM 2 or BRM 1 within the BRM 2 load up sequence
      // Therefore we need to do various checks on the user's session to make sure they go to the
      // right place
      if (this.shouldAppRedirect() && this.appControl.redirectCount <= 3) {
        // if (this.appControl.redirectCount <= 3) {
        // Call function which redirects out of an iframe, if they're in one
        GeneralUtil.redirectOutOfIframe(`${environment.brm1RedirectUrl}?redirectCount=${this.appControl.redirectCount + 1}`);
      } else if (this.shouldAppRedirect() && this.appControl.redirectCount > 3) {
        // } else if (this.appControl.redirectCount > 3) {
        // If we've been redirected too often, then we catch this and let the user know
        this.router.navigate(['/', 'login', 'issue'], {
          queryParams: {
            code: 'redirectLoop'
          }
        }).then();
      } else {

        // initialise beamer
        this.initBeamer(this.brm.session.userid, this.brm.session.email, this.brm.session.beamerFilter, null);

        this.control.initialLoad = false;

        // Setup Fullstory if it is undefined
        if (!isDevMode() && !this.brm.isOperationsUser()) {
          if (typeof FS === 'undefined') {
            this.setupFullstory();
          } else {
            // Restart Fullstory
            FS.restart();
          }
        }

        this.appControl.setupGoogleAnalytics();

        this.router.navigate([this.control.redirectUrl], { queryParams: this.control.redirectUrlParams }).then(
          (success: boolean) => {
            console.log('Redirected to: ', this.control.redirectUrl);

            this.initialSettingsDialog();
          }
        );
      }
    } else {
      this.fbAuth.logout().then(
        fullfilled => {
          this.router.navigate(['/', 'login']);
        }
      );
    }
  }

  private initialSettingsDialog(): void {
    const confirmedSettings = this.userSettings.getAppSetting('confirmed_initial');
    let confirmed = false;

    if (confirmedSettings != null && confirmedSettings !== '') {
      confirmed = true;
    }

    if (!confirmed && this.brmSession.session.user.login_count <= 4 && this.brmPrivileges.isAdminUser() && !this.brmPrivileges.isOperationsUser()) {
      this.matDialog.open(InitialSettingsDialogComponent, GeneralUtil.mediumDialogConfigPopup(false));
    }
  }

  private shouldAppRedirect(): boolean {
    if (!this.appControl.hideNavigationSetting) {
      // If the app has not been instructed to hide the navigation (indicating we're in an iframe in BRM 1)
      // then we most likely need to redirect them to BRM 1
      return true;
    }

    return false;
  }

  public logout(): void {
    this.fbAuth.logout().then(
      fullfilled => {
        location.reload();
      }
    );
  }

  private initBeamer(appUserId, email, filter, language) {
    if (!this.isPageInIframe()) {
      beamer_config.user_id = email;
      beamer_config.user_email = email;
      beamer_config.filter = filter;
      // beamer_config.language = language;

      Beamer.init();
    }
  }

  /**
   * Setup the fullstory when the user logs in
   */
  private setupFullstory(): void {
    if (this.brm.session.session.account.session_recording) {
      // To try and make it a bit easier to edit, the fullstory vars are stored in the environment
      window['_fs_debug'] = environment.fullStory.debug;
      window['_fs_host'] = environment.fullStory.host;
      window['_fs_org'] = environment.fullStory.org;
      window['_fs_namespace'] = environment.fullStory.namespace;

      (function (m, n, e, t, l, o, g, y) {
        if (e in m) {
          if (m.console && m.console.log) {
            m.console.log('FullStory namespace conflict. Please set window["_fs_namespace"].');
          }
          return;
        }
        // @ts-ignore
        g = m[e] = function (a, b, s) {
          g.q ? g.q.push([a, b, s]) : g._api(a, b, s);
        };
        g.q = [];
        o = n.createElement(t);
        o.async = 1;
        o.crossOrigin = 'anonymous';
        o.src = 'https://' + window['_fs_host'] + '/s/fs.js';
        y = n.getElementsByTagName(t)[0];
        y.parentNode.insertBefore(o, y);
        g.identify = function (i, v, s) {
          g(l, { uid: i }, s);
          if (v) {
            g(l, v, s);
          }
        };
        g.setUserVars = function (v, s) {
          g(l, v, s);
        };
        g.event = function (i, v, s) {
          g('event', { n: i, p: v }, s);
        };
        g.shutdown = function () {
          g('rec', !1);
        };
        g.restart = function () {
          g('rec', !0);
        };
        g.consent = function (a) {
          g('consent', !arguments.length || a);
        };
        g.identifyAccount = function (i, v) {
          o = 'account';
          v = v || {};
          v.acctId = i;
          g(o, v);
        };
        g.clearUserCookie = function () {
        };
      })(window, document, window['_fs_namespace'], 'script', 'user');

      FS.identify(this.brm.session.email);
      FS.setUserVars({
        'email': this.brm.session.email,
        'shop_str': this.brm.session.namespace,
        'dc_str': this.brm.session.dataCentre
      });
    }

    if (typeof FS !== 'undefined' && (isDevMode() || this.brm.isOperationsUser())) {
      FS.shutdown();
    }
  }

  /**
   * Tries to detect if the app is hosted within an iFrame, if so, it doesn't activate beamer
   */
  private isPageInIframe(): boolean {
    try {
      return window.self !== window.top;
    } catch (error) {
      return true;
    }
  }

}
