import {
  Component, ElementRef,
  EventEmitter,
  HostListener,
  Input,
  isDevMode,
  OnChanges, OnDestroy,
  OnInit,
  Output,
  Renderer2,
  SimpleChanges,
  ViewChild
} from '@angular/core';
import {DomSanitizer} from '@angular/platform-browser';
import {UntypedFormControl, UntypedFormGroup, Validators} from '@angular/forms';
import {LazyLoadingService} from '../../../core/services/lazy-loading.service';
import {GeneralUtil} from '../../../core/util';
import {MediaLibraryComponent} from '../media-libary/media-library.component';
import {MediaLibraryData} from '../media-libary/media-library-data';
import {HttpClient} from '@angular/common/http';
import {MatDialog, MatDialogConfig} from '@angular/material/dialog';
import {catchError, switchMap, takeUntil} from 'rxjs/operators';
import {Observable, of, Subject} from 'rxjs';

declare var Cropper: any;

enum EDITOR_STATES {
  Lazy,
  Default,
  Editing,
  Uploading,
  UploadingUrl,
  UploadingUrlError,
  Processing,
  Loading,
  Source,
  Error
}


@Component({
  selector: 'app-image-editor-scaler',
  templateUrl: './image-editor-scaler.component.html',
  styleUrls: ['./image-editor-scaler.component.scss']
})
export class ImageEditorScalerComponent implements OnInit, OnChanges, OnDestroy {

  // Give the template access to the enum
  public EDITOR_STATES = EDITOR_STATES;

  @ViewChild('cropper') cropper;
  @ViewChild('cropperFileUpload') cropperFileUpload;
  @ViewChild('cropperDirectUpload') cropperDirectUpload;
  @ViewChild('zoomField') zoomField;
  @ViewChild('parentWrap') parentWrap;
  @ViewChild('editorDiv') editorDiv: ElementRef;
  @ViewChild('sourceUrl') sourceUrl;

  @Input() imageData: string;
  @Input() maxHeight: number;
  @Input() disabled = false;
  @Input() library: boolean = false;
  @Input() outputWidth = 300;
  @Input() outputHeight = 200;
  @Input() directImageUpload: boolean = false;
  @Input() experimentalAutoCropChanges: boolean = false;

  @Input() showActualImageDimensions = false;
  // @Input() aspectRatio = 16 / 9;

  @Output() data: EventEmitter<string>;

  private cropperInst;

  public imageZoomPercentage: number;
  public imageZoom: number;
  public imageCropSize: object;
  public imageSize: object;

  public scaleForm: UntypedFormGroup;

  public editorState = EDITOR_STATES.Lazy;

  public imageUrlUploadForm: UntypedFormGroup;
  public zoomForm: UntypedFormGroup;
  public sourceForm: UntypedFormGroup;

  private initial: boolean;

  public limitedSpace: boolean;

  private dataChange: boolean;

  public error: string;
  public errorMessage: string;

  private worker: Worker;

  public processing: boolean = false;

  private destroyed$: Subject<boolean> = new Subject<boolean>();

  public uploadByUrlDirectUpload: boolean = false;

  constructor(private _sanitiser: DomSanitizer, private lazyLoader: LazyLoadingService,
              private dialog: MatDialog, private http: HttpClient, private renderer: Renderer2) {
    this.dataChange = false;

    this.data = new EventEmitter<string>();

    this.imageCropSize = {width: 0, height: 0};
    this.imageSize = {width: 0, height: 0};

    this.initial = true;

    this.scaleForm = new UntypedFormGroup({
      scale: new UntypedFormControl(1),
      height: new UntypedFormControl(null),
      width: new UntypedFormControl(null)
    });

    this.imageUrlUploadForm = new UntypedFormGroup({
      url: new UntypedFormControl('', Validators.required)
    });

    this.zoomForm = new UntypedFormGroup({
      zoom: new UntypedFormControl(100),
    });

    this.sourceForm = new UntypedFormGroup({
      source: new UntypedFormControl('')
    });

    this.limitedSpace = false;

    this.error = '';


  }

  ngOnInit() {
    if (this.editorState === EDITOR_STATES.Lazy) {
      this.lazyLoadScript();
    } else {
      this.onLazyLoadComplete();
    }

    // this.setupCropper();
  }

  ngOnDestroy(): void {
    this.destroyed$.next(true);
    this.destroyed$.complete();

    if (this.worker != null) {
      this.worker.terminate();
    }
  }

  ngOnChanges(changes: SimpleChanges): void {
    this.setSource();

    if (changes.hasOwnProperty('imageData')) {
      this.dataChange = true;
    }
  }

  private lazyLoadScript(): void {
    const libLocation = 'cropperjs.js';

    this.lazyLoader.loadExternalScript(libLocation).then(
      fullfilled => {
        this.lazyLoadStyle();
      }
    ).catch(
      rejected => {
        console.log('Error loading script');
        this.editorState = EDITOR_STATES.Error;
      }
    );
  }

  private lazyLoadStyle(): void {
    let styleLocation = 'dynamic_cropper-style.css';

    if (isDevMode()) {
      // TODO: Due to ng serve turning scss/css to js files, we need to do this work around for now
      styleLocation = 'assets/css/dynamic/cropper-style.css';
    }

    this.lazyLoader.loadExternalStyles(styleLocation).then(
      fullfilled => {
        this.onLazyLoadComplete();
      }
    ).catch(
      rejected => {
        this.editorState = EDITOR_STATES.Error;
      }
    );
  }

  private onLazyLoadComplete(): void {
    // An img tag is loaded in the background, it most likely will load before the script initialises
    // therefore, we need to check here if we've already set to error
    this.editorState = this.editorState === EDITOR_STATES.Error ? EDITOR_STATES.Error : EDITOR_STATES.Default;

    if (this.parentWrap) {
      this.checkForLimitedSpace();
    }

    this.setSource();

    // Initialise the cropper
    this.setupCropper();
  }

  private setSource(): void {
    if (this.imageData != null && (this.imageData.indexOf('http') >= 0 || this.imageData.indexOf('https') || this.imageData.indexOf('wwww'))) {
      this.sourceForm.controls['source'].setValue(this.imageData);
    } else {
      this.sourceForm.controls['source'].setValue(null);
    }
  }

  public copySourceUrl(): void {
    if (this.sourceUrl != null) {
      this.sourceUrl.nativeElement.select();
      document.execCommand('copy');
    }
  }

  @HostListener('window:resize', ['$event'])
  checkForLimitedSpace(): void {
    const windowWidth = window.innerWidth || document.documentElement.clientWidth || document.body.clientWidth;

    let parentWidth = this.parentWrap.nativeElement.offsetWidth != null ? this.parentWrap.nativeElement.offsetWidth : null;

    if (parentWidth == null) {
      parentWidth = this.parentWrap.nativeElement.clietnWidth != null ? this.parentWrap.nativeEleent.clientWidth : null;
    }

    if (parentWidth == null) {
      this.limitedSpace = false;
    } else {
      if (windowWidth > 576 && parentWidth < 400) {
        this.limitedSpace = true;
      } else {
        this.limitedSpace = false;
      }
    }
  }

  private calculateAutoCropPercentage(): number {
    try {
      const widthAvailable = Math.ceil(this.parentWrap.nativeElement.getBoundingClientRect().width);
      const heightAvailable = 150; // Math.ceil(this.parentWrap.nativeElement.getBoundingClientRect().height);
      const outputPercentageWidth = (this.outputWidth) / widthAvailable;
      const outputPercentageHeight = (this.outputHeight) / heightAvailable;

      const outputPercentage = outputPercentageWidth > outputPercentageHeight ? outputPercentageWidth : outputPercentageHeight;

      if (outputPercentage < 1 && outputPercentage > 0.01) {
        return Math.ceil(outputPercentage * 100000) / 100000;
      }

    } catch (error) {
      console.log(error);
      return 0.8;
    }
  }

  setupCropper(): void {
    if (this.cropper != null && this.cropperInst == null) {
      const _this = this;

      this.cropperInst = new Cropper(this.cropper.nativeElement, {
        responsive: true,
        toggleDragModeOnDblclick: false,
        modal: false,
        initialAspectRatio: this.outputWidth / this.outputHeight,
        aspectRatio: this.outputWidth / this.outputHeight,
        cropBoxResizable: false,
        cropBoxMovable: false,
        autoCrop: true,
        autoCropArea: this.experimentalAutoCropChanges ? this.calculateAutoCropPercentage() : 0.8,
        dragMode: 'move',
        viewMode: 0,
        crop(event) {
          _this.onScaleChange('scale');
        },
        zoom(event) {
          _this.imageZoom = event.detail.ratio;
          _this.calculateZoomPercentage();
        }
      });

      this.cropper.nativeElement.addEventListener('ready', () => {
        this.imageZoom = this.cropperInst.getImageData()['width'] / this.cropperInst['imageData']['naturalWidth'];
        this.imageSize['width'] = this.cropperInst['imageData']['naturalWidth'];
        this.imageSize['height'] = this.cropperInst['imageData']['naturalHeight'];

        this.onScaleChange('scale');

        this.scaleToFit();

        this.calculateZoomPercentage();

        if (this.editorState === EDITOR_STATES.Loading) {
          this.editorState = EDITOR_STATES.Editing;
        }
      });
    }
  }

  private scaleToFit(): void {
    const widthRatio = (this.cropperInst.getCropBoxData()['width'] / this.imageSize['width'] * 10000) / 100;
    const heightRatio = (this.cropperInst.getCropBoxData()['height'] / this.imageSize['height'] * 10000) / 100;

    console.log(widthRatio, heightRatio, 'ratios');

    this.setZoom(widthRatio > heightRatio ? widthRatio : heightRatio);
  }

  public calculateZoomPercentage(): void {
    this.imageZoomPercentage = (this.imageZoom * 10000) / 100;
    this.zoomForm.controls['zoom'].setValue(this.imageZoomPercentage);
  }

  public getImage() {
    if (this.imageData == null) {
      return '';
    }

    return this._sanitiser.bypassSecurityTrustStyle('url(' + this.imageData + ')');
  }

  public rotateClockwise(): void {
    if (this.cropperInst != null) {
      this.cropperInst.rotate(90);
    }
  }

  public rotateAntiClockwise(): void {
    if (this.cropperInst != null) {
      this.cropperInst.rotate(-90);
    }
  }

  public instantlyEmitData(imageData): void {
    this.editorState = EDITOR_STATES.Default;

    this.imageData = imageData;

    this.data.emit(this.imageData);
  }

  public finishEditingImage(): void {
    this.processing = true;

    // @ts-ignore
    /*if (typeof (Worker) !== 'undefined' && typeof (OffscreenCanvas) !== 'undefined') {
      this.worker = new Worker('./assets/js/worker/image-resizer.worker.js');
      this.worker.onmessage = function (e) {
        console.log('Data retrieved? ', e);
      };

      const canvasData = this.cropperInst.getCroppedCanvas({fillColor: '#FFF'}).toDataURL('image/png');

      // Send data to the worker
      this.worker.postMessage({
        outputWidth: this.outputWidth,
        outputHeight: this.outputHeight,
        canvasWidth: canvasData.width,
        canvasHeight: canvasData.height,
        cropperImage: canvasData
      });

      console.log('Worker detected');
    } else {*/
    this.generateImage().then(
      (fullfilled) => {
        this.imageData = fullfilled;
        this.processing = false;

        this.editorState = EDITOR_STATES.Default;

        this.data.emit(this.imageData);
      },
      (rejected) => {

      }
    );


    // }
  }

  public zoomIn(): void {
    if (this.cropperInst != null) {
      this.cropperInst.zoom(0.1);
      this.calculateZoomPercentage();
    }
  }

  private updateZoomForm(): void {
    this.zoomForm.controls['zoom'].setValue(this.imageZoomPercentage);
  }

  public zoomOut(): void {
    if (this.cropperInst != null) {
      this.cropperInst.zoom(-0.1);
      this.calculateZoomPercentage();
    }
  }

  public onZoomKeyPress(event: KeyboardEvent): void {
    const code = event.code != null ? event.code.toLowerCase() : '';

    if (this.zoomField != null) {
      if (code === 'enter') {
        this.zoomField.nativeElement.blur();
      } else if (event.charCode === 13) {
        this.zoomField.nativeElement.blur();
      }
    }
  }

  public setZoom(zoomValue?: number): void {
    if (this.cropperInst != null) {
      let zoom = Number(zoomValue != null ? zoomValue : this.zoomForm.value['zoom']);
      zoom = zoom / 100;

      console.log(zoom);

      this.cropperInst.zoomTo(zoom);
    }
  }

  public onScaleChange(changeType): void {
    const cropBoxData = this.cropperInst.getCropBoxData();

    if (changeType === 'scale') {
      const scale = this.scaleForm.controls['scale'].value;

      this.scaleForm.controls['width'].setValue(cropBoxData['width'] * scale);
      this.scaleForm.controls['height'].setValue(cropBoxData['height'] * scale);
    } else if (changeType === 'width') {
      const scale = this.scaleForm.controls['width'].value / cropBoxData['width'];

      this.scaleForm.controls['scale'].setValue(scale);
      this.scaleForm.controls['height'].setValue(cropBoxData['height'] * scale);
    } else if (changeType === 'height') {
      const scale = this.scaleForm.controls['height'].value / cropBoxData['height'];

      this.scaleForm.controls['scale'].setValue(scale);
      this.scaleForm.controls['width'].setValue(cropBoxData['width'] * scale);
    }
  }

  public generateImage(): Promise<string> {
    return new Promise((resolve, reject) => {
      const widthScale = this.outputWidth / this.cropperInst.getCropBoxData()['width'];
      const heightScale = this.outputHeight / this.cropperInst.getCropBoxData()['height'];

      // const scale = widthScale > heightScale ? heightScale : widthScale; // Number(this.scaleForm.controls['scale'].value);
      // const previousZoom = this.imageZoom;

      // OLD CODE
      /*
      this.cropperInst.scale(scale).zoomTo((1 / scale) * this.imageZoom);

      const data = this.cropperInst.getCroppedCanvas({
        fillColor: '#FFF'
      }).toDataURL('image/jpg', 1);

      console.log(this.cropperInst.getCroppedCanvas({fillColor: '#FFF'}));

      this.cropperInst.zoomTo(previousZoom).scale(1);*/

      const croppedCanvas = this.cropperInst.getCroppedCanvas();

      let canvasWidth = croppedCanvas.width;
      let canvasHeight = croppedCanvas.height;

      const imageWidth = canvasWidth;
      const imageHeight = canvasHeight;

      // Shrink the image by a factor of 3
      let scaleFactor = 1 / 2;
      let shrinking = true;


      const currentWidthScale = this.outputWidth / canvasWidth;
      const currentHeightScale = this.outputHeight / canvasHeight;
      const scale = currentWidthScale > currentHeightScale ? currentWidthScale : currentHeightScale;
      let currentScale = scale;

      // Generic scaling, e.g. scaling down to a 1/3 each time (or increasing by a 1/3) each time
      let genericScaling = true;

      if (scale > 1) {
        // Scaling up
        shrinking = false;
        scaleFactor = (3 / 2);

        canvasWidth = Math.ceil(canvasWidth * scale);
        canvasHeight = Math.ceil(canvasHeight * scale);
      }

      const outputCanvas = this.renderer.createElement('canvas');
      outputCanvas.width = this.outputWidth;
      outputCanvas.height = this.outputHeight;
      const scalingCanvas = this.renderer.createElement('canvas'); // this.outputCanvas.nativeElement;
      scalingCanvas.width = canvasWidth;
      scalingCanvas.height = canvasHeight;

      const outputContext = outputCanvas.getContext('2d');
      const context = scalingCanvas.getContext('2d');
      // Draw the initial image, from the cropper js, this will capture the cropping
      context.drawImage(this.cropperInst.getCroppedCanvas(), 0, 0);

      let steps = 0;

      //return this.cropperInst.getCroppedCanvas({fillColor: '#FFF'}).toDataURL('image/png');

      do {
        const scaleDifference = (currentScale * scaleFactor);
        console.log('Scale difference: ', scaleDifference, ' - Shrinking: ', shrinking, ' desired scale: ', scale);

        // Check that this next step won't go too far either shrinking or enlarging
        if (scaleDifference < scale && shrinking) {
          // check shrink isn't too much
          genericScaling = false;
        } else if (scaleDifference > scale && !shrinking) {
          // check enlarge isn't too much
          genericScaling = false;
        }

        if (genericScaling) {
          // Still generic scaling
          context.drawImage(scalingCanvas,
            0,
            0,
            Math.ceil(imageWidth * (Math.pow(scaleFactor, steps))),
            Math.ceil(imageHeight * (Math.pow(scaleFactor, steps))),
            0,
            0,
            Math.ceil(imageWidth * (Math.pow(scaleFactor, steps + 1))),
            Math.ceil(imageHeight * (Math.pow(scaleFactor, steps + 1)))
          );
        } else {
          // We're on the final scale step, we need to just scale to the exact proportions requested
          outputContext.drawImage(scalingCanvas,
            0,
            0,
            Math.ceil(imageWidth * (Math.pow(scaleFactor, steps))),
            Math.ceil(imageHeight * (Math.pow(scaleFactor, steps))),
            0,
            0,
            Math.ceil(this.outputWidth),
            Math.ceil(this.outputHeight)
          );
        }

        currentScale *= scaleFactor;

        steps++;
      } while (genericScaling);

      resolve(outputCanvas.toDataURL('image/png'));
    });


    /**
     *
     * 1. Get the cropped image from cropper JS
     * 2. Draw the image to an offscreen canvas
     * 3. Proceed to scale the image numerous times
     * 4. Get the image data from the final draw and save it
     *
     */

    // this.cropperInst.zoom(-1 / scale).scale(-scale, -scale);
  }

  public toggleUrlUpload(event, directUpload: boolean = false): void {
    event.stopPropagation();
    event.preventDefault();

    this.uploadByUrlDirectUpload = directUpload;

    this.editorState = this.editorState === EDITOR_STATES.UploadingUrl ? EDITOR_STATES.Default : EDITOR_STATES.UploadingUrl;

    // Clear form, if we close it
    if (this.editorState !== EDITOR_STATES.UploadingUrl) {
      this.imageUrlUploadForm.reset({});
    }
  }

  public uploadByUrl(event, directUpload: boolean = false) {
    this.error = '';

    if (this.imageUrlUploadForm.valid) {
      if (!this.uploadByUrlDirectUpload) {
        // Make sure the form is valid, e.g. URL provided
        this.goToImageEdit(this.imageUrlUploadForm.controls['url'].value);
      } else {
        this.directImageUploadByUrl(this.imageUrlUploadForm.controls['url'].value);
      }
    }
  }

  private directImageUploadByUrl(url: string): void {
    this.editorState = EDITOR_STATES.Lazy;

    this.http.get(url, {responseType: 'blob'}).pipe(
      takeUntil(this.destroyed$),
      switchMap((value: Blob) => {
        return this.readInBlob(value);
      }),
      catchError((err) => {
        return of('');
      })
    ).subscribe(
      (value: string) => {
        if (value === '') {

        } else {
          this.instantlyEmitData(value);
        }
      }
    );

  }

  private readInBlob(data: Blob): Observable<string> {
    return new Observable((observable) => {
      const fileReader = new FileReader();
      fileReader.addEventListener('load', (event) => {
        observable.next(<string>event.target.result);
      });

      fileReader.readAsDataURL(data);
    });
  }

  public onImageLoadError(event) {
    if (this.imageData !== '' && this.imageData != null) {
      this.editorState = EDITOR_STATES.Error;

      console.error('Error loading image in cropper');
      console.error(event);

      this.errorMessage = 'You may try uploading a new image, which will override any image data set.';
    }
  }

  public directUpload(event): void {
    this.error = '';

    const input = this.cropperDirectUpload.nativeElement;

    if (input.files && input.files[0]) {
      const reader = new FileReader();

      // Listen for when the file is loaded
      reader.onload = (e: ProgressEvent) => {
        console.log('onload', e.currentTarget);
        if (e != null && e.currentTarget != null && e.currentTarget['result'] != null) {
          // If the currentTarget / file is a file reader
          // set some vars
          const file = <FileReader>e.currentTarget;
          const data = file.result;

          if (data != null && (typeof data === 'string' || data instanceof String) && data.indexOf('data:image') >= 0) {
            // if returned as a String e.g. DataURL, check it's an image type
            this.instantlyEmitData(e.target['result']);
          } else if (data != null && data instanceof ArrayBuffer) {
            // if for some reason it comes back as an arraybuffer (which it shouldn't) assume it's good.
            this.instantlyEmitData(e.target['result']);
          } else {
            // else display an error saying the file type isn't supported
            this.error = 'File type not supported';
            this.editorState = EDITOR_STATES.Error;
          }

        }
      };

      // Request file loaded as DataURL
      reader.readAsDataURL(input.files[0]);

      input.value = '';
    } else {
      this.error = 'File type not supported';
    }
  }

  public imageFileSelected(event) {
    this.error = '';

    const input = this.cropperFileUpload.nativeElement;

    if (input.files && input.files[0]) {
      const reader = new FileReader();

      // Listen for when the file is loaded
      reader.onload = (e: ProgressEvent) => {
        if (e != null && e.currentTarget != null && e.currentTarget['result'] != null) {
          // If the currentTarget / file is a file reader
          // set some vars
          const file = <FileReader>e.currentTarget;
          const data = file.result;

          if (data != null && (typeof data === 'string' || data instanceof String) && data.indexOf('data:image') >= 0) {
            // if returned as a String e.g. DataURL, check it's an image type
            this.goToImageEdit(e.target['result']);
            // TODO: Instantly emit for now, until we sort out image editor
            // this.instantlyEmitData(e.target['result']);
          } else if (data != null && data instanceof ArrayBuffer) {
            // if for some reason it comes back as an arraybuffer (which it shouldn't) assume it's good.
            this.goToImageEdit(e.target['result']);
            // TODO: Instantly emit for now, until we sort out image editor
            // this.instantlyEmitData(e.target['result']);
          } else {
            // else display an error saying the file type isn't supported
            this.error = 'File type not supported';
            this.editorState = EDITOR_STATES.Error;
          }

        }
      };

      // Request file loaded as DataURL
      reader.readAsDataURL(input.files[0]);

      input.value = '';
    } else {
      this.error = 'File type not supported';
    }
  }

  private updateCropperImage(imageData): void {
    try {
      this.cropperInst.replace(imageData);
    } catch (error) {
      this.error = 'Error loading image';
      this.editorState = EDITOR_STATES.Default;
    }
  }

  public goToImageEdit(imageData: any): void {
    this.editorState = EDITOR_STATES.Loading;

    // Setup cropper if it hasn't already been initialised
    this.setupCropper();

    this.updateCropperImage(imageData);

    if (this.dataChange) {
      this.dataChange = false;
      this.cropperInst.replace(imageData);
    } else if (!this.dataChange) {
      this.editorState = EDITOR_STATES.Editing;
    }
  }

  public openLibrary(): void {
    const config: MatDialogConfig = GeneralUtil.largeDialogConfigPopup(false);
    const data: MediaLibraryData = {
      selectedUrl: this.imageUrlUploadForm.controls['url'].value,
      type: 'test'
    };
    config.data = data;

    const dialogRef = this.dialog.open(MediaLibraryComponent, config);

    dialogRef.afterClosed().subscribe(result => {
      // If image has been changed, we take action
      if (data.selectedUrl != null && data.selectedUrl !== this.imageUrlUploadForm.controls['url'].value) {
        this.imageUrlUploadForm.controls['url'].setValue(data.selectedUrl);
        // this.goToImageEdit(data.selectedUrl);

        // this.updateCropperImage();

        this.goToImageEdit(data.selectedUrl);

        // this.imageData = this.generateImage();

        // emit
        // this.data.emit(this.imageData);
      }
    });
  }

  public editImage(): void {
    this.goToImageEdit(this.imageData);
  }

  public cancelImageEditing(): void {
    this.editorState = EDITOR_STATES.Default;
  }

}
