import {Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges} from '@angular/core';
import {BrmLocation} from '../../../api/settings/brm-location';
import {BrmLocationService} from '../brm-location.service';
import {Observable, of} from 'rxjs';
import {BrmCacheService} from '../../../brm-cache.service';
import {tap} from 'rxjs/operators';

@Component({
  selector: 'location-tag',
  templateUrl: './location-tag.component.html',
  styleUrls: ['./location-tag.component.scss']
})
export class LocationTagComponent implements OnInit, OnChanges {

  @Input() location: BrmLocation;
  @Input() locationShortName: string;
  @Input() locationName: string;

  @Output() clicked: EventEmitter<BrmLocation> = new EventEmitter<BrmLocation>();

  public loading: boolean = true;
  public loader$: Observable<BrmLocation[]>;

  private locationsData: BrmLocation[];

  constructor(public brmLocation: BrmLocationService, private cache: BrmCacheService) {
  }

  ngOnInit(): void {
    if (this.location != null) {
      this.loading = false;
      this.loader$ = of([this.location]);
    } else if (this.locationShortName != null || this.locationName != null) {
      this.loading = true;
      this.loader$ = this.loadLocations();
    } else {
      this.loading = false;
      this.loader$ = of([]);
    }
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.hasOwnProperty('locationShortName') || changes.hasOwnProperty('locationName')) {
      this.findLocation(this.locationsData);
    }
  }

  private loadLocations(): Observable<BrmLocation[]> {
    return this.cache.multiLocationCache.getData().pipe(
      tap({
        next: ((value: BrmLocation[]) => {
          this.loading = false;
          this.locationsData = value;

          this.findLocation(this.locationsData);
        })
      })
    );
  }

  private findLocation(value: BrmLocation[]): void {
    if (this.locationShortName != null) {
      this.location = this.findLocationFromShortName(this.locationShortName, value);
    } else if (this.locationName != null) {
      this.location = this.findLocationFromName(this.locationName, value);
    }
  }

  private findLocationFromShortName(shortName: string, locations: BrmLocation[]): BrmLocation {
    return (locations || []).find((location: BrmLocation) => {
      return location.short_name === shortName;
    });
  }

  private findLocationFromName(name: string, locations: BrmLocation[]): BrmLocation {
    return (locations || []).find((location: BrmLocation) => {
      console.log('Location name: ', location.name, ' == ', name, location.name === name);
      return location.name === name;
    });
  }

  onClick(event): void {
    if (this.location != null) {
      this.clicked.next(this.location);
    }
  }

}
