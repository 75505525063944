import {NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';
import {SplashComponent} from './splash.component';
import {InitialLoadGuard} from '../../core/guards/initial-load.guard';

const routes: Routes = [
  {path: 'load', component: SplashComponent, canActivate: [InitialLoadGuard]},
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
  providers: []
})
export class SplashRoutingModule {}
