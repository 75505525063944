import {Component, OnDestroy, OnInit} from '@angular/core';
import {UntypedFormControl, UntypedFormGroup, Validators} from '@angular/forms';
import {ActivatedRoute, Router} from '@angular/router';
import {NotificationsService} from '../../../core/services/notifications/notifications.service';
import {Observable, of, Subject, Subscription, zip} from 'rxjs';
import {RestRequestsService} from '../../../core/services/rest-requests.service';
import {ShellControlService} from '../../shell/shell-control.service';
import {AppControlService} from '../../../core/services/app-control.service';
import {BrmSettingsService} from '../../../core/services/brm/brm-settings.service';
import {FormCanDeactivateAbstractComponent} from '../../../core/guards/abstract/form-can-deactivate.abstract.component';
import {ProductLineModel} from '../services/models/product-line.model';
import {switchMap, takeUntil, tap} from 'rxjs/operators';
import {ProductFamilyModel} from '../services/models/product-family.model';
import {CategoryModel} from '../services/models/category.model';
import {EventHistoryDialog} from '../../shared/event-history-dialog/event-history-dialog';
import {EventHistoryDialogComponent} from '../../shared/event-history-dialog/event-history-dialog.component';
import {BrmCacheService} from '../../../core/brm2/brm-cache.service';
import {ReservationsViewerDialog} from '../shared/reservations-viewer-dialog/reservations-viewer-dialog';
import {ReservationsViewerDialogComponent} from '../shared/reservations-viewer-dialog/reservations-viewer-dialog.component';
import {BreadcrumbsService} from '../../../core/services/breadcrumbs.service';
import {GeneralUtil, LanguagesUtil} from '../../../core/util';
import {ChangeSizeDialogData} from './change-size-dialog/change-size-dialog-data';
import {ChangeSizeDialogComponent} from './change-size-dialog/change-size-dialog.component';
import {ChangeSizeDialogOutcome} from './change-size-dialog/change-size-dialog-outcome';
import {BrmLocation} from '../../../core/brm2/api/settings/brm-location';
import {ChangeProductFamilyDialogData} from './change-product-family-dialog/change-product-family-dialog-data';
import {ChangeProductFamilyDialogComponent} from './change-product-family-dialog/change-product-family-dialog.component';
import {ChangeProductFamilyDialogOutcome} from './change-product-family-dialog/change-product-family-dialog-outcome';
import {BrmSessionService} from '../../../core/brm2/brm-session.service';
import {HttpErrorResponse} from '@angular/common/http';
import {BrmInventoryErrors} from '../../../core/brm2/data/errors/brm-inventory-errors';
import {InventoryErrorDialog} from '../shared/inventory-error-dialog/inventory-error-dialog';
import {BrmInventoryTypes} from '../../../core/brm2/data/brm-inventory-types';
import {InventoryErrorDialogComponent} from '../shared/inventory-error-dialog/inventory-error-dialog.component';
import {percentageValidatorOptional} from '../../../core/validators/form.validator';
import {BrmLanguagesService} from '../../../core/brm2/services/languages/brm-languages.service';
import {MatDialog, MatDialogConfig} from '@angular/material/dialog';
import {ActionModalDialogComponent} from '../../shared/action-modal-dialog/action-modal-dialog.component';
import {ActionModalDialogData} from '../../shared/action-modal-dialog/action-modal-dialog-data';

@Component({
  selector: 'app-edit-product-family',
  templateUrl: './edit-product-line.component.html',
  styleUrls: ['./edit-product-line.component.scss', '../shared/inventory-shared.scss']
})
export class EditProductLineComponent extends FormCanDeactivateAbstractComponent implements OnInit, OnDestroy {

  public editForm: UntypedFormGroup = new UntypedFormGroup({
    name: new UntypedFormControl(null, Validators.required),
    quantity: new UntypedFormControl(null),
    sku: new UntypedFormControl(null),
    suitability: new UntypedFormControl(null),
    size: new UntypedFormControl(null),
    price_group: new UntypedFormControl(null),
    inventory_type: new UntypedFormControl(null),
    // home_location: new FormControl(null),
    short_code: new UntypedFormControl(null),
    held_back_percentage: new UntypedFormControl(null, percentageValidatorOptional),
    language_config: new UntypedFormGroup({})
  });

  public updateSubscription: Subscription;
  public pageLoader$: Observable<any>;

  public categories: CategoryModel[] = [];
  public families: ProductFamilyModel[] = [];

  private category: CategoryModel;
  public family: ProductFamilyModel;
  public line: ProductLineModel;

  public familyName: string;

  public id: number;
  public skus: string[] = [];

  public locations: BrmLocation[] = [];
  public homeLocation: BrmLocation;

  private destroyed$: Subject<boolean> = new Subject<boolean>();

  constructor(public router: Router, private notify: NotificationsService,
              private rest: RestRequestsService, private shellControl: ShellControlService,
              private appControl: AppControlService, public brmSession: BrmSessionService,
              public brm: BrmSettingsService, private activatedRoute: ActivatedRoute,
              private dialog: MatDialog, private cache: BrmCacheService,
              private breadcrumbs: BreadcrumbsService, private brmLanguage: BrmLanguagesService) {
    super();
  }

  ngOnInit() {
    this.activatedRoute.params.pipe(
      takeUntil(this.destroyed$)
    ).subscribe(
      (params) => {
        if (params.hasOwnProperty('id')) {
          this.id = Number(this.activatedRoute.snapshot.paramMap.get('id'));
          this.pageLoader$ = this.createPageLoader();
        } else {
          this.onPageLoadFailure();
        }
      }
    );
  }

  ngOnDestroy(): void {
    this.destroyed$.next(true);
    this.destroyed$.complete();
  }

  private createPageLoader(): Observable<any> {

    return zip(
      // this.cache.inventoryCache.getData(),
      this.cache.inventoryCache.loadProductLine(this.id, true),
      this.getMultiLocationData(),
      this.cache.categoriesCache.getData(),
    ).pipe(
      switchMap(value => {
        this.line = value[0]; // this.cache.inventoryCache.getProductLine(this.id);
        this.categories = value[2];

        if (this.line != null) {
          this.skus = this.line.skus;
          return this.loadFamilyAndCategoryData(this.line.category_id, this.line.product_family_id);
        }

        return of(null);
      }),
      tap({
        next: value => {
          if (value != null) {
            this.updateForms();
            this.setupBreadcrumbs();
          } else {
            this.onPageLoadFailure();
          }
        }
      })
    );
  }

  private loadFamilyAndCategoryData(categoryId: string, productFamilyId: number): Observable<any> {
    return zip(
      this.cache.inventoryCache.loadCategory(categoryId, true),
      this.cache.inventoryCache.loadProductFamily(productFamilyId)
    ).pipe(
      tap({
        next: (responses) => {
          this.category = responses[0];
          this.family = responses[1];
          this.families = this.category.product_families;

          if (this.family != null) {
            this.homeLocation = this.cache.multiLocationCache.findLocation(this.family.home_location);
          }
        }
      })
    );
  }

  public onPageLoadFailure(): void {
    this.notify.addFailNotification('Failed to load product line data');
    this.router.navigate(['/', 'inventory']);
  }

  private setupBreadcrumbs(): void {
    const defLang = this.brm.session.defaultLanguage;

    if (this.category) {
      const categoryName = this.category.language_config.hasOwnProperty(defLang) ? this.category.language_config[defLang]['name'] : null;
      this.breadcrumbs.updateBreadcrumb('category-edit', {
        label: categoryName,
        path: ['/', 'inventory', 'general', 'shape'],
        hidden: false,
        queryParams: {
          category: this.category.id
        }
      });
    }

    if (this.family) {
      this.familyName = this.family.language_config.hasOwnProperty(defLang) ? this.family.language_config[defLang]['name'] : null;

      this.breadcrumbs.updateBreadcrumb('product-family-edit', {
        label: this.familyName,
        hidden: false,
        path: ['/', 'inventory', 'general', 'shape'],
        queryParams: {
          category: this.category != null ? this.category.id : null,
          family: this.family.id
        }
      });
    }

    if (this.line) {
      const label = `Edit product line "${this.line.name}"`;
      this.breadcrumbs.updateBreadcrumb('product-line-edit', {
        label: label,
        hidden: false,
        path: ['/', 'inventory', 'general', 'shape'],
        queryParams: {
          category: this.category != null ? this.category.id : null,
          family: this.family != null ? this.family.id : null,
          line: this.line.id
        }
      });
    }
  }

  /**
   * updateForms - Sets the form data from the generic instance
   */
  private updateForms(): void {
    if (this.line != null) {
      const skus = this.line.skus != null ? this.line.skus : [];

      // Update each of the controls
      this.editForm.controls['name'].setValue(this.line.name);
      this.editForm.controls['quantity'].setValue(this.line.capacity);
      this.editForm.controls['sku'].setValue(skus.join(','));
      this.editForm.controls['suitability'].setValue(this.line.suitability);
      this.editForm.controls['size'].setValue(this.line.size);
      this.editForm.controls['short_code'].setValue(this.line.short_code);

      this.editForm.controls['inventory_type'].setValue(this.line.inventory_type);
      this.editForm.controls['held_back_percentage'].setValue(this.line.held_back_percentage);
      // this.editForm.controls['home_location'].setValue(this.family.home_location);

      // disable fields the user shouldn't update
      this.editForm.controls['size'].disable();
      this.editForm.controls['inventory_type'].disable();
      this.editForm.controls['name'].disable();
      this.editForm.controls['price_group'].disable();

      if (this.line.has_items) {
        // if the line has items, we no longer allow the user to edit the quantity
        this.editForm.controls['quantity'].disable();
      }
    }
  }

  /**
   * Goes back to the inventory page
   */
  public goToInventory(): void {
    // navigate to / which is the inventory
    this.router.navigate(['/', 'inventory', 'general', 'shape']).then(
      (fullfilled) => {
      }
    );
  }

  public onSubmitForm(): void {
    if (this.editForm.valid) {
      if (this.editForm.controls['short_code'].dirty && this.line.has_items) {
        this.confirmShortcodeRenaming();
      } else {
        this.saveForm();
      }
    }
  }

  private confirmShortcodeRenaming(): void {
    const obs = this.createUpdateShortCodeObs().pipe(
      tap({
        next: (model: ProductLineModel) => {
          this.line.short_code = model.short_code;

          this.editForm.controls['short_code'].setValue(this.line.short_code);
          this.editForm.controls['short_code'].markAsPristine();
        }
      })
    );

    const config: MatDialogConfig = GeneralUtil.mediumDialogConfigPopup(true);

    config.data = <ActionModalDialogData>{
      displayButton: true,
      displayCancel: true,
      cancelText: 'No',
      buttonType: 'success',
      buttonText: 'Rename',
      contentText: `We have detected you have updated your short code / name stem. Do you want to rename all items belonging to this PL? <br />
                    Changing the short code from <b>${this.line.short_code}</b> to <b>${this.editForm.value['short_code']}</b> will rename <b>${this.line.item_count} items</b>.`,
      dialogTitle: 'Save and rename items?',
      action: obs,
    };

    this.dialog.open(ActionModalDialogComponent, config).afterClosed().pipe(
      takeUntil(this.destroyed$)
    ).subscribe(
      (result) => {
        this.saveForm();
      }
    );
  }

  private createSaveShortCodeObs(): Observable<any> {
    return this.cache.inventoryCache.updateProductLine(this.line.id, {
      short_code: this.editForm.value['short_code']
    }).pipe(
      tap(
        (response: ProductLineModel) => {
          this.line = response;
          this.editForm.controls['short_code'].setValue(response.short_code);
          this.editForm.controls['short_code'].markAsPristine();
        },
        (error: HttpErrorResponse) => {
          if (error.error.hasOwnProperty('code') && error.error['code'] === BrmInventoryErrors.INVENTORY_PL_SHORTCODE_CLASH) {
            this.openShortcodeClashError(error);
          }
        }
      )
    );
  }

  /**
   * Called when the user submits the form
   */
  private saveForm(): void {
    // check the form is valid and there isn't an on-going update
    if (this.editForm.valid && (this.updateSubscription == null || this.updateSubscription.closed)) {

      const skus = this.editForm.value['sku'] != null ? this.editForm.value['sku'] : '';
      const skusSplit = skus.split(',');

      const postData = {
        id: this.line.id,
        product_family_id: this.family.id,
        name: this.editForm.value['name'],
        capacity: this.editForm.value['quantity'],
        suitability: this.editForm.value['suitability'],
        size: this.editForm.value['size'],
        short_code: this.editForm.value['short_code'],
        // inventory_type: this.editForm.value['inventory_type'],
        skus: this.editForm.value['sku'] != null ? skusSplit : null,
        language_config: this.editForm.value['language_config'],
        held_back_percentage: Number(this.editForm.value['held_back_percentage'])
      };

      postData['language_config'] = this.brmLanguage.readyLanguageConfigDataForApi(postData['language_config'], this.line);

      this.updateSubscription = this.cache.inventoryCache.updateProductLine(this.line.id, postData).subscribe(
        (response: ProductLineModel) => {
          this.line = response;

          this.updateForms();

          this.notify.addSuccessNotification('Successfully updated product line (' + this.line.id + ')');
          this.cache.inventoryCache.processCapacity();
          this.editForm.markAsPristine();
        },
        (error: HttpErrorResponse) => {
          if (error.error.hasOwnProperty('code') && error.error['code'] === BrmInventoryErrors.INVENTORY_PL_SHORTCODE_CLASH) {
            this.openShortcodeClashError(error);
          }
        }
      );
    }
  }

  private openShortcodeClashError(error: HttpErrorResponse): void {
    const config: MatDialogConfig = new MatDialogConfig();
    const data: InventoryErrorDialog = {
      errorData: error,
      type: BrmInventoryTypes.PRODUCTLINE
    };

    config.data = data;
    config.width = '33%';
    config.disableClose = false;

    this.dialog.open(InventoryErrorDialogComponent, config);
  }

  onClickHistoryButton(): void {
    const config: MatDialogConfig = new MatDialogConfig();
    const data: EventHistoryDialog = {
      id: this.line.id.toString(),
      type: 'line',
      data: this.line
    };

    config.data = data;
    config.width = '66%';
    config.disableClose = false;

    const dialogRef = this.dialog.open(EventHistoryDialogComponent, config);

    dialogRef.afterClosed().subscribe(result => {
    });
  }

  openReservationDialog(): void {
    const config: MatDialogConfig = new MatDialogConfig();
    const data: ReservationsViewerDialog = {
      type: 'pl',
      data: this.line
    };

    config.data = data;

    config.maxWidth = 1000;
    config.width = '99%';

    config.disableClose = false;

    const dialogRef = this.dialog.open(ReservationsViewerDialogComponent, config);

    dialogRef.afterClosed().subscribe(result => {
    });
  }

  private createUpdateShortCodeObs(): Observable<ProductLineModel> {
    return this.cache.inventoryCache.updateProductLineShortcode(this.line.id, this.editForm.value['short_code']).pipe(
      takeUntil(this.destroyed$)
    );
  }

  openRenameItemsDialog(): void {
    const obs = this.createUpdateShortCodeObs().pipe(
      tap({
        next: (model: ProductLineModel) => {
          this.notify.addSuccessNotification(`Short code and ${this.line.item_count} items updated`);

          this.line.short_code = model.short_code;

          this.editForm.controls['short_code'].setValue(this.line.short_code);
          this.editForm.controls['short_code'].markAsPristine();
        }
      })
    );


    const leftObs = this.createSaveShortCodeObs();
    const config: MatDialogConfig = GeneralUtil.mediumDialogConfigPopup(true);

    config.data = <ActionModalDialogData>{
      displayButton: true,
      displayCancel: true,
      displayLeftLink: true,
      buttonType: 'success',
      buttonText: 'Rename',
      leftLinkText: 'Change PL name without renaming items',
      leftLinkAction: leftObs,
      contentText: `Changing the short code from <b>${this.line.short_code}</b> to <b>${this.editForm.value['short_code']}</b> will rename <b>${this.line.item_count} items</b>. Are you sure you want to proceed?`,
      dialogTitle: 'Rename items?',
      action: obs,
    };

    this.dialog.open(ActionModalDialogComponent, config);
  }

  editSize(): void {
    const config: MatDialogConfig = GeneralUtil.mediumDialogConfigPopup(false);
    const data: ChangeSizeDialogData = {
      line: this.line
    };
    config.data = data;

    const dialogRef = this.dialog.open(ChangeSizeDialogComponent, config);

    // Get the outcome size value from the dialog and update the form to reflect this
    dialogRef.afterClosed().subscribe((result: ChangeSizeDialogOutcome) => {
      this.editForm.controls['size'].setValue(result.size);
    });
  }

  editProductFamily(): void {
    const config: MatDialogConfig = GeneralUtil.mediumDialogConfigPopup(false);
    const data: ChangeProductFamilyDialogData = {
      line: this.line,
      family: this.family,
      families: this.families.filter((filterFamily) => {
        return filterFamily.id !== this.family.id;
      }).sort((a, b) => {
        if (a.display_order > b.display_order) {
          return 1;
        } else if (a.display_order < b.display_order) {
          return -1;
        }

        return 0;
      })
    };
    config.data = data;

    const dialogRef = this.dialog.open(ChangeProductFamilyDialogComponent, config);

    dialogRef.afterClosed().subscribe((result: ChangeProductFamilyDialogOutcome) => {
      this.family = result.family;
    });
  }


  public get loading(): boolean {
    return this.updateSubscription != null && !this.updateSubscription.closed;
  }

  canDeactivate(): boolean {
    return !this.editForm.dirty;
  }

  private getMultiLocationData(): Observable<BrmLocation[]> {
    return this.cache.multiLocationCache.getData().pipe(
      tap({
        next: (value: BrmLocation[]) => {
          this.locations = value;
        }
      })
    );
  }
}
