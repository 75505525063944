import {Directive, ElementRef, EventEmitter, forwardRef, HostBinding, HostListener, Input, OnInit} from '@angular/core';
import {
  AbstractControl,
  ControlContainer,
  ControlValueAccessor, FormControl, NG_VALIDATORS, NG_VALUE_ACCESSOR,
  ValidationErrors,
  Validator,
  ValidatorFn,
  Validators
} from '@angular/forms';

import {DateTime} from 'luxon';
import {BrmDateAndTimeFormats} from '../../../data/brm-date-and-time-formats';
import {BrmDatesAndTimeService} from '../brm-dates-and-time.service';

/** @docs-private */
export const BRM_TIMEINPUT_VALIDATORS: any = {
  provide: NG_VALIDATORS,
  useExisting: forwardRef(() => BrmTimeFormatterDirective),
  multi: true
};

@Directive({
  selector: 'input[brmTimeInput]',
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => BrmTimeFormatterDirective),
      multi: true
    },
    BRM_TIMEINPUT_VALIDATORS
  ]
})
export class BrmTimeFormatterDirective implements ControlValueAccessor, Validator {

  public uk: boolean = false;


  private _onChange;

  /** Emits when the value changes (either due to user input or programmatic change). */
  _valueChange = new EventEmitter<string | null>();

  @Input() disabled: boolean = false;

  // Support multiple times in one row
  @Input() multipleTimes: boolean = false;

  private _lastValueValid: boolean = false;
  private _value: string;

  public _validatorOnChange = () => {};
  private _onTouched = () => {};

  private _isValidTimeFormatValidator: ValidatorFn = (control: AbstractControl): ValidationErrors | null => {
    try {
      const controlValue = control.value;

      if (controlValue != null && controlValue !== '' && !this._isValidFormat(controlValue)) {
        return {
          timeFormat: true
        };
      }
    } catch (e) {

    }

    return null;
  };

  public _validator: ValidatorFn | null = Validators.compose([this._isValidTimeFormatValidator]);


  constructor(private _elementRef: ElementRef<HTMLInputElement>, private controlContainer: ControlContainer,
              private brmDatesAndTime: BrmDatesAndTimeService) {
  }

  @Input()
  get value(): string {
    return this._value;
  }

  set value(timeStr: string | null) {
    this._value = timeStr;
    this._lastValueValid = this._isValidFormat(timeStr);
    this._formatValue(this._value);

    // this._validatorOnChange();
  }

  @HostListener('blur', ['$event'])
  _onBlur(event): void {
    const value = event.target.value;

    if (this._isValidFormat(value)) {
      this.value = value;
    }

    // this.value = this._formatValue(value);

    this._onTouched();
  }

  @HostListener('input', ['$event'])
  _onInput(event): void {
    const value = event.target.value;
    const lastValid = this._lastValueValid;
    this._lastValueValid = this._isValidFormat(value);

    if (this._lastValueValid) {

      this._value = this._setActualValue(value); //timeMoment.format('HH:mm');
    } else {
      this._value = value;
    }

    this._onChange(this._value);
    this._valueChange.emit(this._value);

    this._validatorOnChange();
  }

  _setActualValue(userInput: string): string {
    const timeAr: string[] = userInput.split(',');

    const formatted = timeAr.map((timeStr: string) => {
      const timeTrimmed = timeStr.trim();
      const format = this._formatUsed(timeTrimmed);

      return DateTime.fromFormat(timeTrimmed, format).toFormat('HH:mm');
    });

    return formatted.join(',');
  }

  _isValidFormat(time: string): boolean {
    if (time == null || typeof time !== 'string') {
      return false;
    }

    const timeAr: string[] = time.split(',');
    let allValid = true;

    timeAr
      .map((timeStr: string) => {
        return timeStr.trim();
      })
      .forEach((timeStr: string) => {
        timeStr = timeStr.toUpperCase();

        let formatUsed;

        formatUsed = BrmDateAndTimeFormats.TWENTY_FOUR_HOUR_FORMATS.find((format: string) => {
          const formattedString = DateTime.fromFormat(timeStr, format).toFormat(format);

          return formattedString === timeStr;
        });

        if (formatUsed == null) {
          // Failed to find format in 24 hour mode

          formatUsed = BrmDateAndTimeFormats.TWELVE_HOUR_FORMATS.find((format: string) => {
            const formattedString = DateTime.fromFormat(timeStr, format).toFormat(format);

            return formattedString === timeStr;
          });
        }


        if (formatUsed == null) {
          allValid = false;
        }
      });


    return (this.multipleTimes && allValid) || (!this.multipleTimes && allValid && timeAr.length === 1);

    /*const timeFormat = BrmDateAndTimeFormats.US_TIME_FORMAT;
    const timeFormatNoSpaces = timeFormat.replace(' ', '');

    const formattedString = moment(time, timeFormat).format(timeFormat);
    const formattedStringNoSpaces = moment(time, timeFormatNoSpaces).format(timeFormatNoSpaces);

    return formattedString === time || formattedStringNoSpaces === timeFormatNoSpaces;*/
  }

  private _formatUsed(timeString: string): string {
    let formatUsed;

    timeString = (timeString || '').toUpperCase();

    formatUsed = BrmDateAndTimeFormats.TWENTY_FOUR_HOUR_FORMATS.find((format: string) => {
      const formattedString = DateTime.fromFormat(timeString, format).toFormat(format); // moment(timeString, format).format(format);

      return formattedString === timeString;
    });

    if (formatUsed == null) {
      // Failed to find format in 24 hour mode

      formatUsed = BrmDateAndTimeFormats.TWELVE_HOUR_FORMATS.find((format: string) => {
        const formattedString = DateTime.fromFormat(timeString, format).toFormat(format); // moment(timeString, format).format(format);

        return formattedString === timeString;
      });
    }

    return formatUsed;
  }

  // Implemented as part of ControlValueAccessor.
  writeValue(timeString: string): void {
    this.value = timeString;
  }

  // Implemented as part of ControlValueAccessor.
  registerOnChange(fn: (value: any) => void): void {
    this._onChange = fn;
  }

  // Implemented as part of ControlValueAccessor.
  registerOnTouched(fn: () => void): void {
    this._onTouched = fn;
  }

  // Implemented as part of ControlValueAccessor.
  setDisabledState(isDisabled: boolean): void {
    this.disabled = isDisabled;
  }

  registerOnValidatorChange(fn: () => void): void {
    this._validatorOnChange = fn;
  }

  validate(control: AbstractControl): ValidationErrors | null {
    return this._validator ? this._validator(control) : null;
  }

  private _formatValue(value: string | null): void {
    let val = '';

    if (value && typeof value === 'string') {

      const timeAr: string[] = value.split(',').map((timeStr: string) => {
        const trimmed = timeStr.trim();
        const format = this._formatUsed(trimmed) || 'HH:mm';
        /*return moment(trimmed, format).format*/
        return DateTime.fromFormat(trimmed, format).toFormat(BrmDateAndTimeFormats.TIME_FORMAT_OBJ[this.brmDatesAndTime.timeFormatId]);
      });

      val = timeAr.join(',');
    }

    this._elementRef.nativeElement.value = val;
  }

}
