import {TimeUtilError} from './errors/time-util-error';

export class TimeUtil {


  static getIsoDateAndTimeStringFromDateAndTime(date: Date, time: string): string {
    const dateClone = new Date(date);
    const timeStringToNumberObject = this.timeStringToNumberObject(time);

    dateClone.setHours(timeStringToNumberObject[0]);
    dateClone.setMinutes(timeStringToNumberObject[1]);

    return this.getIsoFullStringFromDate(dateClone, false, false);
  }

  static getIsoFullStringFromDate(date: Date, seconds: boolean = false, includeZ: boolean = false): string {
    const _seconds = date.getUTCSeconds();
    const _miliseconds = date.getUTCMilliseconds();

    let rtnString = this.getIsoDateStringFromDate(date) + 'T' + this.getIsoTimeStringFromDate(date);

    if (seconds) {
      rtnString += ':' + seconds + 'Z';
    }

    if (includeZ) {
      rtnString += 'Z';
    }

    return rtnString;
  }

  static getIsoTimeStringFromDate(date: Date): string {
    const hours = date.getUTCHours();
    const minutes = date.getUTCMinutes();

    return (hours < 10 ? '0' + hours : hours) + ':' + (minutes < 10 ? '0' + minutes : minutes);
  }

  static getIsoDateStringFromDate(date: Date): string {
    const year = date.getUTCFullYear();
    const month = date.getUTCMonth() + 1;
    const day = date.getUTCDate();

    return year + '-' + (month < 10 ? '0' + month : month) + '-' + (day < 10 ? '0' + day : day);
  }

  static timestampToUTCDate(timestamp: number): Date {
    const date = new Date(timestamp);

    return this.dateToUTCDate(date);
  }

  /**
   * Takes a Date object which could be in a different timezone, then converts the exact same into a UTC
   * e.g. 8th Jan 08:00 -6 would be 8th Jan 08:00 in UTC, disregards the timezone
   * @param date
   */
  static dateToUTCDate(date: Date): Date {
    const year = date.getFullYear();
    const month = date.getMonth() - 1;
    const day = date.getDate();
    const hour = date.getHours();
    const minutes = date.getMinutes();
    const seconds = date.getSeconds();
    const milliseconds = date.getMilliseconds();

    return new Date(Date.UTC(year, month, day, hour, minutes, seconds, milliseconds));
  }

  static getTimeStringFromDate(date: Date): string {
    const hours = date.getHours();
    const minutes = date.getMinutes();

    return ((hours < 10) ? '0' + hours : hours) + ':' + ((minutes < 10) ? '0' + minutes : minutes);
  }

  static daysDifference(dateA: Date, dateB: Date): number {
    const stampA = dateA.getTime();
    const stampB = dateB.getTime();

    if (stampA > stampB) {
      return ((stampA - stampB) / (1000 * 60 * 60 * 24));
    }

    return ((stampB - stampA) / (1000 * 60 * 60 * 24));
  }

  static timeStringToNumberObject(timeStr: string): number[] {
    if (timeStr == null) {
      return [];
    }

    const timePattern = /([0-2][0-9]):([0-5][0-9])/;
    const matches = timePattern.exec(timeStr);

    if (matches.length === 3) {
      return [Number(matches[1]), Number(matches[2])];
    } else {
      throw new TimeUtilError('Invalid time string', 'The provided time string "' + timeStr + '" does not match HH:mm');
    }

    return [];
  }

  static hourAndMinuteToTimeString(hour: number, minute: number): string {
    let timeStr = '';

    hour = Math.round(hour);
    minute = Math.round(minute);

    if (hour < 10) {
      timeStr += '0';
    }

    timeStr += hour + ':';

    if (minute < 10) {
      timeStr += '0';
    }

    timeStr += minute;

    return timeStr;
  }

  static dateToTimeString(date: Date): string {
    return this.hourAndMinuteToTimeString(date.getHours(), date.getMinutes());
  }

  /***
   * Converts a string of format YYYY-MM-DD to date object
   * @param date - date str in format of YYYY-MM-DD
   */
  static isoToDateObject(date: string): Date {
    const split: string[] = date.split('-');

    if (split.length === 3) {
      const year = Number(split[0]);
      const month = Number(split[1]);
      const day = Number(split[2]);

      return new Date(Date.UTC(year, month - 1, day));

      // return new Date(year, month - 1, day);
    }

    return null;
  }


  /***
   * NEW TIME UTIL
   */

  static convertIsoStringToLocalDate(iso: string): Date {
    // first convert string to UTC timezone, to allow the date object to parse the string automatically
    // this allows us to not to manually parse the string and use the tried and tested one in Date
    const date = new Date(iso);

    // convert the exact day, month, year down to the millisecond into the timezone (ignore timeshift from timezone)
    // we do this as all times are saved relative to the timezone, therefore we do not want the date object to adjust the time
    return new Date(date.getUTCFullYear(), date.getUTCMonth(), date.getUTCDate(), date.getUTCHours(), date.getUTCMinutes(), date.getUTCSeconds(), date.getUTCMilliseconds());
  }

  static convertDateAndTimeToIsoString(date: Date, time: string, useUTCValues: boolean = false): string {
    return this.convertDateToDateIsoString(date, useUTCValues) + 'T' + time;
  }

  static convertDateToIsoString(date: Date, useUTCValues: boolean = false): string {
    return this.convertDateToDateIsoString(date, useUTCValues) + 'T' + this.convertDateToTimeString(date, useUTCValues);
  }

  static convertDateToDateIsoString(date: Date, useUTCValues: boolean = false): string {
    const year: string | number = useUTCValues ? date.getUTCFullYear() : date.getFullYear();
    let month: string | number = useUTCValues ? date.getUTCMonth() + 1 : date.getMonth() + 1;
    let day: string | number = useUTCValues ? date.getUTCDate() : date.getDate();

    if (month < 10) {
      month = '0' + month;
    }

    if (day < 10) {
      day = '0' + day;
    }


    return year + '-' + month + '-' + day;
  }

  static convertDateToTimeString(date: Date, displaySeconds: boolean = false, useUTCValues: boolean = false): string {
    let minutes: string | number = useUTCValues ? date.getUTCMinutes() : date.getMinutes();
    let seconds: string | number = useUTCValues ? date.getUTCSeconds() : date.getSeconds();
    let hour: string | number = useUTCValues ? date.getUTCHours() : date.getHours();

    if (hour < 10) {
      hour = '0' + hour;
    }

    if (minutes < 10) {
      minutes = '0' + minutes;
    }

    if (seconds < 10) {
      seconds = '0' + seconds;
    }

    if (displaySeconds) {
      return hour + ':' + minutes + ':' + seconds;
    }

    return hour + ':' + minutes;
  }

  static hasAmOrPm(value: string): boolean {
    if (value != null) {
      value = value.trim().toUpperCase();

      const end = value.substr(-2);

      return end === 'AM' || end === 'PM';
    }

    return false;
  }

  /**
   * Takes a time string in 12 hour clock or 24 hour and tidies it to ISO format
   * e.g. 1:00PM = 13:00
   * @param value
   */
  static handleTimeFormatsAndConvertToIso(value: string): string {
    const groupsPattern = /([0-9]{1,2})\:([0-9]{1,2})(AM|PM)?/;
    // remove spaces and move to uppercase e.g. gets rid of 01:00 PM, and makes it uppercase to make dealing with
    // AM and PM easier
    value = value.replace(' ', '').toUpperCase().trim();

    const matches = groupsPattern.exec(value);

    if (matches.length > 1) {
      let hours = Number(matches[1]);
      const minutes = Number(matches[2]);

      if (this.hasAmOrPm(value) && matches.length === 4) {
        // 12-hour mode
        const amOrPm = matches[3];
        // if in the PM, double check they've not mixed 24 and 12 hour
        if (amOrPm === 'PM' && hours < 12) {
          hours += 12;
        }
      }

      return (hours < 10 ? '0' + hours : hours) + ':' + (minutes < 10 ? '0' + minutes : minutes);
    }

    throw new TimeUtilError('Invalid time string', `Failed to convert '${value}' to ISO standard`);
  }

}
