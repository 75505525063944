import {Injectable} from '@angular/core';
import {HttpClient, HttpHeaders, HttpParams} from '@angular/common/http';
import {Observable} from 'rxjs/index';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class RestRequestsService {

  private urlPathStructureRegex = /(\/\/([^\/\n\?]+)|(\/([^\/\n\?]+)))/g;

  // \/([^\/\n\?]+)

  /**
   * Service for handling GET / POST requests, other request types can easily be added
   * @param http - Import http client to handle requests
   */
  constructor(private http: HttpClient) {
  }

  /**
   * Encodes the path structure to the API
   * e.g. http://<api url>/api/pricegroups/bike+ test/
   * becomes
   * http://<api url>/api/pricegroups/bike%d%20test/
   *
   * Shouldn't encode any GET parameters, or try to run on the URL itself only the path structure
   * @param url
   */
  private encodeUrlPathStructure(url: string): string {
    return url.replace(this.urlPathStructureRegex, (group, ...args) => {

      if (args[3] != null) {
        return `/${encodeURIComponent(args[3])}`;
      }

      return group;
    });
  }

  /**
   * Sends a GET request to call
   * @param call - URL to call
   * @param parameters - Parameters to add to the request
   * @returns Observable - Returns Obsevable, which will eventually return the response from the GET request
   */
  public getRequest(call: string, parameters: object, encodePathStructure: boolean = true): Observable<any> {
    // Create a parameters object to shop parameters
    const params = {};

    if (encodePathStructure) {
      call = this.encodeUrlPathStructure(call);
    }

    call = encodeURI(call);

    // Check if we're passed parameters for the request
    if (typeof parameters !== 'undefined') {
      // Create HttpParameter object, this is required to handled the GET data
      let httpParameters = new HttpParams();

      // Loop through the parameters and add them to our parameters object
      for (const key in parameters) {
        // Ensure the object has the key and add it to our object
        if (parameters.hasOwnProperty(key)) {
          httpParameters = httpParameters.set(key, parameters[key]);
        }
      }

      // Set the request parameters for the GET request
      params['params'] = httpParameters;
    }
    // Do GET request and return the observable
    return this.http.get(call, params);
  }

  public getFile(filePath : string): Observable<Blob> {
      const headers = new HttpHeaders({ 'Content-Type': 'application/json', responseType : 'blob'});
      return this.http.get<Blob>(filePath, { headers : headers,responseType : 
      'blob' as 'json'});
  }


  public postData(call: string, parameters: object): Observable<any> {
    let fileList: any = parameters['files'];
    if(fileList) {
      let opts = {};
      const formData: FormData = new FormData();
      Object.values(fileList).forEach(function (file: File) {
        formData.append(file.name, file, file.name);
      });
      opts = {
        reportProgress: true,
        observe: 'events'
      }
      return this.http.post(call, formData, opts);
    }
  }

  /**
   * Sends a POST request to call
   * @param call - URL to be called
   * @param parameters - Parameters to be passed to the server
   * @param encodePathStructure - Encodes the URL to be friendly e.g. spaces to %20
   * @param allEvents - Makes the observable report all HTTP events
   * @returns Observable
   */
  public postRequest(call: string, parameters: object | string, encodePathStructure: boolean = true, allEvents: boolean = false): Observable<any> {
    // Create a parameters object to shop parameters
    let params = {};

    if (encodePathStructure) {
      call = this.encodeUrlPathStructure(call);
    }


    call = encodeURI(call);

    // Check if we're passed parameters for the request
    if (parameters != null) {
      if (typeof parameters === 'object') {
        // We send form data via a parameters object and we add these to the HTTPParameters
        // Create HttpParameter object, this is required to handled the POST data
        let httpParameters = new HttpParams();
        httpParameters.append('Content-type', 'charset=utf-8');

        // Loop through the parameters and add them to our parameters object
        for (const key in parameters) {
          // Ensure the object has the key and add it to our object
          if (parameters.hasOwnProperty(key)) {
            httpParameters = httpParameters.set(key, parameters[key]);
          }
        }

        // Set the request parameters for the POST request, unlike GET, we just set the entire object to the httpParameter object
        params = httpParameters;
      } else if (typeof parameters === 'string') {
        // If we want to just send a payload body to the address, we use a string
        // this can be used for sending a JSON body to the address
        params = parameters;
      }
    }

    let opts = {};

    if (allEvents) {
      opts = {
        reportProgress: true,
        observe: 'events'
      };
    }

    // Do POST request and return the observable
    return this.http.post(call, params, opts);
  }

  /**
   * Sends a PUT request to call
   * @param call - URL to be called
   * @param parameters - Parameters to be passed to the server
   * @returns Observable
   */
  public putRequest(call: string, parameters: object | string, encodePathStructure: boolean = true): Observable<any> {
    // Create a parameters object to shop parameters
    let params = {};

    if (encodePathStructure) {
      call = this.encodeUrlPathStructure(call);
    }

    call = encodeURI(call);

    // Check if we're passed parameters for the request
    if (parameters !== null) {

      if (typeof parameters === 'object') {
        // Create HttpParameter object, this is required to handled the POST data
        let httpParameters = new HttpParams();

        // Loop through the parameters and add them to our parameters object
        for (const key in parameters) {
          // Ensure the object has the key and add it to our object
          if (parameters.hasOwnProperty(key)) {
            httpParameters = httpParameters.set(key, parameters[key]);
          }
        }

        // Set the request parameters for the POST request, unlike GET, we just set the entire object to the httpParameter object
        params = httpParameters;
      } else if (typeof parameters === 'string') {
        // If we want to just send a string payload body to the address, we use a string
        // this can be used for sending a JSON body to the address
        params = parameters;
      }
    }

    // Do GET request and return the observable
    return this.http.put(call, params);
  }

  /**
   * Sends a DELETE request to call
   * @param call - URL to be called
   * @param parameters - Parameters to be passed to the server
   * @returns Observable
   */
  public deleteRequest(call: string, parameters: object | string, encodePathStructure: boolean = true): Observable<any> {
    // Create a parameters object to shop parameters
    let params = {};

    if (encodePathStructure) {
      call = this.encodeUrlPathStructure(call);
    }

    call = encodeURI(call);

    // Check if we're passed parameters for the request
    if (parameters !== null) {

      if (typeof parameters === 'object') {
        // Create HttpParameter object, this is required to handled the POST data
        let httpParameters = new HttpParams();

        // Loop through the parameters and add them to our parameters object
        for (const key in parameters) {
          // Ensure the object has the key and add it to our object
          if (parameters.hasOwnProperty(key)) {
            httpParameters = httpParameters.set(key, parameters[key]);
          }
        }

        // Set the request parameters for the POST request, unlike GET, we just set the entire object to the httpParameter object
        params = httpParameters;
      } else if (typeof parameters === 'string') {
        // If we want to just send a string payload body to the address, we use a string
        // this can be used for sending a JSON body to the address
        params = parameters;
      }
    }

    const options = {
      headers: new HttpHeaders({}),
      body: parameters
    };

    // Do GET request and return the observable
    return this.http.delete(call, options);
  }

}
