// For a lot of the error codes, we simply produce a red error lozenge at the top.
// However on some pages error handling is done differently and therefore we need
// to not have a red lozenge + the additional error handling
// Therefore this file lists the error codes handled elsewhere
import {BrmInventoryErrors} from '../brm2/data/errors/brm-inventory-errors';
import {BrmReservationErrors} from '../brm2/data/errors/brm-reservation-errors';
import {BrmDinErrors} from '../brm2/data/errors/brm-din-errors';
import {BrmStripePortalError} from '../brm2/api/billing/brm-stripe-portal';
import {BrmStripePortalErrors} from '../brm2/data/errors/brm-stripe-portal-errors';

export const ERROR_CODES_TO_IGNORE: string[] = [
  'api002', // User not logged in error code
  // Reservation error codes to ignore
  BrmReservationErrors.RESERVATION_ADD_ITEM_NOT_FOUND, // Item not found when searching
  'ass001', // Asset not found
  'ass025', // Multiple items not found
  'ass011', // Item not operational
  'avl000', // Item not available
  // 'ass026', // Item store ref already exists
  BrmInventoryErrors.INVENTORY_INVALID_STATUS_CHANGE,
  BrmInventoryErrors.INVENTORY_PL_SIZE_CLASH,
  BrmInventoryErrors.INVENTORY_INVALID_STATUS_CHANGE,
  BrmReservationErrors.RESERVATION_ASSET_OVERLAP,
  BrmReservationErrors.RESERVATION_UNEXPECTED_DATES,
  BrmReservationErrors.RESERVATION_ITEM_NOT_AVAILABLE,
  BrmReservationErrors.RESERVATION_SWAP_ITEM_BRIKEB,
  BrmStripePortalErrors.STRIPE_PORTAL_CUSTOMER_ID_MISSING,
  BrmDinErrors.INVALID_DIN_COMBO
];
