import {Injectable} from '@angular/core';
import {LOGGER_TYPES, LoggerService} from '../logger.service';
import {MatSnackBar, MatSnackBarConfig, MatSnackBarRef, SimpleSnackBar} from '@angular/material/snack-bar';

@Injectable({
  providedIn: 'root'
})
export class NotificationsService {

  // Code for directly inserting component into the page, switched to snack bar
  /*private notificationReferecnes: ComponentRef<NotificationComponent>;

  constructor(private injector: Injector, private factory: ComponentFactoryResolver, private ref: ApplicationRef) {

  }

  public addNotification(type: number) {
    const factory = this.factory.resolveComponentFactory(NotificationComponent);
    const ref = factory.create(this.injector);

    this.ref.attachView(ref.hostView);

    const dom = (ref.hostView as EmbeddedViewRef<any>).rootNodes[0] as HTMLElement;
    document.body.appendChild(dom);

    console.log('Called!');

    this.notificationReferecnes = ref;
  }*/

  constructor(private snackBar: MatSnackBar, private logger: LoggerService) {

  }


  public addSuccessNotification(msg: string): void {
    const config = new MatSnackBarConfig();
    config.verticalPosition = 'top';
    config.panelClass = ['success-notification'];
    config.duration = 4000;

    this.snackBar.open(msg, 'X', config);

    this.logger.log(msg, LOGGER_TYPES.SUCCESS);
  }

  public addWarningNotification(msg: string): void {
    const config = new MatSnackBarConfig();
    config.verticalPosition = 'top';
    config.panelClass = ['warning-notification'];
    config.duration = 10000;

    this.snackBar.open(msg, 'X', config);

    this.logger.log(msg, LOGGER_TYPES.WARNING);
  }

  public addFailNotification(msg: string): void {
    const config = new MatSnackBarConfig();
    config.verticalPosition = 'top';
    config.panelClass = ['fail-notification'];
    config.duration = 15000;

    this.snackBar.open(msg, 'X', config);

    this.logger.log(msg, LOGGER_TYPES.ERROR);
  }

  public addActionNotification(msg: string, btnStr: string, type: string = 'warning-notification'): MatSnackBarRef<SimpleSnackBar> {
    const config = new MatSnackBarConfig();
    config.verticalPosition = 'top';
    config.panelClass = [type];

    return this.snackBar.open(msg, btnStr, config);
  }

}
