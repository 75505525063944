import {Params} from '@angular/router';

export class LightspeedQueryParamsModel {

  readonly lightspeedQueryParams: string[] = [
    'type',
    'accountID',
    'systemUserID',
    'employeeID',
    'saleID',
    'customerID',
    'shopID',
    'registerID',
    'returnURL'
  ];

  public type: string;
  public accountID: number;
  public systemUserID: number;
  public saleID: number;
  public customerID: number;
  public shopID: number;
  public registerID: number;
  public returnURL: string;

  constructor(queryParams: Params) {
    this.grabParameters(queryParams);
  }

  /**
   * Grabs the parameters from the URL query params, but only the lightspeed applicable ones
   */
  private grabParameters(queryParams: Params): void {
    this.lightspeedQueryParams.forEach((param: string) => {
      let value;

      if (Array.isArray(queryParams[param]) && queryParams[param].length > 0) {
        value = queryParams[param][0];
      } else {
        value = queryParams[param];
      }

      this[param] = value;
    });
  }


}
