import {Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {ConsoleInterceptorService} from '../../../core/services/console-interceptor/console-interceptor.service';
import {ServerRestErrorLogsService} from '../../../core/services/server-rest-error-logs/server-rest-error-logs.service';
import {BrmSessionService} from '../../../core/brm2/brm-session.service';
import {BrmPrivilegesService} from '../../../core/brm2/services/privileges/brm-privileges.service';

@Component({
  selector: 'app-client-error-log',
  templateUrl: './client-error-log.component.html',
  styleUrls: ['./client-error-log.component.scss']
})
export class ClientErrorLogComponent implements OnInit {

  public tabActive: 'CLIENT' | 'SERVER' = 'CLIENT';

  constructor(public dialogRef: MatDialogRef<ClientErrorLogComponent>,
              @Inject(MAT_DIALOG_DATA) public data: any,
              public consoleInterceptor: ConsoleInterceptorService,
              public serverLogs: ServerRestErrorLogsService,
              public brmSession: BrmSessionService,
              public brmPrivileges: BrmPrivilegesService) {
  }

  ngOnInit(): void {
  }

  onCancelClick(event): void {
    this.dialogRef.close(false);
  }

  triggerError() {
    const rand = Math.random() * 10;

    console.log('Random: ', rand);

    if (rand > 5) {
      let a = null;
      a += a.sum;

    } else {
      let b = null;
      b += b.add;
    }

    return null;
  }

}
