import {Injectable} from '@angular/core';
import {Observable, of} from 'rxjs';
import {RestRequestsService} from '../../../services/rest-requests.service';
import {catchError, map, tap} from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class BrmTooltipsService {

  private _tooltips: object;

  constructor(private rest: RestRequestsService) {
  }

  public getTooltip(name: string): string {
    if (this._tooltips != null && this._tooltips.hasOwnProperty(name)) {
      return this._tooltips[name];
    }

    return 'Tooltip not found';
  }

  public loadTooltips(): Observable<boolean> {
    return this.rest.getRequest('./assets/tool-tips.json', {}).pipe(
      map(value => {
        this._tooltips = value;
        return true;
      }),
      catchError((err, caught) => {
        return of(false);
      })
    );
  }
}
