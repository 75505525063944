import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {FreshsalesArticleComponent} from './freshsales-article.component';
import {FreshSalesContentWrapModule} from '../freshsales-content-wrap/freshsales-content-wrap.module';
import {MatProgressSpinnerModule} from '@angular/material/progress-spinner';

@NgModule({
  declarations: [FreshsalesArticleComponent],
  imports: [
    CommonModule,
    MatProgressSpinnerModule,
    FreshSalesContentWrapModule
  ],
  exports: [
    FreshsalesArticleComponent
  ]
})
export class FreshSalesArticleModule {
}
