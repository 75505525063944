import {Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {InventoryErrorDialog} from './inventory-error-dialog';
import {Router} from '@angular/router';
import {BrmInventoryTypes} from '../../../../core/brm2/data/brm-inventory-types';
import {isIdAssetOrProductLine} from '../../../../core/brm2/util/brm-general-util';

@Component({
  selector: 'app-inventory-error-dialog',
  templateUrl: './inventory-error-dialog.component.html',
  styleUrls: ['./inventory-error-dialog.component.scss']
})
export class InventoryErrorDialogComponent implements OnInit {

  public errorMessage: string;
  public resourceId: string;
  public eventCode: string;
  public sourceType: BrmInventoryTypes;

  public sourceLabel: string = 'item';

  public brmInventoryTypes = BrmInventoryTypes;

  constructor(private dialogRef: MatDialogRef<InventoryErrorDialogComponent>, @Inject(MAT_DIALOG_DATA) public data: InventoryErrorDialog,
              private router: Router) {
  }

  ngOnInit() {
    if (this.data != null) {
      this.errorMessage = this.data.errorData.error['message'];
      this.resourceId = this.data.errorData.error['resource_id'];
      this.eventCode = this.data.errorData.error['code'];

      this.setSourceType();
    }
  }

  private setSourceType() {
    this.sourceType = isIdAssetOrProductLine(Number(this.resourceId));

    if (this.sourceType === BrmInventoryTypes.PRODUCTLINE) {
      this.sourceLabel = 'product line';
    } else {
      this.sourceLabel = 'item';
    }
  }


  onCloseClick(): void {
    this.dialogRef.close({});
  }

  goToErrorSource(id: string, eventCode: string): void {
    let url: string;

    if (isIdAssetOrProductLine(Number(id)) === BrmInventoryTypes.PRODUCTLINE) {
      url = 'productline';
    } else {
      url = 'item';
    }

    /*if (this.sourceType === BrmInventoryTypes.ASSET) {
      url = 'item';
    } else if (this.sourceType === BrmInventoryTypes.PRODUCTLINE) {
      url = 'productline';
    }*/

    this.dialogRef.close(true);
    this.router.navigate(['/', 'inventory', url, id]).then();
  }
}
