export class TimeUtilError extends Error {

  constructor(public errorCode: string, public message: string) {
    super(message);
  }

  public toString(): string {
    return '[' + this.errorCode + '] ' + this.message;
  }

}
