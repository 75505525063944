import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {BrmLanguagesService} from '../../../core/brm2/services/languages/brm-languages.service';

@Component({
  selector: 'app-brm-language-selector',
  templateUrl: './brm-language-selector.component.html',
  styleUrls: ['./brm-language-selector.component.scss']
})
export class BrmLanguageSelectorComponent implements OnInit {

  // Two outputs, event fires when a language is selected and when the menu is opened
  @Output() languageSelected;
  @Output() menuOpened;

  constructor(public brmLanguages: BrmLanguagesService) {
    // Initiate outputs and selected value;
    this.languageSelected = new EventEmitter<string>();
    this.menuOpened = new EventEmitter<boolean>();
  }

  ngOnInit() {
  }

  public onLanguageSelected(code: string): void {
    this.brmLanguages.changeLanguage(code);
    this.languageSelected.emit(code);
  }

  public onLanguageMenuOpen(event) {
    this.menuOpened.emit(true);
  }

}
