import {UntypedFormControl, ValidationErrors} from '@angular/forms';

export function latitudeValidator(control: UntypedFormControl): ValidationErrors {
  const regex = RegExp('$|^(-)?([0-9]{1,2}(\\.[0-9]{0,20})?)$');

  if (!regex.test(control.value)) {
    return {
      latitude: false
    };
  } else {
    const value = Number(control.value);

    if (value >= -90 && value <= 90) {
      return null;
    } else {
      return {
        latitude: true
      };
    }
  }
}
