import {Injectable} from '@angular/core';
import {BehaviorSubject, Observable} from 'rxjs';
import {NotificationsService} from './notifications/notifications.service';

@Injectable({
  providedIn: 'root'
})
export class BackgroundRequestsService {

  /***
   * Background requests is a class which stores REST requests which must be completed regardless of page change
   * e.g. If the ordering of products are changed but we don't want to lock the user into the page, we continue the request
   * regardless of the page state
   */

  private _requests: object;
  private _nonNameRequests: Observable<any>[];

  private loading$ = new BehaviorSubject<boolean>(false);
  public loading = this.loading$.asObservable();

  constructor(private notify: NotificationsService) {
    this._requests = {};
    this._nonNameRequests = [];
  }

  public doRequest(key: string, obs: Observable<any>, successMsg?: string, failureMsg?: string, successCb?: any, errorCb?: any): void {
    // Cancel the request if we already have a request with the same key
    this.cancelRequest(key);

    this._requests[key] = {
      observable: obs,
      successCallback: successCb != null ? successCb : null,
      errorCallback: errorCb != null ? errorCb : null,
      success: successMsg != null ? successMsg : null,
      failure: failureMsg != null ? failureMsg : null
    };

    this.checkForRequestsOnGoing();

    this._requests[key].observable.subscribe(
      (response: any) => {
        if (this._requests[key].success != null) {
          this.notify.addSuccessNotification(this._requests[key].success);
        }

        // If we have a success callback, send the result back to the callback
        if (this._requests[key].successCallback != null) {
          this._requests[key].successCallback.call(this, response);
        }
      },
      (error) => {
        if (this._requests[key].failure != null) {
          // this.notify.addFailNotification(this._requests[key].failure);
        }

        if (this._requests[key].errorCallback != null) {
          this._requests[key].errorCallback.call(this, error);
        }

        console.log('Error from background handler below');
        console.log(error);

        delete this._requests[key];

        this.checkForRequestsOnGoing();
      },
      () => {
        delete this._requests[key];

        this.checkForRequestsOnGoing();
      }
    );
  }

  public cancelRequest(key: string): void {
    if (this._requests.hasOwnProperty(key)) {
      this._requests[key].observable.unsubscribe();

      delete this._requests[key];
    }
  }

  /***
   * Temporary? fix for cases where there's a background request but it has to be handled in the component
   * @param key - Key to simulate
   */
  public addRequestKey(key: string): void {
    this._requests[key] = '';

    this.checkForRequestsOnGoing();
  }

  public removeRequestKey(key: string): void {
    delete this._requests[key];

    this.checkForRequestsOnGoing();
  }

  private checkForRequestsOnGoing(): void {
    this.loading$.next(Object.keys(this._requests).length > 0);
  }
}
