import { AfterViewInit, Component, NgZone, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { BrmSessionService } from 'src/app/core/brm2/brm-session.service';
import { FirebaseAuthService } from '../../../core/services/firebase-auth.service';

@Component({
  selector: 'app-login-failed',
  templateUrl: './login-failed.component.html',
  styleUrls: ['./login-failed.component.scss', '../login.component.scss']
})
export class LoginFailedComponent implements OnInit, AfterViewInit {

  public errorCode: string;

  @ViewChild('sec001') sec001: TemplateRef<any>;
  @ViewChild('sec008') sec008: TemplateRef<any>;
  @ViewChild('sec016') sec016: TemplateRef<any>;
  @ViewChild('sec017') sec017: TemplateRef<any>;
  @ViewChild('sec018') sec018: TemplateRef<any>;
  @ViewChild('sec019') sec019: TemplateRef<any>;
  @ViewChild('sec020') sec020: TemplateRef<any>;
  @ViewChild('redirectLoop') redirectLoop: TemplateRef<any>;
  @ViewChild('unknownError') unknownError: TemplateRef<any>;

  public templateToShow: TemplateRef<any>;

  public emailUsed: string;
  public dataCentre: string;
  private errorMessage: string;
  private shopName: string;
  private firebaseId;

  private errorMsgs = {
    'def': {
      'title': 'There was an issue with logging in',
      'message': 'A problem occurred whilst trying to log you in'
    },
    'sec001': {
      'title': 'BRM account not found',
      'message': `The login credentials that you provided do not match any we have on record. Please double check that you have logged in with the correct details.`
    },
    'sec008': {
      'title': 'There was an issue with logging in',
      'message': `We have detected a problem which has prevented you from logging into BRM2.`
    },
    'sec016': {
      'title': 'There was an issue with logging in',
      'message': `We have detected a problem which has prevented you from logging into BRM2.`
    },
    'sec017': {
      'title': 'There was an issue with logging in',
      'message': `We have detected a problem which has prevented you from logging into BRM2.`
    },
    'sec018': {
      'title': 'There was an issue with logging in',
      'message': `We have detected a problem which has prevented you from logging into BRM2.`
    },
    'sec019': {
      'title': 'There was an issue with logging in',
      'message': `We have detected a problem which has prevented you from logging into BRM2.`
    },
    'sec020': {
      'title': 'There was an issue with logging in',
      'message': `We have detected a problem which has prevented you from logging into BRM2.`
    },
    'fb001': {
      'title': 'Invalid login token',
      'message': 'The provided login token was invalid. This may be due to it expiring.'
    }
  };

  constructor(private activatedRoute: ActivatedRoute, public fbAuth: FirebaseAuthService,
    private router: Router,
    private ngZone: NgZone,
    private brmSession: BrmSessionService) {
    this.errorCode = activatedRoute.snapshot.queryParams['code'];


    this.checkAuthStatus();
  }

  ngOnInit() {

  }

  ngAfterViewInit(): void {
  }

  private checkAuthStatus(): void {
    if (this.errorCode != null) {
      if (this.fbAuth.userIsAuthenticated()) {

        this.emailUsed = this.getEmail();
        this.dataCentre = this.getDataCentre();
        this.firebaseId = this.fbAuth.getUser().uid;

        this.shopName = this.brmSession?.session?.shop_name;

        this.fbAuth.logout().then(
          fullfilled => {

            if (this.errorCode !== 'redirectLoop') {
              this.doRedirect();
            } else {
              this.showErrorMessage(this.errorCode);
            }
          }
        );

      } else {
        this.doRedirect();
      }
    } else {
      this.router.navigate(['/', 'login']).then();
    }
  }

  private doRedirect(): void {
    this.ngZone.runOutsideAngular(() => {
      const baseUrl = 'https://bikerentalmanager.com/log-error/';

      const params = new URLSearchParams();

      params.set('ts', new Date().getTime().toString());

      if (this.shopName) {
        params.set('shop', this.shopName);
      }

      if (this.emailUsed) {
        params.set('email', this.emailUsed);
      }

      if (this.firebaseId) {
        params.set('id', this.firebaseId);
      }

      if (this.errorCode) {
        params.set('error_code', this.errorCode);

        if (this.errorMsgs[this.errorCode]) {
          params.set('error_message', this.errorMsgs[this.errorCode].message);
        } else {
          params.set('error_message', this.errorMsgs['def'].message);
        }
      }

      window.location.href = `${baseUrl}?${params.toString()}`;
    });
  }

  private getEmail(): string {
    try {
      return this.fbAuth.getUser().email;
    } catch (error) {
      return null;
    }
  }

  private getDataCentre(): string {
    try {
      const url = window.location.href;

      if (url.indexOf('app.bikerentalmanager') > -1) {
        return 'EU';
      } else {
        return 'US';
      }

    } catch (error) {
      return 'US';
    }
  }

  private showErrorMessage(code: string): void {
    const errorCodeLower = (code || '').toLowerCase();

    switch (errorCodeLower) {
      default:
        this.templateToShow = this.unknownError;
        break;

      case 'sec001':
      case 'api002':
        this.templateToShow = this.sec001;
        break;

      case 'sec008':
        this.templateToShow = this.sec008;
        break;

      case 'sec016':
        this.templateToShow = this.sec016;
        break;

      case 'sec017':
        this.templateToShow = this.sec017;
        break;

      case 'sec018':
        this.templateToShow = this.sec018;
        break;

      case 'sec019':
        this.templateToShow = this.sec019;
        break;

      case 'sec020':
        this.templateToShow = this.sec020;
        break;

      case 'redirectLoop':
        this.templateToShow = this.redirectLoop;
        break;
    }
  }

}
