import {Injectable} from '@angular/core';
import {BehaviorSubject, Observable} from 'rxjs';
import {BrmSession} from './api/session/brm-session';
import {GoogleTagManagerService} from 'angular-google-tag-manager';

declare var ga: any;


@Injectable({
  providedIn: 'root'
})
export class BrmSessionService {

  public session: BrmSession;

  private _sessionBehaviour: BehaviorSubject<BrmSession>;
  public session$: Observable<BrmSession> = new BehaviorSubject(null).asObservable();

  private migrationToken: string;

  constructor(private gtmManager: GoogleTagManagerService) {
    this._sessionBehaviour = new BehaviorSubject<BrmSession>(null);
    this.session$ = this._sessionBehaviour.asObservable();
  }


  public setSession(session: BrmSession): void {
    this.session = session;
    this._sessionBehaviour.next(this.session);

    this.setGoogleAnalytics();
  }

  private setGoogleAnalytics(): void {
    try {
      this.gtmManager.getDataLayer().push({
        dimension1: this.session.shop_name,
        userId: this.session.user.id
      });
    } catch (error) {
      console.error('GA failed to initialise');
    }
  }

  public updateSessionShopSettings(data: object): void {
    this.session.shop_settings = {...this.session.shop_settings, ...data};

    this._sessionBehaviour.next(this.session);
  }

  public updateSessionFeatures(data: object): void {
    this.session.features = {...this.session.features, ...data};

    this._sessionBehaviour.next(this.session);
  }

  public updateAccountSettings(data: object): void {
    this.session.account = {...this.session.account, ...data};

    this._sessionBehaviour.next(this.session);
  }

  public removeSession(): void {
    this.session = null;
    this._sessionBehaviour.next(null);
  }

  public hasSession(): boolean {
    return this.session != null;
  }

  public getMigrationToken(): string {
    return this.migrationToken;
  }

  public setMigrationToken(migrationToken: string): void {
    this.migrationToken = migrationToken;
  }

}
