import {BrmCategory} from '../../../../core/brm2/api/inventory/shape/brm-category';
import {BrmProductFamily} from '../../../../core/brm2/api/inventory/shape/brm-product-family';
import {ShapeFamily} from './shape-family';

export class ShapeCategory {

  public families: ShapeFamily[];

  expanded: 'show' | 'hide' = 'hide';
  total_capacity: number = 0;
  total_assets: number = 0;

  constructor(public data: BrmCategory) {
    this.processCategory();
  }

  private processCategory(): void {
    if (this.data != null) {
      this.families = this.data.product_families.map((family: BrmProductFamily) => {
        return new ShapeFamily(family);
      });
    } else {
      this.families = [];
    }
  }

  public updateCapacityAndAssets(): void {
    this.total_capacity = 0;
    this.total_assets = 0;

    this.families.forEach((family: ShapeFamily) => {
      family.updateCapacityAndAssets();

      this.total_capacity += family.total_capacity;
      this.total_assets += family.total_assets;
    });
  }

  public recalculateCategory(): void {
    // Get all categories which exist in the inventory cache
    const familiesToDelete = this.families.filter((family: ShapeFamily) => {
      return !this.doesFamilyExistInRawData(family.data.id);
    });

    let i = this.families.length - 1;

    while (i >= 0) {
      if (familiesToDelete.indexOf(this.families[i]) >= 0) {
        this.families.splice(i, 1);
      } else {
        this.families[i].recalculateFamily();
      }

      i--;
    }

    // Find the missing categories in our data structure, we do this after the above, as we won't need to recalculate these values
    const missingFamilies = this.data.product_families.filter((family: BrmProductFamily) => {
      return !this.doesFamilyExist(family.id);
    });

    // For each missing category, that's in the cache but not in the Shape format, we create ShapeCategory
    missingFamilies.forEach((family: BrmProductFamily) => {
      this.families.push(new ShapeFamily(family));
    });

    this.updateCapacityAndAssets();
  }

  public doesFamilyExist(id: number): boolean {
    return this.families.find((data: ShapeFamily) => {
      return data.data.id === id;
    }) != null;
  }

  private doesFamilyExistInRawData(id: number): boolean {
    return this.data.product_families.find((family: BrmProductFamily) => {
      return family.id === id;
    }) != null;
  }

  public toggleDropdown(): void {
    this.expanded = this.expanded === 'hide' ? 'show' : 'hide';
  }

  public expandDropdown(): void {
    this.expanded = 'show';
  }

  public hideDropdown(): void {
    this.expanded = 'hide';
  }
}
