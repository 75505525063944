import {Injectable} from '@angular/core';
import {BrmSessionService} from '../../brm-session.service';

@Injectable({
  providedIn: 'root'
})
export class BrmRestDataService {

  constructor(private session: BrmSessionService) {
  }

  public getHeaders(): { [name: string]: string | string[] } {
    const headers = {};

    headers['X-BRM-Application'] = 'brm2';

    // If the user has a session, we send the API version with each call
    if (this.session.session != null) {
      headers['X-BRM-API-Version'] = this.session.session.server_version;
      headers['X-BRM-Session-Token'] = this.session.session.token;
    }

    // If we've been passed a migration token, we need to set this in the next request
    if (this.session.getMigrationToken() != null) {
      // headers.append('X-BRM-Migration-Token', this.brm.migrationToken);
      headers['X-BRM-Migration-Token'] = this.session.getMigrationToken();
    }

    return headers;
  }
}
