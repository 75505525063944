import {Component, NgZone, OnDestroy, OnInit} from '@angular/core';
import {ShellControlService} from '../shell-control.service';
import {ActivatedRoute, Router} from '@angular/router';
import {AppControlService} from '../../../core/services/app-control.service';
import {BrmSettingsService} from '../../../core/services/brm/brm-settings.service';
import {GettingStartedService} from '../../getting-started/getting-started.service';
import {FirebaseAuthService} from '../../../core/services/firebase-auth.service';
import {interval, Observable, Subject, Subscription} from 'rxjs';
import {BrmSessionResponse} from '../../../core/services/brm/responses/brm-session-response.model';
import {NotificationsService} from '../../../core/services/notifications/notifications.service';
import {map, startWith, switchMap, takeUntil} from 'rxjs/operators';
import {BreadcrumbsService} from '../../../core/services/breadcrumbs.service';
import {GeneralUtil} from '../../../core/util';
import {BrmCacheService} from '../../../core/brm2/brm-cache.service';
import {HandsFreeService} from '../../../core/brm2/services/hands-free/hands-free.service';
import {BrmInventoryTypes} from '../../../core/brm2/data/brm-inventory-types';
import {BrmSessionService} from '../../../core/brm2/brm-session.service';
import {DateTime} from 'luxon';
import {MatDialog} from '@angular/material/dialog';
import {BrmGbalService} from '../../../core/brm2/services/gbal/brm-gbal.service';
import {ClientErrorLogComponent} from '../client-error-log/client-error-log.component';
import {BrmPrivilegesService} from '../../../core/brm2/services/privileges/brm-privileges.service';
import {BrmIssuesApiService} from '../../../core/brm2/api-services/issues/brm-issues-api.service';
import {BrmIssueSearch} from '../../../core/brm2/api/issues/brm-issue-search';
import {InitialSettingsDialogComponent} from '../../shared/initial-settings-dialog/initial-settings-dialog.component';

@Component({
  selector: 'app-breadcrumb-header',
  templateUrl: './breadcrumb-header.component.html',
  styleUrls: ['./breadcrumb-header.component.scss']
})
export class BreadcrumbHeaderComponent implements OnInit, OnDestroy {

  public statusNumber = 0;

  private gettingStartedUpdater: Subscription;
  private brmSessionUpdater: Subscription;

  private destroyed$: Subject<boolean> = new Subject<boolean>();

  public userPackage: string;

  public userPackage$: Observable<string>;

  public brmInventoryTypes = BrmInventoryTypes;

  public daysInTrial: number = 0;
  public issueCount: number = 0;

  public shopLink: string;

  constructor(public shellControl: ShellControlService, public router: Router, public appControl: AppControlService,
              public brm: BrmSettingsService, public gettingStarted: GettingStartedService,
              private fbAuth: FirebaseAuthService, private notify: NotificationsService,
              public breadcrumbs: BreadcrumbsService, private activatedRoute: ActivatedRoute,
              private dialog: MatDialog, private zone: NgZone, public cache: BrmCacheService,
              public handsFree: HandsFreeService, public session: BrmSessionService,
              public gbal: BrmGbalService, public brmSession: BrmSessionService,
              public brmPrivileges: BrmPrivilegesService,
              private brmIssuesApi: BrmIssuesApiService) {

    this.shopLink = this.appControl.getOBWLink();

    this.daysInTrial = this.daysInTrialRemaining();

    this.userPackage$ = this.brm.session.sessionUpdates().pipe(
      map((response: BrmSessionResponse) => {
        return response.account.product_package;
      })
    );
  }

  ngOnInit() {
    this.gettingStartedUpdater = this.gettingStarted.update.pipe(
      takeUntil(this.destroyed$)
    ).subscribe(
      (update) => {
        this.statusNumber = this.getReadyStatusNumber();
      }
    );

    this.setupPackageListener();
    this.setupAlertsListener();
  }

  ngOnDestroy(): void {
    this.destroyed$.next(true);
    this.destroyed$.complete();
  }

  private setupAlertsListener(): void {
    if (this.brmPrivileges.isAdminUser()) {
      interval(1000 * 60 * 15).pipe(
        startWith(0),
        switchMap(value => {
          return this.brmIssuesApi.getSmartIssues();
        })
      ).subscribe(
        (value: BrmIssueSearch) => {
          this.issueCount = value.data.filter(issue => {
            return (issue.type !== 'pricing_config' || this.brmPrivileges.isOperationsUser()) && !issue.resolved;
          }).reduce((prev, current) => {
            return prev + 1;
          }, 0);
        }
      );
    }
  }

  private daysInTrialRemaining(): number {
    try {
      const endDate = DateTime.fromISO(this.session.session.account.trial_end_date);
      const now = DateTime.utc();

      return Math.round(endDate.diff(now, 'days').toObject()['days']);
    } catch (error) {
      return -1;
    }
  }

  /***
   * Listens for changes to the user's session settings
   * Updates the package variable
   */
  private setupPackageListener(): void {
    if (this.brm.session != null) {
      // The user's session needs to be initialised to allow us to listen for package changes, but we should safe guard
      // against it not being initalised

      this.brmSessionUpdater = this.brm.session.sessionUpdates().pipe(
        takeUntil(this.destroyed$)
      ).subscribe(
        // Listen for if the user's session data is updated. Manually update package, as we need to able to withstand nulls
        (data: BrmSessionResponse) => {
          this.userPackage = this.brm.session.package;

          if (this.userPackage != null) {
            // If not null, we want this lowercase, for comparison in template
            this.userPackage = this.userPackage.toLowerCase();
          } else {
            // Set to empty string, just to assist with package
            this.userPackage = '';
          }
        }
      );
    } else {
      // Warn the user that the session hasn't been initialised
      this.notify.addWarningNotification('User session failed to initialise, please reload');
    }
  }

  public getReadyStatusNumber(): number {
    const invent = this.gettingStarted.getInventoryData() != null ? this.gettingStarted.getInventoryData().level : 'warning';
    const pricing = this.gettingStarted.getPricingData() != null ? this.gettingStarted.getPricingData().level : 'warning';
    const openingHours = this.gettingStarted.getOpeningHoursData() != null ? this.gettingStarted.getOpeningHoursData().level : 'warning';
    const storeSettings = this.gettingStarted.getStoreSettingsData() != null ? this.gettingStarted.getStoreSettingsData().level : 'warning';
    const notifications = this.gettingStarted.getNotificationsWhoData() != null ? this.gettingStarted.getNotificationsWhoData().level : 'warning';

    let count = 0;

    if (invent !== 'info') {
      count++;
    }

    if (pricing !== 'info') {
      count++;
    }

    if (openingHours !== 'info') {
      count++;
    }

    if (storeSettings !== 'info') {
      count++;
    }

    if (notifications !== 'info') {
      count++;
    }

    return count;
  }

  showUserSettings(): void {
    this.router.navigate(['/', 'settings', 'usersettings']);
  }

  handleShowInitialClick(): void {
    this.dialog.open(InitialSettingsDialogComponent, GeneralUtil.mediumDialogConfigPopup(false));
  }

  showErrorLog(event): void {
    const config = GeneralUtil.largeDialogConfigPopup(false);

    this.dialog.open(ClientErrorLogComponent, config);
  }

  /**
   * Returns the logo assigned to this shop, if assigned
   */
  getShopLogo(): string {
    if (this.brm.session != null && this.brm.session.settings != null && this.brm.session.settings.shop_logo_url != null) {
      return this.brm.session.settings.shop_logo_url;
    }

    return null;
  }

  logout(): void {
    this.fbAuth.logout().then(
      fullfilled => {
        this.zone.runOutsideAngular(() => {
          location.reload();
        });
      }
    );
  }

}
