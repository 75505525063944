import {BrmCache} from './brm-cache';
import {Observable} from 'rxjs';
import {map, tap} from 'rxjs/operators';
import {RestRequestsService} from '../../services/rest-requests.service';
import {AppControlService} from '../../services/app-control.service';
import {BrmTaxCode} from "../api/settings/brm-tax-code";

export class BrmTaxCodeCache extends BrmCache<BrmTaxCode[]> {

  private rest: RestRequestsService;
  private appControl: AppControlService;

  constructor(_caches, _appControl, _rest) {
    super(_caches, 'taxes');

    this.appControl = _appControl;
    this.rest = _rest;
  }

  protected loadData(): Observable<BrmTaxCode[]> {
    return this.rest.getRequest(this.appControl.apiUrl + '/taxes', {}).pipe(
      map((value: BrmTaxCode[]) => {
        return value;
      })
    );
  }

  public deleteTaxCode(taxcode: BrmTaxCode): Observable<BrmTaxCode> {
    return this.rest.deleteRequest(this.appControl.apiUrl + '/taxes/' + taxcode.id, {}).pipe(
      tap((value: BrmTaxCode) => {
          if (this.cachedData != null) {
            this.cachedData.splice(this.cachedData.indexOf(taxcode), 1);
          }
        }, (error: any) => {
        }
      ));
  }

  public findTaxCode(id: number): BrmTaxCode {

    if (this.cachedData == null) {
      return null;
    }

    return this.cachedData.find((value: BrmTaxCode) => {
      return value.id == id;
    })
  }

  public saveTaxCode(id: number, data: object, isNew: boolean = false): Observable<BrmTaxCode> {

    let apiCall = '/taxes';

    if (!isNew) {
      apiCall = '/taxes/' + id;
    }

    return this.rest.postRequest(this.appControl.apiUrl + apiCall, JSON.stringify(data)).pipe(
      map((value: BrmTaxCode) => {
        return value;
      }),
      tap((value: BrmTaxCode) => {
        if (isNew) {
          this.cachedData.push(value);
        } else {
          const taxcode = this.findTaxCode(id);

          if (taxcode != null) {
            Object.assign(taxcode, value);
          }
        }
      })
    );
  }

  protected onLoad(): void {
  }

}
