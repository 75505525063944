import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {SideNavComponent} from './side-nav/side-nav.component';
import {ShellRoutingModule} from './shell.routing.module';
import {ShellComponent} from './shell.component';
import {BreadcrumbHeaderComponent} from './breadcrumb-header/breadcrumb-header.component';
import {EditCategoryModule} from '../inventory/edit-category/edit-category.module';
import {NgxPopperModule} from 'ngx-popper';
import {FreshSalesDialogModule} from '../shared/freshsales/freshsales-dialog/freshsales-dialog.module';
import {MatChipsModule} from '@angular/material/chips';
import {SideNavLanguageSelectorComponent} from './side-nav/side-nav-language-selector/side-nav-language-selector.component';
import {HandsFreeOverlayComponent} from './hands-free-overlay/hands-free-overlay.component';
import {DragDropModule} from '@angular/cdk/drag-drop';
import {BrmLanguagesModule} from '../../core/brm2/services/languages/brm-languages.module';
import {LozengesModule} from '../shared/lozenges/lozenges.module';
import {LocationSelectorComponent} from './breadcrumb-header/location-selector/location-selector.component';
import {SideNavClockComponent} from './side-nav/side-nav-clock/side-nav-clock.component';
import {BrmDatesTimeModule} from '../../core/brm2/services/datesandtime/brm-dates-time.module';
import {FormsModule} from '@angular/forms';
import {AutoFocusInputModule} from '../../core/directives/auto-focus-input.module';
import {TrialMessageComponent} from './breadcrumb-header/trial-message/trial-message.component';
import {StoreMessageComponent} from './breadcrumb-header/store-message/store-message.component';
import {MatMenuModule} from '@angular/material/menu';
import {MatBadgeModule} from '@angular/material/badge';
import {MatFormFieldModule} from '@angular/material/form-field';
import {MatProgressSpinnerModule} from '@angular/material/progress-spinner';
import {MatIconModule} from '@angular/material/icon';
import {MatInputModule} from '@angular/material/input';
import {MatButtonModule} from '@angular/material/button';
import {ClientErrorLogComponent} from './client-error-log/client-error-log.component';
import {MatDialogModule} from '@angular/material/dialog';
import {MatButtonToggleModule} from '@angular/material/button-toggle';
import {LocationTagModule} from '../../core/brm2/services/location/location-tag/location-tag.module';
import {SideNavDropdownSelectorDirective} from './side-nav/side-nav-dropdown-selector/side-nav-dropdown-selector.directive';
import {ToastComponent} from './toast/toast.component';
import {SideNavStoreSelectorComponent} from './side-nav/side-nav-store-selector/side-nav-store-selector.component';
import {MatTooltipModule} from '@angular/material/tooltip';

@NgModule({
  declarations: [
    SideNavComponent,
    ShellComponent,
    BreadcrumbHeaderComponent,
    SideNavLanguageSelectorComponent,
    SideNavStoreSelectorComponent,
    HandsFreeOverlayComponent,
    LocationSelectorComponent,
    SideNavClockComponent,
    TrialMessageComponent,
    StoreMessageComponent,
    ClientErrorLogComponent,
    SideNavDropdownSelectorDirective,
    ToastComponent,
  ],
  imports: [
    MatIconModule,
    MatInputModule,
    MatFormFieldModule,
    MatBadgeModule,
    CommonModule,
    NgxPopperModule,
    ShellRoutingModule,
    EditCategoryModule,
    MatMenuModule,
    FreshSalesDialogModule,
    MatIconModule,
    MatProgressSpinnerModule,
    MatChipsModule,
    MatButtonModule,
    MatDialogModule,
    MatButtonToggleModule,
    DragDropModule,
    BrmLanguagesModule,
    LozengesModule,
    BrmDatesTimeModule,
    FormsModule,
    AutoFocusInputModule,
    LocationTagModule,
    MatTooltipModule,
  ],
  providers: [
    { provide: Window, useValue: window }
  ]
})
export class ShellModule {
}
