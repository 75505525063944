export const brm_taxonomy_types = {
  'kids': 'Child bike',
  'ebike': 'E bike',
  'hybrid': 'Hybrid',
  'mtn': 'Mountain bike',
  'road': 'Road bike',
  'tandem': 'Tandem',
  'fatbike': 'Fat bike',
  'gravel': 'Gravel'
};
