import {BrmCache} from './brm-cache';
import {Observable} from 'rxjs';
import {map, tap} from 'rxjs/operators';
import {RestRequestsService} from '../../services/rest-requests.service';
import {AppControlService} from '../../services/app-control.service';
import {BrmDriver} from "../api/settings/brm-driver";

export class BrmDriverCache extends BrmCache<BrmDriver[]> {

  private rest: RestRequestsService;
  private appControl: AppControlService;

  constructor(_caches, _appControl, _rest) {
    super(_caches, 'drivers');

    this.appControl = _appControl;
    this.rest = _rest;
  }

  protected loadData(): Observable<BrmDriver[]> {
    return this.rest.getRequest(this.appControl.apiUrl + '/deliveries/drivers', {}).pipe(
      map((value: BrmDriver[]) => {
        return value;
      })
    );
  }

  public deleteDriver(driver: BrmDriver): Observable<BrmDriver> {
    return this.rest.deleteRequest(this.appControl.apiUrl + '/deliveries/drivers/' + driver.id, {}).pipe(
      tap((value: BrmDriver) => {
          if (this.cachedData != null) {
            this.cachedData.splice(this.cachedData.indexOf(driver), 1);
          }
        }, (error: any) => {
        }
      ));
  }

  public findDriver(id: number): BrmDriver {

    if (this.cachedData == null) {
      return null;
    }

    return this.cachedData.find((value: BrmDriver) => {
      return value.id == id;
    })
  }

  public saveDriver(id: number, data: object, isNew: boolean = false): Observable<BrmDriver> {

    let apiCall = '/deliveries/drivers';

    if (!isNew) {
      apiCall = '/deliveries/drivers/' + id;
    }

    return this.rest.postRequest(this.appControl.apiUrl + apiCall, JSON.stringify(data)).pipe(
      map((value: BrmDriver) => {
        return value;
      }),
      tap((value: BrmDriver) => {
        if (isNew) {
          this.cachedData.push(value);
        } else {
          const driver = this.findDriver(id);

          if (driver != null) {
            Object.assign(driver, value);
          }
        }
      })
    );
  }

  protected onLoad(): void {
  }

}
