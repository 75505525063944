import {Component, OnInit} from '@angular/core';
import {AppControlService} from '../../../core/services/app-control.service';

@Component({
  selector: 'app-suspended',
  templateUrl: './suspended.component.html',
  styleUrls: ['./suspended.component.scss', '../login.component.scss']
})
export class SuspendedComponent implements OnInit {

  public shopName;

  constructor(public appControl: AppControlService) {
    this.shopName = this.appControl.suspendedShopName;
  }

  ngOnInit() {
  }

}
