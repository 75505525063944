import {Component, Inject, OnDestroy, OnInit} from '@angular/core';
import {Observable, Subject, Subscription} from 'rxjs';
import {NotificationsService} from '../../../../core/services/notifications/notifications.service';
import {RestructureProductFamilyDialog} from './restructure-product-family-dialog';
import {BrmCacheService} from '../../../../core/brm2/brm-cache.service';
import {tap} from 'rxjs/operators';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';

@Component({
  selector: 'app-restructure-product-family-dialog',
  templateUrl: './restructure-product-family-dialog.component.html',
  styleUrls: ['./restructure-product-family-dialog.component.scss']
})
export class RestructureProductFamilyDialogComponent implements OnInit, OnDestroy {

  public states = RestructureProductFamilyStates;
  public title = `Move product family's category?`;
  public status: RestructureProductFamilyStates = this.states.CONFIRMATION;

  public taskSubscription: Subscription;

  private destroyed$: Subject<boolean> = new Subject<boolean>();

  constructor(public dialogRef: MatDialogRef<RestructureProductFamilyDialogComponent>,
              @Inject(MAT_DIALOG_DATA) public data: RestructureProductFamilyDialog,
              private notify: NotificationsService, private cache: BrmCacheService) {
  }

  ngOnInit() {
  }

  ngOnDestroy(): void {
    this.destroyed$.next(true);
    this.destroyed$.complete();
  }

  onCancelClick(): void {
    this.dialogRef.close(false);
  }

  onProceed(): void {
    this.title = 'Product family restructuring...';
    this.status = this.states.PROCESSING;

    this.taskSubscription = this.createTaskObservable().subscribe();
  }

  private createTaskObservable(): Observable<any> {
    const data = {
      category_id: this.data.new_category.id
    };

    return this.cache.inventoryCache.updateProductFamily(this.data.product_family.id, data).pipe(
      tap({
        next: value => {
          this.dialogRef.close(true);
        }
      })
    );
  }

  private onTaskComplete(value): void {
    this.title = 'Product family restructured!';
    this.status = this.states.COMPLETE;
    this.dialogRef.close(true);
  }


}

// Store the states the dialog can be in
export enum RestructureProductFamilyStates {
  CONFIRMATION,
  PROCESSING,
  COMPLETE
}
