import {Component, OnInit} from '@angular/core';
import {ToastService} from './toast.service';
import {IconService} from '../../../core/services/icons/icon-service.service';
import { trigger, state, animate, transition, style, keyframes } from '@angular/animations';
@Component({
  selector: 'app-toast',
  templateUrl: './toast.component.html',
  styleUrls: ['./toast.component.scss'],
  animations: [
    trigger('openClose', [
      transition('void => *', [
        animate('0.5s ease', keyframes([
          style({
            transform: 'translateX(130%)'
          }),
          style({
            transform: 'translateX(0px)'
          })
        ]))
      ]),
        ,
        transition('* => void', [
          animate('0.5s ease', keyframes([
            style({
              transform: 'translateX(0)'
            }),
            style({
              transform: 'translateX(130%)'
            })
          ]))
        ])
      ])
    ]
})


export class ToastComponent implements OnInit {
  
  constructor(public toast: ToastService, public icon: IconService) {
  }


  ngOnInit(): void {
    this.icon.registerIcon('close');
    this.icon.registerIcon('success');
    this.icon.registerIcon('error');
    this.icon.registerIcon('warning');
  }
}
