import {ApplicationRef, Injectable, OnDestroy, OnInit} from '@angular/core';
import {BehaviorSubject, Observable} from 'rxjs';
import {LanguagesUtil} from '../../../util';
import {BrmSessionService} from '../../brm-session.service';
import {BrmSessionResponse} from '../../../services/brm/responses/brm-session-response.model';
import {UntypedFormControl, UntypedFormGroup, ValidatorFn} from '@angular/forms';
import {BrmSession} from '../../api/session/brm-session';
import { RestRequestsService } from '../../../../core/services/rest-requests.service';
import { AppControlService } from '../../../../core/services/app-control.service';
import { BrmSettingsLanguageResponse } from '../../api/settings/brm-settings-language-response.model';

@Injectable({
  providedIn: 'root'
})
export class BrmLanguagesService implements OnDestroy {

  public language$: BehaviorSubject<string> = new BehaviorSubject<string>(null);

  public languages: string[] = [];
  public activeLanguage: string;
  public activeLanguageCode: string;

  private defaultLanguage: string = 'en';


  constructor(private brmSession: BrmSessionService, private rest: RestRequestsService, private appControl: AppControlService) {
    this.listenForSessionChange();
  }

  private listenForSessionChange(): void {
    this.brmSession.session$.subscribe(
      (session: BrmSession) => {
        this.setSupportedLanguages(session.shop_settings.supported_language_codes);
        this.setDefaultLanguage(session.shop_settings.shop_language_code);
        this.changeLanguage(this.defaultLanguage);
      },
      (error: any) => {

      },
      () => {

      }
    );
  }

  ngOnDestroy(): void {
  }

  public changeLanguage(langCode: string): void {
    // Make sure language code has changed
    if (langCode !== this.activeLanguageCode) {
      this.activeLanguage = LanguagesUtil.getLanguageName(langCode);
      this.activeLanguageCode = langCode;

      this.language$.next(langCode);
    }
  }

  public getLanguages(): Observable<BrmSettingsLanguageResponse> {
    return this.rest.getRequest(`${this.appControl.apiUrl}/settings/languages`, {});
  }

  public setSupportedLanguages(langs: string[]): void {
    this.languages = langs;

    this.checkActiveStillSupported();
  }

  public setDefaultLanguage(lang: string): void {
    this.defaultLanguage = lang;

    this.checkActiveStillSupported();
  }

  public getDefaultLanguage(): string {
    return this.defaultLanguage;
  }

  private checkActiveStillSupported(): void {
    if (this.languages.indexOf(this.activeLanguageCode) === -1) {
      this.changeLanguage(this.defaultLanguage);
    }
  }

  /***
   * Code takes a fieldname and a form group and creates the language formcontrol for the field in the provided formgroup
   * @param formGroup - FormGroup to generate the language groups
   * @param fieldName - Field name to create
   * @param dataObject - Data object to grab language_config / field data from
   * @param validators - Validators to apply to the newly created formcontrols
   */
  public createFormControlForField(formGroup: UntypedFormGroup, fieldName: string, dataObject?: object, validators?: ValidatorFn[], defaultLanguageValidators?: ValidatorFn[]): UntypedFormGroup {
    this.languages.forEach((language: string) => {
      let languageFormGroup: UntypedFormGroup;

      if (!formGroup.controls.hasOwnProperty(language)) {
        languageFormGroup = new UntypedFormGroup({});
        formGroup.registerControl(language, languageFormGroup);
      } else {
        languageFormGroup = <UntypedFormGroup>formGroup.controls[language];
      }

      if (languageFormGroup) {
        let fieldValue = null;

        if (dataObject && dataObject.hasOwnProperty('language_config')) {
          if (dataObject['language_config'].hasOwnProperty(language) && dataObject['language_config'][language].hasOwnProperty(fieldName)) {
            const valueInLang = dataObject['language_config'][language][fieldName] || '';

            if (valueInLang.trim().length > 0) {
              fieldValue = valueInLang;
            } else {
              fieldValue = null;
            }
          }
        }

        if (fieldValue == null && dataObject) {
          if (dataObject.hasOwnProperty(fieldName) && this.defaultLanguage === language) {
            fieldValue = dataObject[fieldName] || '';
          }
        }

        let controlValidators = null;

        // If we're provided with defaultLanguageValidators, we set the default language validators to those
        if (language === this.defaultLanguage && defaultLanguageValidators != null) {
          controlValidators = defaultLanguageValidators;
        } else {
          controlValidators = validators;
        }

        const fieldControl = new UntypedFormControl(fieldValue, controlValidators);
        languageFormGroup.registerControl(fieldName, fieldControl);
        fieldControl.updateValueAndValidity();
      }

    });

    return formGroup;
  }
  public readyLanguageConfigDataForApi(language_config: object, original: object): object {
    const languages = Object.keys(language_config);

    languages.forEach((language: string) => {
      const languageData = language_config[language];
      const fields = Object.keys(languageData);

      fields.forEach((field: string) => {
        const fieldData = languageData[field];
        const defaultLanguageFieldData = language_config[this.defaultLanguage][field];

        let originalData = null;

        if (original && typeof original === 'object' && original.hasOwnProperty(language) && original[language][field]) {
          originalData = original[language][field];
        }

        if (this.defaultLanguage === language) {
          // Default language

        } else {
          if (fieldData == null || fieldData === '') {
            languageData[field] = defaultLanguageFieldData;
          } else if (originalData !== fieldData) {

          } else if (defaultLanguageFieldData === fieldData) {
            // Default text matches the original default language text - therefore keep it blank
            languageData[field] = null;
          }
        }


      });
    });

    return language_config;
  }
}
