import {Component, Inject, OnInit, ViewEncapsulation} from '@angular/core';
import {Observable, of} from 'rxjs';
import {DomSanitizer, SafeHtml} from '@angular/platform-browser';
import {RestRequestsService} from '../../../../core/services/rest-requests.service';
import {AppControlService} from '../../../../core/services/app-control.service';
import {catchError, map, shareReplay} from 'rxjs/operators';
import {FreshSalesDialogData} from './freshsales-dialog-data';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';

@Component({
  selector: 'app-freshsales-dialog',
  templateUrl: './freshsales-dialog.component.html',
  styleUrls: ['./freshsales-dialog.component.scss']
})
export class FreshSalesDialogComponent implements OnInit {

  public freshDeskLoader$: Observable<SafeHtml>;
  public error: any;

  constructor(private rest: RestRequestsService, private _sanitiser: DomSanitizer,
              private appControl: AppControlService,
              public dialogRef: MatDialogRef<FreshSalesDialogComponent>,
              @Inject(MAT_DIALOG_DATA) public data: FreshSalesDialogData) {
  }

  ngOnInit() {
    if (this.data.articleId != null) {
      this.loadArticle();
    } else {
      this.error = 'no-id';
    }
  }

  private loadArticle(): void {
    this.freshDeskLoader$ = this.rest.getRequest(this.appControl.baseAddress + 'helparticles/' + this.data.articleId,
      {})
      .pipe(
        shareReplay(1),
        map(value => {
          return this._sanitiser.bypassSecurityTrustHtml(value['description']) || '';
        }),
        catchError((err: any, caught: Observable<SafeHtml>) => {
          this.error = err;
          return of(null);
        })
      );
  }

  public onCancelClick(): void {
    this.dialogRef.close(false);
  }

}
