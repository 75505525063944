import {
  HttpErrorResponse,
  HttpHandler,
  HttpInterceptor,
  HttpRequest
} from '@angular/common/http';
import {catchError, switchMap} from 'rxjs/operators';
import {Router} from '@angular/router';
import {Injectable} from '@angular/core';
import {from, Observable, of, throwError} from 'rxjs';
import {NotificationsService} from '../services/notifications/notifications.service';
import {FirebaseAuthService} from '../services/firebase-auth.service';
import {ERROR_CODES_TO_IGNORE} from './error-codes-to-ignore';
import {AppControlService} from '../services/app-control.service';
import {BrmLoginRedirectCodes} from '../brm2/data/brm-login-redirect-codes';
import {BrmErrors} from '../brm2/data/errors/brm-errors';
import {BrmRestDataService} from '../brm2/services/rest/brm-rest-data.service';
import {BrmGenericError} from '../brm2/api/brm-generic-error';
import {AngularFireAuth} from '@angular/fire/compat/auth';
import {ServerRestErrorLogsService} from '../services/server-rest-error-logs/server-rest-error-logs.service';


@Injectable({
  providedIn: 'root'
})
export class AuthZeroInterceptor implements HttpInterceptor {

  constructor(private notify: NotificationsService, private afAuth: AngularFireAuth,
              private fbAuth: FirebaseAuthService, private router: Router, private restData: BrmRestDataService,
              private appControl: AppControlService, private serverRestErrorLogs: ServerRestErrorLogsService) {
  }

  // intercept request and add token
  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<any> {
    // We store the user in our firebaseauth service, we check if a user has been set
    // as this would indicate a user has logged in.
    if (this.fbAuth.getUser() != null) {

      const headers = this.restData.getHeaders();

      /*headers['X-BRM-Application'] = 'brm2';

      // If the user has a session, we send the API version with each call
      if (this.brm.session != null) {
        headers['X-BRM-API-Version'] = this.brm.session.session.server_version;
      }

      // If we've been passed a migration token, we need to set this in the next request
      if (this.brm.migrationToken != null) {
        // headers.append('X-BRM-Migration-Token', this.brm.migrationToken);
        headers['X-BRM-Migration-Token'] = this.brm.migrationToken;
      }

      if (this.brm.session != null) {
        headers['X-BRM-Session-Token'] = this.brm.session.session.token;
      }*/

      // First, we want to get the IdToken, firebase's service automatically handles refreshing
      // the token if it expires, therefore we don't need to worry about manually refreshing
      return from(this.fbAuth.getUser().getIdToken()).pipe(
        // we then switch the observable to our actual request
        switchMap(response => {

          // response is the value returned from the previous observable, in this case, it's our
          // access token
          headers['Authorization'] = 'Bearer ' + response;

          // add authorisation code to our request
          const requestClone = request.clone({
            setHeaders: headers
          });

          // console.log('-> ' + requestClone.url);
          // console.log(requestClone);

          // tell the interceptor to handle our request
          return next.handle(requestClone);
        }),
        catchError((err: any) => {
          console.log('Error: ', err);

          // catch any errors
          if (err instanceof HttpErrorResponse) {
            if (err.status === 418 && err.error.hasOwnProperty('message') && err.error.hasOwnProperty('code')) {
              // Client out of date message
              this.onClientOutOfDate(err);

              return throwError(err);
            } else if (err.status === 400 && err.error.hasOwnProperty('message') && err.error.hasOwnProperty('code')) {

              console.log('Error: ', err);

              // Store error message
              this.serverRestErrorLogs.addServerError(err.error, err.url);

              // We now have a list of error codes to ignore rather than just one
              if (ERROR_CODES_TO_IGNORE.indexOf(err.error['code']) === -1) {
                // Check the data assigned to the error response, if it has 'message' and 'code' assume from BRM
                this.notify.addFailNotification(err.error['message'] + ' (' + err.error['code'] + ')');
              }

              return throwError(err);
            } else if (err.status === 403) {
              // if 403, we know the user isn't logged in
              if (err.error != null && err.error.hasOwnProperty('code')) {
                const errorCode = (<string>err.error['code']).toLowerCase();

                if (err.error['code'] === BrmErrors.ERROR_SHOP_SUSPENDED) {
                  const error = (<BrmGenericError>err.error).message;

                  this.appControl.suspendedShopName = (err.error as BrmGenericError).resource_id;

                  // If the error message brings back sec014, it means the store is suspended
                  this.fbAuth.logout().then(
                    fullfilled => {
                      // redirect user to login
                      this.router.navigate(['/', 'login', 'suspended']);

                      // end
                      return of(null);
                    }
                  );
                } else if (BrmLoginRedirectCodes.LOGIN_ISSUE_REDIRECTS.indexOf(errorCode) >= 0) {
                  // Any error codes we define as redirect codes, go to the issue page
                  this.fbAuth.loginError = err.error;

                  this.router.navigate(['/', 'login', 'issue'], {
                    queryParams: {
                      code: errorCode
                    }
                  }).then();
                  return of(null);
                } else if (errorCode === BrmErrors.ERROR_NOT_IN_SHOP_YOU_THINK) {
                  // If sec003 (Not in the shop you think you are, just reload the app)
                  this.notify.addWarningNotification(`You're not in the shop you think you are, reloading in 3 seconds...`);

                  setTimeout(() => {
                    this.appControl.reloadApp();
                  }, 3000);

                  return of(null);
                }
              } else {

                if (err.error.hasOwnProperty('message') && err.error.hasOwnProperty('code')) {
                  this.notify.addFailNotification('Access denied - ' + err.error['message'] + '  (' + err.error['code'] + ')');
                }

                this.fbAuth.logout().then(
                  fullfilled => {

                    setTimeout(() => {
                      // Force the entire app to reload - force clear all caches
                      this.appControl.reloadApp();
                    }, 2000);

                    // end
                    return of(null);
                  }
                );
              }
            } else if (err.error.hasOwnProperty('message') && err.error.hasOwnProperty('code')) {

              console.log('Error code: ' + err.error['code'] + ' = ' + ERROR_CODES_TO_IGNORE.indexOf(err.error['code']));
              // We now have a list of error codes to ignore rather than just one
              if (ERROR_CODES_TO_IGNORE.indexOf(err.error['code']) === -1) {
                // throw a notify error to the user
                this.notify.addFailNotification(err.error['message'] + ' (' + err.error['code'] + ')');
              }

              // pass errors down so the lower level components can handle the error
              return throwError(err);
            }
          } else {
            // Non-HTTP related error, output to console.
            this.notify.addFailNotification('An error occurred, please check console for details');
            console.log(err);
          }

          return throwError(err);
        })
      );

    } else {
      console.log('L -> ' + request.url);
      // If the user isn't logged in, we need to still handle the requests
      // we just send the request
      return next.handle(request).pipe(
        catchError((err: any) => {
          if (err instanceof HttpErrorResponse) {
            // if 403, we know the user isn't logged in
            if (err.status === 403) {
              // redirect user to login
              this.router.navigate(['/', 'login']);

              if (err.error.hasOwnProperty('message') && err.error.hasOwnProperty('code')) {
                this.notify.addFailNotification('Access denied (' + err.error['code'] + ')');
              }

              // end
              return of(null);
            } else if (err.error.hasOwnProperty('message') && err.error.hasOwnProperty('code')) {
              this.notify.addFailNotification(err.error['message'] + ' (' + err.error['code'] + ')');

              return throwError(err);
            }
          } else {
            // Non-HTTP related error, output to console.
            this.notify.addFailNotification('An error occurred, please check console for details');
            console.log(err);
          }
        })
      );

    }

  }

  private onClientOutOfDate(err): void {
    this.notify.addWarningNotification('Client out of date! Refreshing in 10s');

    setTimeout(() => {
      window.location.reload();
    }, 10000);
  }

}
