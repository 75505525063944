import {ChangeDetectorRef, OnDestroy, Pipe, PipeTransform} from '@angular/core';
import {DateTime} from 'luxon';
import {Subscription} from 'rxjs';
import {BrmDatesAndTimeService} from '../brm-dates-and-time.service';
import {BrmDateAndTimeFormats} from '../../../data/brm-date-and-time-formats';
import {BrmSessionService} from '../../../brm-session.service';

@Pipe({
  name: 'brmLuxonTime'
})
export class BrmLuxonTimePipe implements PipeTransform, OnDestroy {

  private timeSubscription: Subscription;
  private format: string = 'UK';

  constructor(private brmDatesAndTime: BrmDatesAndTimeService, private session: BrmSessionService, private _ref: ChangeDetectorRef) {
    this.initialiseDateChange();
  }

  ngOnDestroy(): void {
    this.timeSubscription.unsubscribe();
  }

  private initialiseDateChange(): void {
    this.timeSubscription = this.brmDatesAndTime.timeFormat$.subscribe(
      (next: string) => {
        this.format = next;
        this._ref.markForCheck();
      }
    );
  }


  /***
   * @param value - Value to convert, can be millis or ISO time format e.g. 10:59
   * @param fullTime - Show full time, instead of partial format
   * @param onlyNow - Ignore the value and show now value instead
   * @param args
   */
  transform(value: number | string | Date, fullTime = false, onlyNow: boolean = false, setTimezone: boolean = true, format?: string, ...args): any {
    let dateTime;

    if (value == null && !onlyNow) {
      return;
    }

    try {

      if (value instanceof Date) {
        dateTime = DateTime.fromJSDate(value);
      } else if (value === 'now' || onlyNow) {
        dateTime = DateTime.utc();
      } else if (typeof value === 'number') {
        dateTime = DateTime.fromMillis(value);
      } else if (typeof value === 'string') {
        const match = value.trim().match(BrmDateAndTimeFormats.TIME_ISO_REGEX_PATTERN);
        if (match.length === 3) {
          dateTime = DateTime.utc();
          dateTime = dateTime.set({hour: +match[1], minute: +match[2]});
        } else {
          dateTime = DateTime.fromISO(value);
        }
      }

      if (setTimezone) {
        dateTime = dateTime.setZone(this.session.session.shop_settings.shop_time_zone);
      }

      if (fullTime) {
        return dateTime.toFormat(BrmDateAndTimeFormats.TIME_FULL_FORMAT_OBJ[this.format]);
      }

      if (format) {
        return dateTime.toFormat(format);
      }

      return dateTime.toFormat(BrmDateAndTimeFormats.TIME_FORMAT_OBJ[this.format]);

    } catch (error) {
      console.error(`Failed to parse value '${value}'`);
      console.error(error);
      return value;
    }
  }

}
