import {HostListener, Injectable} from '@angular/core';

@Injectable()
export abstract class FormCanDeactivateAbstractComponent {

  /***
   * Abstract class needs to be extended when a route / component has formUnloadGuard.
   * When the user attempts to leave the route, canDeactivated is called (which is defined by the class which extended this)
   * If it's true, then they're allowed to leave
   * If it's false, they're prompted if they want leave or not
   */

  abstract canDeactivate(): boolean;

  @HostListener('window:beforeunload', ['$event'])
  public onPageExit(event: any) {
    if (!this.canDeactivate()) {
      event.returnValue = 'You have unsaved changes, are you sure you want to leave this page?';

      return 'You have unsaved changes, are you sure you want to leave this page?';
    }
  }
}
