import {BrmCache} from './brm-cache';
import {Observable} from 'rxjs';
import {map, tap} from 'rxjs/operators';
import {RestRequestsService} from '../../services/rest-requests.service';
import {AppControlService} from '../../services/app-control.service';
import {BrmPaymentProcessors} from '../api/payment-processors/brm-payment-processors';

export class BrmPaymentProcessorsCache extends BrmCache<BrmPaymentProcessors> {

  private rest: RestRequestsService;
  private appControl: AppControlService;

  constructor(_caches, _appControl, _rest) {
    super(_caches, 'paymentprocessors');

    this.appControl = _appControl;
    this.rest = _rest;
  }

  protected loadData(): Observable<BrmPaymentProcessors> {
    return this.rest.getRequest(this.appControl.apiUrl + '/paymentprocessors', {}).pipe(
      map((value: BrmPaymentProcessors) => {
        return value;
      })
    );
  }

  public updatePaymentProcessors(data: object): Observable<BrmPaymentProcessors> {
    const api = this.appControl.apiUrl + '/paymentprocessors';

    return this.rest.postRequest(api, JSON.stringify(data)).pipe(
      map((value: BrmPaymentProcessors) => {
        return value;
      }),
      tap({
        next: value => {
          this.setData(value);
        }
      })
    );
  }

  protected onLoad(): void {
  }
}
