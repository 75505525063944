import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { LocationTagComponent } from './location-tag.component';
import { MatChipsModule } from '@angular/material/chips';

@NgModule({
  declarations: [LocationTagComponent],
  imports: [CommonModule, MatProgressSpinnerModule, MatChipsModule],
  exports: [LocationTagComponent],
})
export class LocationTagModule {}
