import {ChangeDetectorRef, OnDestroy, Pipe, PipeTransform} from '@angular/core';
import {DateTime} from 'luxon';
import {Subscription} from 'rxjs';
import {BrmDatesAndTimeService} from '../brm-dates-and-time.service';
import {BrmDateAndTimeFormats} from '../../../data/brm-date-and-time-formats';
import {BrmSessionService} from '../../../brm-session.service';

@Pipe({
  name: 'brmLuxonDate'
})
export class BrmLuxonDatePipe implements PipeTransform, OnDestroy {

  private dateSubscription: Subscription;
  private format: string = this.brmDatesAndTime.dateFormatId;

  constructor(private brmDatesAndTime: BrmDatesAndTimeService, private session: BrmSessionService, private _ref: ChangeDetectorRef) {
    this.initialiseDateChange();
  }

  ngOnDestroy(): void {
    this.dateSubscription.unsubscribe();
  }

  private initialiseDateChange(): void {
    this.dateSubscription = this.brmDatesAndTime.dateFormat$.subscribe(
      (next: string) => {
        this.format = next;
        this._ref.markForCheck();
      }
    );
  }


  /***
   * @param value - Value to convert, can be ISO Date format or millis
   * @param fullTime - Show full time, instead of partial format
   * @param onlyNow - Ignore the value and show now value instead
   * @param args
   */
  transform(value: number | string | Date | DateTime, setTimezone: boolean = true, format?: string): any {
    let dateTime;

    try {

      if (value instanceof Date) {
        dateTime = DateTime.fromJSDate(value);
      } else if (typeof value === 'string') {
        value = value.trim();

        if (!isNaN(+value)) {
          dateTime = DateTime.fromMillis(Number(value));
        } else {
          dateTime = DateTime.fromISO(value);

          if (dateTime.invalid?.reason === 'unparsable') {
            dateTime = DateTime.fromFormat(value, BrmDateAndTimeFormats.DATE_LUXON_FORMAT_OBJ[this.format]);
          }
        }

      } else if (typeof value === 'number') {
        dateTime = DateTime.fromMillis(value);
      } else if (value.isLuxonDateTime) {
        dateTime = value;
      }

      if (setTimezone) {
        dateTime = dateTime.setZone(this.session.session.shop_settings.shop_time_zone);
      }

      if (format) {
        if (format === 'ISO') {
          return dateTime.toISODate();
        }

        if (format === 'JS') {
          return dateTime.toJSDate();
        }

        if (format === 'LUXON') {
          return dateTime;
        }

        return dateTime.toFormat(format);
      }

      return dateTime.toFormat(BrmDateAndTimeFormats.DATE_LUXON_FORMAT_OBJ[this.format]);

    } catch (error) {
      return value;
    }
  }

}
