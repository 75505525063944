import {BehaviorSubject, Observable, of} from 'rxjs';
import {map, shareReplay, tap} from 'rxjs/operators';
import {BrmCacheService} from '../brm-cache.service';

export abstract class BrmCache<T> {

  protected cachedData: T = null;

  // variable which tells the cache to reload on next getData call
  private markedForReload = false;

  private initialLoader$: Observable<T>;

  private onChange: BehaviorSubject<T> = new BehaviorSubject<T>(null);
  public onChange$: Observable<T> = this.onChange.asObservable();

  constructor(public caches: BrmCacheService, public name: string, public timeout: number = 1000 * 60 * 15) {

  }

  protected abstract loadData(parameters?: object): Observable<T>;

  protected abstract onLoad(): void;

  protected filterData?(data: T, parameters?: object): T;

  public getData(forceReload?: boolean, parameters?: object): Observable<T> {
    if (this.cachedData != null && (forceReload == null || forceReload === false) && !this.markedForReload) {
      if (parameters && this.filterData) {
        return of(this.filterData(this.cachedData, parameters));
      }
      return of(this.cachedData);
    }

    if (this.initialLoader$ == null || this.markedForReload || forceReload) {
      if (!parameters) {
        parameters = {};
      } else {
        // If parameters were passed we may end up with an incomplete cache, so mark for reload next time to ensure we get the full data
        this.markForReload();
      }

      this.initialLoader$ = this.loadData(parameters).pipe(
        map((value: T) => {
          return value;
        }),
        tap({
          next: value => {
            this.setData(value);
            // add callback for data being set
            this.onLoad();

            this.pushChanges();
          }
        }),
        shareReplay(1)
      );
    }

    return this.initialLoader$;
  }

  public pushChanges(): void {
    this.onChange.next(this.cachedData);
  }

  protected setData(data: T): void {
    this.cachedData = data;
  }

  public getRawData(): T {
    return this.cachedData;
  }

  public hasDataLoaded(): boolean {
    return this.cachedData != null;
  }

  // marks the cache for reload, meaning the next call to getData will load the API not the cache
  public markForReload(): void {
    console.log('Marking: ', this.name, ' - marked: ', this.markedForReload);
    this.markedForReload = true;
  }

  public clearCache(): void {
    this.cachedData = null;
  }
}
