import {Component, Input, OnInit, ViewEncapsulation} from '@angular/core';

@Component({
  selector: 'app-freshsales-content-wrap',
  templateUrl: './freshsales-content-wrap.component.html',
  styleUrls: ['./freshsales-content-wrap.component.scss'],
  encapsulation: ViewEncapsulation.ShadowDom
})
export class FreshSalesContentWrapComponent implements OnInit {

  @Input() data: string;

  constructor() { }

  ngOnInit() {
  }

}
